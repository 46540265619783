.space-y-0 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0px * var(--space-y-reverse))
}

.space-x-0 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse))
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--space-y-reverse))
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--space-y-reverse))
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse))
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--space-y-reverse))
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--space-y-reverse))
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2rem * var(--space-y-reverse))
}

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2rem * var(--space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
}

.space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--space-y-reverse))
}

.space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(3rem * var(--space-y-reverse))
}

.space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3rem * var(--space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
}

.space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(4rem * var(--space-y-reverse))
}

.space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(4rem * var(--space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
}

.space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(5rem * var(--space-y-reverse))
}

.space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(5rem * var(--space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(6rem * var(--space-y-reverse))
}

.space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(6rem * var(--space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
}

.space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(8rem * var(--space-y-reverse))
}

.space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(8rem * var(--space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
}

.space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(10rem * var(--space-y-reverse))
}

.space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(10rem * var(--space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
}

.space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(12rem * var(--space-y-reverse))
}

.space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(12rem * var(--space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
}

.space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(14rem * var(--space-y-reverse))
}

.space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(14rem * var(--space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
}

.space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(16rem * var(--space-y-reverse))
}

.space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16rem * var(--space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
}

.space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1px * var(--space-y-reverse))
}

.space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1px * var(--space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
}

.-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--space-y-reverse))
}

.-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--space-y-reverse))
}

.-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--space-y-reverse))
}

.-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1rem * var(--space-y-reverse))
}

.-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1rem * var(--space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--space-y-reverse))
}

.-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--space-y-reverse))
}

.-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2rem * var(--space-y-reverse))
}

.-space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2rem * var(--space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--space-y-reverse))
}

.-space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-3rem * var(--space-y-reverse))
}

.-space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3rem * var(--space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-4rem * var(--space-y-reverse))
}

.-space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-4rem * var(--space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-5rem * var(--space-y-reverse))
}

.-space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-5rem * var(--space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-6rem * var(--space-y-reverse))
}

.-space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-6rem * var(--space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-8rem * var(--space-y-reverse))
}

.-space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-8rem * var(--space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-10rem * var(--space-y-reverse))
}

.-space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-10rem * var(--space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-12rem * var(--space-y-reverse))
}

.-space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-12rem * var(--space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-14rem * var(--space-y-reverse))
}

.-space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-14rem * var(--space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-16rem * var(--space-y-reverse))
}

.-space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-16rem * var(--space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1px * var(--space-y-reverse))
}

.-space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1px * var(--space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
}

.space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1
}

.space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1
}

.divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(0px * var(--divide-y-reverse))
}

.divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(0px * var(--divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(2px * var(--divide-y-reverse))
}

.divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(2px * var(--divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(4px * var(--divide-y-reverse))
}

.divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(4px * var(--divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(8px * var(--divide-y-reverse))
}

.divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(8px * var(--divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse))
}

.divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(1px * var(--divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1
}

.divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1
}

.divide-transparent > :not(template) ~ :not(template) {
  border-color: transparent
}

.divide-current > :not(template) ~ :not(template) {
  border-color: currentColor
}

.divide-black > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--divide-opacity))
}

.divide-white > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--divide-opacity))
}

.divide-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--divide-opacity))
}

.divide-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--divide-opacity))
}

.divide-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--divide-opacity))
}

.divide-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--divide-opacity))
}

.divide-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--divide-opacity))
}

.divide-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--divide-opacity))
}

.divide-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--divide-opacity))
}

.divide-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--divide-opacity))
}

.divide-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--divide-opacity))
}

.divide-red-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--divide-opacity))
}

.divide-red-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--divide-opacity))
}

.divide-red-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--divide-opacity))
}

.divide-red-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--divide-opacity))
}

.divide-red-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--divide-opacity))
}

.divide-red-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--divide-opacity))
}

.divide-red-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--divide-opacity))
}

.divide-red-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--divide-opacity))
}

.divide-red-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--divide-opacity))
}

.divide-orange-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--divide-opacity))
}

.divide-orange-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--divide-opacity))
}

.divide-orange-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--divide-opacity))
}

.divide-orange-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--divide-opacity))
}

.divide-orange-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--divide-opacity))
}

.divide-orange-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--divide-opacity))
}

.divide-orange-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--divide-opacity))
}

.divide-orange-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--divide-opacity))
}

.divide-orange-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--divide-opacity))
}

.divide-yellow-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--divide-opacity))
}

.divide-yellow-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--divide-opacity))
}

.divide-yellow-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--divide-opacity))
}

.divide-yellow-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--divide-opacity))
}

.divide-yellow-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--divide-opacity))
}

.divide-yellow-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--divide-opacity))
}

.divide-yellow-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--divide-opacity))
}

.divide-yellow-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--divide-opacity))
}

.divide-yellow-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--divide-opacity))
}

.divide-green-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--divide-opacity))
}

.divide-green-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--divide-opacity))
}

.divide-green-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--divide-opacity))
}

.divide-green-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--divide-opacity))
}

.divide-green-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--divide-opacity))
}

.divide-green-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--divide-opacity))
}

.divide-green-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--divide-opacity))
}

.divide-green-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--divide-opacity))
}

.divide-green-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--divide-opacity))
}

.divide-teal-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--divide-opacity))
}

.divide-teal-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--divide-opacity))
}

.divide-teal-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--divide-opacity))
}

.divide-teal-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--divide-opacity))
}

.divide-teal-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--divide-opacity))
}

.divide-teal-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--divide-opacity))
}

.divide-teal-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--divide-opacity))
}

.divide-teal-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--divide-opacity))
}

.divide-teal-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--divide-opacity))
}

.divide-blue-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--divide-opacity))
}

.divide-blue-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--divide-opacity))
}

.divide-blue-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--divide-opacity))
}

.divide-blue-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--divide-opacity))
}

.divide-blue-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--divide-opacity))
}

.divide-blue-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--divide-opacity))
}

.divide-blue-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--divide-opacity))
}

.divide-blue-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--divide-opacity))
}

.divide-blue-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--divide-opacity))
}

.divide-indigo-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--divide-opacity))
}

.divide-indigo-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--divide-opacity))
}

.divide-indigo-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--divide-opacity))
}

.divide-indigo-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--divide-opacity))
}

.divide-indigo-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--divide-opacity))
}

.divide-indigo-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--divide-opacity))
}

.divide-indigo-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--divide-opacity))
}

.divide-indigo-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--divide-opacity))
}

.divide-indigo-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--divide-opacity))
}

.divide-purple-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--divide-opacity))
}

.divide-purple-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--divide-opacity))
}

.divide-purple-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--divide-opacity))
}

.divide-purple-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--divide-opacity))
}

.divide-purple-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--divide-opacity))
}

.divide-purple-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--divide-opacity))
}

.divide-purple-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--divide-opacity))
}

.divide-purple-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--divide-opacity))
}

.divide-purple-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--divide-opacity))
}

.divide-pink-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--divide-opacity))
}

.divide-pink-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--divide-opacity))
}

.divide-pink-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--divide-opacity))
}

.divide-pink-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--divide-opacity))
}

.divide-pink-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--divide-opacity))
}

.divide-pink-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--divide-opacity))
}

.divide-pink-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--divide-opacity))
}

.divide-pink-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--divide-opacity))
}

.divide-pink-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--divide-opacity))
}

.divide-solid > :not(template) ~ :not(template) {
  border-style: solid
}

.divide-dashed > :not(template) ~ :not(template) {
  border-style: dashed
}

.divide-dotted > :not(template) ~ :not(template) {
  border-style: dotted
}

.divide-double > :not(template) ~ :not(template) {
  border-style: double
}

.divide-none > :not(template) ~ :not(template) {
  border-style: none
}

.divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0
}

.divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: 0.25
}

.divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: 0.5
}

.divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: 0.75
}

.divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.bg-fixed {
  background-attachment: fixed
}

.bg-local {
  background-attachment: local
}

.bg-scroll {
  background-attachment: scroll
}

.bg-clip-border {
  background-clip: border-box
}

.bg-clip-padding {
  background-clip: padding-box
}

.bg-clip-content {
  background-clip: content-box
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text
}

.bg-transparent {
  background-color: transparent
}

.bg-current {
  background-color: currentColor
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.bg-gray-500 {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity))
}

.bg-gray-600 {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity))
}

.bg-gray-700 {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity))
}

.bg-gray-800 {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity))
}

.bg-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity))
}

.bg-red-100 {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity))
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.bg-red-300 {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.bg-red-400 {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity))
}

.bg-red-500 {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity))
}

.bg-red-600 {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity))
}

.bg-red-700 {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity))
}

.bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity))
}

.bg-red-900 {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity))
}

.bg-orange-100 {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity))
}

.bg-orange-200 {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity))
}

.bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.bg-orange-400 {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity))
}

.bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity))
}

.bg-orange-600 {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity))
}

.bg-orange-700 {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity))
}

.bg-orange-800 {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity))
}

.bg-orange-900 {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity))
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity))
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity))
}

.bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity))
}

.bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity))
}

.bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity))
}

.bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity))
}

.bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity))
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity))
}

.bg-green-200 {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity))
}

.bg-green-300 {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity))
}

.bg-green-400 {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity))
}

.bg-green-500 {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity))
}

.bg-green-600 {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity))
}

.bg-green-700 {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity))
}

.bg-green-800 {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity))
}

.bg-green-900 {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity))
}

.bg-teal-100 {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity))
}

.bg-teal-200 {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity))
}

.bg-teal-300 {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity))
}

.bg-teal-400 {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity))
}

.bg-teal-500 {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity))
}

.bg-teal-600 {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity))
}

.bg-teal-700 {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity))
}

.bg-teal-800 {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity))
}

.bg-teal-900 {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity))
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.bg-blue-200 {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.bg-blue-300 {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.bg-blue-400 {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity))
}

.bg-blue-500 {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity))
}

.bg-blue-600 {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.bg-blue-800 {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity))
}

.bg-blue-900 {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity))
}

.bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity))
}

.bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity))
}

.bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity))
}

.bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity))
}

.bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity))
}

.bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity))
}

.bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity))
}

.bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity))
}

.bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity))
}

.bg-purple-100 {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity))
}

.bg-purple-200 {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity))
}

.bg-purple-300 {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity))
}

.bg-purple-400 {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity))
}

.bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity))
}

.bg-purple-600 {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity))
}

.bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity))
}

.bg-purple-800 {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity))
}

.bg-purple-900 {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity))
}

.bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity))
}

.bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity))
}

.bg-pink-300 {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity))
}

.bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity))
}

.bg-pink-500 {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity))
}

.bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity))
}

.bg-pink-700 {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity))
}

.bg-pink-800 {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity))
}

.bg-pink-900 {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-current:hover {
  background-color: currentColor
}

.hover\:bg-black:hover {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.hover\:bg-white:hover {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.hover\:bg-gray-100:hover {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.hover\:bg-gray-300:hover {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.hover\:bg-gray-400:hover {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.hover\:bg-gray-500:hover {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity))
}

.hover\:bg-gray-600:hover {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity))
}

.hover\:bg-gray-700:hover {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity))
}

.hover\:bg-gray-800:hover {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity))
}

.hover\:bg-gray-900:hover {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity))
}

.hover\:bg-red-100:hover {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity))
}

.hover\:bg-red-200:hover {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.hover\:bg-red-300:hover {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.hover\:bg-red-400:hover {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity))
}

.hover\:bg-red-500:hover {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity))
}

.hover\:bg-red-600:hover {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity))
}

.hover\:bg-red-700:hover {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity))
}

.hover\:bg-red-800:hover {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity))
}

.hover\:bg-red-900:hover {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity))
}

.hover\:bg-orange-100:hover {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity))
}

.hover\:bg-orange-200:hover {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity))
}

.hover\:bg-orange-300:hover {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.hover\:bg-orange-400:hover {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity))
}

.hover\:bg-orange-500:hover {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity))
}

.hover\:bg-orange-600:hover {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity))
}

.hover\:bg-orange-700:hover {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity))
}

.hover\:bg-orange-800:hover {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity))
}

.hover\:bg-orange-900:hover {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity))
}

.hover\:bg-yellow-100:hover {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity))
}

.hover\:bg-yellow-200:hover {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.hover\:bg-yellow-300:hover {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.hover\:bg-yellow-400:hover {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity))
}

.hover\:bg-yellow-500:hover {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity))
}

.hover\:bg-yellow-600:hover {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity))
}

.hover\:bg-yellow-700:hover {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity))
}

.hover\:bg-yellow-800:hover {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity))
}

.hover\:bg-yellow-900:hover {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity))
}

.hover\:bg-green-100:hover {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity))
}

.hover\:bg-green-200:hover {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity))
}

.hover\:bg-green-300:hover {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity))
}

.hover\:bg-green-400:hover {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity))
}

.hover\:bg-green-500:hover {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity))
}

.hover\:bg-green-600:hover {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity))
}

.hover\:bg-green-700:hover {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity))
}

.hover\:bg-green-800:hover {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity))
}

.hover\:bg-green-900:hover {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity))
}

.hover\:bg-teal-100:hover {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity))
}

.hover\:bg-teal-200:hover {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity))
}

.hover\:bg-teal-300:hover {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity))
}

.hover\:bg-teal-400:hover {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity))
}

.hover\:bg-teal-500:hover {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity))
}

.hover\:bg-teal-600:hover {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity))
}

.hover\:bg-teal-700:hover {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity))
}

.hover\:bg-teal-800:hover {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity))
}

.hover\:bg-teal-900:hover {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity))
}

.hover\:bg-blue-100:hover {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.hover\:bg-blue-200:hover {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.hover\:bg-blue-300:hover {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.hover\:bg-blue-400:hover {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity))
}

.hover\:bg-blue-500:hover {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity))
}

.hover\:bg-blue-600:hover {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.hover\:bg-blue-700:hover {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.hover\:bg-blue-800:hover {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity))
}

.hover\:bg-blue-900:hover {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity))
}

.hover\:bg-indigo-100:hover {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity))
}

.hover\:bg-indigo-200:hover {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity))
}

.hover\:bg-indigo-300:hover {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity))
}

.hover\:bg-indigo-400:hover {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity))
}

.hover\:bg-indigo-500:hover {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity))
}

.hover\:bg-indigo-600:hover {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity))
}

.hover\:bg-indigo-700:hover {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity))
}

.hover\:bg-indigo-800:hover {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity))
}

.hover\:bg-indigo-900:hover {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity))
}

.hover\:bg-purple-100:hover {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity))
}

.hover\:bg-purple-200:hover {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity))
}

.hover\:bg-purple-300:hover {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity))
}

.hover\:bg-purple-400:hover {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity))
}

.hover\:bg-purple-500:hover {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity))
}

.hover\:bg-purple-600:hover {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity))
}

.hover\:bg-purple-700:hover {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity))
}

.hover\:bg-purple-800:hover {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity))
}

.hover\:bg-purple-900:hover {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity))
}

.hover\:bg-pink-100:hover {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity))
}

.hover\:bg-pink-200:hover {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity))
}

.hover\:bg-pink-300:hover {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity))
}

.hover\:bg-pink-400:hover {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity))
}

.hover\:bg-pink-500:hover {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity))
}

.hover\:bg-pink-600:hover {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity))
}

.hover\:bg-pink-700:hover {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity))
}

.hover\:bg-pink-800:hover {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity))
}

.hover\:bg-pink-900:hover {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity))
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.focus\:bg-current:focus {
  background-color: currentColor
}

.focus\:bg-black:focus {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.focus\:bg-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.focus\:bg-gray-200:focus {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.focus\:bg-gray-300:focus {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.focus\:bg-gray-400:focus {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.focus\:bg-gray-500:focus {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity))
}

.focus\:bg-gray-600:focus {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity))
}

.focus\:bg-gray-700:focus {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity))
}

.focus\:bg-gray-800:focus {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity))
}

.focus\:bg-gray-900:focus {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity))
}

.focus\:bg-red-100:focus {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity))
}

.focus\:bg-red-200:focus {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.focus\:bg-red-300:focus {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.focus\:bg-red-400:focus {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity))
}

.focus\:bg-red-500:focus {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity))
}

.focus\:bg-red-600:focus {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity))
}

.focus\:bg-red-700:focus {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity))
}

.focus\:bg-red-800:focus {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity))
}

.focus\:bg-red-900:focus {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity))
}

.focus\:bg-orange-100:focus {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity))
}

.focus\:bg-orange-200:focus {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity))
}

.focus\:bg-orange-300:focus {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.focus\:bg-orange-400:focus {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity))
}

.focus\:bg-orange-500:focus {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity))
}

.focus\:bg-orange-600:focus {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity))
}

.focus\:bg-orange-700:focus {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity))
}

.focus\:bg-orange-800:focus {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity))
}

.focus\:bg-orange-900:focus {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity))
}

.focus\:bg-yellow-100:focus {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity))
}

.focus\:bg-yellow-200:focus {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.focus\:bg-yellow-300:focus {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.focus\:bg-yellow-400:focus {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity))
}

.focus\:bg-yellow-500:focus {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity))
}

.focus\:bg-yellow-600:focus {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity))
}

.focus\:bg-yellow-700:focus {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity))
}

.focus\:bg-yellow-800:focus {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity))
}

.focus\:bg-yellow-900:focus {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity))
}

.focus\:bg-green-100:focus {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity))
}

.focus\:bg-green-200:focus {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity))
}

.focus\:bg-green-300:focus {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity))
}

.focus\:bg-green-400:focus {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity))
}

.focus\:bg-green-500:focus {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity))
}

.focus\:bg-green-600:focus {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity))
}

.focus\:bg-green-700:focus {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity))
}

.focus\:bg-green-800:focus {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity))
}

.focus\:bg-green-900:focus {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity))
}

.focus\:bg-teal-100:focus {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity))
}

.focus\:bg-teal-200:focus {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity))
}

.focus\:bg-teal-300:focus {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity))
}

.focus\:bg-teal-400:focus {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity))
}

.focus\:bg-teal-500:focus {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity))
}

.focus\:bg-teal-600:focus {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity))
}

.focus\:bg-teal-700:focus {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity))
}

.focus\:bg-teal-800:focus {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity))
}

.focus\:bg-teal-900:focus {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity))
}

.focus\:bg-blue-100:focus {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.focus\:bg-blue-200:focus {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.focus\:bg-blue-300:focus {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.focus\:bg-blue-400:focus {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity))
}

.focus\:bg-blue-500:focus {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity))
}

.focus\:bg-blue-600:focus {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.focus\:bg-blue-700:focus {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.focus\:bg-blue-800:focus {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity))
}

.focus\:bg-blue-900:focus {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity))
}

.focus\:bg-indigo-100:focus {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity))
}

.focus\:bg-indigo-200:focus {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity))
}

.focus\:bg-indigo-300:focus {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity))
}

.focus\:bg-indigo-400:focus {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity))
}

.focus\:bg-indigo-500:focus {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity))
}

.focus\:bg-indigo-600:focus {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity))
}

.focus\:bg-indigo-700:focus {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity))
}

.focus\:bg-indigo-800:focus {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity))
}

.focus\:bg-indigo-900:focus {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity))
}

.focus\:bg-purple-100:focus {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity))
}

.focus\:bg-purple-200:focus {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity))
}

.focus\:bg-purple-300:focus {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity))
}

.focus\:bg-purple-400:focus {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity))
}

.focus\:bg-purple-500:focus {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity))
}

.focus\:bg-purple-600:focus {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity))
}

.focus\:bg-purple-700:focus {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity))
}

.focus\:bg-purple-800:focus {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity))
}

.focus\:bg-purple-900:focus {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity))
}

.focus\:bg-pink-100:focus {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity))
}

.focus\:bg-pink-200:focus {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity))
}

.focus\:bg-pink-300:focus {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity))
}

.focus\:bg-pink-400:focus {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity))
}

.focus\:bg-pink-500:focus {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity))
}

.focus\:bg-pink-600:focus {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity))
}

.focus\:bg-pink-700:focus {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity))
}

.focus\:bg-pink-800:focus {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity))
}

.focus\:bg-pink-900:focus {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity))
}

.bg-none {
  background-image: none
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops))
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops))
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops))
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops))
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops))
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops))
}

.from-transparent {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.from-current {
  --gradient-from-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-black {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.from-white {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-gray-100 {
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.from-gray-200 {
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.from-gray-300 {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.from-gray-400 {
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.from-gray-500 {
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.from-gray-600 {
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.from-gray-700 {
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.from-gray-800 {
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.from-gray-900 {
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.from-red-100 {
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.from-red-200 {
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.from-red-300 {
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.from-red-400 {
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.from-red-500 {
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.from-red-600 {
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.from-red-700 {
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.from-red-800 {
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.from-red-900 {
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.from-orange-100 {
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.from-orange-200 {
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.from-orange-300 {
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.from-orange-400 {
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.from-orange-500 {
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.from-orange-600 {
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.from-orange-700 {
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.from-orange-800 {
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.from-orange-900 {
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.from-yellow-100 {
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.from-yellow-200 {
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.from-yellow-300 {
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.from-yellow-400 {
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.from-yellow-500 {
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.from-yellow-600 {
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.from-yellow-700 {
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.from-yellow-800 {
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.from-yellow-900 {
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.from-green-100 {
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.from-green-200 {
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.from-green-300 {
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.from-green-400 {
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.from-green-500 {
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.from-green-600 {
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.from-green-700 {
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.from-green-800 {
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.from-green-900 {
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.from-teal-100 {
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.from-teal-200 {
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.from-teal-300 {
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.from-teal-400 {
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.from-teal-500 {
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.from-teal-600 {
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.from-teal-700 {
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.from-teal-800 {
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.from-teal-900 {
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.from-blue-100 {
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.from-blue-200 {
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.from-blue-300 {
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.from-blue-400 {
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.from-blue-500 {
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.from-blue-600 {
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.from-blue-700 {
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.from-blue-800 {
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.from-blue-900 {
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.from-indigo-100 {
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.from-indigo-200 {
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.from-indigo-300 {
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.from-indigo-400 {
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.from-indigo-500 {
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.from-indigo-600 {
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.from-indigo-700 {
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.from-indigo-800 {
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.from-indigo-900 {
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.from-purple-100 {
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.from-purple-200 {
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.from-purple-300 {
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.from-purple-400 {
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.from-purple-500 {
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.from-purple-600 {
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.from-purple-700 {
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.from-purple-800 {
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.from-purple-900 {
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.from-pink-100 {
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.from-pink-200 {
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.from-pink-300 {
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.from-pink-400 {
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.from-pink-500 {
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.from-pink-600 {
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.from-pink-700 {
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.from-pink-800 {
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.from-pink-900 {
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.via-transparent {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.via-current {
  --gradient-via-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-black {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.via-white {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-gray-100 {
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.via-gray-200 {
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.via-gray-300 {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.via-gray-400 {
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.via-gray-500 {
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.via-gray-600 {
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.via-gray-700 {
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.via-gray-800 {
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.via-gray-900 {
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.via-red-100 {
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.via-red-200 {
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.via-red-300 {
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.via-red-400 {
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.via-red-500 {
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.via-red-600 {
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.via-red-700 {
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.via-red-800 {
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.via-red-900 {
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.via-orange-100 {
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.via-orange-200 {
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.via-orange-300 {
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.via-orange-400 {
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.via-orange-500 {
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.via-orange-600 {
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.via-orange-700 {
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.via-orange-800 {
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.via-orange-900 {
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.via-yellow-100 {
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.via-yellow-200 {
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.via-yellow-300 {
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.via-yellow-400 {
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.via-yellow-500 {
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.via-yellow-600 {
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.via-yellow-700 {
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.via-yellow-800 {
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.via-yellow-900 {
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.via-green-100 {
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.via-green-200 {
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.via-green-300 {
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.via-green-400 {
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.via-green-500 {
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.via-green-600 {
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.via-green-700 {
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.via-green-800 {
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.via-green-900 {
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.via-teal-100 {
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.via-teal-200 {
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.via-teal-300 {
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.via-teal-400 {
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.via-teal-500 {
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.via-teal-600 {
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.via-teal-700 {
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.via-teal-800 {
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.via-teal-900 {
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.via-blue-100 {
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.via-blue-200 {
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.via-blue-300 {
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.via-blue-400 {
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.via-blue-500 {
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.via-blue-600 {
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.via-blue-700 {
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.via-blue-800 {
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.via-blue-900 {
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.via-indigo-100 {
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.via-indigo-200 {
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.via-indigo-300 {
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.via-indigo-400 {
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.via-indigo-500 {
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.via-indigo-600 {
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.via-indigo-700 {
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.via-indigo-800 {
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.via-indigo-900 {
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.via-purple-100 {
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.via-purple-200 {
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.via-purple-300 {
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.via-purple-400 {
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.via-purple-500 {
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.via-purple-600 {
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.via-purple-700 {
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.via-purple-800 {
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.via-purple-900 {
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.via-pink-100 {
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.via-pink-200 {
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.via-pink-300 {
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.via-pink-400 {
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.via-pink-500 {
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.via-pink-600 {
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.via-pink-700 {
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.via-pink-800 {
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.via-pink-900 {
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.to-transparent {
  --gradient-to-color: transparent
}

.to-current {
  --gradient-to-color: currentColor
}

.to-black {
  --gradient-to-color: #000
}

.to-white {
  --gradient-to-color: #fff
}

.to-gray-100 {
  --gradient-to-color: #f7fafc
}

.to-gray-200 {
  --gradient-to-color: #edf2f7
}

.to-gray-300 {
  --gradient-to-color: #e2e8f0
}

.to-gray-400 {
  --gradient-to-color: #cbd5e0
}

.to-gray-500 {
  --gradient-to-color: #a0aec0
}

.to-gray-600 {
  --gradient-to-color: #718096
}

.to-gray-700 {
  --gradient-to-color: #4a5568
}

.to-gray-800 {
  --gradient-to-color: #2d3748
}

.to-gray-900 {
  --gradient-to-color: #1a202c
}

.to-red-100 {
  --gradient-to-color: #fff5f5
}

.to-red-200 {
  --gradient-to-color: #fed7d7
}

.to-red-300 {
  --gradient-to-color: #feb2b2
}

.to-red-400 {
  --gradient-to-color: #fc8181
}

.to-red-500 {
  --gradient-to-color: #f56565
}

.to-red-600 {
  --gradient-to-color: #e53e3e
}

.to-red-700 {
  --gradient-to-color: #c53030
}

.to-red-800 {
  --gradient-to-color: #9b2c2c
}

.to-red-900 {
  --gradient-to-color: #742a2a
}

.to-orange-100 {
  --gradient-to-color: #fffaf0
}

.to-orange-200 {
  --gradient-to-color: #feebc8
}

.to-orange-300 {
  --gradient-to-color: #fbd38d
}

.to-orange-400 {
  --gradient-to-color: #f6ad55
}

.to-orange-500 {
  --gradient-to-color: #ed8936
}

.to-orange-600 {
  --gradient-to-color: #dd6b20
}

.to-orange-700 {
  --gradient-to-color: #c05621
}

.to-orange-800 {
  --gradient-to-color: #9c4221
}

.to-orange-900 {
  --gradient-to-color: #7b341e
}

.to-yellow-100 {
  --gradient-to-color: #fffff0
}

.to-yellow-200 {
  --gradient-to-color: #fefcbf
}

.to-yellow-300 {
  --gradient-to-color: #faf089
}

.to-yellow-400 {
  --gradient-to-color: #f6e05e
}

.to-yellow-500 {
  --gradient-to-color: #ecc94b
}

.to-yellow-600 {
  --gradient-to-color: #d69e2e
}

.to-yellow-700 {
  --gradient-to-color: #b7791f
}

.to-yellow-800 {
  --gradient-to-color: #975a16
}

.to-yellow-900 {
  --gradient-to-color: #744210
}

.to-green-100 {
  --gradient-to-color: #f0fff4
}

.to-green-200 {
  --gradient-to-color: #c6f6d5
}

.to-green-300 {
  --gradient-to-color: #9ae6b4
}

.to-green-400 {
  --gradient-to-color: #68d391
}

.to-green-500 {
  --gradient-to-color: #48bb78
}

.to-green-600 {
  --gradient-to-color: #38a169
}

.to-green-700 {
  --gradient-to-color: #2f855a
}

.to-green-800 {
  --gradient-to-color: #276749
}

.to-green-900 {
  --gradient-to-color: #22543d
}

.to-teal-100 {
  --gradient-to-color: #e6fffa
}

.to-teal-200 {
  --gradient-to-color: #b2f5ea
}

.to-teal-300 {
  --gradient-to-color: #81e6d9
}

.to-teal-400 {
  --gradient-to-color: #4fd1c5
}

.to-teal-500 {
  --gradient-to-color: #38b2ac
}

.to-teal-600 {
  --gradient-to-color: #319795
}

.to-teal-700 {
  --gradient-to-color: #2c7a7b
}

.to-teal-800 {
  --gradient-to-color: #285e61
}

.to-teal-900 {
  --gradient-to-color: #234e52
}

.to-blue-100 {
  --gradient-to-color: #ebf8ff
}

.to-blue-200 {
  --gradient-to-color: #bee3f8
}

.to-blue-300 {
  --gradient-to-color: #90cdf4
}

.to-blue-400 {
  --gradient-to-color: #63b3ed
}

.to-blue-500 {
  --gradient-to-color: #4299e1
}

.to-blue-600 {
  --gradient-to-color: #3182ce
}

.to-blue-700 {
  --gradient-to-color: #2b6cb0
}

.to-blue-800 {
  --gradient-to-color: #2c5282
}

.to-blue-900 {
  --gradient-to-color: #2a4365
}

.to-indigo-100 {
  --gradient-to-color: #ebf4ff
}

.to-indigo-200 {
  --gradient-to-color: #c3dafe
}

.to-indigo-300 {
  --gradient-to-color: #a3bffa
}

.to-indigo-400 {
  --gradient-to-color: #7f9cf5
}

.to-indigo-500 {
  --gradient-to-color: #667eea
}

.to-indigo-600 {
  --gradient-to-color: #5a67d8
}

.to-indigo-700 {
  --gradient-to-color: #4c51bf
}

.to-indigo-800 {
  --gradient-to-color: #434190
}

.to-indigo-900 {
  --gradient-to-color: #3c366b
}

.to-purple-100 {
  --gradient-to-color: #faf5ff
}

.to-purple-200 {
  --gradient-to-color: #e9d8fd
}

.to-purple-300 {
  --gradient-to-color: #d6bcfa
}

.to-purple-400 {
  --gradient-to-color: #b794f4
}

.to-purple-500 {
  --gradient-to-color: #9f7aea
}

.to-purple-600 {
  --gradient-to-color: #805ad5
}

.to-purple-700 {
  --gradient-to-color: #6b46c1
}

.to-purple-800 {
  --gradient-to-color: #553c9a
}

.to-purple-900 {
  --gradient-to-color: #44337a
}

.to-pink-100 {
  --gradient-to-color: #fff5f7
}

.to-pink-200 {
  --gradient-to-color: #fed7e2
}

.to-pink-300 {
  --gradient-to-color: #fbb6ce
}

.to-pink-400 {
  --gradient-to-color: #f687b3
}

.to-pink-500 {
  --gradient-to-color: #ed64a6
}

.to-pink-600 {
  --gradient-to-color: #d53f8c
}

.to-pink-700 {
  --gradient-to-color: #b83280
}

.to-pink-800 {
  --gradient-to-color: #97266d
}

.to-pink-900 {
  --gradient-to-color: #702459
}

.hover\:from-transparent:hover {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:from-current:hover {
  --gradient-from-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-black:hover {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:from-white:hover {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-gray-100:hover {
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.hover\:from-gray-200:hover {
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.hover\:from-gray-300:hover {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.hover\:from-gray-400:hover {
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.hover\:from-gray-500:hover {
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.hover\:from-gray-600:hover {
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.hover\:from-gray-700:hover {
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.hover\:from-gray-800:hover {
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.hover\:from-gray-900:hover {
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.hover\:from-red-100:hover {
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.hover\:from-red-200:hover {
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.hover\:from-red-300:hover {
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.hover\:from-red-400:hover {
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.hover\:from-red-500:hover {
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.hover\:from-red-600:hover {
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.hover\:from-red-700:hover {
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.hover\:from-red-800:hover {
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.hover\:from-red-900:hover {
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.hover\:from-orange-100:hover {
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.hover\:from-orange-200:hover {
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.hover\:from-orange-300:hover {
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.hover\:from-orange-400:hover {
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.hover\:from-orange-500:hover {
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.hover\:from-orange-600:hover {
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.hover\:from-orange-700:hover {
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.hover\:from-orange-800:hover {
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.hover\:from-orange-900:hover {
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.hover\:from-yellow-100:hover {
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.hover\:from-yellow-200:hover {
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.hover\:from-yellow-300:hover {
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.hover\:from-yellow-400:hover {
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.hover\:from-yellow-500:hover {
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.hover\:from-yellow-600:hover {
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.hover\:from-yellow-700:hover {
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.hover\:from-yellow-800:hover {
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.hover\:from-yellow-900:hover {
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.hover\:from-green-100:hover {
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.hover\:from-green-200:hover {
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.hover\:from-green-300:hover {
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.hover\:from-green-400:hover {
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.hover\:from-green-500:hover {
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.hover\:from-green-600:hover {
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.hover\:from-green-700:hover {
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.hover\:from-green-800:hover {
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.hover\:from-green-900:hover {
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.hover\:from-teal-100:hover {
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.hover\:from-teal-200:hover {
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.hover\:from-teal-300:hover {
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.hover\:from-teal-400:hover {
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.hover\:from-teal-500:hover {
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.hover\:from-teal-600:hover {
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.hover\:from-teal-700:hover {
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.hover\:from-teal-800:hover {
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.hover\:from-teal-900:hover {
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.hover\:from-blue-100:hover {
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.hover\:from-blue-200:hover {
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.hover\:from-blue-300:hover {
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.hover\:from-blue-400:hover {
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.hover\:from-blue-500:hover {
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.hover\:from-blue-600:hover {
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.hover\:from-blue-700:hover {
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.hover\:from-blue-800:hover {
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.hover\:from-blue-900:hover {
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.hover\:from-indigo-100:hover {
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.hover\:from-indigo-200:hover {
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.hover\:from-indigo-300:hover {
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.hover\:from-indigo-400:hover {
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.hover\:from-indigo-500:hover {
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.hover\:from-indigo-600:hover {
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.hover\:from-indigo-700:hover {
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.hover\:from-indigo-800:hover {
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.hover\:from-indigo-900:hover {
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.hover\:from-purple-100:hover {
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.hover\:from-purple-200:hover {
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.hover\:from-purple-300:hover {
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.hover\:from-purple-400:hover {
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.hover\:from-purple-500:hover {
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.hover\:from-purple-600:hover {
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.hover\:from-purple-700:hover {
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.hover\:from-purple-800:hover {
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.hover\:from-purple-900:hover {
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.hover\:from-pink-100:hover {
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.hover\:from-pink-200:hover {
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.hover\:from-pink-300:hover {
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.hover\:from-pink-400:hover {
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.hover\:from-pink-500:hover {
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.hover\:from-pink-600:hover {
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.hover\:from-pink-700:hover {
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.hover\:from-pink-800:hover {
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.hover\:from-pink-900:hover {
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.hover\:via-transparent:hover {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:via-current:hover {
  --gradient-via-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-black:hover {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:via-white:hover {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-gray-100:hover {
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.hover\:via-gray-200:hover {
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.hover\:via-gray-300:hover {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.hover\:via-gray-400:hover {
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.hover\:via-gray-500:hover {
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.hover\:via-gray-600:hover {
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.hover\:via-gray-700:hover {
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.hover\:via-gray-800:hover {
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.hover\:via-gray-900:hover {
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.hover\:via-red-100:hover {
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.hover\:via-red-200:hover {
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.hover\:via-red-300:hover {
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.hover\:via-red-400:hover {
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.hover\:via-red-500:hover {
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.hover\:via-red-600:hover {
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.hover\:via-red-700:hover {
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.hover\:via-red-800:hover {
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.hover\:via-red-900:hover {
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.hover\:via-orange-100:hover {
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.hover\:via-orange-200:hover {
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.hover\:via-orange-300:hover {
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.hover\:via-orange-400:hover {
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.hover\:via-orange-500:hover {
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.hover\:via-orange-600:hover {
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.hover\:via-orange-700:hover {
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.hover\:via-orange-800:hover {
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.hover\:via-orange-900:hover {
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.hover\:via-yellow-100:hover {
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.hover\:via-yellow-200:hover {
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.hover\:via-yellow-300:hover {
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.hover\:via-yellow-400:hover {
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.hover\:via-yellow-500:hover {
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.hover\:via-yellow-600:hover {
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.hover\:via-yellow-700:hover {
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.hover\:via-yellow-800:hover {
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.hover\:via-yellow-900:hover {
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.hover\:via-green-100:hover {
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.hover\:via-green-200:hover {
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.hover\:via-green-300:hover {
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.hover\:via-green-400:hover {
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.hover\:via-green-500:hover {
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.hover\:via-green-600:hover {
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.hover\:via-green-700:hover {
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.hover\:via-green-800:hover {
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.hover\:via-green-900:hover {
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.hover\:via-teal-100:hover {
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.hover\:via-teal-200:hover {
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.hover\:via-teal-300:hover {
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.hover\:via-teal-400:hover {
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.hover\:via-teal-500:hover {
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.hover\:via-teal-600:hover {
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.hover\:via-teal-700:hover {
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.hover\:via-teal-800:hover {
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.hover\:via-teal-900:hover {
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.hover\:via-blue-100:hover {
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.hover\:via-blue-200:hover {
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.hover\:via-blue-300:hover {
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.hover\:via-blue-400:hover {
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.hover\:via-blue-500:hover {
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.hover\:via-blue-600:hover {
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.hover\:via-blue-700:hover {
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.hover\:via-blue-800:hover {
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.hover\:via-blue-900:hover {
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.hover\:via-indigo-100:hover {
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.hover\:via-indigo-200:hover {
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.hover\:via-indigo-300:hover {
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.hover\:via-indigo-400:hover {
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.hover\:via-indigo-500:hover {
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.hover\:via-indigo-600:hover {
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.hover\:via-indigo-700:hover {
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.hover\:via-indigo-800:hover {
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.hover\:via-indigo-900:hover {
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.hover\:via-purple-100:hover {
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.hover\:via-purple-200:hover {
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.hover\:via-purple-300:hover {
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.hover\:via-purple-400:hover {
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.hover\:via-purple-500:hover {
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.hover\:via-purple-600:hover {
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.hover\:via-purple-700:hover {
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.hover\:via-purple-800:hover {
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.hover\:via-purple-900:hover {
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.hover\:via-pink-100:hover {
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.hover\:via-pink-200:hover {
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.hover\:via-pink-300:hover {
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.hover\:via-pink-400:hover {
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.hover\:via-pink-500:hover {
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.hover\:via-pink-600:hover {
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.hover\:via-pink-700:hover {
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.hover\:via-pink-800:hover {
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.hover\:via-pink-900:hover {
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.hover\:to-transparent:hover {
  --gradient-to-color: transparent
}

.hover\:to-current:hover {
  --gradient-to-color: currentColor
}

.hover\:to-black:hover {
  --gradient-to-color: #000
}

.hover\:to-white:hover {
  --gradient-to-color: #fff
}

.hover\:to-gray-100:hover {
  --gradient-to-color: #f7fafc
}

.hover\:to-gray-200:hover {
  --gradient-to-color: #edf2f7
}

.hover\:to-gray-300:hover {
  --gradient-to-color: #e2e8f0
}

.hover\:to-gray-400:hover {
  --gradient-to-color: #cbd5e0
}

.hover\:to-gray-500:hover {
  --gradient-to-color: #a0aec0
}

.hover\:to-gray-600:hover {
  --gradient-to-color: #718096
}

.hover\:to-gray-700:hover {
  --gradient-to-color: #4a5568
}

.hover\:to-gray-800:hover {
  --gradient-to-color: #2d3748
}

.hover\:to-gray-900:hover {
  --gradient-to-color: #1a202c
}

.hover\:to-red-100:hover {
  --gradient-to-color: #fff5f5
}

.hover\:to-red-200:hover {
  --gradient-to-color: #fed7d7
}

.hover\:to-red-300:hover {
  --gradient-to-color: #feb2b2
}

.hover\:to-red-400:hover {
  --gradient-to-color: #fc8181
}

.hover\:to-red-500:hover {
  --gradient-to-color: #f56565
}

.hover\:to-red-600:hover {
  --gradient-to-color: #e53e3e
}

.hover\:to-red-700:hover {
  --gradient-to-color: #c53030
}

.hover\:to-red-800:hover {
  --gradient-to-color: #9b2c2c
}

.hover\:to-red-900:hover {
  --gradient-to-color: #742a2a
}

.hover\:to-orange-100:hover {
  --gradient-to-color: #fffaf0
}

.hover\:to-orange-200:hover {
  --gradient-to-color: #feebc8
}

.hover\:to-orange-300:hover {
  --gradient-to-color: #fbd38d
}

.hover\:to-orange-400:hover {
  --gradient-to-color: #f6ad55
}

.hover\:to-orange-500:hover {
  --gradient-to-color: #ed8936
}

.hover\:to-orange-600:hover {
  --gradient-to-color: #dd6b20
}

.hover\:to-orange-700:hover {
  --gradient-to-color: #c05621
}

.hover\:to-orange-800:hover {
  --gradient-to-color: #9c4221
}

.hover\:to-orange-900:hover {
  --gradient-to-color: #7b341e
}

.hover\:to-yellow-100:hover {
  --gradient-to-color: #fffff0
}

.hover\:to-yellow-200:hover {
  --gradient-to-color: #fefcbf
}

.hover\:to-yellow-300:hover {
  --gradient-to-color: #faf089
}

.hover\:to-yellow-400:hover {
  --gradient-to-color: #f6e05e
}

.hover\:to-yellow-500:hover {
  --gradient-to-color: #ecc94b
}

.hover\:to-yellow-600:hover {
  --gradient-to-color: #d69e2e
}

.hover\:to-yellow-700:hover {
  --gradient-to-color: #b7791f
}

.hover\:to-yellow-800:hover {
  --gradient-to-color: #975a16
}

.hover\:to-yellow-900:hover {
  --gradient-to-color: #744210
}

.hover\:to-green-100:hover {
  --gradient-to-color: #f0fff4
}

.hover\:to-green-200:hover {
  --gradient-to-color: #c6f6d5
}

.hover\:to-green-300:hover {
  --gradient-to-color: #9ae6b4
}

.hover\:to-green-400:hover {
  --gradient-to-color: #68d391
}

.hover\:to-green-500:hover {
  --gradient-to-color: #48bb78
}

.hover\:to-green-600:hover {
  --gradient-to-color: #38a169
}

.hover\:to-green-700:hover {
  --gradient-to-color: #2f855a
}

.hover\:to-green-800:hover {
  --gradient-to-color: #276749
}

.hover\:to-green-900:hover {
  --gradient-to-color: #22543d
}

.hover\:to-teal-100:hover {
  --gradient-to-color: #e6fffa
}

.hover\:to-teal-200:hover {
  --gradient-to-color: #b2f5ea
}

.hover\:to-teal-300:hover {
  --gradient-to-color: #81e6d9
}

.hover\:to-teal-400:hover {
  --gradient-to-color: #4fd1c5
}

.hover\:to-teal-500:hover {
  --gradient-to-color: #38b2ac
}

.hover\:to-teal-600:hover {
  --gradient-to-color: #319795
}

.hover\:to-teal-700:hover {
  --gradient-to-color: #2c7a7b
}

.hover\:to-teal-800:hover {
  --gradient-to-color: #285e61
}

.hover\:to-teal-900:hover {
  --gradient-to-color: #234e52
}

.hover\:to-blue-100:hover {
  --gradient-to-color: #ebf8ff
}

.hover\:to-blue-200:hover {
  --gradient-to-color: #bee3f8
}

.hover\:to-blue-300:hover {
  --gradient-to-color: #90cdf4
}

.hover\:to-blue-400:hover {
  --gradient-to-color: #63b3ed
}

.hover\:to-blue-500:hover {
  --gradient-to-color: #4299e1
}

.hover\:to-blue-600:hover {
  --gradient-to-color: #3182ce
}

.hover\:to-blue-700:hover {
  --gradient-to-color: #2b6cb0
}

.hover\:to-blue-800:hover {
  --gradient-to-color: #2c5282
}

.hover\:to-blue-900:hover {
  --gradient-to-color: #2a4365
}

.hover\:to-indigo-100:hover {
  --gradient-to-color: #ebf4ff
}

.hover\:to-indigo-200:hover {
  --gradient-to-color: #c3dafe
}

.hover\:to-indigo-300:hover {
  --gradient-to-color: #a3bffa
}

.hover\:to-indigo-400:hover {
  --gradient-to-color: #7f9cf5
}

.hover\:to-indigo-500:hover {
  --gradient-to-color: #667eea
}

.hover\:to-indigo-600:hover {
  --gradient-to-color: #5a67d8
}

.hover\:to-indigo-700:hover {
  --gradient-to-color: #4c51bf
}

.hover\:to-indigo-800:hover {
  --gradient-to-color: #434190
}

.hover\:to-indigo-900:hover {
  --gradient-to-color: #3c366b
}

.hover\:to-purple-100:hover {
  --gradient-to-color: #faf5ff
}

.hover\:to-purple-200:hover {
  --gradient-to-color: #e9d8fd
}

.hover\:to-purple-300:hover {
  --gradient-to-color: #d6bcfa
}

.hover\:to-purple-400:hover {
  --gradient-to-color: #b794f4
}

.hover\:to-purple-500:hover {
  --gradient-to-color: #9f7aea
}

.hover\:to-purple-600:hover {
  --gradient-to-color: #805ad5
}

.hover\:to-purple-700:hover {
  --gradient-to-color: #6b46c1
}

.hover\:to-purple-800:hover {
  --gradient-to-color: #553c9a
}

.hover\:to-purple-900:hover {
  --gradient-to-color: #44337a
}

.hover\:to-pink-100:hover {
  --gradient-to-color: #fff5f7
}

.hover\:to-pink-200:hover {
  --gradient-to-color: #fed7e2
}

.hover\:to-pink-300:hover {
  --gradient-to-color: #fbb6ce
}

.hover\:to-pink-400:hover {
  --gradient-to-color: #f687b3
}

.hover\:to-pink-500:hover {
  --gradient-to-color: #ed64a6
}

.hover\:to-pink-600:hover {
  --gradient-to-color: #d53f8c
}

.hover\:to-pink-700:hover {
  --gradient-to-color: #b83280
}

.hover\:to-pink-800:hover {
  --gradient-to-color: #97266d
}

.hover\:to-pink-900:hover {
  --gradient-to-color: #702459
}

.focus\:from-transparent:focus {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:from-current:focus {
  --gradient-from-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-black:focus {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:from-white:focus {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-gray-100:focus {
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.focus\:from-gray-200:focus {
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.focus\:from-gray-300:focus {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.focus\:from-gray-400:focus {
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.focus\:from-gray-500:focus {
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.focus\:from-gray-600:focus {
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.focus\:from-gray-700:focus {
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.focus\:from-gray-800:focus {
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.focus\:from-gray-900:focus {
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.focus\:from-red-100:focus {
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.focus\:from-red-200:focus {
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.focus\:from-red-300:focus {
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.focus\:from-red-400:focus {
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.focus\:from-red-500:focus {
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.focus\:from-red-600:focus {
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.focus\:from-red-700:focus {
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.focus\:from-red-800:focus {
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.focus\:from-red-900:focus {
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.focus\:from-orange-100:focus {
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.focus\:from-orange-200:focus {
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.focus\:from-orange-300:focus {
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.focus\:from-orange-400:focus {
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.focus\:from-orange-500:focus {
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.focus\:from-orange-600:focus {
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.focus\:from-orange-700:focus {
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.focus\:from-orange-800:focus {
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.focus\:from-orange-900:focus {
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.focus\:from-yellow-100:focus {
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.focus\:from-yellow-200:focus {
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.focus\:from-yellow-300:focus {
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.focus\:from-yellow-400:focus {
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.focus\:from-yellow-500:focus {
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.focus\:from-yellow-600:focus {
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.focus\:from-yellow-700:focus {
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.focus\:from-yellow-800:focus {
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.focus\:from-yellow-900:focus {
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.focus\:from-green-100:focus {
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.focus\:from-green-200:focus {
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.focus\:from-green-300:focus {
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.focus\:from-green-400:focus {
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.focus\:from-green-500:focus {
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.focus\:from-green-600:focus {
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.focus\:from-green-700:focus {
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.focus\:from-green-800:focus {
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.focus\:from-green-900:focus {
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.focus\:from-teal-100:focus {
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.focus\:from-teal-200:focus {
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.focus\:from-teal-300:focus {
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.focus\:from-teal-400:focus {
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.focus\:from-teal-500:focus {
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.focus\:from-teal-600:focus {
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.focus\:from-teal-700:focus {
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.focus\:from-teal-800:focus {
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.focus\:from-teal-900:focus {
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.focus\:from-blue-100:focus {
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.focus\:from-blue-200:focus {
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.focus\:from-blue-300:focus {
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.focus\:from-blue-400:focus {
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.focus\:from-blue-500:focus {
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.focus\:from-blue-600:focus {
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.focus\:from-blue-700:focus {
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.focus\:from-blue-800:focus {
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.focus\:from-blue-900:focus {
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.focus\:from-indigo-100:focus {
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.focus\:from-indigo-200:focus {
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.focus\:from-indigo-300:focus {
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.focus\:from-indigo-400:focus {
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.focus\:from-indigo-500:focus {
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.focus\:from-indigo-600:focus {
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.focus\:from-indigo-700:focus {
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.focus\:from-indigo-800:focus {
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.focus\:from-indigo-900:focus {
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.focus\:from-purple-100:focus {
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.focus\:from-purple-200:focus {
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.focus\:from-purple-300:focus {
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.focus\:from-purple-400:focus {
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.focus\:from-purple-500:focus {
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.focus\:from-purple-600:focus {
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.focus\:from-purple-700:focus {
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.focus\:from-purple-800:focus {
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.focus\:from-purple-900:focus {
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.focus\:from-pink-100:focus {
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.focus\:from-pink-200:focus {
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.focus\:from-pink-300:focus {
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.focus\:from-pink-400:focus {
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.focus\:from-pink-500:focus {
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.focus\:from-pink-600:focus {
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.focus\:from-pink-700:focus {
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.focus\:from-pink-800:focus {
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.focus\:from-pink-900:focus {
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.focus\:via-transparent:focus {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:via-current:focus {
  --gradient-via-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-black:focus {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:via-white:focus {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-gray-100:focus {
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.focus\:via-gray-200:focus {
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.focus\:via-gray-300:focus {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.focus\:via-gray-400:focus {
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.focus\:via-gray-500:focus {
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.focus\:via-gray-600:focus {
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.focus\:via-gray-700:focus {
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.focus\:via-gray-800:focus {
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.focus\:via-gray-900:focus {
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.focus\:via-red-100:focus {
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.focus\:via-red-200:focus {
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.focus\:via-red-300:focus {
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.focus\:via-red-400:focus {
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.focus\:via-red-500:focus {
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.focus\:via-red-600:focus {
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.focus\:via-red-700:focus {
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.focus\:via-red-800:focus {
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.focus\:via-red-900:focus {
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.focus\:via-orange-100:focus {
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.focus\:via-orange-200:focus {
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.focus\:via-orange-300:focus {
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.focus\:via-orange-400:focus {
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.focus\:via-orange-500:focus {
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.focus\:via-orange-600:focus {
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.focus\:via-orange-700:focus {
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.focus\:via-orange-800:focus {
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.focus\:via-orange-900:focus {
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.focus\:via-yellow-100:focus {
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.focus\:via-yellow-200:focus {
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.focus\:via-yellow-300:focus {
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.focus\:via-yellow-400:focus {
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.focus\:via-yellow-500:focus {
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.focus\:via-yellow-600:focus {
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.focus\:via-yellow-700:focus {
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.focus\:via-yellow-800:focus {
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.focus\:via-yellow-900:focus {
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.focus\:via-green-100:focus {
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.focus\:via-green-200:focus {
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.focus\:via-green-300:focus {
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.focus\:via-green-400:focus {
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.focus\:via-green-500:focus {
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.focus\:via-green-600:focus {
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.focus\:via-green-700:focus {
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.focus\:via-green-800:focus {
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.focus\:via-green-900:focus {
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.focus\:via-teal-100:focus {
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.focus\:via-teal-200:focus {
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.focus\:via-teal-300:focus {
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.focus\:via-teal-400:focus {
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.focus\:via-teal-500:focus {
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.focus\:via-teal-600:focus {
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.focus\:via-teal-700:focus {
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.focus\:via-teal-800:focus {
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.focus\:via-teal-900:focus {
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.focus\:via-blue-100:focus {
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.focus\:via-blue-200:focus {
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.focus\:via-blue-300:focus {
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.focus\:via-blue-400:focus {
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.focus\:via-blue-500:focus {
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.focus\:via-blue-600:focus {
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.focus\:via-blue-700:focus {
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.focus\:via-blue-800:focus {
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.focus\:via-blue-900:focus {
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.focus\:via-indigo-100:focus {
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.focus\:via-indigo-200:focus {
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.focus\:via-indigo-300:focus {
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.focus\:via-indigo-400:focus {
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.focus\:via-indigo-500:focus {
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.focus\:via-indigo-600:focus {
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.focus\:via-indigo-700:focus {
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.focus\:via-indigo-800:focus {
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.focus\:via-indigo-900:focus {
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.focus\:via-purple-100:focus {
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.focus\:via-purple-200:focus {
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.focus\:via-purple-300:focus {
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.focus\:via-purple-400:focus {
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.focus\:via-purple-500:focus {
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.focus\:via-purple-600:focus {
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.focus\:via-purple-700:focus {
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.focus\:via-purple-800:focus {
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.focus\:via-purple-900:focus {
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.focus\:via-pink-100:focus {
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.focus\:via-pink-200:focus {
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.focus\:via-pink-300:focus {
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.focus\:via-pink-400:focus {
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.focus\:via-pink-500:focus {
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.focus\:via-pink-600:focus {
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.focus\:via-pink-700:focus {
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.focus\:via-pink-800:focus {
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.focus\:via-pink-900:focus {
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.focus\:to-transparent:focus {
  --gradient-to-color: transparent
}

.focus\:to-current:focus {
  --gradient-to-color: currentColor
}

.focus\:to-black:focus {
  --gradient-to-color: #000
}

.focus\:to-white:focus {
  --gradient-to-color: #fff
}

.focus\:to-gray-100:focus {
  --gradient-to-color: #f7fafc
}

.focus\:to-gray-200:focus {
  --gradient-to-color: #edf2f7
}

.focus\:to-gray-300:focus {
  --gradient-to-color: #e2e8f0
}

.focus\:to-gray-400:focus {
  --gradient-to-color: #cbd5e0
}

.focus\:to-gray-500:focus {
  --gradient-to-color: #a0aec0
}

.focus\:to-gray-600:focus {
  --gradient-to-color: #718096
}

.focus\:to-gray-700:focus {
  --gradient-to-color: #4a5568
}

.focus\:to-gray-800:focus {
  --gradient-to-color: #2d3748
}

.focus\:to-gray-900:focus {
  --gradient-to-color: #1a202c
}

.focus\:to-red-100:focus {
  --gradient-to-color: #fff5f5
}

.focus\:to-red-200:focus {
  --gradient-to-color: #fed7d7
}

.focus\:to-red-300:focus {
  --gradient-to-color: #feb2b2
}

.focus\:to-red-400:focus {
  --gradient-to-color: #fc8181
}

.focus\:to-red-500:focus {
  --gradient-to-color: #f56565
}

.focus\:to-red-600:focus {
  --gradient-to-color: #e53e3e
}

.focus\:to-red-700:focus {
  --gradient-to-color: #c53030
}

.focus\:to-red-800:focus {
  --gradient-to-color: #9b2c2c
}

.focus\:to-red-900:focus {
  --gradient-to-color: #742a2a
}

.focus\:to-orange-100:focus {
  --gradient-to-color: #fffaf0
}

.focus\:to-orange-200:focus {
  --gradient-to-color: #feebc8
}

.focus\:to-orange-300:focus {
  --gradient-to-color: #fbd38d
}

.focus\:to-orange-400:focus {
  --gradient-to-color: #f6ad55
}

.focus\:to-orange-500:focus {
  --gradient-to-color: #ed8936
}

.focus\:to-orange-600:focus {
  --gradient-to-color: #dd6b20
}

.focus\:to-orange-700:focus {
  --gradient-to-color: #c05621
}

.focus\:to-orange-800:focus {
  --gradient-to-color: #9c4221
}

.focus\:to-orange-900:focus {
  --gradient-to-color: #7b341e
}

.focus\:to-yellow-100:focus {
  --gradient-to-color: #fffff0
}

.focus\:to-yellow-200:focus {
  --gradient-to-color: #fefcbf
}

.focus\:to-yellow-300:focus {
  --gradient-to-color: #faf089
}

.focus\:to-yellow-400:focus {
  --gradient-to-color: #f6e05e
}

.focus\:to-yellow-500:focus {
  --gradient-to-color: #ecc94b
}

.focus\:to-yellow-600:focus {
  --gradient-to-color: #d69e2e
}

.focus\:to-yellow-700:focus {
  --gradient-to-color: #b7791f
}

.focus\:to-yellow-800:focus {
  --gradient-to-color: #975a16
}

.focus\:to-yellow-900:focus {
  --gradient-to-color: #744210
}

.focus\:to-green-100:focus {
  --gradient-to-color: #f0fff4
}

.focus\:to-green-200:focus {
  --gradient-to-color: #c6f6d5
}

.focus\:to-green-300:focus {
  --gradient-to-color: #9ae6b4
}

.focus\:to-green-400:focus {
  --gradient-to-color: #68d391
}

.focus\:to-green-500:focus {
  --gradient-to-color: #48bb78
}

.focus\:to-green-600:focus {
  --gradient-to-color: #38a169
}

.focus\:to-green-700:focus {
  --gradient-to-color: #2f855a
}

.focus\:to-green-800:focus {
  --gradient-to-color: #276749
}

.focus\:to-green-900:focus {
  --gradient-to-color: #22543d
}

.focus\:to-teal-100:focus {
  --gradient-to-color: #e6fffa
}

.focus\:to-teal-200:focus {
  --gradient-to-color: #b2f5ea
}

.focus\:to-teal-300:focus {
  --gradient-to-color: #81e6d9
}

.focus\:to-teal-400:focus {
  --gradient-to-color: #4fd1c5
}

.focus\:to-teal-500:focus {
  --gradient-to-color: #38b2ac
}

.focus\:to-teal-600:focus {
  --gradient-to-color: #319795
}

.focus\:to-teal-700:focus {
  --gradient-to-color: #2c7a7b
}

.focus\:to-teal-800:focus {
  --gradient-to-color: #285e61
}

.focus\:to-teal-900:focus {
  --gradient-to-color: #234e52
}

.focus\:to-blue-100:focus {
  --gradient-to-color: #ebf8ff
}

.focus\:to-blue-200:focus {
  --gradient-to-color: #bee3f8
}

.focus\:to-blue-300:focus {
  --gradient-to-color: #90cdf4
}

.focus\:to-blue-400:focus {
  --gradient-to-color: #63b3ed
}

.focus\:to-blue-500:focus {
  --gradient-to-color: #4299e1
}

.focus\:to-blue-600:focus {
  --gradient-to-color: #3182ce
}

.focus\:to-blue-700:focus {
  --gradient-to-color: #2b6cb0
}

.focus\:to-blue-800:focus {
  --gradient-to-color: #2c5282
}

.focus\:to-blue-900:focus {
  --gradient-to-color: #2a4365
}

.focus\:to-indigo-100:focus {
  --gradient-to-color: #ebf4ff
}

.focus\:to-indigo-200:focus {
  --gradient-to-color: #c3dafe
}

.focus\:to-indigo-300:focus {
  --gradient-to-color: #a3bffa
}

.focus\:to-indigo-400:focus {
  --gradient-to-color: #7f9cf5
}

.focus\:to-indigo-500:focus {
  --gradient-to-color: #667eea
}

.focus\:to-indigo-600:focus {
  --gradient-to-color: #5a67d8
}

.focus\:to-indigo-700:focus {
  --gradient-to-color: #4c51bf
}

.focus\:to-indigo-800:focus {
  --gradient-to-color: #434190
}

.focus\:to-indigo-900:focus {
  --gradient-to-color: #3c366b
}

.focus\:to-purple-100:focus {
  --gradient-to-color: #faf5ff
}

.focus\:to-purple-200:focus {
  --gradient-to-color: #e9d8fd
}

.focus\:to-purple-300:focus {
  --gradient-to-color: #d6bcfa
}

.focus\:to-purple-400:focus {
  --gradient-to-color: #b794f4
}

.focus\:to-purple-500:focus {
  --gradient-to-color: #9f7aea
}

.focus\:to-purple-600:focus {
  --gradient-to-color: #805ad5
}

.focus\:to-purple-700:focus {
  --gradient-to-color: #6b46c1
}

.focus\:to-purple-800:focus {
  --gradient-to-color: #553c9a
}

.focus\:to-purple-900:focus {
  --gradient-to-color: #44337a
}

.focus\:to-pink-100:focus {
  --gradient-to-color: #fff5f7
}

.focus\:to-pink-200:focus {
  --gradient-to-color: #fed7e2
}

.focus\:to-pink-300:focus {
  --gradient-to-color: #fbb6ce
}

.focus\:to-pink-400:focus {
  --gradient-to-color: #f687b3
}

.focus\:to-pink-500:focus {
  --gradient-to-color: #ed64a6
}

.focus\:to-pink-600:focus {
  --gradient-to-color: #d53f8c
}

.focus\:to-pink-700:focus {
  --gradient-to-color: #b83280
}

.focus\:to-pink-800:focus {
  --gradient-to-color: #97266d
}

.focus\:to-pink-900:focus {
  --gradient-to-color: #702459
}

.bg-opacity-0 {
  --bg-opacity: 0
}

.bg-opacity-25 {
  --bg-opacity: 0.25
}

.bg-opacity-50 {
  --bg-opacity: 0.5
}

.bg-opacity-75 {
  --bg-opacity: 0.75
}

.bg-opacity-100 {
  --bg-opacity: 1
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: 0.25
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: 0.5
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: 0.75
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1
}

.bg-bottom {
  background-position: bottom
}

.bg-center {
  background-position: center
}

.bg-left {
  background-position: left
}

.bg-left-bottom {
  background-position: left bottom
}

.bg-left-top {
  background-position: left top
}

.bg-right {
  background-position: right
}

.bg-right-bottom {
  background-position: right bottom
}

.bg-right-top {
  background-position: right top
}

.bg-top {
  background-position: top
}

.bg-repeat {
  background-repeat: repeat
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-repeat-x {
  background-repeat: repeat-x
}

.bg-repeat-y {
  background-repeat: repeat-y
}

.bg-repeat-round {
  background-repeat: round
}

.bg-repeat-space {
  background-repeat: space
}

.bg-auto {
  background-size: auto
}

.bg-cover {
  background-size: cover
}

.bg-contain {
  background-size: contain
}

.border-collapse {
  border-collapse: collapse
}

.border-separate {
  border-collapse: separate
}

.border-transparent {
  border-color: transparent
}

.border-current {
  border-color: currentColor
}

.border-black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity))
}

.border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.border-gray-100 {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity))
}

.border-gray-200 {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity))
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity))
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.border-gray-500 {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity))
}

.border-gray-600 {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity))
}

.border-gray-700 {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity))
}

.border-gray-800 {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity))
}

.border-gray-900 {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity))
}

.border-red-100 {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity))
}

.border-red-200 {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity))
}

.border-red-300 {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity))
}

.border-red-400 {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity))
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity))
}

.border-red-600 {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity))
}

.border-red-700 {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity))
}

.border-red-800 {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.border-red-900 {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity))
}

.border-orange-100 {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity))
}

.border-orange-200 {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity))
}

.border-orange-300 {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity))
}

.border-orange-400 {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity))
}

.border-orange-500 {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity))
}

.border-orange-600 {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity))
}

.border-orange-700 {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity))
}

.border-orange-800 {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity))
}

.border-orange-900 {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity))
}

.border-yellow-100 {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity))
}

.border-yellow-200 {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity))
}

.border-yellow-300 {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity))
}

.border-yellow-400 {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity))
}

.border-yellow-500 {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity))
}

.border-yellow-600 {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity))
}

.border-yellow-700 {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity))
}

.border-yellow-800 {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity))
}

.border-yellow-900 {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity))
}

.border-green-100 {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity))
}

.border-green-200 {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity))
}

.border-green-300 {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity))
}

.border-green-400 {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity))
}

.border-green-500 {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity))
}

.border-green-600 {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity))
}

.border-green-700 {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity))
}

.border-green-800 {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity))
}

.border-green-900 {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity))
}

.border-teal-100 {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity))
}

.border-teal-200 {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity))
}

.border-teal-300 {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity))
}

.border-teal-400 {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity))
}

.border-teal-500 {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity))
}

.border-teal-600 {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity))
}

.border-teal-700 {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity))
}

.border-teal-800 {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity))
}

.border-teal-900 {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity))
}

.border-blue-100 {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity))
}

.border-blue-200 {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity))
}

.border-blue-300 {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity))
}

.border-blue-400 {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity))
}

.border-blue-500 {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.border-blue-600 {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.border-blue-700 {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity))
}

.border-blue-800 {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity))
}

.border-blue-900 {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity))
}

.border-indigo-100 {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity))
}

.border-indigo-200 {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity))
}

.border-indigo-300 {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity))
}

.border-indigo-400 {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity))
}

.border-indigo-500 {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity))
}

.border-indigo-600 {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity))
}

.border-indigo-700 {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity))
}

.border-indigo-800 {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity))
}

.border-indigo-900 {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity))
}

.border-purple-100 {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity))
}

.border-purple-200 {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity))
}

.border-purple-300 {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity))
}

.border-purple-400 {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity))
}

.border-purple-500 {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity))
}

.border-purple-600 {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity))
}

.border-purple-700 {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity))
}

.border-purple-800 {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity))
}

.border-purple-900 {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity))
}

.border-pink-100 {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity))
}

.border-pink-200 {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity))
}

.border-pink-300 {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity))
}

.border-pink-400 {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity))
}

.border-pink-500 {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity))
}

.border-pink-600 {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity))
}

.border-pink-700 {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity))
}

.border-pink-800 {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity))
}

.border-pink-900 {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity))
}

.hover\:border-transparent:hover {
  border-color: transparent
}

.hover\:border-current:hover {
  border-color: currentColor
}

.hover\:border-black:hover {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity))
}

.hover\:border-white:hover {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.hover\:border-gray-100:hover {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity))
}

.hover\:border-gray-200:hover {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity))
}

.hover\:border-gray-300:hover {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity))
}

.hover\:border-gray-400:hover {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.hover\:border-gray-500:hover {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity))
}

.hover\:border-gray-600:hover {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity))
}

.hover\:border-gray-700:hover {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity))
}

.hover\:border-gray-800:hover {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity))
}

.hover\:border-gray-900:hover {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity))
}

.hover\:border-red-100:hover {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity))
}

.hover\:border-red-200:hover {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity))
}

.hover\:border-red-300:hover {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity))
}

.hover\:border-red-400:hover {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity))
}

.hover\:border-red-500:hover {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity))
}

.hover\:border-red-600:hover {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity))
}

.hover\:border-red-700:hover {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity))
}

.hover\:border-red-800:hover {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.hover\:border-red-900:hover {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity))
}

.hover\:border-orange-100:hover {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity))
}

.hover\:border-orange-200:hover {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity))
}

.hover\:border-orange-300:hover {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity))
}

.hover\:border-orange-400:hover {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity))
}

.hover\:border-orange-500:hover {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity))
}

.hover\:border-orange-600:hover {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity))
}

.hover\:border-orange-700:hover {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity))
}

.hover\:border-orange-800:hover {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity))
}

.hover\:border-orange-900:hover {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity))
}

.hover\:border-yellow-100:hover {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity))
}

.hover\:border-yellow-200:hover {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity))
}

.hover\:border-yellow-300:hover {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity))
}

.hover\:border-yellow-400:hover {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity))
}

.hover\:border-yellow-500:hover {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity))
}

.hover\:border-yellow-600:hover {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity))
}

.hover\:border-yellow-700:hover {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity))
}

.hover\:border-yellow-800:hover {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity))
}

.hover\:border-yellow-900:hover {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity))
}

.hover\:border-green-100:hover {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity))
}

.hover\:border-green-200:hover {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity))
}

.hover\:border-green-300:hover {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity))
}

.hover\:border-green-400:hover {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity))
}

.hover\:border-green-500:hover {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity))
}

.hover\:border-green-600:hover {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity))
}

.hover\:border-green-700:hover {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity))
}

.hover\:border-green-800:hover {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity))
}

.hover\:border-green-900:hover {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity))
}

.hover\:border-teal-100:hover {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity))
}

.hover\:border-teal-200:hover {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity))
}

.hover\:border-teal-300:hover {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity))
}

.hover\:border-teal-400:hover {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity))
}

.hover\:border-teal-500:hover {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity))
}

.hover\:border-teal-600:hover {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity))
}

.hover\:border-teal-700:hover {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity))
}

.hover\:border-teal-800:hover {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity))
}

.hover\:border-teal-900:hover {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity))
}

.hover\:border-blue-100:hover {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity))
}

.hover\:border-blue-200:hover {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity))
}

.hover\:border-blue-300:hover {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity))
}

.hover\:border-blue-400:hover {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity))
}

.hover\:border-blue-500:hover {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.hover\:border-blue-600:hover {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.hover\:border-blue-700:hover {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity))
}

.hover\:border-blue-800:hover {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity))
}

.hover\:border-blue-900:hover {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity))
}

.hover\:border-indigo-100:hover {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity))
}

.hover\:border-indigo-200:hover {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity))
}

.hover\:border-indigo-300:hover {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity))
}

.hover\:border-indigo-400:hover {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity))
}

.hover\:border-indigo-500:hover {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity))
}

.hover\:border-indigo-600:hover {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity))
}

.hover\:border-indigo-700:hover {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity))
}

.hover\:border-indigo-800:hover {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity))
}

.hover\:border-indigo-900:hover {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity))
}

.hover\:border-purple-100:hover {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity))
}

.hover\:border-purple-200:hover {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity))
}

.hover\:border-purple-300:hover {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity))
}

.hover\:border-purple-400:hover {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity))
}

.hover\:border-purple-500:hover {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity))
}

.hover\:border-purple-600:hover {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity))
}

.hover\:border-purple-700:hover {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity))
}

.hover\:border-purple-800:hover {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity))
}

.hover\:border-purple-900:hover {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity))
}

.hover\:border-pink-100:hover {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity))
}

.hover\:border-pink-200:hover {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity))
}

.hover\:border-pink-300:hover {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity))
}

.hover\:border-pink-400:hover {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity))
}

.hover\:border-pink-500:hover {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity))
}

.hover\:border-pink-600:hover {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity))
}

.hover\:border-pink-700:hover {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity))
}

.hover\:border-pink-800:hover {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity))
}

.hover\:border-pink-900:hover {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity))
}

.focus\:border-transparent:focus {
  border-color: transparent
}

.focus\:border-current:focus {
  border-color: currentColor
}

.focus\:border-black:focus {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity))
}

.focus\:border-white:focus {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.focus\:border-gray-100:focus {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity))
}

.focus\:border-gray-200:focus {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity))
}

.focus\:border-gray-300:focus {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity))
}

.focus\:border-gray-400:focus {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.focus\:border-gray-500:focus {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity))
}

.focus\:border-gray-600:focus {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity))
}

.focus\:border-gray-700:focus {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity))
}

.focus\:border-gray-800:focus {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity))
}

.focus\:border-gray-900:focus {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity))
}

.focus\:border-red-100:focus {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity))
}

.focus\:border-red-200:focus {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity))
}

.focus\:border-red-300:focus {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity))
}

.focus\:border-red-400:focus {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity))
}

.focus\:border-red-500:focus {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity))
}

.focus\:border-red-600:focus {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity))
}

.focus\:border-red-700:focus {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity))
}

.focus\:border-red-800:focus {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.focus\:border-red-900:focus {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity))
}

.focus\:border-orange-100:focus {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity))
}

.focus\:border-orange-200:focus {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity))
}

.focus\:border-orange-300:focus {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity))
}

.focus\:border-orange-400:focus {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity))
}

.focus\:border-orange-500:focus {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity))
}

.focus\:border-orange-600:focus {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity))
}

.focus\:border-orange-700:focus {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity))
}

.focus\:border-orange-800:focus {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity))
}

.focus\:border-orange-900:focus {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity))
}

.focus\:border-yellow-100:focus {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity))
}

.focus\:border-yellow-200:focus {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity))
}

.focus\:border-yellow-300:focus {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity))
}

.focus\:border-yellow-400:focus {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity))
}

.focus\:border-yellow-500:focus {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity))
}

.focus\:border-yellow-600:focus {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity))
}

.focus\:border-yellow-700:focus {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity))
}

.focus\:border-yellow-800:focus {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity))
}

.focus\:border-yellow-900:focus {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity))
}

.focus\:border-green-100:focus {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity))
}

.focus\:border-green-200:focus {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity))
}

.focus\:border-green-300:focus {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity))
}

.focus\:border-green-400:focus {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity))
}

.focus\:border-green-500:focus {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity))
}

.focus\:border-green-600:focus {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity))
}

.focus\:border-green-700:focus {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity))
}

.focus\:border-green-800:focus {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity))
}

.focus\:border-green-900:focus {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity))
}

.focus\:border-teal-100:focus {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity))
}

.focus\:border-teal-200:focus {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity))
}

.focus\:border-teal-300:focus {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity))
}

.focus\:border-teal-400:focus {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity))
}

.focus\:border-teal-500:focus {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity))
}

.focus\:border-teal-600:focus {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity))
}

.focus\:border-teal-700:focus {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity))
}

.focus\:border-teal-800:focus {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity))
}

.focus\:border-teal-900:focus {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity))
}

.focus\:border-blue-100:focus {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity))
}

.focus\:border-blue-200:focus {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity))
}

.focus\:border-blue-300:focus {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity))
}

.focus\:border-blue-400:focus {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity))
}

.focus\:border-blue-500:focus {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.focus\:border-blue-600:focus {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.focus\:border-blue-700:focus {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity))
}

.focus\:border-blue-800:focus {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity))
}

.focus\:border-blue-900:focus {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity))
}

.focus\:border-indigo-100:focus {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity))
}

.focus\:border-indigo-200:focus {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity))
}

.focus\:border-indigo-300:focus {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity))
}

.focus\:border-indigo-400:focus {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity))
}

.focus\:border-indigo-500:focus {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity))
}

.focus\:border-indigo-600:focus {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity))
}

.focus\:border-indigo-700:focus {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity))
}

.focus\:border-indigo-800:focus {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity))
}

.focus\:border-indigo-900:focus {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity))
}

.focus\:border-purple-100:focus {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity))
}

.focus\:border-purple-200:focus {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity))
}

.focus\:border-purple-300:focus {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity))
}

.focus\:border-purple-400:focus {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity))
}

.focus\:border-purple-500:focus {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity))
}

.focus\:border-purple-600:focus {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity))
}

.focus\:border-purple-700:focus {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity))
}

.focus\:border-purple-800:focus {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity))
}

.focus\:border-purple-900:focus {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity))
}

.focus\:border-pink-100:focus {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity))
}

.focus\:border-pink-200:focus {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity))
}

.focus\:border-pink-300:focus {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity))
}

.focus\:border-pink-400:focus {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity))
}

.focus\:border-pink-500:focus {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity))
}

.focus\:border-pink-600:focus {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity))
}

.focus\:border-pink-700:focus {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity))
}

.focus\:border-pink-800:focus {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity))
}

.focus\:border-pink-900:focus {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity))
}

.border-opacity-0 {
  --border-opacity: 0
}

.border-opacity-25 {
  --border-opacity: 0.25
}

.border-opacity-50 {
  --border-opacity: 0.5
}

.border-opacity-75 {
  --border-opacity: 0.75
}

.border-opacity-100 {
  --border-opacity: 1
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0
}

.hover\:border-opacity-25:hover {
  --border-opacity: 0.25
}

.hover\:border-opacity-50:hover {
  --border-opacity: 0.5
}

.hover\:border-opacity-75:hover {
  --border-opacity: 0.75
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0
}

.focus\:border-opacity-25:focus {
  --border-opacity: 0.25
}

.focus\:border-opacity-50:focus {
  --border-opacity: 0.5
}

.focus\:border-opacity-75:focus {
  --border-opacity: 0.75
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1
}

.rounded-none {
  border-radius: 0
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-2xl {
  border-radius: 1rem
}

.rounded-3xl {
  border-radius: 1.5rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.rounded-tl-none {
  border-top-left-radius: 0
}

.rounded-tr-none {
  border-top-right-radius: 0
}

.rounded-br-none {
  border-bottom-right-radius: 0
}

.rounded-bl-none {
  border-bottom-left-radius: 0
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem
}

.rounded-tl {
  border-top-left-radius: 0.25rem
}

.rounded-tr {
  border-top-right-radius: 0.25rem
}

.rounded-br {
  border-bottom-right-radius: 0.25rem
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem
}

.rounded-br-xl {
  border-bottom-right-radius: 0.75rem
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem
}

.rounded-tl-full {
  border-top-left-radius: 9999px
}

.rounded-tr-full {
  border-top-right-radius: 9999px
}

.rounded-br-full {
  border-bottom-right-radius: 9999px
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-dotted {
  border-style: dotted
}

.border-double {
  border-style: double
}

.border-none {
  border-style: none
}

.border-0 {
  border-width: 0
}

.border-2 {
  border-width: 2px
}

.border-4 {
  border-width: 4px
}

.border-8 {
  border-width: 8px
}

.border {
  border-width: 1px
}

.border-t-0 {
  border-top-width: 0
}

.border-r-0 {
  border-right-width: 0
}

.border-b-0 {
  border-bottom-width: 0
}

.border-l-0 {
  border-left-width: 0
}

.border-t-2 {
  border-top-width: 2px
}

.border-r-2 {
  border-right-width: 2px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-2 {
  border-left-width: 2px
}

.border-t-4 {
  border-top-width: 4px
}

.border-r-4 {
  border-right-width: 4px
}

.border-b-4 {
  border-bottom-width: 4px
}

.border-l-4 {
  border-left-width: 4px
}

.border-t-8 {
  border-top-width: 8px
}

.border-r-8 {
  border-right-width: 8px
}

.border-b-8 {
  border-bottom-width: 8px
}

.border-l-8 {
  border-left-width: 8px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.box-border {
  box-sizing: border-box
}

.box-content {
  box-sizing: content-box
}

.cursor-auto {
  cursor: auto
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.cursor-wait {
  cursor: wait
}

.cursor-text {
  cursor: text
}

.cursor-move {
  cursor: move
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.table-caption {
  display: table-caption
}

.table-cell {
  display: table-cell
}

.table-column {
  display: table-column
}

.table-column-group {
  display: table-column-group
}

.table-footer-group {
  display: table-footer-group
}

.table-header-group {
  display: table-header-group
}

.table-row-group {
  display: table-row-group
}

.table-row {
  display: table-row
}

.flow-root {
  display: flow-root
}

.grid {
  display: grid
}

.inline-grid {
  display: inline-grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.flex-no-wrap {
  flex-wrap: nowrap
}

.place-items-auto {
  place-items: auto
}

.place-items-start {
  place-items: start
}

.place-items-end {
  place-items: end
}

.place-items-center {
  place-items: center
}

.place-items-stretch {
  place-items: stretch
}

.place-content-center {
  place-content: center
}

.place-content-start {
  place-content: start
}

.place-content-end {
  place-content: end
}

.place-content-between {
  place-content: space-between
}

.place-content-around {
  place-content: space-around
}

.place-content-evenly {
  place-content: space-evenly
}

.place-content-stretch {
  place-content: stretch
}

.place-self-auto {
  place-self: auto
}

.place-self-start {
  place-self: start
}

.place-self-end {
  place-self: end
}

.place-self-center {
  place-self: center
}

.place-self-stretch {
  place-self: stretch
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-baseline {
  align-items: baseline
}

.items-stretch {
  align-items: stretch
}

.content-center {
  align-content: center
}

.content-start {
  align-content: flex-start
}

.content-end {
  align-content: flex-end
}

.content-between {
  align-content: space-between
}

.content-around {
  align-content: space-around
}

.content-evenly {
  align-content: space-evenly
}

.self-auto {
  align-self: auto
}

.self-start {
  align-self: flex-start
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.justify-items-auto {
  justify-items: auto
}

.justify-items-start {
  justify-items: start
}

.justify-items-end {
  justify-items: end
}

.justify-items-center {
  justify-items: center
}

.justify-items-stretch {
  justify-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.justify-evenly {
  justify-content: space-evenly
}

.justify-self-auto {
  justify-self: auto
}

.justify-self-start {
  justify-self: start
}

.justify-self-end {
  justify-self: end
}

.justify-self-center {
  justify-self: center
}

.justify-self-stretch {
  justify-self: stretch
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-none {
  flex: none
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-4 {
  order: 4
}

.order-5 {
  order: 5
}

.order-6 {
  order: 6
}

.order-7 {
  order: 7
}

.order-8 {
  order: 8
}

.order-9 {
  order: 9
}

.order-10 {
  order: 10
}

.order-11 {
  order: 11
}

.order-12 {
  order: 12
}

.order-first {
  order: -9999
}

.order-last {
  order: 9999
}

.order-none {
  order: 0
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.float-none {
  float: none
}

.clearfix:after {
  content: "";
  display: table;
  clear: both
}

.clear-left {
  clear: left
}

.clear-right {
  clear: right
}

.clear-both {
  clear: both
}

.clear-none {
  clear: none
}

.font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.font-hairline {
  font-weight: 100
}

.font-thin {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-black {
  font-weight: 900
}

.hover\:font-hairline:hover {
  font-weight: 100
}

.hover\:font-thin:hover {
  font-weight: 200
}

.hover\:font-light:hover {
  font-weight: 300
}

.hover\:font-normal:hover {
  font-weight: 400
}

.hover\:font-medium:hover {
  font-weight: 500
}

.hover\:font-semibold:hover {
  font-weight: 600
}

.hover\:font-bold:hover {
  font-weight: 700
}

.hover\:font-extrabold:hover {
  font-weight: 800
}

.hover\:font-black:hover {
  font-weight: 900
}

.focus\:font-hairline:focus {
  font-weight: 100
}

.focus\:font-thin:focus {
  font-weight: 200
}

.focus\:font-light:focus {
  font-weight: 300
}

.focus\:font-normal:focus {
  font-weight: 400
}

.focus\:font-medium:focus {
  font-weight: 500
}

.focus\:font-semibold:focus {
  font-weight: 600
}

.focus\:font-bold:focus {
  font-weight: 700
}

.focus\:font-extrabold:focus {
  font-weight: 800
}

.focus\:font-black:focus {
  font-weight: 900
}

.h-0 {
  height: 0
}

.h-1 {
  height: 0.25rem
}

.h-2 {
  height: 0.5rem
}

.h-3 {
  height: 0.75rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-8 {
  height: 2rem
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-16 {
  height: 4rem
}

.h-20 {
  height: 5rem
}

.h-24 {
  height: 6rem
}

.h-32 {
  height: 8rem
}

.h-40 {
  height: 10rem
}

.h-48 {
  height: 12rem
}

.h-56 {
  height: 14rem
}

.h-64 {
  height: 16rem
}

.h-auto {
  height: auto
}

.h-px {
  height: 1px
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.text-xs {
  font-size: 0.75rem
}

.text-sm {
  font-size: 0.875rem
}

.text-base {
  font-size: 1rem
}

.text-lg {
  font-size: 1.125rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem
}

.text-3xl {
  font-size: 1.875rem
}

.text-4xl {
  font-size: 2.25rem
}

.text-5xl {
  font-size: 3rem
}

.text-6xl {
  font-size: 4rem
}

.leading-3 {
  line-height: .75rem
}

.leading-4 {
  line-height: 1rem
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.leading-7 {
  line-height: 1.75rem
}

.leading-8 {
  line-height: 2rem
}

.leading-9 {
  line-height: 2.25rem
}

.leading-10 {
  line-height: 2.5rem
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.leading-snug {
  line-height: 1.375
}

.leading-normal {
  line-height: 1.5
}

.leading-relaxed {
  line-height: 1.625
}

.leading-loose {
  line-height: 2
}

.list-inside {
  list-style-position: inside
}

.list-outside {
  list-style-position: outside
}

.list-none {
  list-style-type: none
}

.list-disc {
  list-style-type: disc
}

.list-decimal {
  list-style-type: decimal
}

.m-0 {
  margin: 0
}

.m-1 {
  margin: 0.25rem
}

.m-2 {
  margin: 0.5rem
}

.m-3 {
  margin: 0.75rem
}

.m-4 {
  margin: 1rem
}

.m-5 {
  margin: 1.25rem
}

.m-6 {
  margin: 1.5rem
}

.m-8 {
  margin: 2rem
}

.m-10 {
  margin: 2.5rem
}

.m-12 {
  margin: 3rem
}

.m-16 {
  margin: 4rem
}

.m-20 {
  margin: 5rem
}

.m-24 {
  margin: 6rem
}

.m-32 {
  margin: 8rem
}

.m-40 {
  margin: 10rem
}

.m-48 {
  margin: 12rem
}

.m-56 {
  margin: 14rem
}

.m-64 {
  margin: 16rem
}

.m-auto {
  margin: auto
}

.m-px {
  margin: 1px
}

.-m-1 {
  margin: -0.25rem
}

.-m-2 {
  margin: -0.5rem
}

.-m-3 {
  margin: -0.75rem
}

.-m-4 {
  margin: -1rem
}

.-m-5 {
  margin: -1.25rem
}

.-m-6 {
  margin: -1.5rem
}

.-m-8 {
  margin: -2rem
}

.-m-10 {
  margin: -2.5rem
}

.-m-12 {
  margin: -3rem
}

.-m-16 {
  margin: -4rem
}

.-m-20 {
  margin: -5rem
}

.-m-24 {
  margin: -6rem
}

.-m-32 {
  margin: -8rem
}

.-m-40 {
  margin: -10rem
}

.-m-48 {
  margin: -12rem
}

.-m-56 {
  margin: -14rem
}

.-m-64 {
  margin: -16rem
}

.-m-px {
  margin: -1px
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-0 {
  margin-left: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.ml-3 {
  margin-left: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mr-4 {
  margin-right: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.ml-5 {
  margin-left: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mr-8 {
  margin-right: 2rem
}

.mb-8 {
  margin-bottom: 2rem
}

.ml-8 {
  margin-left: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mr-10 {
  margin-right: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.ml-10 {
  margin-left: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mr-12 {
  margin-right: 3rem
}

.mb-12 {
  margin-bottom: 3rem
}

.ml-12 {
  margin-left: 3rem
}

.mt-16 {
  margin-top: 4rem
}

.mr-16 {
  margin-right: 4rem
}

.mb-16 {
  margin-bottom: 4rem
}

.ml-16 {
  margin-left: 4rem
}

.mt-20 {
  margin-top: 5rem
}

.mr-20 {
  margin-right: 5rem
}

.mb-20 {
  margin-bottom: 5rem
}

.ml-20 {
  margin-left: 5rem
}

.mt-24 {
  margin-top: 6rem
}

.mr-24 {
  margin-right: 6rem
}

.mb-24 {
  margin-bottom: 6rem
}

.ml-24 {
  margin-left: 6rem
}

.mt-32 {
  margin-top: 8rem
}

.mr-32 {
  margin-right: 8rem
}

.mb-32 {
  margin-bottom: 8rem
}

.ml-32 {
  margin-left: 8rem
}

.mt-40 {
  margin-top: 10rem
}

.mr-40 {
  margin-right: 10rem
}

.mb-40 {
  margin-bottom: 10rem
}

.ml-40 {
  margin-left: 10rem
}

.mt-48 {
  margin-top: 12rem
}

.mr-48 {
  margin-right: 12rem
}

.mb-48 {
  margin-bottom: 12rem
}

.ml-48 {
  margin-left: 12rem
}

.mt-56 {
  margin-top: 14rem
}

.mr-56 {
  margin-right: 14rem
}

.mb-56 {
  margin-bottom: 14rem
}

.ml-56 {
  margin-left: 14rem
}

.mt-64 {
  margin-top: 16rem
}

.mr-64 {
  margin-right: 16rem
}

.mb-64 {
  margin-bottom: 16rem
}

.ml-64 {
  margin-left: 16rem
}

.mt-auto {
  margin-top: auto
}

.mr-auto {
  margin-right: auto
}

.mb-auto {
  margin-bottom: auto
}

.ml-auto {
  margin-left: auto
}

.mt-px {
  margin-top: 1px
}

.mr-px {
  margin-right: 1px
}

.mb-px {
  margin-bottom: 1px
}

.ml-px {
  margin-left: 1px
}

.-mt-1 {
  margin-top: -0.25rem
}

.-mr-1 {
  margin-right: -0.25rem
}

.-mb-1 {
  margin-bottom: -0.25rem
}

.-ml-1 {
  margin-left: -0.25rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-mr-2 {
  margin-right: -0.5rem
}

.-mb-2 {
  margin-bottom: -0.5rem
}

.-ml-2 {
  margin-left: -0.5rem
}

.-mt-3 {
  margin-top: -0.75rem
}

.-mr-3 {
  margin-right: -0.75rem
}

.-mb-3 {
  margin-bottom: -0.75rem
}

.-ml-3 {
  margin-left: -0.75rem
}

.-mt-4 {
  margin-top: -1rem
}

.-mr-4 {
  margin-right: -1rem
}

.-mb-4 {
  margin-bottom: -1rem
}

.-ml-4 {
  margin-left: -1rem
}

.-mt-5 {
  margin-top: -1.25rem
}

.-mr-5 {
  margin-right: -1.25rem
}

.-mb-5 {
  margin-bottom: -1.25rem
}

.-ml-5 {
  margin-left: -1.25rem
}

.-mt-6 {
  margin-top: -1.5rem
}

.-mr-6 {
  margin-right: -1.5rem
}

.-mb-6 {
  margin-bottom: -1.5rem
}

.-ml-6 {
  margin-left: -1.5rem
}

.-mt-8 {
  margin-top: -2rem
}

.-mr-8 {
  margin-right: -2rem
}

.-mb-8 {
  margin-bottom: -2rem
}

.-ml-8 {
  margin-left: -2rem
}

.-mt-10 {
  margin-top: -2.5rem
}

.-mr-10 {
  margin-right: -2.5rem
}

.-mb-10 {
  margin-bottom: -2.5rem
}

.-ml-10 {
  margin-left: -2.5rem
}

.-mt-12 {
  margin-top: -3rem
}

.-mr-12 {
  margin-right: -3rem
}

.-mb-12 {
  margin-bottom: -3rem
}

.-ml-12 {
  margin-left: -3rem
}

.-mt-16 {
  margin-top: -4rem
}

.-mr-16 {
  margin-right: -4rem
}

.-mb-16 {
  margin-bottom: -4rem
}

.-ml-16 {
  margin-left: -4rem
}

.-mt-20 {
  margin-top: -5rem
}

.-mr-20 {
  margin-right: -5rem
}

.-mb-20 {
  margin-bottom: -5rem
}

.-ml-20 {
  margin-left: -5rem
}

.-mt-24 {
  margin-top: -6rem
}

.-mr-24 {
  margin-right: -6rem
}

.-mb-24 {
  margin-bottom: -6rem
}

.-ml-24 {
  margin-left: -6rem
}

.-mt-32 {
  margin-top: -8rem
}

.-mr-32 {
  margin-right: -8rem
}

.-mb-32 {
  margin-bottom: -8rem
}

.-ml-32 {
  margin-left: -8rem
}

.-mt-40 {
  margin-top: -10rem
}

.-mr-40 {
  margin-right: -10rem
}

.-mb-40 {
  margin-bottom: -10rem
}

.-ml-40 {
  margin-left: -10rem
}

.-mt-48 {
  margin-top: -12rem
}

.-mr-48 {
  margin-right: -12rem
}

.-mb-48 {
  margin-bottom: -12rem
}

.-ml-48 {
  margin-left: -12rem
}

.-mt-56 {
  margin-top: -14rem
}

.-mr-56 {
  margin-right: -14rem
}

.-mb-56 {
  margin-bottom: -14rem
}

.-ml-56 {
  margin-left: -14rem
}

.-mt-64 {
  margin-top: -16rem
}

.-mr-64 {
  margin-right: -16rem
}

.-mb-64 {
  margin-bottom: -16rem
}

.-ml-64 {
  margin-left: -16rem
}

.-mt-px {
  margin-top: -1px
}

.-mr-px {
  margin-right: -1px
}

.-mb-px {
  margin-bottom: -1px
}

.-ml-px {
  margin-left: -1px
}

.max-h-full {
  max-height: 100%
}

.max-h-screen {
  max-height: 100vh
}

.max-w-none {
  max-width: none
}

.max-w-xs {
  max-width: 20rem
}

.max-w-sm {
  max-width: 24rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xl {
  max-width: 36rem
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-3xl {
  max-width: 48rem
}

.max-w-4xl {
  max-width: 56rem
}

.max-w-5xl {
  max-width: 64rem
}

.max-w-6xl {
  max-width: 72rem
}

.max-w-full {
  max-width: 100%
}

.max-w-screen-sm {
  max-width: 640px
}

.max-w-screen-md {
  max-width: 768px
}

.max-w-screen-lg {
  max-width: 1024px
}

.max-w-screen-xl {
  max-width: 1280px
}

.min-h-0 {
  min-height: 0
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.min-w-0 {
  min-width: 0
}

.min-w-full {
  min-width: 100%
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.object-fill {
  object-fit: fill
}

.object-none {
  object-fit: none
}

.object-scale-down {
  object-fit: scale-down
}

.object-bottom {
  object-position: bottom
}

.object-center {
  object-position: center
}

.object-left {
  object-position: left
}

.object-left-bottom {
  object-position: left bottom
}

.object-left-top {
  object-position: left top
}

.object-right {
  object-position: right
}

.object-right-bottom {
  object-position: right bottom
}

.object-right-top {
  object-position: right top
}

.object-top {
  object-position: top
}

.opacity-0 {
  opacity: 0
}

.opacity-25 {
  opacity: 0.25
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.opacity-100 {
  opacity: 1
}

.hover\:opacity-0:hover {
  opacity: 0
}

.hover\:opacity-25:hover {
  opacity: 0.25
}

.hover\:opacity-50:hover {
  opacity: 0.5
}

.hover\:opacity-75:hover {
  opacity: 0.75
}

.hover\:opacity-100:hover {
  opacity: 1
}

.focus\:opacity-0:focus {
  opacity: 0
}

.focus\:opacity-25:focus {
  opacity: 0.25
}

.focus\:opacity-50:focus {
  opacity: 0.5
}

.focus\:opacity-75:focus {
  opacity: 0.75
}

.focus\:opacity-100:focus {
  opacity: 1
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px
}

.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px
}

.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-x-visible {
  overflow-x: visible
}

.overflow-y-visible {
  overflow-y: visible
}

.overflow-x-scroll {
  overflow-x: scroll
}

.overflow-y-scroll {
  overflow-y: scroll
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto
}

.overscroll-auto {
  -ms-scroll-chaining: chained;
      overscroll-behavior: auto
}

.overscroll-contain {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain
}

.overscroll-none {
  -ms-scroll-chaining: none;
      overscroll-behavior: none
}

.overscroll-y-auto {
  overscroll-behavior-y: auto
}

.overscroll-y-contain {
  overscroll-behavior-y: contain
}

.overscroll-y-none {
  overscroll-behavior-y: none
}

.overscroll-x-auto {
  overscroll-behavior-x: auto
}

.overscroll-x-contain {
  overscroll-behavior-x: contain
}

.overscroll-x-none {
  overscroll-behavior-x: none
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-8 {
  padding: 2rem
}

.p-10 {
  padding: 2.5rem
}

.p-12 {
  padding: 3rem
}

.p-16 {
  padding: 4rem
}

.p-20 {
  padding: 5rem
}

.p-24 {
  padding: 6rem
}

.p-32 {
  padding: 8rem
}

.p-40 {
  padding: 10rem
}

.p-48 {
  padding: 12rem
}

.p-56 {
  padding: 14rem
}

.p-64 {
  padding: 16rem
}

.p-px {
  padding: 1px
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

.px-px {
  padding-left: 1px;
  padding-right: 1px
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.25rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pb-3 {
  padding-bottom: 0.75rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pr-4 {
  padding-right: 1rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-4 {
  padding-left: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pt-8 {
  padding-top: 2rem
}

.pr-8 {
  padding-right: 2rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pl-8 {
  padding-left: 2rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pr-12 {
  padding-right: 3rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pl-12 {
  padding-left: 3rem
}

.pt-16 {
  padding-top: 4rem
}

.pr-16 {
  padding-right: 4rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pl-16 {
  padding-left: 4rem
}

.pt-20 {
  padding-top: 5rem
}

.pr-20 {
  padding-right: 5rem
}

.pb-20 {
  padding-bottom: 5rem
}

.pl-20 {
  padding-left: 5rem
}

.pt-24 {
  padding-top: 6rem
}

.pr-24 {
  padding-right: 6rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pl-24 {
  padding-left: 6rem
}

.pt-32 {
  padding-top: 8rem
}

.pr-32 {
  padding-right: 8rem
}

.pb-32 {
  padding-bottom: 8rem
}

.pl-32 {
  padding-left: 8rem
}

.pt-40 {
  padding-top: 10rem
}

.pr-40 {
  padding-right: 10rem
}

.pb-40 {
  padding-bottom: 10rem
}

.pl-40 {
  padding-left: 10rem
}

.pt-48 {
  padding-top: 12rem
}

.pr-48 {
  padding-right: 12rem
}

.pb-48 {
  padding-bottom: 12rem
}

.pl-48 {
  padding-left: 12rem
}

.pt-56 {
  padding-top: 14rem
}

.pr-56 {
  padding-right: 14rem
}

.pb-56 {
  padding-bottom: 14rem
}

.pl-56 {
  padding-left: 14rem
}

.pt-64 {
  padding-top: 16rem
}

.pr-64 {
  padding-right: 16rem
}

.pb-64 {
  padding-bottom: 16rem
}

.pl-64 {
  padding-left: 16rem
}

.pt-px {
  padding-top: 1px
}

.pr-px {
  padding-right: 1px
}

.pb-px {
  padding-bottom: 1px
}

.pl-px {
  padding-left: 1px
}

.placeholder-transparent:-ms-input-placeholder {
  color: transparent
}

.placeholder-transparent::-ms-input-placeholder {
  color: transparent
}

.placeholder-transparent::placeholder {
  color: transparent
}

.placeholder-current:-ms-input-placeholder {
  color: currentColor
}

.placeholder-current::-ms-input-placeholder {
  color: currentColor
}

.placeholder-current::placeholder {
  color: currentColor
}

.placeholder-black:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.placeholder-black::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.placeholder-black::placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.placeholder-white:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.placeholder-white::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.placeholder-white::placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.placeholder-gray-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.placeholder-gray-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.placeholder-gray-100::placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.placeholder-gray-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.placeholder-gray-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.placeholder-gray-200::placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.placeholder-gray-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.placeholder-gray-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.placeholder-gray-300::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.placeholder-gray-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.placeholder-gray-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.placeholder-gray-400::placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.placeholder-gray-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.placeholder-gray-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.placeholder-gray-500::placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.placeholder-gray-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.placeholder-gray-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.placeholder-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.placeholder-gray-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.placeholder-gray-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.placeholder-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.placeholder-gray-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.placeholder-gray-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.placeholder-gray-800::placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.placeholder-gray-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.placeholder-gray-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.placeholder-gray-900::placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.placeholder-red-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.placeholder-red-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.placeholder-red-100::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.placeholder-red-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.placeholder-red-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.placeholder-red-200::placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.placeholder-red-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.placeholder-red-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.placeholder-red-300::placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.placeholder-red-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.placeholder-red-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.placeholder-red-400::placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.placeholder-red-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.placeholder-red-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.placeholder-red-500::placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.placeholder-red-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.placeholder-red-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.placeholder-red-600::placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.placeholder-red-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.placeholder-red-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.placeholder-red-700::placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.placeholder-red-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.placeholder-red-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.placeholder-red-800::placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.placeholder-red-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.placeholder-red-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.placeholder-red-900::placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.placeholder-orange-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.placeholder-orange-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.placeholder-orange-100::placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.placeholder-orange-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.placeholder-orange-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.placeholder-orange-200::placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.placeholder-orange-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.placeholder-orange-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.placeholder-orange-300::placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.placeholder-orange-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.placeholder-orange-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.placeholder-orange-400::placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.placeholder-orange-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.placeholder-orange-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.placeholder-orange-500::placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.placeholder-orange-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.placeholder-orange-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.placeholder-orange-600::placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.placeholder-orange-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.placeholder-orange-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.placeholder-orange-700::placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.placeholder-orange-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.placeholder-orange-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.placeholder-orange-800::placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.placeholder-orange-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.placeholder-orange-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.placeholder-orange-900::placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.placeholder-yellow-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.placeholder-yellow-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.placeholder-yellow-100::placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.placeholder-yellow-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.placeholder-yellow-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.placeholder-yellow-200::placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.placeholder-yellow-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.placeholder-yellow-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.placeholder-yellow-300::placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.placeholder-yellow-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.placeholder-yellow-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.placeholder-yellow-400::placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.placeholder-yellow-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.placeholder-yellow-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.placeholder-yellow-500::placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.placeholder-yellow-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.placeholder-yellow-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.placeholder-yellow-600::placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.placeholder-yellow-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.placeholder-yellow-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.placeholder-yellow-700::placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.placeholder-yellow-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.placeholder-yellow-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.placeholder-yellow-800::placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.placeholder-yellow-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.placeholder-yellow-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.placeholder-yellow-900::placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.placeholder-green-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.placeholder-green-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.placeholder-green-100::placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.placeholder-green-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.placeholder-green-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.placeholder-green-200::placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.placeholder-green-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.placeholder-green-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.placeholder-green-300::placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.placeholder-green-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.placeholder-green-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.placeholder-green-400::placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.placeholder-green-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.placeholder-green-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.placeholder-green-500::placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.placeholder-green-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.placeholder-green-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.placeholder-green-600::placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.placeholder-green-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.placeholder-green-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.placeholder-green-700::placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.placeholder-green-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.placeholder-green-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.placeholder-green-800::placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.placeholder-green-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.placeholder-green-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.placeholder-green-900::placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.placeholder-teal-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.placeholder-teal-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.placeholder-teal-100::placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.placeholder-teal-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.placeholder-teal-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.placeholder-teal-200::placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.placeholder-teal-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.placeholder-teal-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.placeholder-teal-300::placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.placeholder-teal-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.placeholder-teal-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.placeholder-teal-400::placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.placeholder-teal-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.placeholder-teal-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.placeholder-teal-500::placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.placeholder-teal-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.placeholder-teal-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.placeholder-teal-600::placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.placeholder-teal-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.placeholder-teal-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.placeholder-teal-700::placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.placeholder-teal-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.placeholder-teal-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.placeholder-teal-800::placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.placeholder-teal-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.placeholder-teal-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.placeholder-teal-900::placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.placeholder-blue-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.placeholder-blue-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.placeholder-blue-100::placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.placeholder-blue-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.placeholder-blue-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.placeholder-blue-200::placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.placeholder-blue-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.placeholder-blue-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.placeholder-blue-300::placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.placeholder-blue-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.placeholder-blue-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.placeholder-blue-400::placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.placeholder-blue-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.placeholder-blue-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.placeholder-blue-500::placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.placeholder-blue-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.placeholder-blue-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.placeholder-blue-600::placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.placeholder-blue-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.placeholder-blue-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.placeholder-blue-700::placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.placeholder-blue-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.placeholder-blue-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.placeholder-blue-800::placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.placeholder-blue-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.placeholder-blue-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.placeholder-blue-900::placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.placeholder-indigo-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.placeholder-indigo-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.placeholder-indigo-100::placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.placeholder-indigo-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.placeholder-indigo-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.placeholder-indigo-200::placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.placeholder-indigo-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.placeholder-indigo-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.placeholder-indigo-300::placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.placeholder-indigo-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.placeholder-indigo-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.placeholder-indigo-400::placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.placeholder-indigo-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.placeholder-indigo-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.placeholder-indigo-500::placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.placeholder-indigo-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.placeholder-indigo-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.placeholder-indigo-600::placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.placeholder-indigo-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.placeholder-indigo-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.placeholder-indigo-700::placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.placeholder-indigo-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.placeholder-indigo-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.placeholder-indigo-800::placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.placeholder-indigo-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.placeholder-indigo-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.placeholder-indigo-900::placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.placeholder-purple-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.placeholder-purple-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.placeholder-purple-100::placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.placeholder-purple-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.placeholder-purple-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.placeholder-purple-200::placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.placeholder-purple-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.placeholder-purple-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.placeholder-purple-300::placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.placeholder-purple-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.placeholder-purple-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.placeholder-purple-400::placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.placeholder-purple-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.placeholder-purple-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.placeholder-purple-500::placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.placeholder-purple-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.placeholder-purple-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.placeholder-purple-600::placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.placeholder-purple-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.placeholder-purple-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.placeholder-purple-700::placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.placeholder-purple-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.placeholder-purple-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.placeholder-purple-800::placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.placeholder-purple-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.placeholder-purple-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.placeholder-purple-900::placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.placeholder-pink-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.placeholder-pink-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.placeholder-pink-100::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.placeholder-pink-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.placeholder-pink-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.placeholder-pink-200::placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.placeholder-pink-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.placeholder-pink-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.placeholder-pink-300::placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.placeholder-pink-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.placeholder-pink-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.placeholder-pink-400::placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.placeholder-pink-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.placeholder-pink-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.placeholder-pink-500::placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.placeholder-pink-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.placeholder-pink-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.placeholder-pink-600::placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.placeholder-pink-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.placeholder-pink-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.placeholder-pink-700::placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.placeholder-pink-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.placeholder-pink-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.placeholder-pink-800::placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.placeholder-pink-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.placeholder-pink-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.placeholder-pink-900::placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:placeholder-transparent:focus:-ms-input-placeholder {
  color: transparent
}

.focus\:placeholder-transparent:focus::-ms-input-placeholder {
  color: transparent
}

.focus\:placeholder-transparent:focus::placeholder {
  color: transparent
}

.focus\:placeholder-current:focus:-ms-input-placeholder {
  color: currentColor
}

.focus\:placeholder-current:focus::-ms-input-placeholder {
  color: currentColor
}

.focus\:placeholder-current:focus::placeholder {
  color: currentColor
}

.focus\:placeholder-black:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:placeholder-black:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:placeholder-black:focus::placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:placeholder-white:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:placeholder-white:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:placeholder-white:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:placeholder-gray-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:placeholder-gray-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:placeholder-gray-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:placeholder-gray-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:placeholder-gray-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:placeholder-gray-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:placeholder-gray-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:placeholder-gray-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:placeholder-gray-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:placeholder-gray-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:placeholder-gray-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:placeholder-gray-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:placeholder-gray-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:placeholder-gray-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:placeholder-gray-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:placeholder-gray-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:placeholder-gray-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:placeholder-gray-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:placeholder-gray-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:placeholder-gray-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:placeholder-gray-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:placeholder-gray-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:placeholder-gray-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:placeholder-gray-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:placeholder-gray-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:placeholder-gray-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:placeholder-gray-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:placeholder-red-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:placeholder-red-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:placeholder-red-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:placeholder-red-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:placeholder-red-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:placeholder-red-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:placeholder-red-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:placeholder-red-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:placeholder-red-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:placeholder-red-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:placeholder-red-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:placeholder-red-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:placeholder-red-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:placeholder-red-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:placeholder-red-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:placeholder-red-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:placeholder-red-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:placeholder-red-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:placeholder-red-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:placeholder-red-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:placeholder-red-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:placeholder-red-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:placeholder-red-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:placeholder-red-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:placeholder-red-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:placeholder-red-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:placeholder-red-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:placeholder-orange-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:placeholder-orange-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:placeholder-orange-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:placeholder-orange-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:placeholder-orange-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:placeholder-orange-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:placeholder-orange-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:placeholder-orange-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:placeholder-orange-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:placeholder-orange-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:placeholder-orange-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:placeholder-orange-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:placeholder-orange-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:placeholder-orange-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:placeholder-orange-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:placeholder-orange-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:placeholder-orange-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:placeholder-orange-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:placeholder-orange-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:placeholder-orange-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:placeholder-orange-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:placeholder-orange-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:placeholder-orange-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:placeholder-orange-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:placeholder-orange-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:placeholder-orange-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:placeholder-orange-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:placeholder-yellow-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:placeholder-green-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:placeholder-green-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:placeholder-green-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:placeholder-green-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:placeholder-green-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:placeholder-green-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:placeholder-green-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:placeholder-green-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:placeholder-green-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:placeholder-green-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:placeholder-green-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:placeholder-green-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:placeholder-green-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:placeholder-green-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:placeholder-green-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:placeholder-green-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:placeholder-green-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:placeholder-green-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:placeholder-green-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:placeholder-green-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:placeholder-green-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:placeholder-green-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:placeholder-green-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:placeholder-green-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:placeholder-green-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:placeholder-green-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:placeholder-green-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:placeholder-teal-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:placeholder-teal-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:placeholder-teal-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:placeholder-teal-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:placeholder-teal-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:placeholder-teal-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:placeholder-teal-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:placeholder-teal-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:placeholder-teal-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:placeholder-teal-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:placeholder-teal-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:placeholder-teal-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:placeholder-teal-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:placeholder-teal-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:placeholder-teal-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:placeholder-teal-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:placeholder-teal-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:placeholder-teal-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:placeholder-teal-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:placeholder-teal-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:placeholder-teal-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:placeholder-teal-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:placeholder-teal-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:placeholder-teal-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:placeholder-teal-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:placeholder-teal-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:placeholder-teal-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:placeholder-blue-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:placeholder-blue-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:placeholder-blue-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:placeholder-blue-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:placeholder-blue-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:placeholder-blue-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:placeholder-blue-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:placeholder-blue-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:placeholder-blue-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:placeholder-blue-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:placeholder-blue-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:placeholder-blue-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:placeholder-blue-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:placeholder-blue-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:placeholder-blue-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:placeholder-blue-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:placeholder-blue-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:placeholder-blue-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:placeholder-blue-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:placeholder-blue-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:placeholder-blue-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:placeholder-blue-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:placeholder-blue-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:placeholder-blue-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:placeholder-blue-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:placeholder-blue-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:placeholder-blue-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:placeholder-indigo-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:placeholder-purple-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:placeholder-purple-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:placeholder-purple-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:placeholder-purple-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:placeholder-purple-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:placeholder-purple-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:placeholder-purple-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:placeholder-purple-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:placeholder-purple-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:placeholder-purple-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:placeholder-purple-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:placeholder-purple-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:placeholder-purple-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:placeholder-purple-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:placeholder-purple-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:placeholder-purple-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:placeholder-purple-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:placeholder-purple-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:placeholder-purple-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:placeholder-purple-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:placeholder-purple-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:placeholder-purple-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:placeholder-purple-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:placeholder-purple-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:placeholder-purple-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:placeholder-purple-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:placeholder-purple-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:placeholder-pink-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:placeholder-pink-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:placeholder-pink-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:placeholder-pink-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:placeholder-pink-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:placeholder-pink-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:placeholder-pink-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:placeholder-pink-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:placeholder-pink-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:placeholder-pink-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:placeholder-pink-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:placeholder-pink-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:placeholder-pink-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:placeholder-pink-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:placeholder-pink-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:placeholder-pink-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:placeholder-pink-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:placeholder-pink-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:placeholder-pink-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:placeholder-pink-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:placeholder-pink-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:placeholder-pink-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:placeholder-pink-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:placeholder-pink-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:placeholder-pink-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:placeholder-pink-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:placeholder-pink-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.placeholder-opacity-0:-ms-input-placeholder {
  --placeholder-opacity: 0
}

.placeholder-opacity-0::-ms-input-placeholder {
  --placeholder-opacity: 0
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0
}

.placeholder-opacity-25:-ms-input-placeholder {
  --placeholder-opacity: 0.25
}

.placeholder-opacity-25::-ms-input-placeholder {
  --placeholder-opacity: 0.25
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25
}

.placeholder-opacity-50:-ms-input-placeholder {
  --placeholder-opacity: 0.5
}

.placeholder-opacity-50::-ms-input-placeholder {
  --placeholder-opacity: 0.5
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5
}

.placeholder-opacity-75:-ms-input-placeholder {
  --placeholder-opacity: 0.75
}

.placeholder-opacity-75::-ms-input-placeholder {
  --placeholder-opacity: 0.75
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75
}

.placeholder-opacity-100:-ms-input-placeholder {
  --placeholder-opacity: 1
}

.placeholder-opacity-100::-ms-input-placeholder {
  --placeholder-opacity: 1
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1
}

.focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
  --placeholder-opacity: 0
}

.focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
  --placeholder-opacity: 0
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0
}

.focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.25
}

.focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.25
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25
}

.focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.5
}

.focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.5
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5
}

.focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.75
}

.focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.75
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75
}

.focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1
}

.focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.inset-x-0 {
  right: 0;
  left: 0
}

.inset-y-auto {
  top: auto;
  bottom: auto
}

.inset-x-auto {
  right: auto;
  left: auto
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.top-auto {
  top: auto
}

.right-auto {
  right: auto
}

.bottom-auto {
  bottom: auto
}

.left-auto {
  left: auto
}

.resize-none {
  resize: none
}

.resize-y {
  resize: vertical
}

.resize-x {
  resize: horizontal
}

.resize {
  resize: both
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.shadow-none {
  box-shadow: none
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.hover\:shadow-none:hover {
  box-shadow: none
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.focus\:shadow-none:focus {
  box-shadow: none
}

.fill-current {
  fill: currentColor
}

.stroke-current {
  stroke: currentColor
}

.stroke-0 {
  stroke-width: 0
}

.stroke-1 {
  stroke-width: 1
}

.stroke-2 {
  stroke-width: 2
}

.table-auto {
  table-layout: auto
}

.table-fixed {
  table-layout: fixed
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-transparent {
  color: transparent
}

.text-current {
  color: currentColor
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-gray-100 {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity))
}

.text-gray-200 {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity))
}

.text-gray-300 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity))
}

.text-gray-400 {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity))
}

.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity))
}

.text-red-100 {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity))
}

.text-red-200 {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity))
}

.text-red-300 {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity))
}

.text-red-400 {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity))
}

.text-red-500 {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.text-red-600 {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.text-red-700 {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.text-red-800 {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity))
}

.text-red-900 {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity))
}

.text-orange-100 {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity))
}

.text-orange-200 {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity))
}

.text-orange-300 {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity))
}

.text-orange-400 {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity))
}

.text-orange-500 {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.text-orange-600 {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity))
}

.text-orange-700 {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.text-orange-800 {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity))
}

.text-orange-900 {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity))
}

.text-yellow-100 {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity))
}

.text-yellow-200 {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity))
}

.text-yellow-300 {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity))
}

.text-yellow-400 {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity))
}

.text-yellow-500 {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity))
}

.text-yellow-600 {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity))
}

.text-yellow-700 {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity))
}

.text-yellow-800 {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity))
}

.text-yellow-900 {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity))
}

.text-green-100 {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity))
}

.text-green-200 {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity))
}

.text-green-300 {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity))
}

.text-green-400 {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity))
}

.text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.text-green-600 {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.text-green-700 {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.text-green-800 {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity))
}

.text-green-900 {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity))
}

.text-teal-100 {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity))
}

.text-teal-200 {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity))
}

.text-teal-300 {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity))
}

.text-teal-400 {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity))
}

.text-teal-500 {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity))
}

.text-teal-600 {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity))
}

.text-teal-700 {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity))
}

.text-teal-800 {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity))
}

.text-teal-900 {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity))
}

.text-blue-100 {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity))
}

.text-blue-200 {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity))
}

.text-blue-300 {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity))
}

.text-blue-400 {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity))
}

.text-blue-500 {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.text-blue-600 {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.text-blue-700 {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.text-blue-800 {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity))
}

.text-blue-900 {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity))
}

.text-indigo-100 {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity))
}

.text-indigo-200 {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity))
}

.text-indigo-300 {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity))
}

.text-indigo-400 {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity))
}

.text-indigo-500 {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity))
}

.text-indigo-600 {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity))
}

.text-indigo-700 {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity))
}

.text-indigo-800 {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity))
}

.text-indigo-900 {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity))
}

.text-purple-100 {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity))
}

.text-purple-200 {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity))
}

.text-purple-300 {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity))
}

.text-purple-400 {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity))
}

.text-purple-500 {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity))
}

.text-purple-600 {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity))
}

.text-purple-700 {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity))
}

.text-purple-800 {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity))
}

.text-purple-900 {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity))
}

.text-pink-100 {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity))
}

.text-pink-200 {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity))
}

.text-pink-300 {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity))
}

.text-pink-400 {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity))
}

.text-pink-500 {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity))
}

.text-pink-600 {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity))
}

.text-pink-700 {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity))
}

.text-pink-800 {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity))
}

.text-pink-900 {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity))
}

.hover\:text-transparent:hover {
  color: transparent
}

.hover\:text-current:hover {
  color: currentColor
}

.hover\:text-black:hover {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.hover\:text-gray-100:hover {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity))
}

.hover\:text-gray-200:hover {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity))
}

.hover\:text-gray-300:hover {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity))
}

.hover\:text-gray-400:hover {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity))
}

.hover\:text-gray-500:hover {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.hover\:text-gray-600:hover {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.hover\:text-gray-700:hover {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.hover\:text-gray-800:hover {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.hover\:text-gray-900:hover {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity))
}

.hover\:text-red-100:hover {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity))
}

.hover\:text-red-200:hover {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity))
}

.hover\:text-red-300:hover {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity))
}

.hover\:text-red-400:hover {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity))
}

.hover\:text-red-500:hover {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.hover\:text-red-600:hover {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.hover\:text-red-700:hover {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.hover\:text-red-800:hover {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity))
}

.hover\:text-red-900:hover {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity))
}

.hover\:text-orange-100:hover {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity))
}

.hover\:text-orange-200:hover {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity))
}

.hover\:text-orange-300:hover {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity))
}

.hover\:text-orange-400:hover {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity))
}

.hover\:text-orange-500:hover {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.hover\:text-orange-600:hover {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity))
}

.hover\:text-orange-700:hover {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.hover\:text-orange-800:hover {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity))
}

.hover\:text-orange-900:hover {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity))
}

.hover\:text-yellow-100:hover {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity))
}

.hover\:text-yellow-200:hover {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity))
}

.hover\:text-yellow-300:hover {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity))
}

.hover\:text-yellow-400:hover {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity))
}

.hover\:text-yellow-500:hover {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity))
}

.hover\:text-yellow-600:hover {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity))
}

.hover\:text-yellow-700:hover {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity))
}

.hover\:text-yellow-800:hover {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity))
}

.hover\:text-yellow-900:hover {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity))
}

.hover\:text-green-100:hover {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity))
}

.hover\:text-green-200:hover {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity))
}

.hover\:text-green-300:hover {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity))
}

.hover\:text-green-400:hover {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity))
}

.hover\:text-green-500:hover {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.hover\:text-green-600:hover {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.hover\:text-green-700:hover {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.hover\:text-green-800:hover {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity))
}

.hover\:text-green-900:hover {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity))
}

.hover\:text-teal-100:hover {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity))
}

.hover\:text-teal-200:hover {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity))
}

.hover\:text-teal-300:hover {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity))
}

.hover\:text-teal-400:hover {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity))
}

.hover\:text-teal-500:hover {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity))
}

.hover\:text-teal-600:hover {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity))
}

.hover\:text-teal-700:hover {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity))
}

.hover\:text-teal-800:hover {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity))
}

.hover\:text-teal-900:hover {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity))
}

.hover\:text-blue-100:hover {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity))
}

.hover\:text-blue-200:hover {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity))
}

.hover\:text-blue-300:hover {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity))
}

.hover\:text-blue-400:hover {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity))
}

.hover\:text-blue-500:hover {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.hover\:text-blue-600:hover {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.hover\:text-blue-700:hover {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.hover\:text-blue-800:hover {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity))
}

.hover\:text-blue-900:hover {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity))
}

.hover\:text-indigo-100:hover {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity))
}

.hover\:text-indigo-200:hover {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity))
}

.hover\:text-indigo-300:hover {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity))
}

.hover\:text-indigo-400:hover {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity))
}

.hover\:text-indigo-500:hover {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity))
}

.hover\:text-indigo-600:hover {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity))
}

.hover\:text-indigo-700:hover {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity))
}

.hover\:text-indigo-800:hover {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity))
}

.hover\:text-indigo-900:hover {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity))
}

.hover\:text-purple-100:hover {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity))
}

.hover\:text-purple-200:hover {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity))
}

.hover\:text-purple-300:hover {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity))
}

.hover\:text-purple-400:hover {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity))
}

.hover\:text-purple-500:hover {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity))
}

.hover\:text-purple-600:hover {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity))
}

.hover\:text-purple-700:hover {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity))
}

.hover\:text-purple-800:hover {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity))
}

.hover\:text-purple-900:hover {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity))
}

.hover\:text-pink-100:hover {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity))
}

.hover\:text-pink-200:hover {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity))
}

.hover\:text-pink-300:hover {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity))
}

.hover\:text-pink-400:hover {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity))
}

.hover\:text-pink-500:hover {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity))
}

.hover\:text-pink-600:hover {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity))
}

.hover\:text-pink-700:hover {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity))
}

.hover\:text-pink-800:hover {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity))
}

.hover\:text-pink-900:hover {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity))
}

.focus\:text-transparent:focus {
  color: transparent
}

.focus\:text-current:focus {
  color: currentColor
}

.focus\:text-black:focus {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.focus\:text-white:focus {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.focus\:text-gray-100:focus {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity))
}

.focus\:text-gray-200:focus {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity))
}

.focus\:text-gray-300:focus {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity))
}

.focus\:text-gray-400:focus {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity))
}

.focus\:text-gray-500:focus {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.focus\:text-gray-600:focus {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.focus\:text-gray-700:focus {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.focus\:text-gray-800:focus {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.focus\:text-gray-900:focus {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity))
}

.focus\:text-red-100:focus {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity))
}

.focus\:text-red-200:focus {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity))
}

.focus\:text-red-300:focus {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity))
}

.focus\:text-red-400:focus {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity))
}

.focus\:text-red-500:focus {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.focus\:text-red-600:focus {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.focus\:text-red-700:focus {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.focus\:text-red-800:focus {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity))
}

.focus\:text-red-900:focus {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity))
}

.focus\:text-orange-100:focus {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity))
}

.focus\:text-orange-200:focus {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity))
}

.focus\:text-orange-300:focus {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity))
}

.focus\:text-orange-400:focus {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity))
}

.focus\:text-orange-500:focus {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.focus\:text-orange-600:focus {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity))
}

.focus\:text-orange-700:focus {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.focus\:text-orange-800:focus {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity))
}

.focus\:text-orange-900:focus {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity))
}

.focus\:text-yellow-100:focus {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity))
}

.focus\:text-yellow-200:focus {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity))
}

.focus\:text-yellow-300:focus {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity))
}

.focus\:text-yellow-400:focus {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity))
}

.focus\:text-yellow-500:focus {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity))
}

.focus\:text-yellow-600:focus {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity))
}

.focus\:text-yellow-700:focus {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity))
}

.focus\:text-yellow-800:focus {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity))
}

.focus\:text-yellow-900:focus {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity))
}

.focus\:text-green-100:focus {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity))
}

.focus\:text-green-200:focus {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity))
}

.focus\:text-green-300:focus {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity))
}

.focus\:text-green-400:focus {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity))
}

.focus\:text-green-500:focus {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.focus\:text-green-600:focus {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.focus\:text-green-700:focus {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.focus\:text-green-800:focus {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity))
}

.focus\:text-green-900:focus {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity))
}

.focus\:text-teal-100:focus {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity))
}

.focus\:text-teal-200:focus {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity))
}

.focus\:text-teal-300:focus {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity))
}

.focus\:text-teal-400:focus {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity))
}

.focus\:text-teal-500:focus {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity))
}

.focus\:text-teal-600:focus {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity))
}

.focus\:text-teal-700:focus {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity))
}

.focus\:text-teal-800:focus {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity))
}

.focus\:text-teal-900:focus {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity))
}

.focus\:text-blue-100:focus {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity))
}

.focus\:text-blue-200:focus {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity))
}

.focus\:text-blue-300:focus {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity))
}

.focus\:text-blue-400:focus {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity))
}

.focus\:text-blue-500:focus {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.focus\:text-blue-600:focus {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.focus\:text-blue-700:focus {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.focus\:text-blue-800:focus {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity))
}

.focus\:text-blue-900:focus {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity))
}

.focus\:text-indigo-100:focus {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity))
}

.focus\:text-indigo-200:focus {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity))
}

.focus\:text-indigo-300:focus {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity))
}

.focus\:text-indigo-400:focus {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity))
}

.focus\:text-indigo-500:focus {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity))
}

.focus\:text-indigo-600:focus {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity))
}

.focus\:text-indigo-700:focus {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity))
}

.focus\:text-indigo-800:focus {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity))
}

.focus\:text-indigo-900:focus {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity))
}

.focus\:text-purple-100:focus {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity))
}

.focus\:text-purple-200:focus {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity))
}

.focus\:text-purple-300:focus {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity))
}

.focus\:text-purple-400:focus {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity))
}

.focus\:text-purple-500:focus {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity))
}

.focus\:text-purple-600:focus {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity))
}

.focus\:text-purple-700:focus {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity))
}

.focus\:text-purple-800:focus {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity))
}

.focus\:text-purple-900:focus {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity))
}

.focus\:text-pink-100:focus {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity))
}

.focus\:text-pink-200:focus {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity))
}

.focus\:text-pink-300:focus {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity))
}

.focus\:text-pink-400:focus {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity))
}

.focus\:text-pink-500:focus {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity))
}

.focus\:text-pink-600:focus {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity))
}

.focus\:text-pink-700:focus {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity))
}

.focus\:text-pink-800:focus {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity))
}

.focus\:text-pink-900:focus {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity))
}

.text-opacity-0 {
  --text-opacity: 0
}

.text-opacity-25 {
  --text-opacity: 0.25
}

.text-opacity-50 {
  --text-opacity: 0.5
}

.text-opacity-75 {
  --text-opacity: 0.75
}

.text-opacity-100 {
  --text-opacity: 1
}

.hover\:text-opacity-0:hover {
  --text-opacity: 0
}

.hover\:text-opacity-25:hover {
  --text-opacity: 0.25
}

.hover\:text-opacity-50:hover {
  --text-opacity: 0.5
}

.hover\:text-opacity-75:hover {
  --text-opacity: 0.75
}

.hover\:text-opacity-100:hover {
  --text-opacity: 1
}

.focus\:text-opacity-0:focus {
  --text-opacity: 0
}

.focus\:text-opacity-25:focus {
  --text-opacity: 0.25
}

.focus\:text-opacity-50:focus {
  --text-opacity: 0.5
}

.focus\:text-opacity-75:focus {
  --text-opacity: 0.75
}

.focus\:text-opacity-100:focus {
  --text-opacity: 1
}

.italic {
  font-style: italic
}

.not-italic {
  font-style: normal
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.no-underline {
  text-decoration: none
}

.hover\:underline:hover {
  text-decoration: underline
}

.hover\:line-through:hover {
  text-decoration: line-through
}

.hover\:no-underline:hover {
  text-decoration: none
}

.focus\:underline:focus {
  text-decoration: underline
}

.focus\:line-through:focus {
  text-decoration: line-through
}

.focus\:no-underline:focus {
  text-decoration: none
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
}

.normal-nums {
  font-variant-numeric: normal
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal
}

.slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero
}

.lining-nums {
  --font-variant-numeric-figure: lining-nums
}

.oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums
}

.proportional-nums {
  --font-variant-numeric-spacing: proportional-nums
}

.tabular-nums {
  --font-variant-numeric-spacing: tabular-nums
}

.diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions
}

.stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions
}

.tracking-tighter {
  letter-spacing: -0.05em
}

.tracking-tight {
  letter-spacing: -0.025em
}

.tracking-normal {
  letter-spacing: 0
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-widest {
  letter-spacing: 0.1em
}

.select-none {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.select-text {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text
}

.select-all {
  -webkit-user-select: all;
      -ms-user-select: all;
          user-select: all
}

.select-auto {
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto
}

.align-baseline {
  vertical-align: baseline
}

.align-top {
  vertical-align: top
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.align-text-top {
  vertical-align: text-top
}

.align-text-bottom {
  vertical-align: text-bottom
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-normal {
  white-space: normal
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre {
  white-space: pre
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-normal {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-0 {
  width: 0
}

.w-1 {
  width: 0.25rem
}

.w-2 {
  width: 0.5rem
}

.w-3 {
  width: 0.75rem
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-6 {
  width: 1.5rem
}

.w-8 {
  width: 2rem
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-16 {
  width: 4rem
}

.w-20 {
  width: 5rem
}

.w-24 {
  width: 6rem
}

.w-32 {
  width: 8rem
}

.w-40 {
  width: 10rem
}

.w-48 {
  width: 12rem
}

.w-56 {
  width: 14rem
}

.w-64 {
  width: 16rem
}

.w-auto {
  width: auto
}

.w-px {
  width: 1px
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-3\/6 {
  width: 50%
}

.w-4\/6 {
  width: 66.666667%
}

.w-5\/6 {
  width: 83.333333%
}

.w-1\/12 {
  width: 8.333333%
}

.w-2\/12 {
  width: 16.666667%
}

.w-3\/12 {
  width: 25%
}

.w-4\/12 {
  width: 33.333333%
}

.w-5\/12 {
  width: 41.666667%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-8\/12 {
  width: 66.666667%
}

.w-9\/12 {
  width: 75%
}

.w-10\/12 {
  width: 83.333333%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-auto {
  z-index: auto
}

.gap-0 {
  grid-gap: 0;
  gap: 0
}

.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem
}

.gap-8 {
  grid-gap: 2rem;
  gap: 2rem
}

.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem
}

.gap-12 {
  grid-gap: 3rem;
  gap: 3rem
}

.gap-16 {
  grid-gap: 4rem;
  gap: 4rem
}

.gap-20 {
  grid-gap: 5rem;
  gap: 5rem
}

.gap-24 {
  grid-gap: 6rem;
  gap: 6rem
}

.gap-32 {
  grid-gap: 8rem;
  gap: 8rem
}

.gap-40 {
  grid-gap: 10rem;
  gap: 10rem
}

.gap-48 {
  grid-gap: 12rem;
  gap: 12rem
}

.gap-56 {
  grid-gap: 14rem;
  gap: 14rem
}

.gap-64 {
  grid-gap: 16rem;
  gap: 16rem
}

.gap-px {
  grid-gap: 1px;
  gap: 1px
}

.col-gap-0 {
  grid-column-gap: 0;
  column-gap: 0
}

.col-gap-1 {
  grid-column-gap: 0.25rem;
  column-gap: 0.25rem
}

.col-gap-2 {
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem
}

.col-gap-3 {
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem
}

.col-gap-4 {
  grid-column-gap: 1rem;
  column-gap: 1rem
}

.col-gap-5 {
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem
}

.col-gap-6 {
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem
}

.col-gap-8 {
  grid-column-gap: 2rem;
  column-gap: 2rem
}

.col-gap-10 {
  grid-column-gap: 2.5rem;
  column-gap: 2.5rem
}

.col-gap-12 {
  grid-column-gap: 3rem;
  column-gap: 3rem
}

.col-gap-16 {
  grid-column-gap: 4rem;
  column-gap: 4rem
}

.col-gap-20 {
  grid-column-gap: 5rem;
  column-gap: 5rem
}

.col-gap-24 {
  grid-column-gap: 6rem;
  column-gap: 6rem
}

.col-gap-32 {
  grid-column-gap: 8rem;
  column-gap: 8rem
}

.col-gap-40 {
  grid-column-gap: 10rem;
  column-gap: 10rem
}

.col-gap-48 {
  grid-column-gap: 12rem;
  column-gap: 12rem
}

.col-gap-56 {
  grid-column-gap: 14rem;
  column-gap: 14rem
}

.col-gap-64 {
  grid-column-gap: 16rem;
  column-gap: 16rem
}

.col-gap-px {
  grid-column-gap: 1px;
  column-gap: 1px
}

.gap-x-0 {
  grid-column-gap: 0;
  column-gap: 0
}

.gap-x-1 {
  grid-column-gap: 0.25rem;
  column-gap: 0.25rem
}

.gap-x-2 {
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem
}

.gap-x-3 {
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem
}

.gap-x-4 {
  grid-column-gap: 1rem;
  column-gap: 1rem
}

.gap-x-5 {
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem
}

.gap-x-6 {
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem
}

.gap-x-8 {
  grid-column-gap: 2rem;
  column-gap: 2rem
}

.gap-x-10 {
  grid-column-gap: 2.5rem;
  column-gap: 2.5rem
}

.gap-x-12 {
  grid-column-gap: 3rem;
  column-gap: 3rem
}

.gap-x-16 {
  grid-column-gap: 4rem;
  column-gap: 4rem
}

.gap-x-20 {
  grid-column-gap: 5rem;
  column-gap: 5rem
}

.gap-x-24 {
  grid-column-gap: 6rem;
  column-gap: 6rem
}

.gap-x-32 {
  grid-column-gap: 8rem;
  column-gap: 8rem
}

.gap-x-40 {
  grid-column-gap: 10rem;
  column-gap: 10rem
}

.gap-x-48 {
  grid-column-gap: 12rem;
  column-gap: 12rem
}

.gap-x-56 {
  grid-column-gap: 14rem;
  column-gap: 14rem
}

.gap-x-64 {
  grid-column-gap: 16rem;
  column-gap: 16rem
}

.gap-x-px {
  grid-column-gap: 1px;
  column-gap: 1px
}

.row-gap-0 {
  grid-row-gap: 0;
  row-gap: 0
}

.row-gap-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem
}

.row-gap-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.row-gap-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem
}

.row-gap-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.row-gap-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.row-gap-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem
}

.row-gap-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.row-gap-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem
}

.row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.row-gap-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem
}

.row-gap-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.row-gap-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem
}

.row-gap-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem
}

.row-gap-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem
}

.row-gap-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem
}

.row-gap-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem
}

.row-gap-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem
}

.row-gap-px {
  grid-row-gap: 1px;
  row-gap: 1px
}

.gap-y-0 {
  grid-row-gap: 0;
  row-gap: 0
}

.gap-y-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem
}

.gap-y-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.gap-y-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem
}

.gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem
}

.gap-y-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.gap-y-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem
}

.gap-y-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.gap-y-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem
}

.gap-y-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem
}

.gap-y-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem
}

.gap-y-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem
}

.gap-y-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem
}

.gap-y-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem
}

.gap-y-px {
  grid-row-gap: 1px;
  row-gap: 1px
}

.grid-flow-row {
  grid-auto-flow: row
}

.grid-flow-col {
  grid-auto-flow: column
}

.grid-flow-row-dense {
  grid-auto-flow: row dense
}

.grid-flow-col-dense {
  grid-auto-flow: column dense
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr))
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr))
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr))
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-none {
  grid-template-columns: none
}

.auto-cols-auto {
  grid-auto-columns: auto
}

.auto-cols-min {
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content
}

.auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr)
}

.col-auto {
  grid-column: auto
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-5 {
  grid-column: span 5 / span 5
}

.col-span-6 {
  grid-column: span 6 / span 6
}

.col-span-7 {
  grid-column: span 7 / span 7
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-9 {
  grid-column: span 9 / span 9
}

.col-span-10 {
  grid-column: span 10 / span 10
}

.col-span-11 {
  grid-column: span 11 / span 11
}

.col-span-12 {
  grid-column: span 12 / span 12
}

.col-span-full {
  grid-column: 1 / -1
}

.col-start-1 {
  grid-column-start: 1
}

.col-start-2 {
  grid-column-start: 2
}

.col-start-3 {
  grid-column-start: 3
}

.col-start-4 {
  grid-column-start: 4
}

.col-start-5 {
  grid-column-start: 5
}

.col-start-6 {
  grid-column-start: 6
}

.col-start-7 {
  grid-column-start: 7
}

.col-start-8 {
  grid-column-start: 8
}

.col-start-9 {
  grid-column-start: 9
}

.col-start-10 {
  grid-column-start: 10
}

.col-start-11 {
  grid-column-start: 11
}

.col-start-12 {
  grid-column-start: 12
}

.col-start-13 {
  grid-column-start: 13
}

.col-start-auto {
  grid-column-start: auto
}

.col-end-1 {
  grid-column-end: 1
}

.col-end-2 {
  grid-column-end: 2
}

.col-end-3 {
  grid-column-end: 3
}

.col-end-4 {
  grid-column-end: 4
}

.col-end-5 {
  grid-column-end: 5
}

.col-end-6 {
  grid-column-end: 6
}

.col-end-7 {
  grid-column-end: 7
}

.col-end-8 {
  grid-column-end: 8
}

.col-end-9 {
  grid-column-end: 9
}

.col-end-10 {
  grid-column-end: 10
}

.col-end-11 {
  grid-column-end: 11
}

.col-end-12 {
  grid-column-end: 12
}

.col-end-13 {
  grid-column-end: 13
}

.col-end-auto {
  grid-column-end: auto
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr))
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr))
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr))
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr))
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr))
}

.grid-rows-none {
  grid-template-rows: none
}

.auto-rows-auto {
  grid-auto-rows: auto
}

.auto-rows-min {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr)
}

.row-auto {
  grid-row: auto
}

.row-span-1 {
  grid-row: span 1 / span 1
}

.row-span-2 {
  grid-row: span 2 / span 2
}

.row-span-3 {
  grid-row: span 3 / span 3
}

.row-span-4 {
  grid-row: span 4 / span 4
}

.row-span-5 {
  grid-row: span 5 / span 5
}

.row-span-6 {
  grid-row: span 6 / span 6
}

.row-span-full {
  grid-row: 1 / -1
}

.row-start-1 {
  grid-row-start: 1
}

.row-start-2 {
  grid-row-start: 2
}

.row-start-3 {
  grid-row-start: 3
}

.row-start-4 {
  grid-row-start: 4
}

.row-start-5 {
  grid-row-start: 5
}

.row-start-6 {
  grid-row-start: 6
}

.row-start-7 {
  grid-row-start: 7
}

.row-start-auto {
  grid-row-start: auto
}

.row-end-1 {
  grid-row-end: 1
}

.row-end-2 {
  grid-row-end: 2
}

.row-end-3 {
  grid-row-end: 3
}

.row-end-4 {
  grid-row-end: 4
}

.row-end-5 {
  grid-row-end: 5
}

.row-end-6 {
  grid-row-end: 6
}

.row-end-7 {
  grid-row-end: 7
}

.row-end-auto {
  grid-row-end: auto
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transform-none {
  transform: none
}

.origin-center {
  transform-origin: center
}

.origin-top {
  transform-origin: top
}

.origin-top-right {
  transform-origin: top right
}

.origin-right {
  transform-origin: right
}

.origin-bottom-right {
  transform-origin: bottom right
}

.origin-bottom {
  transform-origin: bottom
}

.origin-bottom-left {
  transform-origin: bottom left
}

.origin-left {
  transform-origin: left
}

.origin-top-left {
  transform-origin: top left
}

.scale-0 {
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.scale-50 {
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.scale-75 {
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.scale-90 {
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.scale-95 {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.scale-105 {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.scale-110 {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.scale-x-0 {
  --transform-scale-x: 0
}

.scale-x-50 {
  --transform-scale-x: .5
}

.scale-x-75 {
  --transform-scale-x: .75
}

.scale-x-90 {
  --transform-scale-x: .9
}

.scale-x-95 {
  --transform-scale-x: .95
}

.scale-x-100 {
  --transform-scale-x: 1
}

.scale-x-105 {
  --transform-scale-x: 1.05
}

.scale-x-110 {
  --transform-scale-x: 1.1
}

.scale-x-125 {
  --transform-scale-x: 1.25
}

.scale-x-150 {
  --transform-scale-x: 1.5
}

.scale-y-0 {
  --transform-scale-y: 0
}

.scale-y-50 {
  --transform-scale-y: .5
}

.scale-y-75 {
  --transform-scale-y: .75
}

.scale-y-90 {
  --transform-scale-y: .9
}

.scale-y-95 {
  --transform-scale-y: .95
}

.scale-y-100 {
  --transform-scale-y: 1
}

.scale-y-105 {
  --transform-scale-y: 1.05
}

.scale-y-110 {
  --transform-scale-y: 1.1
}

.scale-y-125 {
  --transform-scale-y: 1.25
}

.scale-y-150 {
  --transform-scale-y: 1.5
}

.hover\:scale-0:hover {
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.hover\:scale-50:hover {
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.hover\:scale-75:hover {
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.hover\:scale-90:hover {
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.hover\:scale-95:hover {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.hover\:scale-100:hover {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.hover\:scale-105:hover {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.hover\:scale-110:hover {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.hover\:scale-125:hover {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.hover\:scale-150:hover {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.hover\:scale-x-0:hover {
  --transform-scale-x: 0
}

.hover\:scale-x-50:hover {
  --transform-scale-x: .5
}

.hover\:scale-x-75:hover {
  --transform-scale-x: .75
}

.hover\:scale-x-90:hover {
  --transform-scale-x: .9
}

.hover\:scale-x-95:hover {
  --transform-scale-x: .95
}

.hover\:scale-x-100:hover {
  --transform-scale-x: 1
}

.hover\:scale-x-105:hover {
  --transform-scale-x: 1.05
}

.hover\:scale-x-110:hover {
  --transform-scale-x: 1.1
}

.hover\:scale-x-125:hover {
  --transform-scale-x: 1.25
}

.hover\:scale-x-150:hover {
  --transform-scale-x: 1.5
}

.hover\:scale-y-0:hover {
  --transform-scale-y: 0
}

.hover\:scale-y-50:hover {
  --transform-scale-y: .5
}

.hover\:scale-y-75:hover {
  --transform-scale-y: .75
}

.hover\:scale-y-90:hover {
  --transform-scale-y: .9
}

.hover\:scale-y-95:hover {
  --transform-scale-y: .95
}

.hover\:scale-y-100:hover {
  --transform-scale-y: 1
}

.hover\:scale-y-105:hover {
  --transform-scale-y: 1.05
}

.hover\:scale-y-110:hover {
  --transform-scale-y: 1.1
}

.hover\:scale-y-125:hover {
  --transform-scale-y: 1.25
}

.hover\:scale-y-150:hover {
  --transform-scale-y: 1.5
}

.focus\:scale-0:focus {
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.focus\:scale-50:focus {
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.focus\:scale-75:focus {
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.focus\:scale-90:focus {
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.focus\:scale-95:focus {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.focus\:scale-100:focus {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.focus\:scale-105:focus {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.focus\:scale-110:focus {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.focus\:scale-125:focus {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.focus\:scale-150:focus {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.focus\:scale-x-0:focus {
  --transform-scale-x: 0
}

.focus\:scale-x-50:focus {
  --transform-scale-x: .5
}

.focus\:scale-x-75:focus {
  --transform-scale-x: .75
}

.focus\:scale-x-90:focus {
  --transform-scale-x: .9
}

.focus\:scale-x-95:focus {
  --transform-scale-x: .95
}

.focus\:scale-x-100:focus {
  --transform-scale-x: 1
}

.focus\:scale-x-105:focus {
  --transform-scale-x: 1.05
}

.focus\:scale-x-110:focus {
  --transform-scale-x: 1.1
}

.focus\:scale-x-125:focus {
  --transform-scale-x: 1.25
}

.focus\:scale-x-150:focus {
  --transform-scale-x: 1.5
}

.focus\:scale-y-0:focus {
  --transform-scale-y: 0
}

.focus\:scale-y-50:focus {
  --transform-scale-y: .5
}

.focus\:scale-y-75:focus {
  --transform-scale-y: .75
}

.focus\:scale-y-90:focus {
  --transform-scale-y: .9
}

.focus\:scale-y-95:focus {
  --transform-scale-y: .95
}

.focus\:scale-y-100:focus {
  --transform-scale-y: 1
}

.focus\:scale-y-105:focus {
  --transform-scale-y: 1.05
}

.focus\:scale-y-110:focus {
  --transform-scale-y: 1.1
}

.focus\:scale-y-125:focus {
  --transform-scale-y: 1.25
}

.focus\:scale-y-150:focus {
  --transform-scale-y: 1.5
}

.rotate-0 {
  --transform-rotate: 0
}

.rotate-1 {
  --transform-rotate: 1deg
}

.rotate-2 {
  --transform-rotate: 2deg
}

.rotate-3 {
  --transform-rotate: 3deg
}

.rotate-6 {
  --transform-rotate: 6deg
}

.rotate-12 {
  --transform-rotate: 12deg
}

.rotate-45 {
  --transform-rotate: 45deg
}

.rotate-90 {
  --transform-rotate: 90deg
}

.rotate-180 {
  --transform-rotate: 180deg
}

.-rotate-180 {
  --transform-rotate: -180deg
}

.-rotate-90 {
  --transform-rotate: -90deg
}

.-rotate-45 {
  --transform-rotate: -45deg
}

.-rotate-12 {
  --transform-rotate: -12deg
}

.-rotate-6 {
  --transform-rotate: -6deg
}

.-rotate-3 {
  --transform-rotate: -3deg
}

.-rotate-2 {
  --transform-rotate: -2deg
}

.-rotate-1 {
  --transform-rotate: -1deg
}

.hover\:rotate-0:hover {
  --transform-rotate: 0
}

.hover\:rotate-1:hover {
  --transform-rotate: 1deg
}

.hover\:rotate-2:hover {
  --transform-rotate: 2deg
}

.hover\:rotate-3:hover {
  --transform-rotate: 3deg
}

.hover\:rotate-6:hover {
  --transform-rotate: 6deg
}

.hover\:rotate-12:hover {
  --transform-rotate: 12deg
}

.hover\:rotate-45:hover {
  --transform-rotate: 45deg
}

.hover\:rotate-90:hover {
  --transform-rotate: 90deg
}

.hover\:rotate-180:hover {
  --transform-rotate: 180deg
}

.hover\:-rotate-180:hover {
  --transform-rotate: -180deg
}

.hover\:-rotate-90:hover {
  --transform-rotate: -90deg
}

.hover\:-rotate-45:hover {
  --transform-rotate: -45deg
}

.hover\:-rotate-12:hover {
  --transform-rotate: -12deg
}

.hover\:-rotate-6:hover {
  --transform-rotate: -6deg
}

.hover\:-rotate-3:hover {
  --transform-rotate: -3deg
}

.hover\:-rotate-2:hover {
  --transform-rotate: -2deg
}

.hover\:-rotate-1:hover {
  --transform-rotate: -1deg
}

.focus\:rotate-0:focus {
  --transform-rotate: 0
}

.focus\:rotate-1:focus {
  --transform-rotate: 1deg
}

.focus\:rotate-2:focus {
  --transform-rotate: 2deg
}

.focus\:rotate-3:focus {
  --transform-rotate: 3deg
}

.focus\:rotate-6:focus {
  --transform-rotate: 6deg
}

.focus\:rotate-12:focus {
  --transform-rotate: 12deg
}

.focus\:rotate-45:focus {
  --transform-rotate: 45deg
}

.focus\:rotate-90:focus {
  --transform-rotate: 90deg
}

.focus\:rotate-180:focus {
  --transform-rotate: 180deg
}

.focus\:-rotate-180:focus {
  --transform-rotate: -180deg
}

.focus\:-rotate-90:focus {
  --transform-rotate: -90deg
}

.focus\:-rotate-45:focus {
  --transform-rotate: -45deg
}

.focus\:-rotate-12:focus {
  --transform-rotate: -12deg
}

.focus\:-rotate-6:focus {
  --transform-rotate: -6deg
}

.focus\:-rotate-3:focus {
  --transform-rotate: -3deg
}

.focus\:-rotate-2:focus {
  --transform-rotate: -2deg
}

.focus\:-rotate-1:focus {
  --transform-rotate: -1deg
}

.translate-x-0 {
  --transform-translate-x: 0
}

.translate-x-1 {
  --transform-translate-x: 0.25rem
}

.translate-x-2 {
  --transform-translate-x: 0.5rem
}

.translate-x-3 {
  --transform-translate-x: 0.75rem
}

.translate-x-4 {
  --transform-translate-x: 1rem
}

.translate-x-5 {
  --transform-translate-x: 1.25rem
}

.translate-x-6 {
  --transform-translate-x: 1.5rem
}

.translate-x-8 {
  --transform-translate-x: 2rem
}

.translate-x-10 {
  --transform-translate-x: 2.5rem
}

.translate-x-12 {
  --transform-translate-x: 3rem
}

.translate-x-16 {
  --transform-translate-x: 4rem
}

.translate-x-20 {
  --transform-translate-x: 5rem
}

.translate-x-24 {
  --transform-translate-x: 6rem
}

.translate-x-32 {
  --transform-translate-x: 8rem
}

.translate-x-40 {
  --transform-translate-x: 10rem
}

.translate-x-48 {
  --transform-translate-x: 12rem
}

.translate-x-56 {
  --transform-translate-x: 14rem
}

.translate-x-64 {
  --transform-translate-x: 16rem
}

.translate-x-px {
  --transform-translate-x: 1px
}

.-translate-x-1 {
  --transform-translate-x: -0.25rem
}

.-translate-x-2 {
  --transform-translate-x: -0.5rem
}

.-translate-x-3 {
  --transform-translate-x: -0.75rem
}

.-translate-x-4 {
  --transform-translate-x: -1rem
}

.-translate-x-5 {
  --transform-translate-x: -1.25rem
}

.-translate-x-6 {
  --transform-translate-x: -1.5rem
}

.-translate-x-8 {
  --transform-translate-x: -2rem
}

.-translate-x-10 {
  --transform-translate-x: -2.5rem
}

.-translate-x-12 {
  --transform-translate-x: -3rem
}

.-translate-x-16 {
  --transform-translate-x: -4rem
}

.-translate-x-20 {
  --transform-translate-x: -5rem
}

.-translate-x-24 {
  --transform-translate-x: -6rem
}

.-translate-x-32 {
  --transform-translate-x: -8rem
}

.-translate-x-40 {
  --transform-translate-x: -10rem
}

.-translate-x-48 {
  --transform-translate-x: -12rem
}

.-translate-x-56 {
  --transform-translate-x: -14rem
}

.-translate-x-64 {
  --transform-translate-x: -16rem
}

.-translate-x-px {
  --transform-translate-x: -1px
}

.-translate-x-full {
  --transform-translate-x: -100%
}

.-translate-x-1\/2 {
  --transform-translate-x: -50%
}

.translate-x-1\/2 {
  --transform-translate-x: 50%
}

.translate-x-full {
  --transform-translate-x: 100%
}

.translate-y-0 {
  --transform-translate-y: 0
}

.translate-y-1 {
  --transform-translate-y: 0.25rem
}

.translate-y-2 {
  --transform-translate-y: 0.5rem
}

.translate-y-3 {
  --transform-translate-y: 0.75rem
}

.translate-y-4 {
  --transform-translate-y: 1rem
}

.translate-y-5 {
  --transform-translate-y: 1.25rem
}

.translate-y-6 {
  --transform-translate-y: 1.5rem
}

.translate-y-8 {
  --transform-translate-y: 2rem
}

.translate-y-10 {
  --transform-translate-y: 2.5rem
}

.translate-y-12 {
  --transform-translate-y: 3rem
}

.translate-y-16 {
  --transform-translate-y: 4rem
}

.translate-y-20 {
  --transform-translate-y: 5rem
}

.translate-y-24 {
  --transform-translate-y: 6rem
}

.translate-y-32 {
  --transform-translate-y: 8rem
}

.translate-y-40 {
  --transform-translate-y: 10rem
}

.translate-y-48 {
  --transform-translate-y: 12rem
}

.translate-y-56 {
  --transform-translate-y: 14rem
}

.translate-y-64 {
  --transform-translate-y: 16rem
}

.translate-y-px {
  --transform-translate-y: 1px
}

.-translate-y-1 {
  --transform-translate-y: -0.25rem
}

.-translate-y-2 {
  --transform-translate-y: -0.5rem
}

.-translate-y-3 {
  --transform-translate-y: -0.75rem
}

.-translate-y-4 {
  --transform-translate-y: -1rem
}

.-translate-y-5 {
  --transform-translate-y: -1.25rem
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem
}

.-translate-y-8 {
  --transform-translate-y: -2rem
}

.-translate-y-10 {
  --transform-translate-y: -2.5rem
}

.-translate-y-12 {
  --transform-translate-y: -3rem
}

.-translate-y-16 {
  --transform-translate-y: -4rem
}

.-translate-y-20 {
  --transform-translate-y: -5rem
}

.-translate-y-24 {
  --transform-translate-y: -6rem
}

.-translate-y-32 {
  --transform-translate-y: -8rem
}

.-translate-y-40 {
  --transform-translate-y: -10rem
}

.-translate-y-48 {
  --transform-translate-y: -12rem
}

.-translate-y-56 {
  --transform-translate-y: -14rem
}

.-translate-y-64 {
  --transform-translate-y: -16rem
}

.-translate-y-px {
  --transform-translate-y: -1px
}

.-translate-y-full {
  --transform-translate-y: -100%
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%
}

.translate-y-1\/2 {
  --transform-translate-y: 50%
}

.translate-y-full {
  --transform-translate-y: 100%
}

.hover\:translate-x-0:hover {
  --transform-translate-x: 0
}

.hover\:translate-x-1:hover {
  --transform-translate-x: 0.25rem
}

.hover\:translate-x-2:hover {
  --transform-translate-x: 0.5rem
}

.hover\:translate-x-3:hover {
  --transform-translate-x: 0.75rem
}

.hover\:translate-x-4:hover {
  --transform-translate-x: 1rem
}

.hover\:translate-x-5:hover {
  --transform-translate-x: 1.25rem
}

.hover\:translate-x-6:hover {
  --transform-translate-x: 1.5rem
}

.hover\:translate-x-8:hover {
  --transform-translate-x: 2rem
}

.hover\:translate-x-10:hover {
  --transform-translate-x: 2.5rem
}

.hover\:translate-x-12:hover {
  --transform-translate-x: 3rem
}

.hover\:translate-x-16:hover {
  --transform-translate-x: 4rem
}

.hover\:translate-x-20:hover {
  --transform-translate-x: 5rem
}

.hover\:translate-x-24:hover {
  --transform-translate-x: 6rem
}

.hover\:translate-x-32:hover {
  --transform-translate-x: 8rem
}

.hover\:translate-x-40:hover {
  --transform-translate-x: 10rem
}

.hover\:translate-x-48:hover {
  --transform-translate-x: 12rem
}

.hover\:translate-x-56:hover {
  --transform-translate-x: 14rem
}

.hover\:translate-x-64:hover {
  --transform-translate-x: 16rem
}

.hover\:translate-x-px:hover {
  --transform-translate-x: 1px
}

.hover\:-translate-x-1:hover {
  --transform-translate-x: -0.25rem
}

.hover\:-translate-x-2:hover {
  --transform-translate-x: -0.5rem
}

.hover\:-translate-x-3:hover {
  --transform-translate-x: -0.75rem
}

.hover\:-translate-x-4:hover {
  --transform-translate-x: -1rem
}

.hover\:-translate-x-5:hover {
  --transform-translate-x: -1.25rem
}

.hover\:-translate-x-6:hover {
  --transform-translate-x: -1.5rem
}

.hover\:-translate-x-8:hover {
  --transform-translate-x: -2rem
}

.hover\:-translate-x-10:hover {
  --transform-translate-x: -2.5rem
}

.hover\:-translate-x-12:hover {
  --transform-translate-x: -3rem
}

.hover\:-translate-x-16:hover {
  --transform-translate-x: -4rem
}

.hover\:-translate-x-20:hover {
  --transform-translate-x: -5rem
}

.hover\:-translate-x-24:hover {
  --transform-translate-x: -6rem
}

.hover\:-translate-x-32:hover {
  --transform-translate-x: -8rem
}

.hover\:-translate-x-40:hover {
  --transform-translate-x: -10rem
}

.hover\:-translate-x-48:hover {
  --transform-translate-x: -12rem
}

.hover\:-translate-x-56:hover {
  --transform-translate-x: -14rem
}

.hover\:-translate-x-64:hover {
  --transform-translate-x: -16rem
}

.hover\:-translate-x-px:hover {
  --transform-translate-x: -1px
}

.hover\:-translate-x-full:hover {
  --transform-translate-x: -100%
}

.hover\:-translate-x-1\/2:hover {
  --transform-translate-x: -50%
}

.hover\:translate-x-1\/2:hover {
  --transform-translate-x: 50%
}

.hover\:translate-x-full:hover {
  --transform-translate-x: 100%
}

.hover\:translate-y-0:hover {
  --transform-translate-y: 0
}

.hover\:translate-y-1:hover {
  --transform-translate-y: 0.25rem
}

.hover\:translate-y-2:hover {
  --transform-translate-y: 0.5rem
}

.hover\:translate-y-3:hover {
  --transform-translate-y: 0.75rem
}

.hover\:translate-y-4:hover {
  --transform-translate-y: 1rem
}

.hover\:translate-y-5:hover {
  --transform-translate-y: 1.25rem
}

.hover\:translate-y-6:hover {
  --transform-translate-y: 1.5rem
}

.hover\:translate-y-8:hover {
  --transform-translate-y: 2rem
}

.hover\:translate-y-10:hover {
  --transform-translate-y: 2.5rem
}

.hover\:translate-y-12:hover {
  --transform-translate-y: 3rem
}

.hover\:translate-y-16:hover {
  --transform-translate-y: 4rem
}

.hover\:translate-y-20:hover {
  --transform-translate-y: 5rem
}

.hover\:translate-y-24:hover {
  --transform-translate-y: 6rem
}

.hover\:translate-y-32:hover {
  --transform-translate-y: 8rem
}

.hover\:translate-y-40:hover {
  --transform-translate-y: 10rem
}

.hover\:translate-y-48:hover {
  --transform-translate-y: 12rem
}

.hover\:translate-y-56:hover {
  --transform-translate-y: 14rem
}

.hover\:translate-y-64:hover {
  --transform-translate-y: 16rem
}

.hover\:translate-y-px:hover {
  --transform-translate-y: 1px
}

.hover\:-translate-y-1:hover {
  --transform-translate-y: -0.25rem
}

.hover\:-translate-y-2:hover {
  --transform-translate-y: -0.5rem
}

.hover\:-translate-y-3:hover {
  --transform-translate-y: -0.75rem
}

.hover\:-translate-y-4:hover {
  --transform-translate-y: -1rem
}

.hover\:-translate-y-5:hover {
  --transform-translate-y: -1.25rem
}

.hover\:-translate-y-6:hover {
  --transform-translate-y: -1.5rem
}

.hover\:-translate-y-8:hover {
  --transform-translate-y: -2rem
}

.hover\:-translate-y-10:hover {
  --transform-translate-y: -2.5rem
}

.hover\:-translate-y-12:hover {
  --transform-translate-y: -3rem
}

.hover\:-translate-y-16:hover {
  --transform-translate-y: -4rem
}

.hover\:-translate-y-20:hover {
  --transform-translate-y: -5rem
}

.hover\:-translate-y-24:hover {
  --transform-translate-y: -6rem
}

.hover\:-translate-y-32:hover {
  --transform-translate-y: -8rem
}

.hover\:-translate-y-40:hover {
  --transform-translate-y: -10rem
}

.hover\:-translate-y-48:hover {
  --transform-translate-y: -12rem
}

.hover\:-translate-y-56:hover {
  --transform-translate-y: -14rem
}

.hover\:-translate-y-64:hover {
  --transform-translate-y: -16rem
}

.hover\:-translate-y-px:hover {
  --transform-translate-y: -1px
}

.hover\:-translate-y-full:hover {
  --transform-translate-y: -100%
}

.hover\:-translate-y-1\/2:hover {
  --transform-translate-y: -50%
}

.hover\:translate-y-1\/2:hover {
  --transform-translate-y: 50%
}

.hover\:translate-y-full:hover {
  --transform-translate-y: 100%
}

.focus\:translate-x-0:focus {
  --transform-translate-x: 0
}

.focus\:translate-x-1:focus {
  --transform-translate-x: 0.25rem
}

.focus\:translate-x-2:focus {
  --transform-translate-x: 0.5rem
}

.focus\:translate-x-3:focus {
  --transform-translate-x: 0.75rem
}

.focus\:translate-x-4:focus {
  --transform-translate-x: 1rem
}

.focus\:translate-x-5:focus {
  --transform-translate-x: 1.25rem
}

.focus\:translate-x-6:focus {
  --transform-translate-x: 1.5rem
}

.focus\:translate-x-8:focus {
  --transform-translate-x: 2rem
}

.focus\:translate-x-10:focus {
  --transform-translate-x: 2.5rem
}

.focus\:translate-x-12:focus {
  --transform-translate-x: 3rem
}

.focus\:translate-x-16:focus {
  --transform-translate-x: 4rem
}

.focus\:translate-x-20:focus {
  --transform-translate-x: 5rem
}

.focus\:translate-x-24:focus {
  --transform-translate-x: 6rem
}

.focus\:translate-x-32:focus {
  --transform-translate-x: 8rem
}

.focus\:translate-x-40:focus {
  --transform-translate-x: 10rem
}

.focus\:translate-x-48:focus {
  --transform-translate-x: 12rem
}

.focus\:translate-x-56:focus {
  --transform-translate-x: 14rem
}

.focus\:translate-x-64:focus {
  --transform-translate-x: 16rem
}

.focus\:translate-x-px:focus {
  --transform-translate-x: 1px
}

.focus\:-translate-x-1:focus {
  --transform-translate-x: -0.25rem
}

.focus\:-translate-x-2:focus {
  --transform-translate-x: -0.5rem
}

.focus\:-translate-x-3:focus {
  --transform-translate-x: -0.75rem
}

.focus\:-translate-x-4:focus {
  --transform-translate-x: -1rem
}

.focus\:-translate-x-5:focus {
  --transform-translate-x: -1.25rem
}

.focus\:-translate-x-6:focus {
  --transform-translate-x: -1.5rem
}

.focus\:-translate-x-8:focus {
  --transform-translate-x: -2rem
}

.focus\:-translate-x-10:focus {
  --transform-translate-x: -2.5rem
}

.focus\:-translate-x-12:focus {
  --transform-translate-x: -3rem
}

.focus\:-translate-x-16:focus {
  --transform-translate-x: -4rem
}

.focus\:-translate-x-20:focus {
  --transform-translate-x: -5rem
}

.focus\:-translate-x-24:focus {
  --transform-translate-x: -6rem
}

.focus\:-translate-x-32:focus {
  --transform-translate-x: -8rem
}

.focus\:-translate-x-40:focus {
  --transform-translate-x: -10rem
}

.focus\:-translate-x-48:focus {
  --transform-translate-x: -12rem
}

.focus\:-translate-x-56:focus {
  --transform-translate-x: -14rem
}

.focus\:-translate-x-64:focus {
  --transform-translate-x: -16rem
}

.focus\:-translate-x-px:focus {
  --transform-translate-x: -1px
}

.focus\:-translate-x-full:focus {
  --transform-translate-x: -100%
}

.focus\:-translate-x-1\/2:focus {
  --transform-translate-x: -50%
}

.focus\:translate-x-1\/2:focus {
  --transform-translate-x: 50%
}

.focus\:translate-x-full:focus {
  --transform-translate-x: 100%
}

.focus\:translate-y-0:focus {
  --transform-translate-y: 0
}

.focus\:translate-y-1:focus {
  --transform-translate-y: 0.25rem
}

.focus\:translate-y-2:focus {
  --transform-translate-y: 0.5rem
}

.focus\:translate-y-3:focus {
  --transform-translate-y: 0.75rem
}

.focus\:translate-y-4:focus {
  --transform-translate-y: 1rem
}

.focus\:translate-y-5:focus {
  --transform-translate-y: 1.25rem
}

.focus\:translate-y-6:focus {
  --transform-translate-y: 1.5rem
}

.focus\:translate-y-8:focus {
  --transform-translate-y: 2rem
}

.focus\:translate-y-10:focus {
  --transform-translate-y: 2.5rem
}

.focus\:translate-y-12:focus {
  --transform-translate-y: 3rem
}

.focus\:translate-y-16:focus {
  --transform-translate-y: 4rem
}

.focus\:translate-y-20:focus {
  --transform-translate-y: 5rem
}

.focus\:translate-y-24:focus {
  --transform-translate-y: 6rem
}

.focus\:translate-y-32:focus {
  --transform-translate-y: 8rem
}

.focus\:translate-y-40:focus {
  --transform-translate-y: 10rem
}

.focus\:translate-y-48:focus {
  --transform-translate-y: 12rem
}

.focus\:translate-y-56:focus {
  --transform-translate-y: 14rem
}

.focus\:translate-y-64:focus {
  --transform-translate-y: 16rem
}

.focus\:translate-y-px:focus {
  --transform-translate-y: 1px
}

.focus\:-translate-y-1:focus {
  --transform-translate-y: -0.25rem
}

.focus\:-translate-y-2:focus {
  --transform-translate-y: -0.5rem
}

.focus\:-translate-y-3:focus {
  --transform-translate-y: -0.75rem
}

.focus\:-translate-y-4:focus {
  --transform-translate-y: -1rem
}

.focus\:-translate-y-5:focus {
  --transform-translate-y: -1.25rem
}

.focus\:-translate-y-6:focus {
  --transform-translate-y: -1.5rem
}

.focus\:-translate-y-8:focus {
  --transform-translate-y: -2rem
}

.focus\:-translate-y-10:focus {
  --transform-translate-y: -2.5rem
}

.focus\:-translate-y-12:focus {
  --transform-translate-y: -3rem
}

.focus\:-translate-y-16:focus {
  --transform-translate-y: -4rem
}

.focus\:-translate-y-20:focus {
  --transform-translate-y: -5rem
}

.focus\:-translate-y-24:focus {
  --transform-translate-y: -6rem
}

.focus\:-translate-y-32:focus {
  --transform-translate-y: -8rem
}

.focus\:-translate-y-40:focus {
  --transform-translate-y: -10rem
}

.focus\:-translate-y-48:focus {
  --transform-translate-y: -12rem
}

.focus\:-translate-y-56:focus {
  --transform-translate-y: -14rem
}

.focus\:-translate-y-64:focus {
  --transform-translate-y: -16rem
}

.focus\:-translate-y-px:focus {
  --transform-translate-y: -1px
}

.focus\:-translate-y-full:focus {
  --transform-translate-y: -100%
}

.focus\:-translate-y-1\/2:focus {
  --transform-translate-y: -50%
}

.focus\:translate-y-1\/2:focus {
  --transform-translate-y: 50%
}

.focus\:translate-y-full:focus {
  --transform-translate-y: 100%
}

.skew-x-0 {
  --transform-skew-x: 0
}

.skew-x-1 {
  --transform-skew-x: 1deg
}

.skew-x-2 {
  --transform-skew-x: 2deg
}

.skew-x-3 {
  --transform-skew-x: 3deg
}

.skew-x-6 {
  --transform-skew-x: 6deg
}

.skew-x-12 {
  --transform-skew-x: 12deg
}

.-skew-x-12 {
  --transform-skew-x: -12deg
}

.-skew-x-6 {
  --transform-skew-x: -6deg
}

.-skew-x-3 {
  --transform-skew-x: -3deg
}

.-skew-x-2 {
  --transform-skew-x: -2deg
}

.-skew-x-1 {
  --transform-skew-x: -1deg
}

.skew-y-0 {
  --transform-skew-y: 0
}

.skew-y-1 {
  --transform-skew-y: 1deg
}

.skew-y-2 {
  --transform-skew-y: 2deg
}

.skew-y-3 {
  --transform-skew-y: 3deg
}

.skew-y-6 {
  --transform-skew-y: 6deg
}

.skew-y-12 {
  --transform-skew-y: 12deg
}

.-skew-y-12 {
  --transform-skew-y: -12deg
}

.-skew-y-6 {
  --transform-skew-y: -6deg
}

.-skew-y-3 {
  --transform-skew-y: -3deg
}

.-skew-y-2 {
  --transform-skew-y: -2deg
}

.-skew-y-1 {
  --transform-skew-y: -1deg
}

.hover\:skew-x-0:hover {
  --transform-skew-x: 0
}

.hover\:skew-x-1:hover {
  --transform-skew-x: 1deg
}

.hover\:skew-x-2:hover {
  --transform-skew-x: 2deg
}

.hover\:skew-x-3:hover {
  --transform-skew-x: 3deg
}

.hover\:skew-x-6:hover {
  --transform-skew-x: 6deg
}

.hover\:skew-x-12:hover {
  --transform-skew-x: 12deg
}

.hover\:-skew-x-12:hover {
  --transform-skew-x: -12deg
}

.hover\:-skew-x-6:hover {
  --transform-skew-x: -6deg
}

.hover\:-skew-x-3:hover {
  --transform-skew-x: -3deg
}

.hover\:-skew-x-2:hover {
  --transform-skew-x: -2deg
}

.hover\:-skew-x-1:hover {
  --transform-skew-x: -1deg
}

.hover\:skew-y-0:hover {
  --transform-skew-y: 0
}

.hover\:skew-y-1:hover {
  --transform-skew-y: 1deg
}

.hover\:skew-y-2:hover {
  --transform-skew-y: 2deg
}

.hover\:skew-y-3:hover {
  --transform-skew-y: 3deg
}

.hover\:skew-y-6:hover {
  --transform-skew-y: 6deg
}

.hover\:skew-y-12:hover {
  --transform-skew-y: 12deg
}

.hover\:-skew-y-12:hover {
  --transform-skew-y: -12deg
}

.hover\:-skew-y-6:hover {
  --transform-skew-y: -6deg
}

.hover\:-skew-y-3:hover {
  --transform-skew-y: -3deg
}

.hover\:-skew-y-2:hover {
  --transform-skew-y: -2deg
}

.hover\:-skew-y-1:hover {
  --transform-skew-y: -1deg
}

.focus\:skew-x-0:focus {
  --transform-skew-x: 0
}

.focus\:skew-x-1:focus {
  --transform-skew-x: 1deg
}

.focus\:skew-x-2:focus {
  --transform-skew-x: 2deg
}

.focus\:skew-x-3:focus {
  --transform-skew-x: 3deg
}

.focus\:skew-x-6:focus {
  --transform-skew-x: 6deg
}

.focus\:skew-x-12:focus {
  --transform-skew-x: 12deg
}

.focus\:-skew-x-12:focus {
  --transform-skew-x: -12deg
}

.focus\:-skew-x-6:focus {
  --transform-skew-x: -6deg
}

.focus\:-skew-x-3:focus {
  --transform-skew-x: -3deg
}

.focus\:-skew-x-2:focus {
  --transform-skew-x: -2deg
}

.focus\:-skew-x-1:focus {
  --transform-skew-x: -1deg
}

.focus\:skew-y-0:focus {
  --transform-skew-y: 0
}

.focus\:skew-y-1:focus {
  --transform-skew-y: 1deg
}

.focus\:skew-y-2:focus {
  --transform-skew-y: 2deg
}

.focus\:skew-y-3:focus {
  --transform-skew-y: 3deg
}

.focus\:skew-y-6:focus {
  --transform-skew-y: 6deg
}

.focus\:skew-y-12:focus {
  --transform-skew-y: 12deg
}

.focus\:-skew-y-12:focus {
  --transform-skew-y: -12deg
}

.focus\:-skew-y-6:focus {
  --transform-skew-y: -6deg
}

.focus\:-skew-y-3:focus {
  --transform-skew-y: -3deg
}

.focus\:-skew-y-2:focus {
  --transform-skew-y: -2deg
}

.focus\:-skew-y-1:focus {
  --transform-skew-y: -1deg
}

.transition-none {
  transition-property: none
}

.transition-all {
  transition-property: all
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke
}

.transition-opacity {
  transition-property: opacity
}

.transition-shadow {
  transition-property: box-shadow
}

.transition-transform {
  transition-property: transform
}

.ease-linear {
  transition-timing-function: linear
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-75 {
  transition-duration: 75ms
}

.duration-100 {
  transition-duration: 100ms
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

.duration-300 {
  transition-duration: 300ms
}

.duration-500 {
  transition-duration: 500ms
}

.duration-700 {
  transition-duration: 700ms
}

.duration-1000 {
  transition-duration: 1000ms
}

.delay-75 {
  transition-delay: 75ms
}

.delay-100 {
  transition-delay: 100ms
}

.delay-150 {
  transition-delay: 150ms
}

.delay-200 {
  transition-delay: 200ms
}

.delay-300 {
  transition-delay: 300ms
}

.delay-500 {
  transition-delay: 500ms
}

.delay-700 {
  transition-delay: 700ms
}

.delay-1000 {
  transition-delay: 1000ms
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.animate-none {
  animation: none
}

.animate-spin {
  animation: spin 1s linear infinite
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.animate-bounce {
  animation: bounce 1s infinite
}

@media (min-width: 640px) {
  .sm\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .sm\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .sm\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .sm\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .sm\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .sm\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .sm\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .sm\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .sm\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .sm\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .sm\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .sm\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .sm\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .sm\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .sm\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .sm\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .sm\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .sm\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .sm\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .sm\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .sm\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .sm\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .sm\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .sm\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .sm\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .sm\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .sm\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .sm\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .sm\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .sm\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .sm\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .sm\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .sm\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .sm\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .sm\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .sm\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .sm\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .sm\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .sm\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .sm\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .sm\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .sm\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .sm\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent
  }

  .sm\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor
  }

  .sm\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .sm\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .sm\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .sm\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .sm\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .sm\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .sm\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .sm\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .sm\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .sm\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .sm\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .sm\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .sm\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .sm\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .sm\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .sm\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .sm\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .sm\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .sm\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .sm\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .sm\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .sm\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .sm\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .sm\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .sm\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .sm\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .sm\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .sm\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .sm\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .sm\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .sm\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .sm\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .sm\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .sm\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .sm\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .sm\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .sm\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .sm\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .sm\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .sm\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .sm\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .sm\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .sm\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .sm\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .sm\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .sm\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .sm\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .sm\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .sm\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .sm\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .sm\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .sm\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .sm\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .sm\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .sm\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .sm\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .sm\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .sm\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .sm\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .sm\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .sm\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .sm\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .sm\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .sm\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .sm\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .sm\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .sm\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .sm\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .sm\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .sm\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .sm\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .sm\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .sm\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .sm\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .sm\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .sm\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .sm\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .sm\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .sm\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .sm\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .sm\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .sm\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .sm\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .sm\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .sm\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .sm\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .sm\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .sm\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .sm\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .sm\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .sm\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .sm\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .sm\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .sm\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .sm\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .sm\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .sm\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .sm\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .sm\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .sm\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .sm\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .sm\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .sm\:bg-fixed {
    background-attachment: fixed
  }

  .sm\:bg-local {
    background-attachment: local
  }

  .sm\:bg-scroll {
    background-attachment: scroll
  }

  .sm\:bg-clip-border {
    background-clip: border-box
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box
  }

  .sm\:bg-clip-content {
    background-clip: content-box
  }

  .sm\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .sm\:bg-transparent {
    background-color: transparent
  }

  .sm\:bg-current {
    background-color: currentColor
  }

  .sm\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .sm\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .sm\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .sm\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .sm\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .sm\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .sm\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .sm\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .sm\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .sm\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .sm\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .sm\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .sm\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .sm\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .sm\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .sm\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .sm\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .sm\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .sm\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .sm\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .sm\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .sm\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .sm\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .sm\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .sm\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .sm\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .sm\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .sm\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .sm\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .sm\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .sm\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .sm\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .sm\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .sm\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .sm\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .sm\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .sm\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .sm\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .sm\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .sm\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .sm\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .sm\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .sm\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .sm\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .sm\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .sm\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .sm\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .sm\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .sm\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .sm\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .sm\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .sm\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .sm\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .sm\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .sm\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .sm\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .sm\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .sm\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .sm\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .sm\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .sm\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .sm\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .sm\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .sm\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .sm\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .sm\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .sm\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .sm\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .sm\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .sm\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .sm\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .sm\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .sm\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .sm\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .sm\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .sm\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .sm\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .sm\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .sm\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .sm\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .sm\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .sm\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .sm\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .sm\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .sm\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .sm\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .sm\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .sm\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .sm\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .sm\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .sm\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .sm\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .sm\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .sm\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .sm\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .sm\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .sm\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .sm\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .sm\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .sm\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .sm\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .sm\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .sm\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .sm\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .sm\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .sm\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .sm\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .sm\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .sm\:bg-none {
    background-image: none
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .sm\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:to-transparent {
    --gradient-to-color: transparent
  }

  .sm\:to-current {
    --gradient-to-color: currentColor
  }

  .sm\:to-black {
    --gradient-to-color: #000
  }

  .sm\:to-white {
    --gradient-to-color: #fff
  }

  .sm\:to-gray-100 {
    --gradient-to-color: #f7fafc
  }

  .sm\:to-gray-200 {
    --gradient-to-color: #edf2f7
  }

  .sm\:to-gray-300 {
    --gradient-to-color: #e2e8f0
  }

  .sm\:to-gray-400 {
    --gradient-to-color: #cbd5e0
  }

  .sm\:to-gray-500 {
    --gradient-to-color: #a0aec0
  }

  .sm\:to-gray-600 {
    --gradient-to-color: #718096
  }

  .sm\:to-gray-700 {
    --gradient-to-color: #4a5568
  }

  .sm\:to-gray-800 {
    --gradient-to-color: #2d3748
  }

  .sm\:to-gray-900 {
    --gradient-to-color: #1a202c
  }

  .sm\:to-red-100 {
    --gradient-to-color: #fff5f5
  }

  .sm\:to-red-200 {
    --gradient-to-color: #fed7d7
  }

  .sm\:to-red-300 {
    --gradient-to-color: #feb2b2
  }

  .sm\:to-red-400 {
    --gradient-to-color: #fc8181
  }

  .sm\:to-red-500 {
    --gradient-to-color: #f56565
  }

  .sm\:to-red-600 {
    --gradient-to-color: #e53e3e
  }

  .sm\:to-red-700 {
    --gradient-to-color: #c53030
  }

  .sm\:to-red-800 {
    --gradient-to-color: #9b2c2c
  }

  .sm\:to-red-900 {
    --gradient-to-color: #742a2a
  }

  .sm\:to-orange-100 {
    --gradient-to-color: #fffaf0
  }

  .sm\:to-orange-200 {
    --gradient-to-color: #feebc8
  }

  .sm\:to-orange-300 {
    --gradient-to-color: #fbd38d
  }

  .sm\:to-orange-400 {
    --gradient-to-color: #f6ad55
  }

  .sm\:to-orange-500 {
    --gradient-to-color: #ed8936
  }

  .sm\:to-orange-600 {
    --gradient-to-color: #dd6b20
  }

  .sm\:to-orange-700 {
    --gradient-to-color: #c05621
  }

  .sm\:to-orange-800 {
    --gradient-to-color: #9c4221
  }

  .sm\:to-orange-900 {
    --gradient-to-color: #7b341e
  }

  .sm\:to-yellow-100 {
    --gradient-to-color: #fffff0
  }

  .sm\:to-yellow-200 {
    --gradient-to-color: #fefcbf
  }

  .sm\:to-yellow-300 {
    --gradient-to-color: #faf089
  }

  .sm\:to-yellow-400 {
    --gradient-to-color: #f6e05e
  }

  .sm\:to-yellow-500 {
    --gradient-to-color: #ecc94b
  }

  .sm\:to-yellow-600 {
    --gradient-to-color: #d69e2e
  }

  .sm\:to-yellow-700 {
    --gradient-to-color: #b7791f
  }

  .sm\:to-yellow-800 {
    --gradient-to-color: #975a16
  }

  .sm\:to-yellow-900 {
    --gradient-to-color: #744210
  }

  .sm\:to-green-100 {
    --gradient-to-color: #f0fff4
  }

  .sm\:to-green-200 {
    --gradient-to-color: #c6f6d5
  }

  .sm\:to-green-300 {
    --gradient-to-color: #9ae6b4
  }

  .sm\:to-green-400 {
    --gradient-to-color: #68d391
  }

  .sm\:to-green-500 {
    --gradient-to-color: #48bb78
  }

  .sm\:to-green-600 {
    --gradient-to-color: #38a169
  }

  .sm\:to-green-700 {
    --gradient-to-color: #2f855a
  }

  .sm\:to-green-800 {
    --gradient-to-color: #276749
  }

  .sm\:to-green-900 {
    --gradient-to-color: #22543d
  }

  .sm\:to-teal-100 {
    --gradient-to-color: #e6fffa
  }

  .sm\:to-teal-200 {
    --gradient-to-color: #b2f5ea
  }

  .sm\:to-teal-300 {
    --gradient-to-color: #81e6d9
  }

  .sm\:to-teal-400 {
    --gradient-to-color: #4fd1c5
  }

  .sm\:to-teal-500 {
    --gradient-to-color: #38b2ac
  }

  .sm\:to-teal-600 {
    --gradient-to-color: #319795
  }

  .sm\:to-teal-700 {
    --gradient-to-color: #2c7a7b
  }

  .sm\:to-teal-800 {
    --gradient-to-color: #285e61
  }

  .sm\:to-teal-900 {
    --gradient-to-color: #234e52
  }

  .sm\:to-blue-100 {
    --gradient-to-color: #ebf8ff
  }

  .sm\:to-blue-200 {
    --gradient-to-color: #bee3f8
  }

  .sm\:to-blue-300 {
    --gradient-to-color: #90cdf4
  }

  .sm\:to-blue-400 {
    --gradient-to-color: #63b3ed
  }

  .sm\:to-blue-500 {
    --gradient-to-color: #4299e1
  }

  .sm\:to-blue-600 {
    --gradient-to-color: #3182ce
  }

  .sm\:to-blue-700 {
    --gradient-to-color: #2b6cb0
  }

  .sm\:to-blue-800 {
    --gradient-to-color: #2c5282
  }

  .sm\:to-blue-900 {
    --gradient-to-color: #2a4365
  }

  .sm\:to-indigo-100 {
    --gradient-to-color: #ebf4ff
  }

  .sm\:to-indigo-200 {
    --gradient-to-color: #c3dafe
  }

  .sm\:to-indigo-300 {
    --gradient-to-color: #a3bffa
  }

  .sm\:to-indigo-400 {
    --gradient-to-color: #7f9cf5
  }

  .sm\:to-indigo-500 {
    --gradient-to-color: #667eea
  }

  .sm\:to-indigo-600 {
    --gradient-to-color: #5a67d8
  }

  .sm\:to-indigo-700 {
    --gradient-to-color: #4c51bf
  }

  .sm\:to-indigo-800 {
    --gradient-to-color: #434190
  }

  .sm\:to-indigo-900 {
    --gradient-to-color: #3c366b
  }

  .sm\:to-purple-100 {
    --gradient-to-color: #faf5ff
  }

  .sm\:to-purple-200 {
    --gradient-to-color: #e9d8fd
  }

  .sm\:to-purple-300 {
    --gradient-to-color: #d6bcfa
  }

  .sm\:to-purple-400 {
    --gradient-to-color: #b794f4
  }

  .sm\:to-purple-500 {
    --gradient-to-color: #9f7aea
  }

  .sm\:to-purple-600 {
    --gradient-to-color: #805ad5
  }

  .sm\:to-purple-700 {
    --gradient-to-color: #6b46c1
  }

  .sm\:to-purple-800 {
    --gradient-to-color: #553c9a
  }

  .sm\:to-purple-900 {
    --gradient-to-color: #44337a
  }

  .sm\:to-pink-100 {
    --gradient-to-color: #fff5f7
  }

  .sm\:to-pink-200 {
    --gradient-to-color: #fed7e2
  }

  .sm\:to-pink-300 {
    --gradient-to-color: #fbb6ce
  }

  .sm\:to-pink-400 {
    --gradient-to-color: #f687b3
  }

  .sm\:to-pink-500 {
    --gradient-to-color: #ed64a6
  }

  .sm\:to-pink-600 {
    --gradient-to-color: #d53f8c
  }

  .sm\:to-pink-700 {
    --gradient-to-color: #b83280
  }

  .sm\:to-pink-800 {
    --gradient-to-color: #97266d
  }

  .sm\:to-pink-900 {
    --gradient-to-color: #702459
  }

  .sm\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:hover\:from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:hover\:via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:hover\:to-transparent:hover {
    --gradient-to-color: transparent
  }

  .sm\:hover\:to-current:hover {
    --gradient-to-color: currentColor
  }

  .sm\:hover\:to-black:hover {
    --gradient-to-color: #000
  }

  .sm\:hover\:to-white:hover {
    --gradient-to-color: #fff
  }

  .sm\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc
  }

  .sm\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7
  }

  .sm\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0
  }

  .sm\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0
  }

  .sm\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0
  }

  .sm\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096
  }

  .sm\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568
  }

  .sm\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748
  }

  .sm\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c
  }

  .sm\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5
  }

  .sm\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7
  }

  .sm\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2
  }

  .sm\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181
  }

  .sm\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565
  }

  .sm\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e
  }

  .sm\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030
  }

  .sm\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c
  }

  .sm\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a
  }

  .sm\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0
  }

  .sm\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8
  }

  .sm\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d
  }

  .sm\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55
  }

  .sm\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936
  }

  .sm\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20
  }

  .sm\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621
  }

  .sm\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221
  }

  .sm\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e
  }

  .sm\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0
  }

  .sm\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf
  }

  .sm\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089
  }

  .sm\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e
  }

  .sm\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b
  }

  .sm\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e
  }

  .sm\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f
  }

  .sm\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16
  }

  .sm\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210
  }

  .sm\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4
  }

  .sm\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5
  }

  .sm\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4
  }

  .sm\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391
  }

  .sm\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78
  }

  .sm\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169
  }

  .sm\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a
  }

  .sm\:hover\:to-green-800:hover {
    --gradient-to-color: #276749
  }

  .sm\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d
  }

  .sm\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa
  }

  .sm\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea
  }

  .sm\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9
  }

  .sm\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5
  }

  .sm\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac
  }

  .sm\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795
  }

  .sm\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b
  }

  .sm\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61
  }

  .sm\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52
  }

  .sm\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff
  }

  .sm\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8
  }

  .sm\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4
  }

  .sm\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed
  }

  .sm\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1
  }

  .sm\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce
  }

  .sm\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0
  }

  .sm\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282
  }

  .sm\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365
  }

  .sm\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff
  }

  .sm\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe
  }

  .sm\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa
  }

  .sm\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5
  }

  .sm\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea
  }

  .sm\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8
  }

  .sm\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf
  }

  .sm\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190
  }

  .sm\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b
  }

  .sm\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff
  }

  .sm\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd
  }

  .sm\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa
  }

  .sm\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4
  }

  .sm\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea
  }

  .sm\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5
  }

  .sm\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1
  }

  .sm\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a
  }

  .sm\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a
  }

  .sm\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7
  }

  .sm\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2
  }

  .sm\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce
  }

  .sm\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3
  }

  .sm\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6
  }

  .sm\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c
  }

  .sm\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280
  }

  .sm\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d
  }

  .sm\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459
  }

  .sm\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:focus\:from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:focus\:via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:focus\:to-transparent:focus {
    --gradient-to-color: transparent
  }

  .sm\:focus\:to-current:focus {
    --gradient-to-color: currentColor
  }

  .sm\:focus\:to-black:focus {
    --gradient-to-color: #000
  }

  .sm\:focus\:to-white:focus {
    --gradient-to-color: #fff
  }

  .sm\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc
  }

  .sm\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7
  }

  .sm\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0
  }

  .sm\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0
  }

  .sm\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0
  }

  .sm\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096
  }

  .sm\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568
  }

  .sm\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748
  }

  .sm\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c
  }

  .sm\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5
  }

  .sm\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7
  }

  .sm\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2
  }

  .sm\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181
  }

  .sm\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565
  }

  .sm\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e
  }

  .sm\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030
  }

  .sm\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c
  }

  .sm\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a
  }

  .sm\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0
  }

  .sm\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8
  }

  .sm\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d
  }

  .sm\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55
  }

  .sm\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936
  }

  .sm\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20
  }

  .sm\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621
  }

  .sm\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221
  }

  .sm\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e
  }

  .sm\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0
  }

  .sm\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf
  }

  .sm\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089
  }

  .sm\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e
  }

  .sm\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b
  }

  .sm\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e
  }

  .sm\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f
  }

  .sm\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16
  }

  .sm\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210
  }

  .sm\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4
  }

  .sm\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5
  }

  .sm\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4
  }

  .sm\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391
  }

  .sm\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78
  }

  .sm\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169
  }

  .sm\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a
  }

  .sm\:focus\:to-green-800:focus {
    --gradient-to-color: #276749
  }

  .sm\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d
  }

  .sm\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa
  }

  .sm\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea
  }

  .sm\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9
  }

  .sm\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5
  }

  .sm\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac
  }

  .sm\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795
  }

  .sm\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b
  }

  .sm\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61
  }

  .sm\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52
  }

  .sm\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff
  }

  .sm\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8
  }

  .sm\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4
  }

  .sm\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed
  }

  .sm\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1
  }

  .sm\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce
  }

  .sm\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0
  }

  .sm\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282
  }

  .sm\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365
  }

  .sm\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff
  }

  .sm\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe
  }

  .sm\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa
  }

  .sm\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5
  }

  .sm\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea
  }

  .sm\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8
  }

  .sm\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf
  }

  .sm\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190
  }

  .sm\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b
  }

  .sm\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff
  }

  .sm\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd
  }

  .sm\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa
  }

  .sm\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4
  }

  .sm\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea
  }

  .sm\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5
  }

  .sm\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1
  }

  .sm\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a
  }

  .sm\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a
  }

  .sm\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7
  }

  .sm\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2
  }

  .sm\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce
  }

  .sm\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3
  }

  .sm\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6
  }

  .sm\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c
  }

  .sm\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280
  }

  .sm\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d
  }

  .sm\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459
  }

  .sm\:bg-opacity-0 {
    --bg-opacity: 0
  }

  .sm\:bg-opacity-25 {
    --bg-opacity: 0.25
  }

  .sm\:bg-opacity-50 {
    --bg-opacity: 0.5
  }

  .sm\:bg-opacity-75 {
    --bg-opacity: 0.75
  }

  .sm\:bg-opacity-100 {
    --bg-opacity: 1
  }

  .sm\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0
  }

  .sm\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25
  }

  .sm\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5
  }

  .sm\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75
  }

  .sm\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1
  }

  .sm\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0
  }

  .sm\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25
  }

  .sm\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5
  }

  .sm\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75
  }

  .sm\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1
  }

  .sm\:bg-bottom {
    background-position: bottom
  }

  .sm\:bg-center {
    background-position: center
  }

  .sm\:bg-left {
    background-position: left
  }

  .sm\:bg-left-bottom {
    background-position: left bottom
  }

  .sm\:bg-left-top {
    background-position: left top
  }

  .sm\:bg-right {
    background-position: right
  }

  .sm\:bg-right-bottom {
    background-position: right bottom
  }

  .sm\:bg-right-top {
    background-position: right top
  }

  .sm\:bg-top {
    background-position: top
  }

  .sm\:bg-repeat {
    background-repeat: repeat
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .sm\:bg-repeat-round {
    background-repeat: round
  }

  .sm\:bg-repeat-space {
    background-repeat: space
  }

  .sm\:bg-auto {
    background-size: auto
  }

  .sm\:bg-cover {
    background-size: cover
  }

  .sm\:bg-contain {
    background-size: contain
  }

  .sm\:border-collapse {
    border-collapse: collapse
  }

  .sm\:border-separate {
    border-collapse: separate
  }

  .sm\:border-transparent {
    border-color: transparent
  }

  .sm\:border-current {
    border-color: currentColor
  }

  .sm\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .sm\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .sm\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .sm\:border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .sm\:border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .sm\:border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .sm\:border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .sm\:border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .sm\:border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .sm\:border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .sm\:border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .sm\:border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .sm\:border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .sm\:border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .sm\:border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .sm\:border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .sm\:border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .sm\:border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .sm\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .sm\:border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .sm\:border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .sm\:border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .sm\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .sm\:border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .sm\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .sm\:border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .sm\:border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .sm\:border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .sm\:border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .sm\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .sm\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .sm\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .sm\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .sm\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .sm\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .sm\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .sm\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .sm\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .sm\:border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .sm\:border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .sm\:border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .sm\:border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .sm\:border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .sm\:border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .sm\:border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .sm\:border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .sm\:border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .sm\:border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .sm\:border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .sm\:border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .sm\:border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .sm\:border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .sm\:border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .sm\:border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .sm\:border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .sm\:border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .sm\:border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .sm\:border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .sm\:border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .sm\:border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .sm\:border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .sm\:border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .sm\:border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .sm\:border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .sm\:border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .sm\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .sm\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .sm\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .sm\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .sm\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .sm\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .sm\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .sm\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .sm\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .sm\:border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .sm\:border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .sm\:border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .sm\:border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .sm\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .sm\:border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .sm\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .sm\:border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .sm\:border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .sm\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .sm\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .sm\:border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .sm\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .sm\:border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .sm\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .sm\:border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .sm\:border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .sm\:border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .sm\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .sm\:hover\:border-current:hover {
    border-color: currentColor
  }

  .sm\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .sm\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .sm\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .sm\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .sm\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .sm\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .sm\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .sm\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .sm\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .sm\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .sm\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .sm\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .sm\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .sm\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .sm\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .sm\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .sm\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .sm\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .sm\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .sm\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .sm\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .sm\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .sm\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .sm\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .sm\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .sm\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .sm\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .sm\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .sm\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .sm\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .sm\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .sm\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .sm\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .sm\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .sm\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .sm\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .sm\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .sm\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .sm\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .sm\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .sm\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .sm\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .sm\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .sm\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .sm\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .sm\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .sm\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .sm\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .sm\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .sm\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .sm\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .sm\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .sm\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .sm\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .sm\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .sm\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .sm\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .sm\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .sm\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .sm\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .sm\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .sm\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .sm\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .sm\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .sm\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .sm\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .sm\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .sm\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .sm\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .sm\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .sm\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .sm\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .sm\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .sm\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .sm\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .sm\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .sm\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .sm\:focus\:border-current:focus {
    border-color: currentColor
  }

  .sm\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .sm\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .sm\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .sm\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .sm\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .sm\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .sm\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .sm\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .sm\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .sm\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .sm\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .sm\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .sm\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .sm\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .sm\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .sm\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .sm\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .sm\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .sm\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .sm\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .sm\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .sm\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .sm\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .sm\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .sm\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .sm\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .sm\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .sm\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .sm\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .sm\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .sm\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .sm\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .sm\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .sm\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .sm\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .sm\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .sm\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .sm\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .sm\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .sm\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .sm\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .sm\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .sm\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .sm\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .sm\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .sm\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .sm\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .sm\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .sm\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .sm\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .sm\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .sm\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .sm\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .sm\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .sm\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .sm\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .sm\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .sm\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .sm\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .sm\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .sm\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .sm\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .sm\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .sm\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .sm\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .sm\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .sm\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .sm\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .sm\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .sm\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .sm\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .sm\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .sm\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .sm\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .sm\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .sm\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .sm\:border-opacity-0 {
    --border-opacity: 0
  }

  .sm\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .sm\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .sm\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .sm\:border-opacity-100 {
    --border-opacity: 1
  }

  .sm\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .sm\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .sm\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .sm\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .sm\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .sm\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .sm\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .sm\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .sm\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .sm\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .sm\:rounded-none {
    border-radius: 0
  }

  .sm\:rounded-sm {
    border-radius: 0.125rem
  }

  .sm\:rounded {
    border-radius: 0.25rem
  }

  .sm\:rounded-md {
    border-radius: 0.375rem
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem
  }

  .sm\:rounded-xl {
    border-radius: 0.75rem
  }

  .sm\:rounded-2xl {
    border-radius: 1rem
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem
  }

  .sm\:rounded-full {
    border-radius: 9999px
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .sm\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .sm\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .sm\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .sm\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .sm\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .sm\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .sm\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .sm\:border-solid {
    border-style: solid
  }

  .sm\:border-dashed {
    border-style: dashed
  }

  .sm\:border-dotted {
    border-style: dotted
  }

  .sm\:border-double {
    border-style: double
  }

  .sm\:border-none {
    border-style: none
  }

  .sm\:border-0 {
    border-width: 0
  }

  .sm\:border-2 {
    border-width: 2px
  }

  .sm\:border-4 {
    border-width: 4px
  }

  .sm\:border-8 {
    border-width: 8px
  }

  .sm\:border {
    border-width: 1px
  }

  .sm\:border-t-0 {
    border-top-width: 0
  }

  .sm\:border-r-0 {
    border-right-width: 0
  }

  .sm\:border-b-0 {
    border-bottom-width: 0
  }

  .sm\:border-l-0 {
    border-left-width: 0
  }

  .sm\:border-t-2 {
    border-top-width: 2px
  }

  .sm\:border-r-2 {
    border-right-width: 2px
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px
  }

  .sm\:border-l-2 {
    border-left-width: 2px
  }

  .sm\:border-t-4 {
    border-top-width: 4px
  }

  .sm\:border-r-4 {
    border-right-width: 4px
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px
  }

  .sm\:border-l-4 {
    border-left-width: 4px
  }

  .sm\:border-t-8 {
    border-top-width: 8px
  }

  .sm\:border-r-8 {
    border-right-width: 8px
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px
  }

  .sm\:border-l-8 {
    border-left-width: 8px
  }

  .sm\:border-t {
    border-top-width: 1px
  }

  .sm\:border-r {
    border-right-width: 1px
  }

  .sm\:border-b {
    border-bottom-width: 1px
  }

  .sm\:border-l {
    border-left-width: 1px
  }

  .sm\:box-border {
    box-sizing: border-box
  }

  .sm\:box-content {
    box-sizing: content-box
  }

  .sm\:cursor-auto {
    cursor: auto
  }

  .sm\:cursor-default {
    cursor: default
  }

  .sm\:cursor-pointer {
    cursor: pointer
  }

  .sm\:cursor-wait {
    cursor: wait
  }

  .sm\:cursor-text {
    cursor: text
  }

  .sm\:cursor-move {
    cursor: move
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed
  }

  .sm\:block {
    display: block
  }

  .sm\:inline-block {
    display: inline-block
  }

  .sm\:inline {
    display: inline
  }

  .sm\:flex {
    display: flex
  }

  .sm\:inline-flex {
    display: inline-flex
  }

  .sm\:table {
    display: table
  }

  .sm\:table-caption {
    display: table-caption
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:table-column {
    display: table-column
  }

  .sm\:table-column-group {
    display: table-column-group
  }

  .sm\:table-footer-group {
    display: table-footer-group
  }

  .sm\:table-header-group {
    display: table-header-group
  }

  .sm\:table-row-group {
    display: table-row-group
  }

  .sm\:table-row {
    display: table-row
  }

  .sm\:flow-root {
    display: flow-root
  }

  .sm\:grid {
    display: grid
  }

  .sm\:inline-grid {
    display: inline-grid
  }

  .sm\:contents {
    display: contents
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .sm\:flex-wrap {
    flex-wrap: wrap
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .sm\:place-items-auto {
    place-items: auto
  }

  .sm\:place-items-start {
    place-items: start
  }

  .sm\:place-items-end {
    place-items: end
  }

  .sm\:place-items-center {
    place-items: center
  }

  .sm\:place-items-stretch {
    place-items: stretch
  }

  .sm\:place-content-center {
    place-content: center
  }

  .sm\:place-content-start {
    place-content: start
  }

  .sm\:place-content-end {
    place-content: end
  }

  .sm\:place-content-between {
    place-content: space-between
  }

  .sm\:place-content-around {
    place-content: space-around
  }

  .sm\:place-content-evenly {
    place-content: space-evenly
  }

  .sm\:place-content-stretch {
    place-content: stretch
  }

  .sm\:place-self-auto {
    place-self: auto
  }

  .sm\:place-self-start {
    place-self: start
  }

  .sm\:place-self-end {
    place-self: end
  }

  .sm\:place-self-center {
    place-self: center
  }

  .sm\:place-self-stretch {
    place-self: stretch
  }

  .sm\:items-start {
    align-items: flex-start
  }

  .sm\:items-end {
    align-items: flex-end
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:items-baseline {
    align-items: baseline
  }

  .sm\:items-stretch {
    align-items: stretch
  }

  .sm\:content-center {
    align-content: center
  }

  .sm\:content-start {
    align-content: flex-start
  }

  .sm\:content-end {
    align-content: flex-end
  }

  .sm\:content-between {
    align-content: space-between
  }

  .sm\:content-around {
    align-content: space-around
  }

  .sm\:content-evenly {
    align-content: space-evenly
  }

  .sm\:self-auto {
    align-self: auto
  }

  .sm\:self-start {
    align-self: flex-start
  }

  .sm\:self-end {
    align-self: flex-end
  }

  .sm\:self-center {
    align-self: center
  }

  .sm\:self-stretch {
    align-self: stretch
  }

  .sm\:justify-items-auto {
    justify-items: auto
  }

  .sm\:justify-items-start {
    justify-items: start
  }

  .sm\:justify-items-end {
    justify-items: end
  }

  .sm\:justify-items-center {
    justify-items: center
  }

  .sm\:justify-items-stretch {
    justify-items: stretch
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:justify-center {
    justify-content: center
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:justify-around {
    justify-content: space-around
  }

  .sm\:justify-evenly {
    justify-content: space-evenly
  }

  .sm\:justify-self-auto {
    justify-self: auto
  }

  .sm\:justify-self-start {
    justify-self: start
  }

  .sm\:justify-self-end {
    justify-self: end
  }

  .sm\:justify-self-center {
    justify-self: center
  }

  .sm\:justify-self-stretch {
    justify-self: stretch
  }

  .sm\:flex-1 {
    flex: 1 1 0%
  }

  .sm\:flex-auto {
    flex: 1 1 auto
  }

  .sm\:flex-initial {
    flex: 0 1 auto
  }

  .sm\:flex-none {
    flex: none
  }

  .sm\:flex-grow-0 {
    flex-grow: 0
  }

  .sm\:flex-grow {
    flex-grow: 1
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0
  }

  .sm\:flex-shrink {
    flex-shrink: 1
  }

  .sm\:order-1 {
    order: 1
  }

  .sm\:order-2 {
    order: 2
  }

  .sm\:order-3 {
    order: 3
  }

  .sm\:order-4 {
    order: 4
  }

  .sm\:order-5 {
    order: 5
  }

  .sm\:order-6 {
    order: 6
  }

  .sm\:order-7 {
    order: 7
  }

  .sm\:order-8 {
    order: 8
  }

  .sm\:order-9 {
    order: 9
  }

  .sm\:order-10 {
    order: 10
  }

  .sm\:order-11 {
    order: 11
  }

  .sm\:order-12 {
    order: 12
  }

  .sm\:order-first {
    order: -9999
  }

  .sm\:order-last {
    order: 9999
  }

  .sm\:order-none {
    order: 0
  }

  .sm\:float-right {
    float: right
  }

  .sm\:float-left {
    float: left
  }

  .sm\:float-none {
    float: none
  }

  .sm\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .sm\:clear-left {
    clear: left
  }

  .sm\:clear-right {
    clear: right
  }

  .sm\:clear-both {
    clear: both
  }

  .sm\:clear-none {
    clear: none
  }

  .sm\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .sm\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .sm\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .sm\:font-hairline {
    font-weight: 100
  }

  .sm\:font-thin {
    font-weight: 200
  }

  .sm\:font-light {
    font-weight: 300
  }

  .sm\:font-normal {
    font-weight: 400
  }

  .sm\:font-medium {
    font-weight: 500
  }

  .sm\:font-semibold {
    font-weight: 600
  }

  .sm\:font-bold {
    font-weight: 700
  }

  .sm\:font-extrabold {
    font-weight: 800
  }

  .sm\:font-black {
    font-weight: 900
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900
  }

  .sm\:h-0 {
    height: 0
  }

  .sm\:h-1 {
    height: 0.25rem
  }

  .sm\:h-2 {
    height: 0.5rem
  }

  .sm\:h-3 {
    height: 0.75rem
  }

  .sm\:h-4 {
    height: 1rem
  }

  .sm\:h-5 {
    height: 1.25rem
  }

  .sm\:h-6 {
    height: 1.5rem
  }

  .sm\:h-8 {
    height: 2rem
  }

  .sm\:h-10 {
    height: 2.5rem
  }

  .sm\:h-12 {
    height: 3rem
  }

  .sm\:h-16 {
    height: 4rem
  }

  .sm\:h-20 {
    height: 5rem
  }

  .sm\:h-24 {
    height: 6rem
  }

  .sm\:h-32 {
    height: 8rem
  }

  .sm\:h-40 {
    height: 10rem
  }

  .sm\:h-48 {
    height: 12rem
  }

  .sm\:h-56 {
    height: 14rem
  }

  .sm\:h-64 {
    height: 16rem
  }

  .sm\:h-auto {
    height: auto
  }

  .sm\:h-px {
    height: 1px
  }

  .sm\:h-full {
    height: 100%
  }

  .sm\:h-screen {
    height: 100vh
  }

  .sm\:text-xs {
    font-size: 0.75rem
  }

  .sm\:text-sm {
    font-size: 0.875rem
  }

  .sm\:text-base {
    font-size: 1rem
  }

  .sm\:text-lg {
    font-size: 1.125rem
  }

  .sm\:text-xl {
    font-size: 1.25rem
  }

  .sm\:text-2xl {
    font-size: 1.5rem
  }

  .sm\:text-3xl {
    font-size: 1.875rem
  }

  .sm\:text-4xl {
    font-size: 2.25rem
  }

  .sm\:text-5xl {
    font-size: 3rem
  }

  .sm\:text-6xl {
    font-size: 4rem
  }

  .sm\:leading-3 {
    line-height: .75rem
  }

  .sm\:leading-4 {
    line-height: 1rem
  }

  .sm\:leading-5 {
    line-height: 1.25rem
  }

  .sm\:leading-6 {
    line-height: 1.5rem
  }

  .sm\:leading-7 {
    line-height: 1.75rem
  }

  .sm\:leading-8 {
    line-height: 2rem
  }

  .sm\:leading-9 {
    line-height: 2.25rem
  }

  .sm\:leading-10 {
    line-height: 2.5rem
  }

  .sm\:leading-none {
    line-height: 1
  }

  .sm\:leading-tight {
    line-height: 1.25
  }

  .sm\:leading-snug {
    line-height: 1.375
  }

  .sm\:leading-normal {
    line-height: 1.5
  }

  .sm\:leading-relaxed {
    line-height: 1.625
  }

  .sm\:leading-loose {
    line-height: 2
  }

  .sm\:list-inside {
    list-style-position: inside
  }

  .sm\:list-outside {
    list-style-position: outside
  }

  .sm\:list-none {
    list-style-type: none
  }

  .sm\:list-disc {
    list-style-type: disc
  }

  .sm\:list-decimal {
    list-style-type: decimal
  }

  .sm\:m-0 {
    margin: 0
  }

  .sm\:m-1 {
    margin: 0.25rem
  }

  .sm\:m-2 {
    margin: 0.5rem
  }

  .sm\:m-3 {
    margin: 0.75rem
  }

  .sm\:m-4 {
    margin: 1rem
  }

  .sm\:m-5 {
    margin: 1.25rem
  }

  .sm\:m-6 {
    margin: 1.5rem
  }

  .sm\:m-8 {
    margin: 2rem
  }

  .sm\:m-10 {
    margin: 2.5rem
  }

  .sm\:m-12 {
    margin: 3rem
  }

  .sm\:m-16 {
    margin: 4rem
  }

  .sm\:m-20 {
    margin: 5rem
  }

  .sm\:m-24 {
    margin: 6rem
  }

  .sm\:m-32 {
    margin: 8rem
  }

  .sm\:m-40 {
    margin: 10rem
  }

  .sm\:m-48 {
    margin: 12rem
  }

  .sm\:m-56 {
    margin: 14rem
  }

  .sm\:m-64 {
    margin: 16rem
  }

  .sm\:m-auto {
    margin: auto
  }

  .sm\:m-px {
    margin: 1px
  }

  .sm\:-m-1 {
    margin: -0.25rem
  }

  .sm\:-m-2 {
    margin: -0.5rem
  }

  .sm\:-m-3 {
    margin: -0.75rem
  }

  .sm\:-m-4 {
    margin: -1rem
  }

  .sm\:-m-5 {
    margin: -1.25rem
  }

  .sm\:-m-6 {
    margin: -1.5rem
  }

  .sm\:-m-8 {
    margin: -2rem
  }

  .sm\:-m-10 {
    margin: -2.5rem
  }

  .sm\:-m-12 {
    margin: -3rem
  }

  .sm\:-m-16 {
    margin: -4rem
  }

  .sm\:-m-20 {
    margin: -5rem
  }

  .sm\:-m-24 {
    margin: -6rem
  }

  .sm\:-m-32 {
    margin: -8rem
  }

  .sm\:-m-40 {
    margin: -10rem
  }

  .sm\:-m-48 {
    margin: -12rem
  }

  .sm\:-m-56 {
    margin: -14rem
  }

  .sm\:-m-64 {
    margin: -16rem
  }

  .sm\:-m-px {
    margin: -1px
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .sm\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .sm\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .sm\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .sm\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .sm\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .sm\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .sm\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .sm\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .sm\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:mr-0 {
    margin-right: 0
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:ml-0 {
    margin-left: 0
  }

  .sm\:mt-1 {
    margin-top: 0.25rem
  }

  .sm\:mr-1 {
    margin-right: 0.25rem
  }

  .sm\:mb-1 {
    margin-bottom: 0.25rem
  }

  .sm\:ml-1 {
    margin-left: 0.25rem
  }

  .sm\:mt-2 {
    margin-top: 0.5rem
  }

  .sm\:mr-2 {
    margin-right: 0.5rem
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem
  }

  .sm\:ml-2 {
    margin-left: 0.5rem
  }

  .sm\:mt-3 {
    margin-top: 0.75rem
  }

  .sm\:mr-3 {
    margin-right: 0.75rem
  }

  .sm\:mb-3 {
    margin-bottom: 0.75rem
  }

  .sm\:ml-3 {
    margin-left: 0.75rem
  }

  .sm\:mt-4 {
    margin-top: 1rem
  }

  .sm\:mr-4 {
    margin-right: 1rem
  }

  .sm\:mb-4 {
    margin-bottom: 1rem
  }

  .sm\:ml-4 {
    margin-left: 1rem
  }

  .sm\:mt-5 {
    margin-top: 1.25rem
  }

  .sm\:mr-5 {
    margin-right: 1.25rem
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem
  }

  .sm\:ml-5 {
    margin-left: 1.25rem
  }

  .sm\:mt-6 {
    margin-top: 1.5rem
  }

  .sm\:mr-6 {
    margin-right: 1.5rem
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem
  }

  .sm\:ml-6 {
    margin-left: 1.5rem
  }

  .sm\:mt-8 {
    margin-top: 2rem
  }

  .sm\:mr-8 {
    margin-right: 2rem
  }

  .sm\:mb-8 {
    margin-bottom: 2rem
  }

  .sm\:ml-8 {
    margin-left: 2rem
  }

  .sm\:mt-10 {
    margin-top: 2.5rem
  }

  .sm\:mr-10 {
    margin-right: 2.5rem
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem
  }

  .sm\:ml-10 {
    margin-left: 2.5rem
  }

  .sm\:mt-12 {
    margin-top: 3rem
  }

  .sm\:mr-12 {
    margin-right: 3rem
  }

  .sm\:mb-12 {
    margin-bottom: 3rem
  }

  .sm\:ml-12 {
    margin-left: 3rem
  }

  .sm\:mt-16 {
    margin-top: 4rem
  }

  .sm\:mr-16 {
    margin-right: 4rem
  }

  .sm\:mb-16 {
    margin-bottom: 4rem
  }

  .sm\:ml-16 {
    margin-left: 4rem
  }

  .sm\:mt-20 {
    margin-top: 5rem
  }

  .sm\:mr-20 {
    margin-right: 5rem
  }

  .sm\:mb-20 {
    margin-bottom: 5rem
  }

  .sm\:ml-20 {
    margin-left: 5rem
  }

  .sm\:mt-24 {
    margin-top: 6rem
  }

  .sm\:mr-24 {
    margin-right: 6rem
  }

  .sm\:mb-24 {
    margin-bottom: 6rem
  }

  .sm\:ml-24 {
    margin-left: 6rem
  }

  .sm\:mt-32 {
    margin-top: 8rem
  }

  .sm\:mr-32 {
    margin-right: 8rem
  }

  .sm\:mb-32 {
    margin-bottom: 8rem
  }

  .sm\:ml-32 {
    margin-left: 8rem
  }

  .sm\:mt-40 {
    margin-top: 10rem
  }

  .sm\:mr-40 {
    margin-right: 10rem
  }

  .sm\:mb-40 {
    margin-bottom: 10rem
  }

  .sm\:ml-40 {
    margin-left: 10rem
  }

  .sm\:mt-48 {
    margin-top: 12rem
  }

  .sm\:mr-48 {
    margin-right: 12rem
  }

  .sm\:mb-48 {
    margin-bottom: 12rem
  }

  .sm\:ml-48 {
    margin-left: 12rem
  }

  .sm\:mt-56 {
    margin-top: 14rem
  }

  .sm\:mr-56 {
    margin-right: 14rem
  }

  .sm\:mb-56 {
    margin-bottom: 14rem
  }

  .sm\:ml-56 {
    margin-left: 14rem
  }

  .sm\:mt-64 {
    margin-top: 16rem
  }

  .sm\:mr-64 {
    margin-right: 16rem
  }

  .sm\:mb-64 {
    margin-bottom: 16rem
  }

  .sm\:ml-64 {
    margin-left: 16rem
  }

  .sm\:mt-auto {
    margin-top: auto
  }

  .sm\:mr-auto {
    margin-right: auto
  }

  .sm\:mb-auto {
    margin-bottom: auto
  }

  .sm\:ml-auto {
    margin-left: auto
  }

  .sm\:mt-px {
    margin-top: 1px
  }

  .sm\:mr-px {
    margin-right: 1px
  }

  .sm\:mb-px {
    margin-bottom: 1px
  }

  .sm\:ml-px {
    margin-left: 1px
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem
  }

  .sm\:-mr-1 {
    margin-right: -0.25rem
  }

  .sm\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .sm\:-ml-1 {
    margin-left: -0.25rem
  }

  .sm\:-mt-2 {
    margin-top: -0.5rem
  }

  .sm\:-mr-2 {
    margin-right: -0.5rem
  }

  .sm\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .sm\:-ml-2 {
    margin-left: -0.5rem
  }

  .sm\:-mt-3 {
    margin-top: -0.75rem
  }

  .sm\:-mr-3 {
    margin-right: -0.75rem
  }

  .sm\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .sm\:-ml-3 {
    margin-left: -0.75rem
  }

  .sm\:-mt-4 {
    margin-top: -1rem
  }

  .sm\:-mr-4 {
    margin-right: -1rem
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem
  }

  .sm\:-ml-4 {
    margin-left: -1rem
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem
  }

  .sm\:-mt-8 {
    margin-top: -2rem
  }

  .sm\:-mr-8 {
    margin-right: -2rem
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem
  }

  .sm\:-ml-8 {
    margin-left: -2rem
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem
  }

  .sm\:-mt-12 {
    margin-top: -3rem
  }

  .sm\:-mr-12 {
    margin-right: -3rem
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem
  }

  .sm\:-ml-12 {
    margin-left: -3rem
  }

  .sm\:-mt-16 {
    margin-top: -4rem
  }

  .sm\:-mr-16 {
    margin-right: -4rem
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem
  }

  .sm\:-ml-16 {
    margin-left: -4rem
  }

  .sm\:-mt-20 {
    margin-top: -5rem
  }

  .sm\:-mr-20 {
    margin-right: -5rem
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem
  }

  .sm\:-ml-20 {
    margin-left: -5rem
  }

  .sm\:-mt-24 {
    margin-top: -6rem
  }

  .sm\:-mr-24 {
    margin-right: -6rem
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem
  }

  .sm\:-ml-24 {
    margin-left: -6rem
  }

  .sm\:-mt-32 {
    margin-top: -8rem
  }

  .sm\:-mr-32 {
    margin-right: -8rem
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem
  }

  .sm\:-ml-32 {
    margin-left: -8rem
  }

  .sm\:-mt-40 {
    margin-top: -10rem
  }

  .sm\:-mr-40 {
    margin-right: -10rem
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem
  }

  .sm\:-ml-40 {
    margin-left: -10rem
  }

  .sm\:-mt-48 {
    margin-top: -12rem
  }

  .sm\:-mr-48 {
    margin-right: -12rem
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem
  }

  .sm\:-ml-48 {
    margin-left: -12rem
  }

  .sm\:-mt-56 {
    margin-top: -14rem
  }

  .sm\:-mr-56 {
    margin-right: -14rem
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem
  }

  .sm\:-ml-56 {
    margin-left: -14rem
  }

  .sm\:-mt-64 {
    margin-top: -16rem
  }

  .sm\:-mr-64 {
    margin-right: -16rem
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem
  }

  .sm\:-ml-64 {
    margin-left: -16rem
  }

  .sm\:-mt-px {
    margin-top: -1px
  }

  .sm\:-mr-px {
    margin-right: -1px
  }

  .sm\:-mb-px {
    margin-bottom: -1px
  }

  .sm\:-ml-px {
    margin-left: -1px
  }

  .sm\:max-h-full {
    max-height: 100%
  }

  .sm\:max-h-screen {
    max-height: 100vh
  }

  .sm\:max-w-none {
    max-width: none
  }

  .sm\:max-w-xs {
    max-width: 20rem
  }

  .sm\:max-w-sm {
    max-width: 24rem
  }

  .sm\:max-w-md {
    max-width: 28rem
  }

  .sm\:max-w-lg {
    max-width: 32rem
  }

  .sm\:max-w-xl {
    max-width: 36rem
  }

  .sm\:max-w-2xl {
    max-width: 42rem
  }

  .sm\:max-w-3xl {
    max-width: 48rem
  }

  .sm\:max-w-4xl {
    max-width: 56rem
  }

  .sm\:max-w-5xl {
    max-width: 64rem
  }

  .sm\:max-w-6xl {
    max-width: 72rem
  }

  .sm\:max-w-full {
    max-width: 100%
  }

  .sm\:max-w-screen-sm {
    max-width: 640px
  }

  .sm\:max-w-screen-md {
    max-width: 768px
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px
  }

  .sm\:max-w-screen-xl {
    max-width: 1280px
  }

  .sm\:min-h-0 {
    min-height: 0
  }

  .sm\:min-h-full {
    min-height: 100%
  }

  .sm\:min-h-screen {
    min-height: 100vh
  }

  .sm\:min-w-0 {
    min-width: 0
  }

  .sm\:min-w-full {
    min-width: 100%
  }

  .sm\:object-contain {
    object-fit: contain
  }

  .sm\:object-cover {
    object-fit: cover
  }

  .sm\:object-fill {
    object-fit: fill
  }

  .sm\:object-none {
    object-fit: none
  }

  .sm\:object-scale-down {
    object-fit: scale-down
  }

  .sm\:object-bottom {
    object-position: bottom
  }

  .sm\:object-center {
    object-position: center
  }

  .sm\:object-left {
    object-position: left
  }

  .sm\:object-left-bottom {
    object-position: left bottom
  }

  .sm\:object-left-top {
    object-position: left top
  }

  .sm\:object-right {
    object-position: right
  }

  .sm\:object-right-bottom {
    object-position: right bottom
  }

  .sm\:object-right-top {
    object-position: right top
  }

  .sm\:object-top {
    object-position: top
  }

  .sm\:opacity-0 {
    opacity: 0
  }

  .sm\:opacity-25 {
    opacity: 0.25
  }

  .sm\:opacity-50 {
    opacity: 0.5
  }

  .sm\:opacity-75 {
    opacity: 0.75
  }

  .sm\:opacity-100 {
    opacity: 1
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1
  }

  .sm\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:overflow-auto {
    overflow: auto
  }

  .sm\:overflow-hidden {
    overflow: hidden
  }

  .sm\:overflow-visible {
    overflow: visible
  }

  .sm\:overflow-scroll {
    overflow: scroll
  }

  .sm\:overflow-x-auto {
    overflow-x: auto
  }

  .sm\:overflow-y-auto {
    overflow-y: auto
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden
  }

  .sm\:overflow-x-visible {
    overflow-x: visible
  }

  .sm\:overflow-y-visible {
    overflow-y: visible
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .sm\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .sm\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .sm\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .sm\:p-0 {
    padding: 0
  }

  .sm\:p-1 {
    padding: 0.25rem
  }

  .sm\:p-2 {
    padding: 0.5rem
  }

  .sm\:p-3 {
    padding: 0.75rem
  }

  .sm\:p-4 {
    padding: 1rem
  }

  .sm\:p-5 {
    padding: 1.25rem
  }

  .sm\:p-6 {
    padding: 1.5rem
  }

  .sm\:p-8 {
    padding: 2rem
  }

  .sm\:p-10 {
    padding: 2.5rem
  }

  .sm\:p-12 {
    padding: 3rem
  }

  .sm\:p-16 {
    padding: 4rem
  }

  .sm\:p-20 {
    padding: 5rem
  }

  .sm\:p-24 {
    padding: 6rem
  }

  .sm\:p-32 {
    padding: 8rem
  }

  .sm\:p-40 {
    padding: 10rem
  }

  .sm\:p-48 {
    padding: 12rem
  }

  .sm\:p-56 {
    padding: 14rem
  }

  .sm\:p-64 {
    padding: 16rem
  }

  .sm\:p-px {
    padding: 1px
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .sm\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pr-0 {
    padding-right: 0
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pl-0 {
    padding-left: 0
  }

  .sm\:pt-1 {
    padding-top: 0.25rem
  }

  .sm\:pr-1 {
    padding-right: 0.25rem
  }

  .sm\:pb-1 {
    padding-bottom: 0.25rem
  }

  .sm\:pl-1 {
    padding-left: 0.25rem
  }

  .sm\:pt-2 {
    padding-top: 0.5rem
  }

  .sm\:pr-2 {
    padding-right: 0.5rem
  }

  .sm\:pb-2 {
    padding-bottom: 0.5rem
  }

  .sm\:pl-2 {
    padding-left: 0.5rem
  }

  .sm\:pt-3 {
    padding-top: 0.75rem
  }

  .sm\:pr-3 {
    padding-right: 0.75rem
  }

  .sm\:pb-3 {
    padding-bottom: 0.75rem
  }

  .sm\:pl-3 {
    padding-left: 0.75rem
  }

  .sm\:pt-4 {
    padding-top: 1rem
  }

  .sm\:pr-4 {
    padding-right: 1rem
  }

  .sm\:pb-4 {
    padding-bottom: 1rem
  }

  .sm\:pl-4 {
    padding-left: 1rem
  }

  .sm\:pt-5 {
    padding-top: 1.25rem
  }

  .sm\:pr-5 {
    padding-right: 1.25rem
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem
  }

  .sm\:pl-5 {
    padding-left: 1.25rem
  }

  .sm\:pt-6 {
    padding-top: 1.5rem
  }

  .sm\:pr-6 {
    padding-right: 1.5rem
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem
  }

  .sm\:pl-6 {
    padding-left: 1.5rem
  }

  .sm\:pt-8 {
    padding-top: 2rem
  }

  .sm\:pr-8 {
    padding-right: 2rem
  }

  .sm\:pb-8 {
    padding-bottom: 2rem
  }

  .sm\:pl-8 {
    padding-left: 2rem
  }

  .sm\:pt-10 {
    padding-top: 2.5rem
  }

  .sm\:pr-10 {
    padding-right: 2.5rem
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem
  }

  .sm\:pl-10 {
    padding-left: 2.5rem
  }

  .sm\:pt-12 {
    padding-top: 3rem
  }

  .sm\:pr-12 {
    padding-right: 3rem
  }

  .sm\:pb-12 {
    padding-bottom: 3rem
  }

  .sm\:pl-12 {
    padding-left: 3rem
  }

  .sm\:pt-16 {
    padding-top: 4rem
  }

  .sm\:pr-16 {
    padding-right: 4rem
  }

  .sm\:pb-16 {
    padding-bottom: 4rem
  }

  .sm\:pl-16 {
    padding-left: 4rem
  }

  .sm\:pt-20 {
    padding-top: 5rem
  }

  .sm\:pr-20 {
    padding-right: 5rem
  }

  .sm\:pb-20 {
    padding-bottom: 5rem
  }

  .sm\:pl-20 {
    padding-left: 5rem
  }

  .sm\:pt-24 {
    padding-top: 6rem
  }

  .sm\:pr-24 {
    padding-right: 6rem
  }

  .sm\:pb-24 {
    padding-bottom: 6rem
  }

  .sm\:pl-24 {
    padding-left: 6rem
  }

  .sm\:pt-32 {
    padding-top: 8rem
  }

  .sm\:pr-32 {
    padding-right: 8rem
  }

  .sm\:pb-32 {
    padding-bottom: 8rem
  }

  .sm\:pl-32 {
    padding-left: 8rem
  }

  .sm\:pt-40 {
    padding-top: 10rem
  }

  .sm\:pr-40 {
    padding-right: 10rem
  }

  .sm\:pb-40 {
    padding-bottom: 10rem
  }

  .sm\:pl-40 {
    padding-left: 10rem
  }

  .sm\:pt-48 {
    padding-top: 12rem
  }

  .sm\:pr-48 {
    padding-right: 12rem
  }

  .sm\:pb-48 {
    padding-bottom: 12rem
  }

  .sm\:pl-48 {
    padding-left: 12rem
  }

  .sm\:pt-56 {
    padding-top: 14rem
  }

  .sm\:pr-56 {
    padding-right: 14rem
  }

  .sm\:pb-56 {
    padding-bottom: 14rem
  }

  .sm\:pl-56 {
    padding-left: 14rem
  }

  .sm\:pt-64 {
    padding-top: 16rem
  }

  .sm\:pr-64 {
    padding-right: 16rem
  }

  .sm\:pb-64 {
    padding-bottom: 16rem
  }

  .sm\:pl-64 {
    padding-left: 16rem
  }

  .sm\:pt-px {
    padding-top: 1px
  }

  .sm\:pr-px {
    padding-right: 1px
  }

  .sm\:pb-px {
    padding-bottom: 1px
  }

  .sm\:pl-px {
    padding-left: 1px
  }

  .sm\:placeholder-transparent:-ms-input-placeholder {
    color: transparent
  }

  .sm\:placeholder-transparent::-ms-input-placeholder {
    color: transparent
  }

  .sm\:placeholder-transparent::placeholder {
    color: transparent
  }

  .sm\:placeholder-current:-ms-input-placeholder {
    color: currentColor
  }

  .sm\:placeholder-current::-ms-input-placeholder {
    color: currentColor
  }

  .sm\:placeholder-current::placeholder {
    color: currentColor
  }

  .sm\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent
  }

  .sm\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .sm\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor
  }

  .sm\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor
  }

  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .sm\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .sm\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .sm\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .sm\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .sm\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .sm\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .sm\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .sm\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .sm\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .sm\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .sm\:pointer-events-none {
    pointer-events: none
  }

  .sm\:pointer-events-auto {
    pointer-events: auto
  }

  .sm\:static {
    position: static
  }

  .sm\:fixed {
    position: fixed
  }

  .sm\:absolute {
    position: absolute
  }

  .sm\:relative {
    position: relative
  }

  .sm\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .sm\:inset-x-0 {
    right: 0;
    left: 0
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .sm\:inset-x-auto {
    right: auto;
    left: auto
  }

  .sm\:top-0 {
    top: 0
  }

  .sm\:right-0 {
    right: 0
  }

  .sm\:bottom-0 {
    bottom: 0
  }

  .sm\:left-0 {
    left: 0
  }

  .sm\:top-auto {
    top: auto
  }

  .sm\:right-auto {
    right: auto
  }

  .sm\:bottom-auto {
    bottom: auto
  }

  .sm\:left-auto {
    left: auto
  }

  .sm\:resize-none {
    resize: none
  }

  .sm\:resize-y {
    resize: vertical
  }

  .sm\:resize-x {
    resize: horizontal
  }

  .sm\:resize {
    resize: both
  }

  .sm\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:shadow-none {
    box-shadow: none
  }

  .sm\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .sm\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .sm\:fill-current {
    fill: currentColor
  }

  .sm\:stroke-current {
    stroke: currentColor
  }

  .sm\:stroke-0 {
    stroke-width: 0
  }

  .sm\:stroke-1 {
    stroke-width: 1
  }

  .sm\:stroke-2 {
    stroke-width: 2
  }

  .sm\:table-auto {
    table-layout: auto
  }

  .sm\:table-fixed {
    table-layout: fixed
  }

  .sm\:text-left {
    text-align: left
  }

  .sm\:text-center {
    text-align: center
  }

  .sm\:text-right {
    text-align: right
  }

  .sm\:text-justify {
    text-align: justify
  }

  .sm\:text-transparent {
    color: transparent
  }

  .sm\:text-current {
    color: currentColor
  }

  .sm\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .sm\:text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .sm\:text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .sm\:text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .sm\:text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .sm\:text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .sm\:text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .sm\:text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .sm\:text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .sm\:text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .sm\:text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .sm\:text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .sm\:text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .sm\:text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .sm\:text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .sm\:text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .sm\:text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .sm\:text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .sm\:text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .sm\:text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .sm\:text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .sm\:text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .sm\:text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .sm\:text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .sm\:text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .sm\:text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .sm\:text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .sm\:text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .sm\:text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .sm\:text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .sm\:text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .sm\:text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .sm\:text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .sm\:text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .sm\:text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .sm\:text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .sm\:text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .sm\:text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .sm\:text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .sm\:text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .sm\:text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .sm\:text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .sm\:text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .sm\:text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .sm\:text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .sm\:text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .sm\:text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .sm\:text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .sm\:text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .sm\:text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .sm\:text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .sm\:text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .sm\:text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .sm\:text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .sm\:text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .sm\:text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .sm\:text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .sm\:text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .sm\:text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .sm\:text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .sm\:text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .sm\:text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .sm\:text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .sm\:text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .sm\:text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .sm\:text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .sm\:text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .sm\:text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .sm\:text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .sm\:text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .sm\:text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .sm\:text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .sm\:text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .sm\:text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .sm\:text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .sm\:text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .sm\:text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .sm\:text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .sm\:text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .sm\:text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .sm\:text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .sm\:text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .sm\:text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .sm\:text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .sm\:text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .sm\:text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .sm\:text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .sm\:text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .sm\:text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .sm\:text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .sm\:hover\:text-transparent:hover {
    color: transparent
  }

  .sm\:hover\:text-current:hover {
    color: currentColor
  }

  .sm\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .sm\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .sm\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .sm\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .sm\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .sm\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .sm\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .sm\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .sm\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .sm\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .sm\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .sm\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .sm\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .sm\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .sm\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .sm\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .sm\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .sm\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .sm\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .sm\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .sm\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .sm\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .sm\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .sm\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .sm\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .sm\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .sm\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .sm\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .sm\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .sm\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .sm\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .sm\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .sm\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .sm\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .sm\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .sm\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .sm\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .sm\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .sm\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .sm\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .sm\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .sm\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .sm\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .sm\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .sm\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .sm\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .sm\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .sm\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .sm\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .sm\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .sm\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .sm\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .sm\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .sm\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .sm\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .sm\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .sm\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .sm\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .sm\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .sm\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .sm\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .sm\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .sm\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .sm\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .sm\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .sm\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .sm\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .sm\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .sm\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .sm\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .sm\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .sm\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .sm\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .sm\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .sm\:focus\:text-transparent:focus {
    color: transparent
  }

  .sm\:focus\:text-current:focus {
    color: currentColor
  }

  .sm\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .sm\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .sm\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .sm\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .sm\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .sm\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .sm\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .sm\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .sm\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .sm\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .sm\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .sm\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .sm\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .sm\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .sm\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .sm\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .sm\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .sm\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .sm\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .sm\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .sm\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .sm\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .sm\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .sm\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .sm\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .sm\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .sm\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .sm\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .sm\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .sm\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .sm\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .sm\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .sm\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .sm\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .sm\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .sm\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .sm\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .sm\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .sm\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .sm\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .sm\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .sm\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .sm\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .sm\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .sm\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .sm\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .sm\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .sm\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .sm\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .sm\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .sm\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .sm\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .sm\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .sm\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .sm\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .sm\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .sm\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .sm\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .sm\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .sm\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .sm\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .sm\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .sm\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .sm\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .sm\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .sm\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .sm\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .sm\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .sm\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .sm\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .sm\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .sm\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .sm\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .sm\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .sm\:text-opacity-0 {
    --text-opacity: 0
  }

  .sm\:text-opacity-25 {
    --text-opacity: 0.25
  }

  .sm\:text-opacity-50 {
    --text-opacity: 0.5
  }

  .sm\:text-opacity-75 {
    --text-opacity: 0.75
  }

  .sm\:text-opacity-100 {
    --text-opacity: 1
  }

  .sm\:hover\:text-opacity-0:hover {
    --text-opacity: 0
  }

  .sm\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25
  }

  .sm\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5
  }

  .sm\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75
  }

  .sm\:hover\:text-opacity-100:hover {
    --text-opacity: 1
  }

  .sm\:focus\:text-opacity-0:focus {
    --text-opacity: 0
  }

  .sm\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25
  }

  .sm\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5
  }

  .sm\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75
  }

  .sm\:focus\:text-opacity-100:focus {
    --text-opacity: 1
  }

  .sm\:italic {
    font-style: italic
  }

  .sm\:not-italic {
    font-style: normal
  }

  .sm\:uppercase {
    text-transform: uppercase
  }

  .sm\:lowercase {
    text-transform: lowercase
  }

  .sm\:capitalize {
    text-transform: capitalize
  }

  .sm\:normal-case {
    text-transform: none
  }

  .sm\:underline {
    text-decoration: underline
  }

  .sm\:line-through {
    text-decoration: line-through
  }

  .sm\:no-underline {
    text-decoration: none
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .sm\:normal-nums {
    font-variant-numeric: normal
  }

  .sm\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .sm\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .sm\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .sm\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .sm\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .sm\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .sm\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .sm\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em
  }

  .sm\:tracking-normal {
    letter-spacing: 0
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em
  }

  .sm\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .sm\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .sm\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .sm\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .sm\:align-baseline {
    vertical-align: baseline
  }

  .sm\:align-top {
    vertical-align: top
  }

  .sm\:align-middle {
    vertical-align: middle
  }

  .sm\:align-bottom {
    vertical-align: bottom
  }

  .sm\:align-text-top {
    vertical-align: text-top
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom
  }

  .sm\:visible {
    visibility: visible
  }

  .sm\:invisible {
    visibility: hidden
  }

  .sm\:whitespace-normal {
    white-space: normal
  }

  .sm\:whitespace-no-wrap {
    white-space: nowrap
  }

  .sm\:whitespace-pre {
    white-space: pre
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .sm\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .sm\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .sm\:break-all {
    word-break: break-all
  }

  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm\:w-0 {
    width: 0
  }

  .sm\:w-1 {
    width: 0.25rem
  }

  .sm\:w-2 {
    width: 0.5rem
  }

  .sm\:w-3 {
    width: 0.75rem
  }

  .sm\:w-4 {
    width: 1rem
  }

  .sm\:w-5 {
    width: 1.25rem
  }

  .sm\:w-6 {
    width: 1.5rem
  }

  .sm\:w-8 {
    width: 2rem
  }

  .sm\:w-10 {
    width: 2.5rem
  }

  .sm\:w-12 {
    width: 3rem
  }

  .sm\:w-16 {
    width: 4rem
  }

  .sm\:w-20 {
    width: 5rem
  }

  .sm\:w-24 {
    width: 6rem
  }

  .sm\:w-32 {
    width: 8rem
  }

  .sm\:w-40 {
    width: 10rem
  }

  .sm\:w-48 {
    width: 12rem
  }

  .sm\:w-56 {
    width: 14rem
  }

  .sm\:w-64 {
    width: 16rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-px {
    width: 1px
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }

  .sm\:w-2\/3 {
    width: 66.666667%
  }

  .sm\:w-1\/4 {
    width: 25%
  }

  .sm\:w-2\/4 {
    width: 50%
  }

  .sm\:w-3\/4 {
    width: 75%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }

  .sm\:w-3\/5 {
    width: 60%
  }

  .sm\:w-4\/5 {
    width: 80%
  }

  .sm\:w-1\/6 {
    width: 16.666667%
  }

  .sm\:w-2\/6 {
    width: 33.333333%
  }

  .sm\:w-3\/6 {
    width: 50%
  }

  .sm\:w-4\/6 {
    width: 66.666667%
  }

  .sm\:w-5\/6 {
    width: 83.333333%
  }

  .sm\:w-1\/12 {
    width: 8.333333%
  }

  .sm\:w-2\/12 {
    width: 16.666667%
  }

  .sm\:w-3\/12 {
    width: 25%
  }

  .sm\:w-4\/12 {
    width: 33.333333%
  }

  .sm\:w-5\/12 {
    width: 41.666667%
  }

  .sm\:w-6\/12 {
    width: 50%
  }

  .sm\:w-7\/12 {
    width: 58.333333%
  }

  .sm\:w-8\/12 {
    width: 66.666667%
  }

  .sm\:w-9\/12 {
    width: 75%
  }

  .sm\:w-10\/12 {
    width: 83.333333%
  }

  .sm\:w-11\/12 {
    width: 91.666667%
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:w-screen {
    width: 100vw
  }

  .sm\:z-0 {
    z-index: 0
  }

  .sm\:z-10 {
    z-index: 10
  }

  .sm\:z-20 {
    z-index: 20
  }

  .sm\:z-30 {
    z-index: 30
  }

  .sm\:z-40 {
    z-index: 40
  }

  .sm\:z-50 {
    z-index: 50
  }

  .sm\:z-auto {
    z-index: auto
  }

  .sm\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .sm\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .sm\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .sm\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .sm\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .sm\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .sm\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .sm\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .sm\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .sm\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .sm\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .sm\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .sm\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .sm\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .sm\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .sm\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .sm\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .sm\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .sm\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .sm\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .sm\:col-gap-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .sm\:col-gap-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .sm\:col-gap-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .sm\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .sm\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .sm\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .sm\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .sm\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .sm\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .sm\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .sm\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .sm\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .sm\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .sm\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .sm\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .sm\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .sm\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .sm\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .sm\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .sm\:gap-x-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .sm\:gap-x-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .sm\:gap-x-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .sm\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .sm\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .sm\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .sm\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .sm\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .sm\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .sm\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .sm\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .sm\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .sm\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .sm\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .sm\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .sm\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .sm\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .sm\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .sm\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .sm\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .sm\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .sm\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .sm\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .sm\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .sm\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .sm\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .sm\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .sm\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .sm\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .sm\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .sm\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .sm\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .sm\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .sm\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .sm\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .sm\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .sm\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .sm\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .sm\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .sm\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .sm\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .sm\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .sm\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .sm\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .sm\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .sm\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .sm\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .sm\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .sm\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .sm\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .sm\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .sm\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .sm\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .sm\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .sm\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .sm\:grid-cols-none {
    grid-template-columns: none
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .sm\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .sm\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .sm\:col-auto {
    grid-column: auto
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .sm\:col-span-full {
    grid-column: 1 / -1
  }

  .sm\:col-start-1 {
    grid-column-start: 1
  }

  .sm\:col-start-2 {
    grid-column-start: 2
  }

  .sm\:col-start-3 {
    grid-column-start: 3
  }

  .sm\:col-start-4 {
    grid-column-start: 4
  }

  .sm\:col-start-5 {
    grid-column-start: 5
  }

  .sm\:col-start-6 {
    grid-column-start: 6
  }

  .sm\:col-start-7 {
    grid-column-start: 7
  }

  .sm\:col-start-8 {
    grid-column-start: 8
  }

  .sm\:col-start-9 {
    grid-column-start: 9
  }

  .sm\:col-start-10 {
    grid-column-start: 10
  }

  .sm\:col-start-11 {
    grid-column-start: 11
  }

  .sm\:col-start-12 {
    grid-column-start: 12
  }

  .sm\:col-start-13 {
    grid-column-start: 13
  }

  .sm\:col-start-auto {
    grid-column-start: auto
  }

  .sm\:col-end-1 {
    grid-column-end: 1
  }

  .sm\:col-end-2 {
    grid-column-end: 2
  }

  .sm\:col-end-3 {
    grid-column-end: 3
  }

  .sm\:col-end-4 {
    grid-column-end: 4
  }

  .sm\:col-end-5 {
    grid-column-end: 5
  }

  .sm\:col-end-6 {
    grid-column-end: 6
  }

  .sm\:col-end-7 {
    grid-column-end: 7
  }

  .sm\:col-end-8 {
    grid-column-end: 8
  }

  .sm\:col-end-9 {
    grid-column-end: 9
  }

  .sm\:col-end-10 {
    grid-column-end: 10
  }

  .sm\:col-end-11 {
    grid-column-end: 11
  }

  .sm\:col-end-12 {
    grid-column-end: 12
  }

  .sm\:col-end-13 {
    grid-column-end: 13
  }

  .sm\:col-end-auto {
    grid-column-end: auto
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-rows-none {
    grid-template-rows: none
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .sm\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .sm\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .sm\:row-auto {
    grid-row: auto
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .sm\:row-span-full {
    grid-row: 1 / -1
  }

  .sm\:row-start-1 {
    grid-row-start: 1
  }

  .sm\:row-start-2 {
    grid-row-start: 2
  }

  .sm\:row-start-3 {
    grid-row-start: 3
  }

  .sm\:row-start-4 {
    grid-row-start: 4
  }

  .sm\:row-start-5 {
    grid-row-start: 5
  }

  .sm\:row-start-6 {
    grid-row-start: 6
  }

  .sm\:row-start-7 {
    grid-row-start: 7
  }

  .sm\:row-start-auto {
    grid-row-start: auto
  }

  .sm\:row-end-1 {
    grid-row-end: 1
  }

  .sm\:row-end-2 {
    grid-row-end: 2
  }

  .sm\:row-end-3 {
    grid-row-end: 3
  }

  .sm\:row-end-4 {
    grid-row-end: 4
  }

  .sm\:row-end-5 {
    grid-row-end: 5
  }

  .sm\:row-end-6 {
    grid-row-end: 6
  }

  .sm\:row-end-7 {
    grid-row-end: 7
  }

  .sm\:row-end-auto {
    grid-row-end: auto
  }

  .sm\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .sm\:transform-none {
    transform: none
  }

  .sm\:origin-center {
    transform-origin: center
  }

  .sm\:origin-top {
    transform-origin: top
  }

  .sm\:origin-top-right {
    transform-origin: top right
  }

  .sm\:origin-right {
    transform-origin: right
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right
  }

  .sm\:origin-bottom {
    transform-origin: bottom
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left
  }

  .sm\:origin-left {
    transform-origin: left
  }

  .sm\:origin-top-left {
    transform-origin: top left
  }

  .sm\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:scale-x-0 {
    --transform-scale-x: 0
  }

  .sm\:scale-x-50 {
    --transform-scale-x: .5
  }

  .sm\:scale-x-75 {
    --transform-scale-x: .75
  }

  .sm\:scale-x-90 {
    --transform-scale-x: .9
  }

  .sm\:scale-x-95 {
    --transform-scale-x: .95
  }

  .sm\:scale-x-100 {
    --transform-scale-x: 1
  }

  .sm\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .sm\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .sm\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .sm\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .sm\:scale-y-0 {
    --transform-scale-y: 0
  }

  .sm\:scale-y-50 {
    --transform-scale-y: .5
  }

  .sm\:scale-y-75 {
    --transform-scale-y: .75
  }

  .sm\:scale-y-90 {
    --transform-scale-y: .9
  }

  .sm\:scale-y-95 {
    --transform-scale-y: .95
  }

  .sm\:scale-y-100 {
    --transform-scale-y: 1
  }

  .sm\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .sm\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .sm\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .sm\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .sm\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .sm\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .sm\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .sm\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .sm\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .sm\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .sm\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .sm\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .sm\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .sm\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .sm\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .sm\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .sm\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .sm\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .sm\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .sm\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .sm\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .sm\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .sm\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .sm\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .sm\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .sm\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .sm\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .sm\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .sm\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .sm\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .sm\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .sm\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .sm\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .sm\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .sm\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .sm\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .sm\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .sm\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .sm\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .sm\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .sm\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .sm\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .sm\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .sm\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .sm\:rotate-0 {
    --transform-rotate: 0
  }

  .sm\:rotate-1 {
    --transform-rotate: 1deg
  }

  .sm\:rotate-2 {
    --transform-rotate: 2deg
  }

  .sm\:rotate-3 {
    --transform-rotate: 3deg
  }

  .sm\:rotate-6 {
    --transform-rotate: 6deg
  }

  .sm\:rotate-12 {
    --transform-rotate: 12deg
  }

  .sm\:rotate-45 {
    --transform-rotate: 45deg
  }

  .sm\:rotate-90 {
    --transform-rotate: 90deg
  }

  .sm\:rotate-180 {
    --transform-rotate: 180deg
  }

  .sm\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .sm\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .sm\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .sm\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .sm\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .sm\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .sm\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .sm\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .sm\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .sm\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .sm\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .sm\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .sm\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .sm\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .sm\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .sm\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .sm\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .sm\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .sm\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .sm\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .sm\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .sm\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .sm\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .sm\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .sm\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .sm\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .sm\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .sm\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .sm\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .sm\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .sm\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .sm\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .sm\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .sm\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .sm\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .sm\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .sm\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .sm\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .sm\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .sm\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .sm\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .sm\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .sm\:translate-x-0 {
    --transform-translate-x: 0
  }

  .sm\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .sm\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .sm\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .sm\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .sm\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .sm\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .sm\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .sm\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .sm\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .sm\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .sm\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .sm\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .sm\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .sm\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .sm\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .sm\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .sm\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .sm\:translate-x-px {
    --transform-translate-x: 1px
  }

  .sm\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .sm\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .sm\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .sm\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .sm\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .sm\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .sm\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .sm\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .sm\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .sm\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .sm\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .sm\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .sm\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .sm\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .sm\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .sm\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .sm\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .sm\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .sm\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .sm\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .sm\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .sm\:translate-x-full {
    --transform-translate-x: 100%
  }

  .sm\:translate-y-0 {
    --transform-translate-y: 0
  }

  .sm\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .sm\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .sm\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .sm\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .sm\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .sm\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .sm\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .sm\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .sm\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .sm\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .sm\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .sm\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .sm\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .sm\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .sm\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .sm\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .sm\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .sm\:translate-y-px {
    --transform-translate-y: 1px
  }

  .sm\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .sm\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .sm\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .sm\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .sm\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .sm\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .sm\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .sm\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .sm\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .sm\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .sm\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .sm\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .sm\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .sm\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .sm\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .sm\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .sm\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .sm\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .sm\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .sm\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .sm\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .sm\:translate-y-full {
    --transform-translate-y: 100%
  }

  .sm\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .sm\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .sm\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .sm\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .sm\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .sm\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .sm\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .sm\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .sm\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .sm\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .sm\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .sm\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .sm\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .sm\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .sm\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .sm\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .sm\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .sm\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .sm\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .sm\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .sm\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .sm\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .sm\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .sm\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .sm\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .sm\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .sm\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .sm\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .sm\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .sm\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .sm\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .sm\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .sm\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .sm\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .sm\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .sm\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .sm\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .sm\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .sm\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .sm\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .sm\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .sm\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .sm\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .sm\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .sm\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .sm\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .sm\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .sm\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .sm\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .sm\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .sm\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .sm\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .sm\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .sm\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .sm\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .sm\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .sm\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .sm\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .sm\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .sm\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .sm\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .sm\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .sm\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .sm\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .sm\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .sm\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .sm\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .sm\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .sm\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .sm\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .sm\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .sm\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .sm\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .sm\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .sm\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .sm\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .sm\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .sm\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .sm\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .sm\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .sm\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .sm\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .sm\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .sm\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .sm\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .sm\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .sm\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .sm\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .sm\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .sm\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .sm\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .sm\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .sm\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .sm\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .sm\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .sm\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .sm\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .sm\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .sm\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .sm\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .sm\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .sm\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .sm\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .sm\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .sm\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .sm\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .sm\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .sm\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .sm\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .sm\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .sm\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .sm\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .sm\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .sm\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .sm\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .sm\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .sm\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .sm\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .sm\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .sm\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .sm\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .sm\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .sm\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .sm\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .sm\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .sm\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .sm\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .sm\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .sm\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .sm\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .sm\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .sm\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .sm\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .sm\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .sm\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .sm\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .sm\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .sm\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .sm\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .sm\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .sm\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .sm\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .sm\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .sm\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .sm\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .sm\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .sm\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .sm\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .sm\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .sm\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .sm\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .sm\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .sm\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .sm\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .sm\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .sm\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .sm\:skew-x-0 {
    --transform-skew-x: 0
  }

  .sm\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .sm\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .sm\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .sm\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .sm\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .sm\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .sm\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .sm\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .sm\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .sm\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .sm\:skew-y-0 {
    --transform-skew-y: 0
  }

  .sm\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .sm\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .sm\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .sm\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .sm\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .sm\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .sm\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .sm\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .sm\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .sm\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .sm\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .sm\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .sm\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .sm\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .sm\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .sm\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .sm\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .sm\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .sm\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .sm\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .sm\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .sm\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .sm\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .sm\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .sm\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .sm\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .sm\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .sm\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .sm\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .sm\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .sm\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .sm\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .sm\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .sm\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .sm\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .sm\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .sm\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .sm\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .sm\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .sm\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .sm\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .sm\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .sm\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .sm\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .sm\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .sm\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .sm\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .sm\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .sm\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .sm\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .sm\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .sm\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .sm\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .sm\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .sm\:transition-none {
    transition-property: none
  }

  .sm\:transition-all {
    transition-property: all
  }

  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .sm\:transition-opacity {
    transition-property: opacity
  }

  .sm\:transition-shadow {
    transition-property: box-shadow
  }

  .sm\:transition-transform {
    transition-property: transform
  }

  .sm\:ease-linear {
    transition-timing-function: linear
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .sm\:duration-75 {
    transition-duration: 75ms
  }

  .sm\:duration-100 {
    transition-duration: 100ms
  }

  .sm\:duration-150 {
    transition-duration: 150ms
  }

  .sm\:duration-200 {
    transition-duration: 200ms
  }

  .sm\:duration-300 {
    transition-duration: 300ms
  }

  .sm\:duration-500 {
    transition-duration: 500ms
  }

  .sm\:duration-700 {
    transition-duration: 700ms
  }

  .sm\:duration-1000 {
    transition-duration: 1000ms
  }

  .sm\:delay-75 {
    transition-delay: 75ms
  }

  .sm\:delay-100 {
    transition-delay: 100ms
  }

  .sm\:delay-150 {
    transition-delay: 150ms
  }

  .sm\:delay-200 {
    transition-delay: 200ms
  }

  .sm\:delay-300 {
    transition-delay: 300ms
  }

  .sm\:delay-500 {
    transition-delay: 500ms
  }

  .sm\:delay-700 {
    transition-delay: 700ms
  }

  .sm\:delay-1000 {
    transition-delay: 1000ms
  }

  .sm\:animate-none {
    animation: none
  }

  .sm\:animate-spin {
    animation: spin 1s linear infinite
  }

  .sm\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .sm\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .sm\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media (min-width: 768px) {
  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .md\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent
  }

  .md\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor
  }

  .md\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .md\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .md\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .md\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .md\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .md\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .md\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .md\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .md\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .md\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .md\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .md\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .md\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .md\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .md\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .md\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .md\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .md\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .md\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .md\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .md\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .md\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .md\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .md\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .md\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .md\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .md\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .md\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .md\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .md\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .md\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .md\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .md\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .md\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .md\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .md\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .md\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .md\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .md\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .md\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .md\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .md\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .md\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .md\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .md\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .md\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .md\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .md\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .md\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .md\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .md\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .md\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .md\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .md\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .md\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .md\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .md\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .md\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .md\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .md\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .md\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .md\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .md\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .md\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .md\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .md\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .md\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .md\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .md\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .md\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .md\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .md\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .md\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .md\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .md\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .md\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .md\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .md\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .md\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .md\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .md\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .md\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .md\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .md\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .md\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .md\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .md\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .md\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .md\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .md\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .md\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .md\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .md\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .md\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .md\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .md\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .md\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .md\:bg-fixed {
    background-attachment: fixed
  }

  .md\:bg-local {
    background-attachment: local
  }

  .md\:bg-scroll {
    background-attachment: scroll
  }

  .md\:bg-clip-border {
    background-clip: border-box
  }

  .md\:bg-clip-padding {
    background-clip: padding-box
  }

  .md\:bg-clip-content {
    background-clip: content-box
  }

  .md\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .md\:bg-transparent {
    background-color: transparent
  }

  .md\:bg-current {
    background-color: currentColor
  }

  .md\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .md\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .md\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .md\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .md\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .md\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .md\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .md\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .md\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .md\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .md\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .md\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .md\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .md\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .md\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .md\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .md\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .md\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .md\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .md\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .md\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .md\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .md\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .md\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .md\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .md\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .md\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .md\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .md\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .md\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .md\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .md\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .md\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .md\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .md\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .md\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .md\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .md\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .md\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .md\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .md\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .md\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .md\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .md\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .md\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .md\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .md\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .md\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .md\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .md\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .md\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .md\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .md\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .md\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .md\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .md\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .md\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .md\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .md\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .md\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .md\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .md\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .md\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .md\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .md\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .md\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .md\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .md\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .md\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .md\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .md\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .md\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .md\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .md\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .md\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .md\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .md\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .md\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .md\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .md\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .md\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .md\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .md\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .md\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .md\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .md\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .md\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .md\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .md\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .md\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .md\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .md\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .md\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .md\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .md\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .md\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .md\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .md\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .md\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .md\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .md\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .md\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .md\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .md\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .md\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .md\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .md\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .md\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .md\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .md\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .md\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .md\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .md\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .md\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .md\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .md\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .md\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .md\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .md\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .md\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .md\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .md\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .md\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .md\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .md\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .md\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .md\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .md\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .md\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .md\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .md\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .md\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .md\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .md\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .md\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .md\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .md\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .md\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .md\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .md\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .md\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .md\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .md\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .md\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .md\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .md\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .md\:bg-none {
    background-image: none
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .md\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:to-transparent {
    --gradient-to-color: transparent
  }

  .md\:to-current {
    --gradient-to-color: currentColor
  }

  .md\:to-black {
    --gradient-to-color: #000
  }

  .md\:to-white {
    --gradient-to-color: #fff
  }

  .md\:to-gray-100 {
    --gradient-to-color: #f7fafc
  }

  .md\:to-gray-200 {
    --gradient-to-color: #edf2f7
  }

  .md\:to-gray-300 {
    --gradient-to-color: #e2e8f0
  }

  .md\:to-gray-400 {
    --gradient-to-color: #cbd5e0
  }

  .md\:to-gray-500 {
    --gradient-to-color: #a0aec0
  }

  .md\:to-gray-600 {
    --gradient-to-color: #718096
  }

  .md\:to-gray-700 {
    --gradient-to-color: #4a5568
  }

  .md\:to-gray-800 {
    --gradient-to-color: #2d3748
  }

  .md\:to-gray-900 {
    --gradient-to-color: #1a202c
  }

  .md\:to-red-100 {
    --gradient-to-color: #fff5f5
  }

  .md\:to-red-200 {
    --gradient-to-color: #fed7d7
  }

  .md\:to-red-300 {
    --gradient-to-color: #feb2b2
  }

  .md\:to-red-400 {
    --gradient-to-color: #fc8181
  }

  .md\:to-red-500 {
    --gradient-to-color: #f56565
  }

  .md\:to-red-600 {
    --gradient-to-color: #e53e3e
  }

  .md\:to-red-700 {
    --gradient-to-color: #c53030
  }

  .md\:to-red-800 {
    --gradient-to-color: #9b2c2c
  }

  .md\:to-red-900 {
    --gradient-to-color: #742a2a
  }

  .md\:to-orange-100 {
    --gradient-to-color: #fffaf0
  }

  .md\:to-orange-200 {
    --gradient-to-color: #feebc8
  }

  .md\:to-orange-300 {
    --gradient-to-color: #fbd38d
  }

  .md\:to-orange-400 {
    --gradient-to-color: #f6ad55
  }

  .md\:to-orange-500 {
    --gradient-to-color: #ed8936
  }

  .md\:to-orange-600 {
    --gradient-to-color: #dd6b20
  }

  .md\:to-orange-700 {
    --gradient-to-color: #c05621
  }

  .md\:to-orange-800 {
    --gradient-to-color: #9c4221
  }

  .md\:to-orange-900 {
    --gradient-to-color: #7b341e
  }

  .md\:to-yellow-100 {
    --gradient-to-color: #fffff0
  }

  .md\:to-yellow-200 {
    --gradient-to-color: #fefcbf
  }

  .md\:to-yellow-300 {
    --gradient-to-color: #faf089
  }

  .md\:to-yellow-400 {
    --gradient-to-color: #f6e05e
  }

  .md\:to-yellow-500 {
    --gradient-to-color: #ecc94b
  }

  .md\:to-yellow-600 {
    --gradient-to-color: #d69e2e
  }

  .md\:to-yellow-700 {
    --gradient-to-color: #b7791f
  }

  .md\:to-yellow-800 {
    --gradient-to-color: #975a16
  }

  .md\:to-yellow-900 {
    --gradient-to-color: #744210
  }

  .md\:to-green-100 {
    --gradient-to-color: #f0fff4
  }

  .md\:to-green-200 {
    --gradient-to-color: #c6f6d5
  }

  .md\:to-green-300 {
    --gradient-to-color: #9ae6b4
  }

  .md\:to-green-400 {
    --gradient-to-color: #68d391
  }

  .md\:to-green-500 {
    --gradient-to-color: #48bb78
  }

  .md\:to-green-600 {
    --gradient-to-color: #38a169
  }

  .md\:to-green-700 {
    --gradient-to-color: #2f855a
  }

  .md\:to-green-800 {
    --gradient-to-color: #276749
  }

  .md\:to-green-900 {
    --gradient-to-color: #22543d
  }

  .md\:to-teal-100 {
    --gradient-to-color: #e6fffa
  }

  .md\:to-teal-200 {
    --gradient-to-color: #b2f5ea
  }

  .md\:to-teal-300 {
    --gradient-to-color: #81e6d9
  }

  .md\:to-teal-400 {
    --gradient-to-color: #4fd1c5
  }

  .md\:to-teal-500 {
    --gradient-to-color: #38b2ac
  }

  .md\:to-teal-600 {
    --gradient-to-color: #319795
  }

  .md\:to-teal-700 {
    --gradient-to-color: #2c7a7b
  }

  .md\:to-teal-800 {
    --gradient-to-color: #285e61
  }

  .md\:to-teal-900 {
    --gradient-to-color: #234e52
  }

  .md\:to-blue-100 {
    --gradient-to-color: #ebf8ff
  }

  .md\:to-blue-200 {
    --gradient-to-color: #bee3f8
  }

  .md\:to-blue-300 {
    --gradient-to-color: #90cdf4
  }

  .md\:to-blue-400 {
    --gradient-to-color: #63b3ed
  }

  .md\:to-blue-500 {
    --gradient-to-color: #4299e1
  }

  .md\:to-blue-600 {
    --gradient-to-color: #3182ce
  }

  .md\:to-blue-700 {
    --gradient-to-color: #2b6cb0
  }

  .md\:to-blue-800 {
    --gradient-to-color: #2c5282
  }

  .md\:to-blue-900 {
    --gradient-to-color: #2a4365
  }

  .md\:to-indigo-100 {
    --gradient-to-color: #ebf4ff
  }

  .md\:to-indigo-200 {
    --gradient-to-color: #c3dafe
  }

  .md\:to-indigo-300 {
    --gradient-to-color: #a3bffa
  }

  .md\:to-indigo-400 {
    --gradient-to-color: #7f9cf5
  }

  .md\:to-indigo-500 {
    --gradient-to-color: #667eea
  }

  .md\:to-indigo-600 {
    --gradient-to-color: #5a67d8
  }

  .md\:to-indigo-700 {
    --gradient-to-color: #4c51bf
  }

  .md\:to-indigo-800 {
    --gradient-to-color: #434190
  }

  .md\:to-indigo-900 {
    --gradient-to-color: #3c366b
  }

  .md\:to-purple-100 {
    --gradient-to-color: #faf5ff
  }

  .md\:to-purple-200 {
    --gradient-to-color: #e9d8fd
  }

  .md\:to-purple-300 {
    --gradient-to-color: #d6bcfa
  }

  .md\:to-purple-400 {
    --gradient-to-color: #b794f4
  }

  .md\:to-purple-500 {
    --gradient-to-color: #9f7aea
  }

  .md\:to-purple-600 {
    --gradient-to-color: #805ad5
  }

  .md\:to-purple-700 {
    --gradient-to-color: #6b46c1
  }

  .md\:to-purple-800 {
    --gradient-to-color: #553c9a
  }

  .md\:to-purple-900 {
    --gradient-to-color: #44337a
  }

  .md\:to-pink-100 {
    --gradient-to-color: #fff5f7
  }

  .md\:to-pink-200 {
    --gradient-to-color: #fed7e2
  }

  .md\:to-pink-300 {
    --gradient-to-color: #fbb6ce
  }

  .md\:to-pink-400 {
    --gradient-to-color: #f687b3
  }

  .md\:to-pink-500 {
    --gradient-to-color: #ed64a6
  }

  .md\:to-pink-600 {
    --gradient-to-color: #d53f8c
  }

  .md\:to-pink-700 {
    --gradient-to-color: #b83280
  }

  .md\:to-pink-800 {
    --gradient-to-color: #97266d
  }

  .md\:to-pink-900 {
    --gradient-to-color: #702459
  }

  .md\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:hover\:from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:hover\:via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:hover\:to-transparent:hover {
    --gradient-to-color: transparent
  }

  .md\:hover\:to-current:hover {
    --gradient-to-color: currentColor
  }

  .md\:hover\:to-black:hover {
    --gradient-to-color: #000
  }

  .md\:hover\:to-white:hover {
    --gradient-to-color: #fff
  }

  .md\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc
  }

  .md\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7
  }

  .md\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0
  }

  .md\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0
  }

  .md\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0
  }

  .md\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096
  }

  .md\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568
  }

  .md\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748
  }

  .md\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c
  }

  .md\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5
  }

  .md\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7
  }

  .md\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2
  }

  .md\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181
  }

  .md\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565
  }

  .md\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e
  }

  .md\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030
  }

  .md\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c
  }

  .md\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a
  }

  .md\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0
  }

  .md\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8
  }

  .md\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d
  }

  .md\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55
  }

  .md\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936
  }

  .md\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20
  }

  .md\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621
  }

  .md\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221
  }

  .md\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e
  }

  .md\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0
  }

  .md\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf
  }

  .md\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089
  }

  .md\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e
  }

  .md\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b
  }

  .md\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e
  }

  .md\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f
  }

  .md\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16
  }

  .md\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210
  }

  .md\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4
  }

  .md\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5
  }

  .md\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4
  }

  .md\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391
  }

  .md\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78
  }

  .md\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169
  }

  .md\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a
  }

  .md\:hover\:to-green-800:hover {
    --gradient-to-color: #276749
  }

  .md\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d
  }

  .md\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa
  }

  .md\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea
  }

  .md\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9
  }

  .md\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5
  }

  .md\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac
  }

  .md\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795
  }

  .md\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b
  }

  .md\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61
  }

  .md\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52
  }

  .md\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff
  }

  .md\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8
  }

  .md\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4
  }

  .md\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed
  }

  .md\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1
  }

  .md\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce
  }

  .md\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0
  }

  .md\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282
  }

  .md\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365
  }

  .md\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff
  }

  .md\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe
  }

  .md\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa
  }

  .md\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5
  }

  .md\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea
  }

  .md\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8
  }

  .md\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf
  }

  .md\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190
  }

  .md\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b
  }

  .md\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff
  }

  .md\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd
  }

  .md\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa
  }

  .md\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4
  }

  .md\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea
  }

  .md\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5
  }

  .md\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1
  }

  .md\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a
  }

  .md\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a
  }

  .md\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7
  }

  .md\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2
  }

  .md\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce
  }

  .md\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3
  }

  .md\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6
  }

  .md\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c
  }

  .md\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280
  }

  .md\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d
  }

  .md\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459
  }

  .md\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:focus\:from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:focus\:via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:focus\:to-transparent:focus {
    --gradient-to-color: transparent
  }

  .md\:focus\:to-current:focus {
    --gradient-to-color: currentColor
  }

  .md\:focus\:to-black:focus {
    --gradient-to-color: #000
  }

  .md\:focus\:to-white:focus {
    --gradient-to-color: #fff
  }

  .md\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc
  }

  .md\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7
  }

  .md\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0
  }

  .md\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0
  }

  .md\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0
  }

  .md\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096
  }

  .md\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568
  }

  .md\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748
  }

  .md\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c
  }

  .md\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5
  }

  .md\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7
  }

  .md\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2
  }

  .md\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181
  }

  .md\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565
  }

  .md\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e
  }

  .md\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030
  }

  .md\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c
  }

  .md\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a
  }

  .md\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0
  }

  .md\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8
  }

  .md\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d
  }

  .md\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55
  }

  .md\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936
  }

  .md\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20
  }

  .md\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621
  }

  .md\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221
  }

  .md\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e
  }

  .md\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0
  }

  .md\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf
  }

  .md\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089
  }

  .md\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e
  }

  .md\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b
  }

  .md\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e
  }

  .md\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f
  }

  .md\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16
  }

  .md\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210
  }

  .md\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4
  }

  .md\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5
  }

  .md\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4
  }

  .md\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391
  }

  .md\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78
  }

  .md\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169
  }

  .md\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a
  }

  .md\:focus\:to-green-800:focus {
    --gradient-to-color: #276749
  }

  .md\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d
  }

  .md\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa
  }

  .md\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea
  }

  .md\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9
  }

  .md\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5
  }

  .md\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac
  }

  .md\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795
  }

  .md\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b
  }

  .md\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61
  }

  .md\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52
  }

  .md\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff
  }

  .md\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8
  }

  .md\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4
  }

  .md\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed
  }

  .md\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1
  }

  .md\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce
  }

  .md\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0
  }

  .md\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282
  }

  .md\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365
  }

  .md\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff
  }

  .md\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe
  }

  .md\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa
  }

  .md\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5
  }

  .md\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea
  }

  .md\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8
  }

  .md\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf
  }

  .md\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190
  }

  .md\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b
  }

  .md\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff
  }

  .md\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd
  }

  .md\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa
  }

  .md\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4
  }

  .md\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea
  }

  .md\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5
  }

  .md\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1
  }

  .md\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a
  }

  .md\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a
  }

  .md\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7
  }

  .md\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2
  }

  .md\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce
  }

  .md\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3
  }

  .md\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6
  }

  .md\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c
  }

  .md\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280
  }

  .md\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d
  }

  .md\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459
  }

  .md\:bg-opacity-0 {
    --bg-opacity: 0
  }

  .md\:bg-opacity-25 {
    --bg-opacity: 0.25
  }

  .md\:bg-opacity-50 {
    --bg-opacity: 0.5
  }

  .md\:bg-opacity-75 {
    --bg-opacity: 0.75
  }

  .md\:bg-opacity-100 {
    --bg-opacity: 1
  }

  .md\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0
  }

  .md\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25
  }

  .md\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5
  }

  .md\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75
  }

  .md\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1
  }

  .md\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0
  }

  .md\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25
  }

  .md\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5
  }

  .md\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75
  }

  .md\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1
  }

  .md\:bg-bottom {
    background-position: bottom
  }

  .md\:bg-center {
    background-position: center
  }

  .md\:bg-left {
    background-position: left
  }

  .md\:bg-left-bottom {
    background-position: left bottom
  }

  .md\:bg-left-top {
    background-position: left top
  }

  .md\:bg-right {
    background-position: right
  }

  .md\:bg-right-bottom {
    background-position: right bottom
  }

  .md\:bg-right-top {
    background-position: right top
  }

  .md\:bg-top {
    background-position: top
  }

  .md\:bg-repeat {
    background-repeat: repeat
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .md\:bg-repeat-round {
    background-repeat: round
  }

  .md\:bg-repeat-space {
    background-repeat: space
  }

  .md\:bg-auto {
    background-size: auto
  }

  .md\:bg-cover {
    background-size: cover
  }

  .md\:bg-contain {
    background-size: contain
  }

  .md\:border-collapse {
    border-collapse: collapse
  }

  .md\:border-separate {
    border-collapse: separate
  }

  .md\:border-transparent {
    border-color: transparent
  }

  .md\:border-current {
    border-color: currentColor
  }

  .md\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .md\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .md\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .md\:border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .md\:border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .md\:border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .md\:border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .md\:border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .md\:border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .md\:border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .md\:border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .md\:border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .md\:border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .md\:border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .md\:border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .md\:border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .md\:border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .md\:border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .md\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .md\:border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .md\:border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .md\:border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .md\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .md\:border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .md\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .md\:border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .md\:border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .md\:border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .md\:border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .md\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .md\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .md\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .md\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .md\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .md\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .md\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .md\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .md\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .md\:border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .md\:border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .md\:border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .md\:border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .md\:border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .md\:border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .md\:border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .md\:border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .md\:border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .md\:border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .md\:border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .md\:border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .md\:border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .md\:border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .md\:border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .md\:border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .md\:border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .md\:border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .md\:border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .md\:border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .md\:border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .md\:border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .md\:border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .md\:border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .md\:border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .md\:border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .md\:border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .md\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .md\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .md\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .md\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .md\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .md\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .md\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .md\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .md\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .md\:border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .md\:border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .md\:border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .md\:border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .md\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .md\:border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .md\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .md\:border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .md\:border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .md\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .md\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .md\:border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .md\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .md\:border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .md\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .md\:border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .md\:border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .md\:border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .md\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .md\:hover\:border-current:hover {
    border-color: currentColor
  }

  .md\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .md\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .md\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .md\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .md\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .md\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .md\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .md\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .md\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .md\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .md\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .md\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .md\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .md\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .md\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .md\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .md\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .md\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .md\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .md\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .md\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .md\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .md\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .md\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .md\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .md\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .md\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .md\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .md\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .md\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .md\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .md\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .md\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .md\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .md\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .md\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .md\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .md\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .md\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .md\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .md\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .md\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .md\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .md\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .md\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .md\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .md\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .md\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .md\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .md\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .md\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .md\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .md\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .md\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .md\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .md\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .md\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .md\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .md\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .md\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .md\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .md\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .md\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .md\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .md\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .md\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .md\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .md\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .md\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .md\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .md\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .md\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .md\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .md\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .md\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .md\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .md\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .md\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .md\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .md\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .md\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .md\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .md\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .md\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .md\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .md\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .md\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .md\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .md\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .md\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .md\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .md\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .md\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .md\:focus\:border-current:focus {
    border-color: currentColor
  }

  .md\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .md\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .md\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .md\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .md\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .md\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .md\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .md\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .md\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .md\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .md\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .md\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .md\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .md\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .md\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .md\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .md\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .md\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .md\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .md\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .md\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .md\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .md\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .md\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .md\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .md\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .md\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .md\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .md\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .md\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .md\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .md\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .md\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .md\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .md\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .md\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .md\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .md\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .md\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .md\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .md\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .md\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .md\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .md\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .md\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .md\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .md\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .md\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .md\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .md\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .md\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .md\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .md\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .md\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .md\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .md\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .md\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .md\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .md\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .md\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .md\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .md\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .md\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .md\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .md\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .md\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .md\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .md\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .md\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .md\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .md\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .md\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .md\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .md\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .md\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .md\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .md\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .md\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .md\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .md\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .md\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .md\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .md\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .md\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .md\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .md\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .md\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .md\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .md\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .md\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .md\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .md\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .md\:border-opacity-0 {
    --border-opacity: 0
  }

  .md\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .md\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .md\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .md\:border-opacity-100 {
    --border-opacity: 1
  }

  .md\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .md\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .md\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .md\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .md\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .md\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .md\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .md\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .md\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .md\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .md\:rounded-none {
    border-radius: 0
  }

  .md\:rounded-sm {
    border-radius: 0.125rem
  }

  .md\:rounded {
    border-radius: 0.25rem
  }

  .md\:rounded-md {
    border-radius: 0.375rem
  }

  .md\:rounded-lg {
    border-radius: 0.5rem
  }

  .md\:rounded-xl {
    border-radius: 0.75rem
  }

  .md\:rounded-2xl {
    border-radius: 1rem
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem
  }

  .md\:rounded-full {
    border-radius: 9999px
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .md\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .md\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .md\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .md\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .md\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .md\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .md\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .md\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .md\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .md\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .md\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .md\:border-solid {
    border-style: solid
  }

  .md\:border-dashed {
    border-style: dashed
  }

  .md\:border-dotted {
    border-style: dotted
  }

  .md\:border-double {
    border-style: double
  }

  .md\:border-none {
    border-style: none
  }

  .md\:border-0 {
    border-width: 0
  }

  .md\:border-2 {
    border-width: 2px
  }

  .md\:border-4 {
    border-width: 4px
  }

  .md\:border-8 {
    border-width: 8px
  }

  .md\:border {
    border-width: 1px
  }

  .md\:border-t-0 {
    border-top-width: 0
  }

  .md\:border-r-0 {
    border-right-width: 0
  }

  .md\:border-b-0 {
    border-bottom-width: 0
  }

  .md\:border-l-0 {
    border-left-width: 0
  }

  .md\:border-t-2 {
    border-top-width: 2px
  }

  .md\:border-r-2 {
    border-right-width: 2px
  }

  .md\:border-b-2 {
    border-bottom-width: 2px
  }

  .md\:border-l-2 {
    border-left-width: 2px
  }

  .md\:border-t-4 {
    border-top-width: 4px
  }

  .md\:border-r-4 {
    border-right-width: 4px
  }

  .md\:border-b-4 {
    border-bottom-width: 4px
  }

  .md\:border-l-4 {
    border-left-width: 4px
  }

  .md\:border-t-8 {
    border-top-width: 8px
  }

  .md\:border-r-8 {
    border-right-width: 8px
  }

  .md\:border-b-8 {
    border-bottom-width: 8px
  }

  .md\:border-l-8 {
    border-left-width: 8px
  }

  .md\:border-t {
    border-top-width: 1px
  }

  .md\:border-r {
    border-right-width: 1px
  }

  .md\:border-b {
    border-bottom-width: 1px
  }

  .md\:border-l {
    border-left-width: 1px
  }

  .md\:box-border {
    box-sizing: border-box
  }

  .md\:box-content {
    box-sizing: content-box
  }

  .md\:cursor-auto {
    cursor: auto
  }

  .md\:cursor-default {
    cursor: default
  }

  .md\:cursor-pointer {
    cursor: pointer
  }

  .md\:cursor-wait {
    cursor: wait
  }

  .md\:cursor-text {
    cursor: text
  }

  .md\:cursor-move {
    cursor: move
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed
  }

  .md\:block {
    display: block
  }

  .md\:inline-block {
    display: inline-block
  }

  .md\:inline {
    display: inline
  }

  .md\:flex {
    display: flex
  }

  .md\:inline-flex {
    display: inline-flex
  }

  .md\:table {
    display: table
  }

  .md\:table-caption {
    display: table-caption
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:table-column {
    display: table-column
  }

  .md\:table-column-group {
    display: table-column-group
  }

  .md\:table-footer-group {
    display: table-footer-group
  }

  .md\:table-header-group {
    display: table-header-group
  }

  .md\:table-row-group {
    display: table-row-group
  }

  .md\:table-row {
    display: table-row
  }

  .md\:flow-root {
    display: flow-root
  }

  .md\:grid {
    display: grid
  }

  .md\:inline-grid {
    display: inline-grid
  }

  .md\:contents {
    display: contents
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .md\:place-items-auto {
    place-items: auto
  }

  .md\:place-items-start {
    place-items: start
  }

  .md\:place-items-end {
    place-items: end
  }

  .md\:place-items-center {
    place-items: center
  }

  .md\:place-items-stretch {
    place-items: stretch
  }

  .md\:place-content-center {
    place-content: center
  }

  .md\:place-content-start {
    place-content: start
  }

  .md\:place-content-end {
    place-content: end
  }

  .md\:place-content-between {
    place-content: space-between
  }

  .md\:place-content-around {
    place-content: space-around
  }

  .md\:place-content-evenly {
    place-content: space-evenly
  }

  .md\:place-content-stretch {
    place-content: stretch
  }

  .md\:place-self-auto {
    place-self: auto
  }

  .md\:place-self-start {
    place-self: start
  }

  .md\:place-self-end {
    place-self: end
  }

  .md\:place-self-center {
    place-self: center
  }

  .md\:place-self-stretch {
    place-self: stretch
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:items-end {
    align-items: flex-end
  }

  .md\:items-center {
    align-items: center
  }

  .md\:items-baseline {
    align-items: baseline
  }

  .md\:items-stretch {
    align-items: stretch
  }

  .md\:content-center {
    align-content: center
  }

  .md\:content-start {
    align-content: flex-start
  }

  .md\:content-end {
    align-content: flex-end
  }

  .md\:content-between {
    align-content: space-between
  }

  .md\:content-around {
    align-content: space-around
  }

  .md\:content-evenly {
    align-content: space-evenly
  }

  .md\:self-auto {
    align-self: auto
  }

  .md\:self-start {
    align-self: flex-start
  }

  .md\:self-end {
    align-self: flex-end
  }

  .md\:self-center {
    align-self: center
  }

  .md\:self-stretch {
    align-self: stretch
  }

  .md\:justify-items-auto {
    justify-items: auto
  }

  .md\:justify-items-start {
    justify-items: start
  }

  .md\:justify-items-end {
    justify-items: end
  }

  .md\:justify-items-center {
    justify-items: center
  }

  .md\:justify-items-stretch {
    justify-items: stretch
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:justify-center {
    justify-content: center
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:justify-around {
    justify-content: space-around
  }

  .md\:justify-evenly {
    justify-content: space-evenly
  }

  .md\:justify-self-auto {
    justify-self: auto
  }

  .md\:justify-self-start {
    justify-self: start
  }

  .md\:justify-self-end {
    justify-self: end
  }

  .md\:justify-self-center {
    justify-self: center
  }

  .md\:justify-self-stretch {
    justify-self: stretch
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-auto {
    flex: 1 1 auto
  }

  .md\:flex-initial {
    flex: 0 1 auto
  }

  .md\:flex-none {
    flex: none
  }

  .md\:flex-grow-0 {
    flex-grow: 0
  }

  .md\:flex-grow {
    flex-grow: 1
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:flex-shrink {
    flex-shrink: 1
  }

  .md\:order-1 {
    order: 1
  }

  .md\:order-2 {
    order: 2
  }

  .md\:order-3 {
    order: 3
  }

  .md\:order-4 {
    order: 4
  }

  .md\:order-5 {
    order: 5
  }

  .md\:order-6 {
    order: 6
  }

  .md\:order-7 {
    order: 7
  }

  .md\:order-8 {
    order: 8
  }

  .md\:order-9 {
    order: 9
  }

  .md\:order-10 {
    order: 10
  }

  .md\:order-11 {
    order: 11
  }

  .md\:order-12 {
    order: 12
  }

  .md\:order-first {
    order: -9999
  }

  .md\:order-last {
    order: 9999
  }

  .md\:order-none {
    order: 0
  }

  .md\:float-right {
    float: right
  }

  .md\:float-left {
    float: left
  }

  .md\:float-none {
    float: none
  }

  .md\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .md\:clear-left {
    clear: left
  }

  .md\:clear-right {
    clear: right
  }

  .md\:clear-both {
    clear: both
  }

  .md\:clear-none {
    clear: none
  }

  .md\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .md\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .md\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .md\:font-hairline {
    font-weight: 100
  }

  .md\:font-thin {
    font-weight: 200
  }

  .md\:font-light {
    font-weight: 300
  }

  .md\:font-normal {
    font-weight: 400
  }

  .md\:font-medium {
    font-weight: 500
  }

  .md\:font-semibold {
    font-weight: 600
  }

  .md\:font-bold {
    font-weight: 700
  }

  .md\:font-extrabold {
    font-weight: 800
  }

  .md\:font-black {
    font-weight: 900
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200
  }

  .md\:hover\:font-light:hover {
    font-weight: 300
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .md\:hover\:font-black:hover {
    font-weight: 900
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200
  }

  .md\:focus\:font-light:focus {
    font-weight: 300
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .md\:focus\:font-black:focus {
    font-weight: 900
  }

  .md\:h-0 {
    height: 0
  }

  .md\:h-1 {
    height: 0.25rem
  }

  .md\:h-2 {
    height: 0.5rem
  }

  .md\:h-3 {
    height: 0.75rem
  }

  .md\:h-4 {
    height: 1rem
  }

  .md\:h-5 {
    height: 1.25rem
  }

  .md\:h-6 {
    height: 1.5rem
  }

  .md\:h-8 {
    height: 2rem
  }

  .md\:h-10 {
    height: 2.5rem
  }

  .md\:h-12 {
    height: 3rem
  }

  .md\:h-16 {
    height: 4rem
  }

  .md\:h-20 {
    height: 5rem
  }

  .md\:h-24 {
    height: 6rem
  }

  .md\:h-32 {
    height: 8rem
  }

  .md\:h-40 {
    height: 10rem
  }

  .md\:h-48 {
    height: 12rem
  }

  .md\:h-56 {
    height: 14rem
  }

  .md\:h-64 {
    height: 16rem
  }

  .md\:h-auto {
    height: auto
  }

  .md\:h-px {
    height: 1px
  }

  .md\:h-full {
    height: 100%
  }

  .md\:h-screen {
    height: 100vh
  }

  .md\:text-xs {
    font-size: 0.75rem
  }

  .md\:text-sm {
    font-size: 0.875rem
  }

  .md\:text-base {
    font-size: 1rem
  }

  .md\:text-lg {
    font-size: 1.125rem
  }

  .md\:text-xl {
    font-size: 1.25rem
  }

  .md\:text-2xl {
    font-size: 1.5rem
  }

  .md\:text-3xl {
    font-size: 1.875rem
  }

  .md\:text-4xl {
    font-size: 2.25rem
  }

  .md\:text-5xl {
    font-size: 3rem
  }

  .md\:text-6xl {
    font-size: 4rem
  }

  .md\:leading-3 {
    line-height: .75rem
  }

  .md\:leading-4 {
    line-height: 1rem
  }

  .md\:leading-5 {
    line-height: 1.25rem
  }

  .md\:leading-6 {
    line-height: 1.5rem
  }

  .md\:leading-7 {
    line-height: 1.75rem
  }

  .md\:leading-8 {
    line-height: 2rem
  }

  .md\:leading-9 {
    line-height: 2.25rem
  }

  .md\:leading-10 {
    line-height: 2.5rem
  }

  .md\:leading-none {
    line-height: 1
  }

  .md\:leading-tight {
    line-height: 1.25
  }

  .md\:leading-snug {
    line-height: 1.375
  }

  .md\:leading-normal {
    line-height: 1.5
  }

  .md\:leading-relaxed {
    line-height: 1.625
  }

  .md\:leading-loose {
    line-height: 2
  }

  .md\:list-inside {
    list-style-position: inside
  }

  .md\:list-outside {
    list-style-position: outside
  }

  .md\:list-none {
    list-style-type: none
  }

  .md\:list-disc {
    list-style-type: disc
  }

  .md\:list-decimal {
    list-style-type: decimal
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:m-1 {
    margin: 0.25rem
  }

  .md\:m-2 {
    margin: 0.5rem
  }

  .md\:m-3 {
    margin: 0.75rem
  }

  .md\:m-4 {
    margin: 1rem
  }

  .md\:m-5 {
    margin: 1.25rem
  }

  .md\:m-6 {
    margin: 1.5rem
  }

  .md\:m-8 {
    margin: 2rem
  }

  .md\:m-10 {
    margin: 2.5rem
  }

  .md\:m-12 {
    margin: 3rem
  }

  .md\:m-16 {
    margin: 4rem
  }

  .md\:m-20 {
    margin: 5rem
  }

  .md\:m-24 {
    margin: 6rem
  }

  .md\:m-32 {
    margin: 8rem
  }

  .md\:m-40 {
    margin: 10rem
  }

  .md\:m-48 {
    margin: 12rem
  }

  .md\:m-56 {
    margin: 14rem
  }

  .md\:m-64 {
    margin: 16rem
  }

  .md\:m-auto {
    margin: auto
  }

  .md\:m-px {
    margin: 1px
  }

  .md\:-m-1 {
    margin: -0.25rem
  }

  .md\:-m-2 {
    margin: -0.5rem
  }

  .md\:-m-3 {
    margin: -0.75rem
  }

  .md\:-m-4 {
    margin: -1rem
  }

  .md\:-m-5 {
    margin: -1.25rem
  }

  .md\:-m-6 {
    margin: -1.5rem
  }

  .md\:-m-8 {
    margin: -2rem
  }

  .md\:-m-10 {
    margin: -2.5rem
  }

  .md\:-m-12 {
    margin: -3rem
  }

  .md\:-m-16 {
    margin: -4rem
  }

  .md\:-m-20 {
    margin: -5rem
  }

  .md\:-m-24 {
    margin: -6rem
  }

  .md\:-m-32 {
    margin: -8rem
  }

  .md\:-m-40 {
    margin: -10rem
  }

  .md\:-m-48 {
    margin: -12rem
  }

  .md\:-m-56 {
    margin: -14rem
  }

  .md\:-m-64 {
    margin: -16rem
  }

  .md\:-m-px {
    margin: -1px
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .md\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .md\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .md\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .md\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .md\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .md\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .md\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .md\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .md\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .md\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:ml-0 {
    margin-left: 0
  }

  .md\:mt-1 {
    margin-top: 0.25rem
  }

  .md\:mr-1 {
    margin-right: 0.25rem
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem
  }

  .md\:ml-1 {
    margin-left: 0.25rem
  }

  .md\:mt-2 {
    margin-top: 0.5rem
  }

  .md\:mr-2 {
    margin-right: 0.5rem
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem
  }

  .md\:ml-2 {
    margin-left: 0.5rem
  }

  .md\:mt-3 {
    margin-top: 0.75rem
  }

  .md\:mr-3 {
    margin-right: 0.75rem
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem
  }

  .md\:ml-3 {
    margin-left: 0.75rem
  }

  .md\:mt-4 {
    margin-top: 1rem
  }

  .md\:mr-4 {
    margin-right: 1rem
  }

  .md\:mb-4 {
    margin-bottom: 1rem
  }

  .md\:ml-4 {
    margin-left: 1rem
  }

  .md\:mt-5 {
    margin-top: 1.25rem
  }

  .md\:mr-5 {
    margin-right: 1.25rem
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem
  }

  .md\:ml-5 {
    margin-left: 1.25rem
  }

  .md\:mt-6 {
    margin-top: 1.5rem
  }

  .md\:mr-6 {
    margin-right: 1.5rem
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem
  }

  .md\:ml-6 {
    margin-left: 1.5rem
  }

  .md\:mt-8 {
    margin-top: 2rem
  }

  .md\:mr-8 {
    margin-right: 2rem
  }

  .md\:mb-8 {
    margin-bottom: 2rem
  }

  .md\:ml-8 {
    margin-left: 2rem
  }

  .md\:mt-10 {
    margin-top: 2.5rem
  }

  .md\:mr-10 {
    margin-right: 2.5rem
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem
  }

  .md\:ml-10 {
    margin-left: 2.5rem
  }

  .md\:mt-12 {
    margin-top: 3rem
  }

  .md\:mr-12 {
    margin-right: 3rem
  }

  .md\:mb-12 {
    margin-bottom: 3rem
  }

  .md\:ml-12 {
    margin-left: 3rem
  }

  .md\:mt-16 {
    margin-top: 4rem
  }

  .md\:mr-16 {
    margin-right: 4rem
  }

  .md\:mb-16 {
    margin-bottom: 4rem
  }

  .md\:ml-16 {
    margin-left: 4rem
  }

  .md\:mt-20 {
    margin-top: 5rem
  }

  .md\:mr-20 {
    margin-right: 5rem
  }

  .md\:mb-20 {
    margin-bottom: 5rem
  }

  .md\:ml-20 {
    margin-left: 5rem
  }

  .md\:mt-24 {
    margin-top: 6rem
  }

  .md\:mr-24 {
    margin-right: 6rem
  }

  .md\:mb-24 {
    margin-bottom: 6rem
  }

  .md\:ml-24 {
    margin-left: 6rem
  }

  .md\:mt-32 {
    margin-top: 8rem
  }

  .md\:mr-32 {
    margin-right: 8rem
  }

  .md\:mb-32 {
    margin-bottom: 8rem
  }

  .md\:ml-32 {
    margin-left: 8rem
  }

  .md\:mt-40 {
    margin-top: 10rem
  }

  .md\:mr-40 {
    margin-right: 10rem
  }

  .md\:mb-40 {
    margin-bottom: 10rem
  }

  .md\:ml-40 {
    margin-left: 10rem
  }

  .md\:mt-48 {
    margin-top: 12rem
  }

  .md\:mr-48 {
    margin-right: 12rem
  }

  .md\:mb-48 {
    margin-bottom: 12rem
  }

  .md\:ml-48 {
    margin-left: 12rem
  }

  .md\:mt-56 {
    margin-top: 14rem
  }

  .md\:mr-56 {
    margin-right: 14rem
  }

  .md\:mb-56 {
    margin-bottom: 14rem
  }

  .md\:ml-56 {
    margin-left: 14rem
  }

  .md\:mt-64 {
    margin-top: 16rem
  }

  .md\:mr-64 {
    margin-right: 16rem
  }

  .md\:mb-64 {
    margin-bottom: 16rem
  }

  .md\:ml-64 {
    margin-left: 16rem
  }

  .md\:mt-auto {
    margin-top: auto
  }

  .md\:mr-auto {
    margin-right: auto
  }

  .md\:mb-auto {
    margin-bottom: auto
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:mt-px {
    margin-top: 1px
  }

  .md\:mr-px {
    margin-right: 1px
  }

  .md\:mb-px {
    margin-bottom: 1px
  }

  .md\:ml-px {
    margin-left: 1px
  }

  .md\:-mt-1 {
    margin-top: -0.25rem
  }

  .md\:-mr-1 {
    margin-right: -0.25rem
  }

  .md\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .md\:-ml-1 {
    margin-left: -0.25rem
  }

  .md\:-mt-2 {
    margin-top: -0.5rem
  }

  .md\:-mr-2 {
    margin-right: -0.5rem
  }

  .md\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .md\:-ml-2 {
    margin-left: -0.5rem
  }

  .md\:-mt-3 {
    margin-top: -0.75rem
  }

  .md\:-mr-3 {
    margin-right: -0.75rem
  }

  .md\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .md\:-ml-3 {
    margin-left: -0.75rem
  }

  .md\:-mt-4 {
    margin-top: -1rem
  }

  .md\:-mr-4 {
    margin-right: -1rem
  }

  .md\:-mb-4 {
    margin-bottom: -1rem
  }

  .md\:-ml-4 {
    margin-left: -1rem
  }

  .md\:-mt-5 {
    margin-top: -1.25rem
  }

  .md\:-mr-5 {
    margin-right: -1.25rem
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .md\:-ml-5 {
    margin-left: -1.25rem
  }

  .md\:-mt-6 {
    margin-top: -1.5rem
  }

  .md\:-mr-6 {
    margin-right: -1.5rem
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .md\:-ml-6 {
    margin-left: -1.5rem
  }

  .md\:-mt-8 {
    margin-top: -2rem
  }

  .md\:-mr-8 {
    margin-right: -2rem
  }

  .md\:-mb-8 {
    margin-bottom: -2rem
  }

  .md\:-ml-8 {
    margin-left: -2rem
  }

  .md\:-mt-10 {
    margin-top: -2.5rem
  }

  .md\:-mr-10 {
    margin-right: -2.5rem
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .md\:-ml-10 {
    margin-left: -2.5rem
  }

  .md\:-mt-12 {
    margin-top: -3rem
  }

  .md\:-mr-12 {
    margin-right: -3rem
  }

  .md\:-mb-12 {
    margin-bottom: -3rem
  }

  .md\:-ml-12 {
    margin-left: -3rem
  }

  .md\:-mt-16 {
    margin-top: -4rem
  }

  .md\:-mr-16 {
    margin-right: -4rem
  }

  .md\:-mb-16 {
    margin-bottom: -4rem
  }

  .md\:-ml-16 {
    margin-left: -4rem
  }

  .md\:-mt-20 {
    margin-top: -5rem
  }

  .md\:-mr-20 {
    margin-right: -5rem
  }

  .md\:-mb-20 {
    margin-bottom: -5rem
  }

  .md\:-ml-20 {
    margin-left: -5rem
  }

  .md\:-mt-24 {
    margin-top: -6rem
  }

  .md\:-mr-24 {
    margin-right: -6rem
  }

  .md\:-mb-24 {
    margin-bottom: -6rem
  }

  .md\:-ml-24 {
    margin-left: -6rem
  }

  .md\:-mt-32 {
    margin-top: -8rem
  }

  .md\:-mr-32 {
    margin-right: -8rem
  }

  .md\:-mb-32 {
    margin-bottom: -8rem
  }

  .md\:-ml-32 {
    margin-left: -8rem
  }

  .md\:-mt-40 {
    margin-top: -10rem
  }

  .md\:-mr-40 {
    margin-right: -10rem
  }

  .md\:-mb-40 {
    margin-bottom: -10rem
  }

  .md\:-ml-40 {
    margin-left: -10rem
  }

  .md\:-mt-48 {
    margin-top: -12rem
  }

  .md\:-mr-48 {
    margin-right: -12rem
  }

  .md\:-mb-48 {
    margin-bottom: -12rem
  }

  .md\:-ml-48 {
    margin-left: -12rem
  }

  .md\:-mt-56 {
    margin-top: -14rem
  }

  .md\:-mr-56 {
    margin-right: -14rem
  }

  .md\:-mb-56 {
    margin-bottom: -14rem
  }

  .md\:-ml-56 {
    margin-left: -14rem
  }

  .md\:-mt-64 {
    margin-top: -16rem
  }

  .md\:-mr-64 {
    margin-right: -16rem
  }

  .md\:-mb-64 {
    margin-bottom: -16rem
  }

  .md\:-ml-64 {
    margin-left: -16rem
  }

  .md\:-mt-px {
    margin-top: -1px
  }

  .md\:-mr-px {
    margin-right: -1px
  }

  .md\:-mb-px {
    margin-bottom: -1px
  }

  .md\:-ml-px {
    margin-left: -1px
  }

  .md\:max-h-full {
    max-height: 100%
  }

  .md\:max-h-screen {
    max-height: 100vh
  }

  .md\:max-w-none {
    max-width: none
  }

  .md\:max-w-xs {
    max-width: 20rem
  }

  .md\:max-w-sm {
    max-width: 24rem
  }

  .md\:max-w-md {
    max-width: 28rem
  }

  .md\:max-w-lg {
    max-width: 32rem
  }

  .md\:max-w-xl {
    max-width: 36rem
  }

  .md\:max-w-2xl {
    max-width: 42rem
  }

  .md\:max-w-3xl {
    max-width: 48rem
  }

  .md\:max-w-4xl {
    max-width: 56rem
  }

  .md\:max-w-5xl {
    max-width: 64rem
  }

  .md\:max-w-6xl {
    max-width: 72rem
  }

  .md\:max-w-full {
    max-width: 100%
  }

  .md\:max-w-screen-sm {
    max-width: 640px
  }

  .md\:max-w-screen-md {
    max-width: 768px
  }

  .md\:max-w-screen-lg {
    max-width: 1024px
  }

  .md\:max-w-screen-xl {
    max-width: 1280px
  }

  .md\:min-h-0 {
    min-height: 0
  }

  .md\:min-h-full {
    min-height: 100%
  }

  .md\:min-h-screen {
    min-height: 100vh
  }

  .md\:min-w-0 {
    min-width: 0
  }

  .md\:min-w-full {
    min-width: 100%
  }

  .md\:object-contain {
    object-fit: contain
  }

  .md\:object-cover {
    object-fit: cover
  }

  .md\:object-fill {
    object-fit: fill
  }

  .md\:object-none {
    object-fit: none
  }

  .md\:object-scale-down {
    object-fit: scale-down
  }

  .md\:object-bottom {
    object-position: bottom
  }

  .md\:object-center {
    object-position: center
  }

  .md\:object-left {
    object-position: left
  }

  .md\:object-left-bottom {
    object-position: left bottom
  }

  .md\:object-left-top {
    object-position: left top
  }

  .md\:object-right {
    object-position: right
  }

  .md\:object-right-bottom {
    object-position: right bottom
  }

  .md\:object-right-top {
    object-position: right top
  }

  .md\:object-top {
    object-position: top
  }

  .md\:opacity-0 {
    opacity: 0
  }

  .md\:opacity-25 {
    opacity: 0.25
  }

  .md\:opacity-50 {
    opacity: 0.5
  }

  .md\:opacity-75 {
    opacity: 0.75
  }

  .md\:opacity-100 {
    opacity: 1
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1
  }

  .md\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:overflow-auto {
    overflow: auto
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:overflow-visible {
    overflow: visible
  }

  .md\:overflow-scroll {
    overflow: scroll
  }

  .md\:overflow-x-auto {
    overflow-x: auto
  }

  .md\:overflow-y-auto {
    overflow-y: auto
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden
  }

  .md\:overflow-x-visible {
    overflow-x: visible
  }

  .md\:overflow-y-visible {
    overflow-y: visible
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .md\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .md\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .md\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-1 {
    padding: 0.25rem
  }

  .md\:p-2 {
    padding: 0.5rem
  }

  .md\:p-3 {
    padding: 0.75rem
  }

  .md\:p-4 {
    padding: 1rem
  }

  .md\:p-5 {
    padding: 1.25rem
  }

  .md\:p-6 {
    padding: 1.5rem
  }

  .md\:p-8 {
    padding: 2rem
  }

  .md\:p-10 {
    padding: 2.5rem
  }

  .md\:p-12 {
    padding: 3rem
  }

  .md\:p-16 {
    padding: 4rem
  }

  .md\:p-20 {
    padding: 5rem
  }

  .md\:p-24 {
    padding: 6rem
  }

  .md\:p-32 {
    padding: 8rem
  }

  .md\:p-40 {
    padding: 10rem
  }

  .md\:p-48 {
    padding: 12rem
  }

  .md\:p-56 {
    padding: 14rem
  }

  .md\:p-64 {
    padding: 16rem
  }

  .md\:p-px {
    padding: 1px
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .md\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pr-0 {
    padding-right: 0
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pl-0 {
    padding-left: 0
  }

  .md\:pt-1 {
    padding-top: 0.25rem
  }

  .md\:pr-1 {
    padding-right: 0.25rem
  }

  .md\:pb-1 {
    padding-bottom: 0.25rem
  }

  .md\:pl-1 {
    padding-left: 0.25rem
  }

  .md\:pt-2 {
    padding-top: 0.5rem
  }

  .md\:pr-2 {
    padding-right: 0.5rem
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem
  }

  .md\:pl-2 {
    padding-left: 0.5rem
  }

  .md\:pt-3 {
    padding-top: 0.75rem
  }

  .md\:pr-3 {
    padding-right: 0.75rem
  }

  .md\:pb-3 {
    padding-bottom: 0.75rem
  }

  .md\:pl-3 {
    padding-left: 0.75rem
  }

  .md\:pt-4 {
    padding-top: 1rem
  }

  .md\:pr-4 {
    padding-right: 1rem
  }

  .md\:pb-4 {
    padding-bottom: 1rem
  }

  .md\:pl-4 {
    padding-left: 1rem
  }

  .md\:pt-5 {
    padding-top: 1.25rem
  }

  .md\:pr-5 {
    padding-right: 1.25rem
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem
  }

  .md\:pl-5 {
    padding-left: 1.25rem
  }

  .md\:pt-6 {
    padding-top: 1.5rem
  }

  .md\:pr-6 {
    padding-right: 1.5rem
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem
  }

  .md\:pl-6 {
    padding-left: 1.5rem
  }

  .md\:pt-8 {
    padding-top: 2rem
  }

  .md\:pr-8 {
    padding-right: 2rem
  }

  .md\:pb-8 {
    padding-bottom: 2rem
  }

  .md\:pl-8 {
    padding-left: 2rem
  }

  .md\:pt-10 {
    padding-top: 2.5rem
  }

  .md\:pr-10 {
    padding-right: 2.5rem
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem
  }

  .md\:pl-10 {
    padding-left: 2.5rem
  }

  .md\:pt-12 {
    padding-top: 3rem
  }

  .md\:pr-12 {
    padding-right: 3rem
  }

  .md\:pb-12 {
    padding-bottom: 3rem
  }

  .md\:pl-12 {
    padding-left: 3rem
  }

  .md\:pt-16 {
    padding-top: 4rem
  }

  .md\:pr-16 {
    padding-right: 4rem
  }

  .md\:pb-16 {
    padding-bottom: 4rem
  }

  .md\:pl-16 {
    padding-left: 4rem
  }

  .md\:pt-20 {
    padding-top: 5rem
  }

  .md\:pr-20 {
    padding-right: 5rem
  }

  .md\:pb-20 {
    padding-bottom: 5rem
  }

  .md\:pl-20 {
    padding-left: 5rem
  }

  .md\:pt-24 {
    padding-top: 6rem
  }

  .md\:pr-24 {
    padding-right: 6rem
  }

  .md\:pb-24 {
    padding-bottom: 6rem
  }

  .md\:pl-24 {
    padding-left: 6rem
  }

  .md\:pt-32 {
    padding-top: 8rem
  }

  .md\:pr-32 {
    padding-right: 8rem
  }

  .md\:pb-32 {
    padding-bottom: 8rem
  }

  .md\:pl-32 {
    padding-left: 8rem
  }

  .md\:pt-40 {
    padding-top: 10rem
  }

  .md\:pr-40 {
    padding-right: 10rem
  }

  .md\:pb-40 {
    padding-bottom: 10rem
  }

  .md\:pl-40 {
    padding-left: 10rem
  }

  .md\:pt-48 {
    padding-top: 12rem
  }

  .md\:pr-48 {
    padding-right: 12rem
  }

  .md\:pb-48 {
    padding-bottom: 12rem
  }

  .md\:pl-48 {
    padding-left: 12rem
  }

  .md\:pt-56 {
    padding-top: 14rem
  }

  .md\:pr-56 {
    padding-right: 14rem
  }

  .md\:pb-56 {
    padding-bottom: 14rem
  }

  .md\:pl-56 {
    padding-left: 14rem
  }

  .md\:pt-64 {
    padding-top: 16rem
  }

  .md\:pr-64 {
    padding-right: 16rem
  }

  .md\:pb-64 {
    padding-bottom: 16rem
  }

  .md\:pl-64 {
    padding-left: 16rem
  }

  .md\:pt-px {
    padding-top: 1px
  }

  .md\:pr-px {
    padding-right: 1px
  }

  .md\:pb-px {
    padding-bottom: 1px
  }

  .md\:pl-px {
    padding-left: 1px
  }

  .md\:placeholder-transparent:-ms-input-placeholder {
    color: transparent
  }

  .md\:placeholder-transparent::-ms-input-placeholder {
    color: transparent
  }

  .md\:placeholder-transparent::placeholder {
    color: transparent
  }

  .md\:placeholder-current:-ms-input-placeholder {
    color: currentColor
  }

  .md\:placeholder-current::-ms-input-placeholder {
    color: currentColor
  }

  .md\:placeholder-current::placeholder {
    color: currentColor
  }

  .md\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:placeholder-red-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:placeholder-red-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:placeholder-red-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:placeholder-red-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:placeholder-red-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:placeholder-red-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:placeholder-red-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:placeholder-red-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:placeholder-red-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:placeholder-green-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-green-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:placeholder-green-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:placeholder-green-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:placeholder-green-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:placeholder-green-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:placeholder-green-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:placeholder-green-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:placeholder-green-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent
  }

  .md\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .md\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor
  }

  .md\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor
  }

  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .md\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .md\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .md\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .md\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .md\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .md\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .md\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .md\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .md\:pointer-events-none {
    pointer-events: none
  }

  .md\:pointer-events-auto {
    pointer-events: auto
  }

  .md\:static {
    position: static
  }

  .md\:fixed {
    position: fixed
  }

  .md\:absolute {
    position: absolute
  }

  .md\:relative {
    position: relative
  }

  .md\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .md\:inset-x-0 {
    right: 0;
    left: 0
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .md\:inset-x-auto {
    right: auto;
    left: auto
  }

  .md\:top-0 {
    top: 0
  }

  .md\:right-0 {
    right: 0
  }

  .md\:bottom-0 {
    bottom: 0
  }

  .md\:left-0 {
    left: 0
  }

  .md\:top-auto {
    top: auto
  }

  .md\:right-auto {
    right: auto
  }

  .md\:bottom-auto {
    bottom: auto
  }

  .md\:left-auto {
    left: auto
  }

  .md\:resize-none {
    resize: none
  }

  .md\:resize-y {
    resize: vertical
  }

  .md\:resize-x {
    resize: horizontal
  }

  .md\:resize {
    resize: both
  }

  .md\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .md\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .md\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:shadow-none {
    box-shadow: none
  }

  .md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .md\:fill-current {
    fill: currentColor
  }

  .md\:stroke-current {
    stroke: currentColor
  }

  .md\:stroke-0 {
    stroke-width: 0
  }

  .md\:stroke-1 {
    stroke-width: 1
  }

  .md\:stroke-2 {
    stroke-width: 2
  }

  .md\:table-auto {
    table-layout: auto
  }

  .md\:table-fixed {
    table-layout: fixed
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-center {
    text-align: center
  }

  .md\:text-right {
    text-align: right
  }

  .md\:text-justify {
    text-align: justify
  }

  .md\:text-transparent {
    color: transparent
  }

  .md\:text-current {
    color: currentColor
  }

  .md\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .md\:text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .md\:text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .md\:text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .md\:text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .md\:text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .md\:text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .md\:text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .md\:text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .md\:text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .md\:text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .md\:text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .md\:text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .md\:text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .md\:text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .md\:text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .md\:text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .md\:text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .md\:text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .md\:text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .md\:text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .md\:text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .md\:text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .md\:text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .md\:text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .md\:text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .md\:text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .md\:text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .md\:text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .md\:text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .md\:text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .md\:text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .md\:text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .md\:text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .md\:text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .md\:text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .md\:text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .md\:text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .md\:text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .md\:text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .md\:text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .md\:text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .md\:text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .md\:text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .md\:text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .md\:text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .md\:text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .md\:text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .md\:text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .md\:text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .md\:text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .md\:text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .md\:text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .md\:text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .md\:text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .md\:text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .md\:text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .md\:text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .md\:text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .md\:text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .md\:text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .md\:text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .md\:text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .md\:text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .md\:text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .md\:text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .md\:text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .md\:text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .md\:text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .md\:text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .md\:text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .md\:text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .md\:text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .md\:text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .md\:text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .md\:text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .md\:text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .md\:text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .md\:text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .md\:text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .md\:text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .md\:text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .md\:text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .md\:text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .md\:text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .md\:text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .md\:text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .md\:text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .md\:text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .md\:text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .md\:hover\:text-transparent:hover {
    color: transparent
  }

  .md\:hover\:text-current:hover {
    color: currentColor
  }

  .md\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .md\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .md\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .md\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .md\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .md\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .md\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .md\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .md\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .md\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .md\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .md\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .md\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .md\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .md\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .md\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .md\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .md\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .md\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .md\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .md\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .md\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .md\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .md\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .md\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .md\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .md\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .md\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .md\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .md\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .md\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .md\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .md\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .md\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .md\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .md\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .md\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .md\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .md\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .md\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .md\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .md\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .md\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .md\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .md\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .md\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .md\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .md\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .md\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .md\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .md\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .md\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .md\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .md\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .md\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .md\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .md\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .md\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .md\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .md\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .md\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .md\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .md\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .md\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .md\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .md\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .md\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .md\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .md\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .md\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .md\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .md\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .md\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .md\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .md\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .md\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .md\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .md\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .md\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .md\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .md\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .md\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .md\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .md\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .md\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .md\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .md\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .md\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .md\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .md\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .md\:focus\:text-transparent:focus {
    color: transparent
  }

  .md\:focus\:text-current:focus {
    color: currentColor
  }

  .md\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .md\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .md\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .md\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .md\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .md\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .md\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .md\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .md\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .md\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .md\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .md\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .md\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .md\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .md\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .md\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .md\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .md\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .md\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .md\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .md\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .md\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .md\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .md\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .md\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .md\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .md\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .md\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .md\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .md\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .md\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .md\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .md\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .md\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .md\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .md\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .md\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .md\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .md\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .md\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .md\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .md\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .md\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .md\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .md\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .md\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .md\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .md\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .md\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .md\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .md\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .md\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .md\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .md\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .md\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .md\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .md\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .md\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .md\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .md\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .md\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .md\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .md\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .md\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .md\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .md\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .md\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .md\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .md\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .md\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .md\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .md\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .md\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .md\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .md\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .md\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .md\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .md\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .md\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .md\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .md\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .md\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .md\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .md\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .md\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .md\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .md\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .md\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .md\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .md\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .md\:text-opacity-0 {
    --text-opacity: 0
  }

  .md\:text-opacity-25 {
    --text-opacity: 0.25
  }

  .md\:text-opacity-50 {
    --text-opacity: 0.5
  }

  .md\:text-opacity-75 {
    --text-opacity: 0.75
  }

  .md\:text-opacity-100 {
    --text-opacity: 1
  }

  .md\:hover\:text-opacity-0:hover {
    --text-opacity: 0
  }

  .md\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25
  }

  .md\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5
  }

  .md\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75
  }

  .md\:hover\:text-opacity-100:hover {
    --text-opacity: 1
  }

  .md\:focus\:text-opacity-0:focus {
    --text-opacity: 0
  }

  .md\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25
  }

  .md\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5
  }

  .md\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75
  }

  .md\:focus\:text-opacity-100:focus {
    --text-opacity: 1
  }

  .md\:italic {
    font-style: italic
  }

  .md\:not-italic {
    font-style: normal
  }

  .md\:uppercase {
    text-transform: uppercase
  }

  .md\:lowercase {
    text-transform: lowercase
  }

  .md\:capitalize {
    text-transform: capitalize
  }

  .md\:normal-case {
    text-transform: none
  }

  .md\:underline {
    text-decoration: underline
  }

  .md\:line-through {
    text-decoration: line-through
  }

  .md\:no-underline {
    text-decoration: none
  }

  .md\:hover\:underline:hover {
    text-decoration: underline
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none
  }

  .md\:focus\:underline:focus {
    text-decoration: underline
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .md\:normal-nums {
    font-variant-numeric: normal
  }

  .md\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .md\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .md\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .md\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .md\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .md\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .md\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .md\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em
  }

  .md\:tracking-normal {
    letter-spacing: 0
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em
  }

  .md\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .md\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .md\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .md\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .md\:align-baseline {
    vertical-align: baseline
  }

  .md\:align-top {
    vertical-align: top
  }

  .md\:align-middle {
    vertical-align: middle
  }

  .md\:align-bottom {
    vertical-align: bottom
  }

  .md\:align-text-top {
    vertical-align: text-top
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom
  }

  .md\:visible {
    visibility: visible
  }

  .md\:invisible {
    visibility: hidden
  }

  .md\:whitespace-normal {
    white-space: normal
  }

  .md\:whitespace-no-wrap {
    white-space: nowrap
  }

  .md\:whitespace-pre {
    white-space: pre
  }

  .md\:whitespace-pre-line {
    white-space: pre-line
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .md\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .md\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .md\:break-all {
    word-break: break-all
  }

  .md\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .md\:w-0 {
    width: 0
  }

  .md\:w-1 {
    width: 0.25rem
  }

  .md\:w-2 {
    width: 0.5rem
  }

  .md\:w-3 {
    width: 0.75rem
  }

  .md\:w-4 {
    width: 1rem
  }

  .md\:w-5 {
    width: 1.25rem
  }

  .md\:w-6 {
    width: 1.5rem
  }

  .md\:w-8 {
    width: 2rem
  }

  .md\:w-10 {
    width: 2.5rem
  }

  .md\:w-12 {
    width: 3rem
  }

  .md\:w-16 {
    width: 4rem
  }

  .md\:w-20 {
    width: 5rem
  }

  .md\:w-24 {
    width: 6rem
  }

  .md\:w-32 {
    width: 8rem
  }

  .md\:w-40 {
    width: 10rem
  }

  .md\:w-48 {
    width: 12rem
  }

  .md\:w-56 {
    width: 14rem
  }

  .md\:w-64 {
    width: 16rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-px {
    width: 1px
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-2\/4 {
    width: 50%
  }

  .md\:w-3\/4 {
    width: 75%
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-4\/5 {
    width: 80%
  }

  .md\:w-1\/6 {
    width: 16.666667%
  }

  .md\:w-2\/6 {
    width: 33.333333%
  }

  .md\:w-3\/6 {
    width: 50%
  }

  .md\:w-4\/6 {
    width: 66.666667%
  }

  .md\:w-5\/6 {
    width: 83.333333%
  }

  .md\:w-1\/12 {
    width: 8.333333%
  }

  .md\:w-2\/12 {
    width: 16.666667%
  }

  .md\:w-3\/12 {
    width: 25%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-6\/12 {
    width: 50%
  }

  .md\:w-7\/12 {
    width: 58.333333%
  }

  .md\:w-8\/12 {
    width: 66.666667%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-10\/12 {
    width: 83.333333%
  }

  .md\:w-11\/12 {
    width: 91.666667%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:w-screen {
    width: 100vw
  }

  .md\:z-0 {
    z-index: 0
  }

  .md\:z-10 {
    z-index: 10
  }

  .md\:z-20 {
    z-index: 20
  }

  .md\:z-30 {
    z-index: 30
  }

  .md\:z-40 {
    z-index: 40
  }

  .md\:z-50 {
    z-index: 50
  }

  .md\:z-auto {
    z-index: auto
  }

  .md\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .md\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .md\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .md\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .md\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .md\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .md\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .md\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .md\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .md\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .md\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .md\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .md\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .md\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .md\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .md\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .md\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .md\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .md\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .md\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .md\:col-gap-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .md\:col-gap-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .md\:col-gap-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .md\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .md\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .md\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .md\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .md\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .md\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .md\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .md\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .md\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .md\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .md\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .md\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .md\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .md\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .md\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .md\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .md\:gap-x-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .md\:gap-x-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .md\:gap-x-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .md\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .md\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .md\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .md\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .md\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .md\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .md\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .md\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .md\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .md\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .md\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .md\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .md\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .md\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .md\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .md\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .md\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .md\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .md\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .md\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .md\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .md\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .md\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .md\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .md\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .md\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .md\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .md\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .md\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .md\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .md\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .md\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .md\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .md\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .md\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .md\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .md\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .md\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .md\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .md\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .md\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .md\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .md\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .md\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .md\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .md\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .md\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .md\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .md\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .md\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .md\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .md\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .md\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .md\:grid-flow-row {
    grid-auto-flow: row
  }

  .md\:grid-flow-col {
    grid-auto-flow: column
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:grid-cols-none {
    grid-template-columns: none
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .md\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .md\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .md\:col-auto {
    grid-column: auto
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .md\:col-span-full {
    grid-column: 1 / -1
  }

  .md\:col-start-1 {
    grid-column-start: 1
  }

  .md\:col-start-2 {
    grid-column-start: 2
  }

  .md\:col-start-3 {
    grid-column-start: 3
  }

  .md\:col-start-4 {
    grid-column-start: 4
  }

  .md\:col-start-5 {
    grid-column-start: 5
  }

  .md\:col-start-6 {
    grid-column-start: 6
  }

  .md\:col-start-7 {
    grid-column-start: 7
  }

  .md\:col-start-8 {
    grid-column-start: 8
  }

  .md\:col-start-9 {
    grid-column-start: 9
  }

  .md\:col-start-10 {
    grid-column-start: 10
  }

  .md\:col-start-11 {
    grid-column-start: 11
  }

  .md\:col-start-12 {
    grid-column-start: 12
  }

  .md\:col-start-13 {
    grid-column-start: 13
  }

  .md\:col-start-auto {
    grid-column-start: auto
  }

  .md\:col-end-1 {
    grid-column-end: 1
  }

  .md\:col-end-2 {
    grid-column-end: 2
  }

  .md\:col-end-3 {
    grid-column-end: 3
  }

  .md\:col-end-4 {
    grid-column-end: 4
  }

  .md\:col-end-5 {
    grid-column-end: 5
  }

  .md\:col-end-6 {
    grid-column-end: 6
  }

  .md\:col-end-7 {
    grid-column-end: 7
  }

  .md\:col-end-8 {
    grid-column-end: 8
  }

  .md\:col-end-9 {
    grid-column-end: 9
  }

  .md\:col-end-10 {
    grid-column-end: 10
  }

  .md\:col-end-11 {
    grid-column-end: 11
  }

  .md\:col-end-12 {
    grid-column-end: 12
  }

  .md\:col-end-13 {
    grid-column-end: 13
  }

  .md\:col-end-auto {
    grid-column-end: auto
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-rows-none {
    grid-template-rows: none
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .md\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .md\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .md\:row-auto {
    grid-row: auto
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .md\:row-span-full {
    grid-row: 1 / -1
  }

  .md\:row-start-1 {
    grid-row-start: 1
  }

  .md\:row-start-2 {
    grid-row-start: 2
  }

  .md\:row-start-3 {
    grid-row-start: 3
  }

  .md\:row-start-4 {
    grid-row-start: 4
  }

  .md\:row-start-5 {
    grid-row-start: 5
  }

  .md\:row-start-6 {
    grid-row-start: 6
  }

  .md\:row-start-7 {
    grid-row-start: 7
  }

  .md\:row-start-auto {
    grid-row-start: auto
  }

  .md\:row-end-1 {
    grid-row-end: 1
  }

  .md\:row-end-2 {
    grid-row-end: 2
  }

  .md\:row-end-3 {
    grid-row-end: 3
  }

  .md\:row-end-4 {
    grid-row-end: 4
  }

  .md\:row-end-5 {
    grid-row-end: 5
  }

  .md\:row-end-6 {
    grid-row-end: 6
  }

  .md\:row-end-7 {
    grid-row-end: 7
  }

  .md\:row-end-auto {
    grid-row-end: auto
  }

  .md\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .md\:transform-none {
    transform: none
  }

  .md\:origin-center {
    transform-origin: center
  }

  .md\:origin-top {
    transform-origin: top
  }

  .md\:origin-top-right {
    transform-origin: top right
  }

  .md\:origin-right {
    transform-origin: right
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right
  }

  .md\:origin-bottom {
    transform-origin: bottom
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left
  }

  .md\:origin-left {
    transform-origin: left
  }

  .md\:origin-top-left {
    transform-origin: top left
  }

  .md\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:scale-x-0 {
    --transform-scale-x: 0
  }

  .md\:scale-x-50 {
    --transform-scale-x: .5
  }

  .md\:scale-x-75 {
    --transform-scale-x: .75
  }

  .md\:scale-x-90 {
    --transform-scale-x: .9
  }

  .md\:scale-x-95 {
    --transform-scale-x: .95
  }

  .md\:scale-x-100 {
    --transform-scale-x: 1
  }

  .md\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .md\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .md\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .md\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .md\:scale-y-0 {
    --transform-scale-y: 0
  }

  .md\:scale-y-50 {
    --transform-scale-y: .5
  }

  .md\:scale-y-75 {
    --transform-scale-y: .75
  }

  .md\:scale-y-90 {
    --transform-scale-y: .9
  }

  .md\:scale-y-95 {
    --transform-scale-y: .95
  }

  .md\:scale-y-100 {
    --transform-scale-y: 1
  }

  .md\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .md\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .md\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .md\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .md\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .md\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .md\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .md\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .md\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .md\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .md\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .md\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .md\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .md\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .md\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .md\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .md\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .md\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .md\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .md\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .md\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .md\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .md\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .md\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .md\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .md\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .md\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .md\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .md\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .md\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .md\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .md\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .md\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .md\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .md\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .md\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .md\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .md\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .md\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .md\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .md\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .md\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .md\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .md\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .md\:rotate-0 {
    --transform-rotate: 0
  }

  .md\:rotate-1 {
    --transform-rotate: 1deg
  }

  .md\:rotate-2 {
    --transform-rotate: 2deg
  }

  .md\:rotate-3 {
    --transform-rotate: 3deg
  }

  .md\:rotate-6 {
    --transform-rotate: 6deg
  }

  .md\:rotate-12 {
    --transform-rotate: 12deg
  }

  .md\:rotate-45 {
    --transform-rotate: 45deg
  }

  .md\:rotate-90 {
    --transform-rotate: 90deg
  }

  .md\:rotate-180 {
    --transform-rotate: 180deg
  }

  .md\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .md\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .md\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .md\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .md\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .md\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .md\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .md\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .md\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .md\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .md\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .md\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .md\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .md\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .md\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .md\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .md\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .md\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .md\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .md\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .md\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .md\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .md\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .md\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .md\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .md\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .md\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .md\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .md\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .md\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .md\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .md\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .md\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .md\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .md\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .md\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .md\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .md\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .md\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .md\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .md\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .md\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .md\:translate-x-0 {
    --transform-translate-x: 0
  }

  .md\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .md\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .md\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .md\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .md\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .md\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .md\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .md\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .md\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .md\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .md\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .md\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .md\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .md\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .md\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .md\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .md\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .md\:translate-x-px {
    --transform-translate-x: 1px
  }

  .md\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .md\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .md\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .md\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .md\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .md\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .md\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .md\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .md\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .md\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .md\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .md\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .md\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .md\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .md\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .md\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .md\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .md\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .md\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .md\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .md\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .md\:translate-x-full {
    --transform-translate-x: 100%
  }

  .md\:translate-y-0 {
    --transform-translate-y: 0
  }

  .md\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .md\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .md\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .md\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .md\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .md\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .md\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .md\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .md\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .md\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .md\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .md\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .md\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .md\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .md\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .md\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .md\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .md\:translate-y-px {
    --transform-translate-y: 1px
  }

  .md\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .md\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .md\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .md\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .md\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .md\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .md\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .md\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .md\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .md\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .md\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .md\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .md\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .md\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .md\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .md\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .md\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .md\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .md\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .md\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .md\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .md\:translate-y-full {
    --transform-translate-y: 100%
  }

  .md\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .md\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .md\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .md\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .md\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .md\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .md\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .md\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .md\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .md\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .md\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .md\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .md\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .md\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .md\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .md\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .md\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .md\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .md\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .md\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .md\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .md\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .md\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .md\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .md\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .md\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .md\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .md\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .md\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .md\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .md\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .md\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .md\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .md\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .md\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .md\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .md\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .md\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .md\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .md\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .md\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .md\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .md\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .md\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .md\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .md\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .md\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .md\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .md\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .md\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .md\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .md\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .md\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .md\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .md\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .md\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .md\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .md\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .md\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .md\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .md\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .md\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .md\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .md\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .md\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .md\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .md\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .md\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .md\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .md\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .md\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .md\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .md\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .md\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .md\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .md\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .md\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .md\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .md\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .md\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .md\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .md\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .md\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .md\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .md\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .md\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .md\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .md\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .md\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .md\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .md\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .md\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .md\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .md\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .md\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .md\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .md\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .md\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .md\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .md\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .md\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .md\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .md\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .md\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .md\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .md\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .md\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .md\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .md\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .md\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .md\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .md\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .md\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .md\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .md\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .md\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .md\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .md\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .md\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .md\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .md\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .md\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .md\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .md\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .md\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .md\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .md\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .md\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .md\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .md\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .md\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .md\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .md\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .md\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .md\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .md\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .md\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .md\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .md\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .md\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .md\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .md\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .md\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .md\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .md\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .md\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .md\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .md\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .md\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .md\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .md\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .md\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .md\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .md\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .md\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .md\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .md\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .md\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .md\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .md\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .md\:skew-x-0 {
    --transform-skew-x: 0
  }

  .md\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .md\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .md\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .md\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .md\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .md\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .md\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .md\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .md\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .md\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .md\:skew-y-0 {
    --transform-skew-y: 0
  }

  .md\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .md\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .md\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .md\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .md\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .md\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .md\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .md\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .md\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .md\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .md\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .md\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .md\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .md\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .md\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .md\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .md\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .md\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .md\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .md\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .md\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .md\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .md\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .md\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .md\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .md\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .md\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .md\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .md\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .md\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .md\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .md\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .md\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .md\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .md\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .md\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .md\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .md\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .md\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .md\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .md\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .md\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .md\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .md\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .md\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .md\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .md\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .md\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .md\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .md\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .md\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .md\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .md\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .md\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .md\:transition-none {
    transition-property: none
  }

  .md\:transition-all {
    transition-property: all
  }

  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .md\:transition-opacity {
    transition-property: opacity
  }

  .md\:transition-shadow {
    transition-property: box-shadow
  }

  .md\:transition-transform {
    transition-property: transform
  }

  .md\:ease-linear {
    transition-timing-function: linear
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .md\:duration-75 {
    transition-duration: 75ms
  }

  .md\:duration-100 {
    transition-duration: 100ms
  }

  .md\:duration-150 {
    transition-duration: 150ms
  }

  .md\:duration-200 {
    transition-duration: 200ms
  }

  .md\:duration-300 {
    transition-duration: 300ms
  }

  .md\:duration-500 {
    transition-duration: 500ms
  }

  .md\:duration-700 {
    transition-duration: 700ms
  }

  .md\:duration-1000 {
    transition-duration: 1000ms
  }

  .md\:delay-75 {
    transition-delay: 75ms
  }

  .md\:delay-100 {
    transition-delay: 100ms
  }

  .md\:delay-150 {
    transition-delay: 150ms
  }

  .md\:delay-200 {
    transition-delay: 200ms
  }

  .md\:delay-300 {
    transition-delay: 300ms
  }

  .md\:delay-500 {
    transition-delay: 500ms
  }

  .md\:delay-700 {
    transition-delay: 700ms
  }

  .md\:delay-1000 {
    transition-delay: 1000ms
  }

  .md\:animate-none {
    animation: none
  }

  .md\:animate-spin {
    animation: spin 1s linear infinite
  }

  .md\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .md\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .md\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media (min-width: 1024px) {
  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .lg\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent
  }

  .lg\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor
  }

  .lg\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .lg\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .lg\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .lg\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .lg\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .lg\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .lg\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .lg\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .lg\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .lg\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .lg\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .lg\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .lg\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .lg\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .lg\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .lg\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .lg\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .lg\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .lg\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .lg\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .lg\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .lg\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .lg\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .lg\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .lg\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .lg\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .lg\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .lg\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .lg\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .lg\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .lg\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .lg\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .lg\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .lg\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .lg\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .lg\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .lg\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .lg\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .lg\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .lg\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .lg\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .lg\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .lg\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .lg\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .lg\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .lg\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .lg\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .lg\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .lg\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .lg\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .lg\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .lg\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .lg\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .lg\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .lg\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .lg\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .lg\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .lg\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .lg\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .lg\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .lg\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .lg\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .lg\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .lg\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .lg\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .lg\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .lg\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .lg\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .lg\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .lg\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .lg\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .lg\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .lg\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .lg\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .lg\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .lg\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .lg\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .lg\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .lg\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .lg\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .lg\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .lg\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .lg\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .lg\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .lg\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .lg\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .lg\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .lg\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .lg\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .lg\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .lg\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .lg\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .lg\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .lg\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .lg\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .lg\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .lg\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .lg\:bg-fixed {
    background-attachment: fixed
  }

  .lg\:bg-local {
    background-attachment: local
  }

  .lg\:bg-scroll {
    background-attachment: scroll
  }

  .lg\:bg-clip-border {
    background-clip: border-box
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box
  }

  .lg\:bg-clip-content {
    background-clip: content-box
  }

  .lg\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .lg\:bg-transparent {
    background-color: transparent
  }

  .lg\:bg-current {
    background-color: currentColor
  }

  .lg\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .lg\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .lg\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .lg\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .lg\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .lg\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .lg\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .lg\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .lg\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .lg\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .lg\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .lg\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .lg\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .lg\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .lg\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .lg\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .lg\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .lg\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .lg\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .lg\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .lg\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .lg\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .lg\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .lg\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .lg\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .lg\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .lg\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .lg\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .lg\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .lg\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .lg\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .lg\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .lg\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .lg\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .lg\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .lg\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .lg\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .lg\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .lg\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .lg\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .lg\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .lg\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .lg\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .lg\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .lg\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .lg\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .lg\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .lg\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .lg\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .lg\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .lg\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .lg\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .lg\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .lg\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .lg\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .lg\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .lg\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .lg\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .lg\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .lg\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .lg\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .lg\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .lg\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .lg\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .lg\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .lg\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .lg\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .lg\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .lg\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .lg\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .lg\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .lg\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .lg\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .lg\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .lg\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .lg\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .lg\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .lg\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .lg\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .lg\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .lg\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .lg\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .lg\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .lg\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .lg\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .lg\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .lg\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .lg\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .lg\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .lg\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .lg\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .lg\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .lg\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .lg\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .lg\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .lg\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .lg\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .lg\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .lg\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .lg\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .lg\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .lg\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .lg\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .lg\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .lg\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .lg\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .lg\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .lg\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .lg\:bg-none {
    background-image: none
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .lg\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:to-transparent {
    --gradient-to-color: transparent
  }

  .lg\:to-current {
    --gradient-to-color: currentColor
  }

  .lg\:to-black {
    --gradient-to-color: #000
  }

  .lg\:to-white {
    --gradient-to-color: #fff
  }

  .lg\:to-gray-100 {
    --gradient-to-color: #f7fafc
  }

  .lg\:to-gray-200 {
    --gradient-to-color: #edf2f7
  }

  .lg\:to-gray-300 {
    --gradient-to-color: #e2e8f0
  }

  .lg\:to-gray-400 {
    --gradient-to-color: #cbd5e0
  }

  .lg\:to-gray-500 {
    --gradient-to-color: #a0aec0
  }

  .lg\:to-gray-600 {
    --gradient-to-color: #718096
  }

  .lg\:to-gray-700 {
    --gradient-to-color: #4a5568
  }

  .lg\:to-gray-800 {
    --gradient-to-color: #2d3748
  }

  .lg\:to-gray-900 {
    --gradient-to-color: #1a202c
  }

  .lg\:to-red-100 {
    --gradient-to-color: #fff5f5
  }

  .lg\:to-red-200 {
    --gradient-to-color: #fed7d7
  }

  .lg\:to-red-300 {
    --gradient-to-color: #feb2b2
  }

  .lg\:to-red-400 {
    --gradient-to-color: #fc8181
  }

  .lg\:to-red-500 {
    --gradient-to-color: #f56565
  }

  .lg\:to-red-600 {
    --gradient-to-color: #e53e3e
  }

  .lg\:to-red-700 {
    --gradient-to-color: #c53030
  }

  .lg\:to-red-800 {
    --gradient-to-color: #9b2c2c
  }

  .lg\:to-red-900 {
    --gradient-to-color: #742a2a
  }

  .lg\:to-orange-100 {
    --gradient-to-color: #fffaf0
  }

  .lg\:to-orange-200 {
    --gradient-to-color: #feebc8
  }

  .lg\:to-orange-300 {
    --gradient-to-color: #fbd38d
  }

  .lg\:to-orange-400 {
    --gradient-to-color: #f6ad55
  }

  .lg\:to-orange-500 {
    --gradient-to-color: #ed8936
  }

  .lg\:to-orange-600 {
    --gradient-to-color: #dd6b20
  }

  .lg\:to-orange-700 {
    --gradient-to-color: #c05621
  }

  .lg\:to-orange-800 {
    --gradient-to-color: #9c4221
  }

  .lg\:to-orange-900 {
    --gradient-to-color: #7b341e
  }

  .lg\:to-yellow-100 {
    --gradient-to-color: #fffff0
  }

  .lg\:to-yellow-200 {
    --gradient-to-color: #fefcbf
  }

  .lg\:to-yellow-300 {
    --gradient-to-color: #faf089
  }

  .lg\:to-yellow-400 {
    --gradient-to-color: #f6e05e
  }

  .lg\:to-yellow-500 {
    --gradient-to-color: #ecc94b
  }

  .lg\:to-yellow-600 {
    --gradient-to-color: #d69e2e
  }

  .lg\:to-yellow-700 {
    --gradient-to-color: #b7791f
  }

  .lg\:to-yellow-800 {
    --gradient-to-color: #975a16
  }

  .lg\:to-yellow-900 {
    --gradient-to-color: #744210
  }

  .lg\:to-green-100 {
    --gradient-to-color: #f0fff4
  }

  .lg\:to-green-200 {
    --gradient-to-color: #c6f6d5
  }

  .lg\:to-green-300 {
    --gradient-to-color: #9ae6b4
  }

  .lg\:to-green-400 {
    --gradient-to-color: #68d391
  }

  .lg\:to-green-500 {
    --gradient-to-color: #48bb78
  }

  .lg\:to-green-600 {
    --gradient-to-color: #38a169
  }

  .lg\:to-green-700 {
    --gradient-to-color: #2f855a
  }

  .lg\:to-green-800 {
    --gradient-to-color: #276749
  }

  .lg\:to-green-900 {
    --gradient-to-color: #22543d
  }

  .lg\:to-teal-100 {
    --gradient-to-color: #e6fffa
  }

  .lg\:to-teal-200 {
    --gradient-to-color: #b2f5ea
  }

  .lg\:to-teal-300 {
    --gradient-to-color: #81e6d9
  }

  .lg\:to-teal-400 {
    --gradient-to-color: #4fd1c5
  }

  .lg\:to-teal-500 {
    --gradient-to-color: #38b2ac
  }

  .lg\:to-teal-600 {
    --gradient-to-color: #319795
  }

  .lg\:to-teal-700 {
    --gradient-to-color: #2c7a7b
  }

  .lg\:to-teal-800 {
    --gradient-to-color: #285e61
  }

  .lg\:to-teal-900 {
    --gradient-to-color: #234e52
  }

  .lg\:to-blue-100 {
    --gradient-to-color: #ebf8ff
  }

  .lg\:to-blue-200 {
    --gradient-to-color: #bee3f8
  }

  .lg\:to-blue-300 {
    --gradient-to-color: #90cdf4
  }

  .lg\:to-blue-400 {
    --gradient-to-color: #63b3ed
  }

  .lg\:to-blue-500 {
    --gradient-to-color: #4299e1
  }

  .lg\:to-blue-600 {
    --gradient-to-color: #3182ce
  }

  .lg\:to-blue-700 {
    --gradient-to-color: #2b6cb0
  }

  .lg\:to-blue-800 {
    --gradient-to-color: #2c5282
  }

  .lg\:to-blue-900 {
    --gradient-to-color: #2a4365
  }

  .lg\:to-indigo-100 {
    --gradient-to-color: #ebf4ff
  }

  .lg\:to-indigo-200 {
    --gradient-to-color: #c3dafe
  }

  .lg\:to-indigo-300 {
    --gradient-to-color: #a3bffa
  }

  .lg\:to-indigo-400 {
    --gradient-to-color: #7f9cf5
  }

  .lg\:to-indigo-500 {
    --gradient-to-color: #667eea
  }

  .lg\:to-indigo-600 {
    --gradient-to-color: #5a67d8
  }

  .lg\:to-indigo-700 {
    --gradient-to-color: #4c51bf
  }

  .lg\:to-indigo-800 {
    --gradient-to-color: #434190
  }

  .lg\:to-indigo-900 {
    --gradient-to-color: #3c366b
  }

  .lg\:to-purple-100 {
    --gradient-to-color: #faf5ff
  }

  .lg\:to-purple-200 {
    --gradient-to-color: #e9d8fd
  }

  .lg\:to-purple-300 {
    --gradient-to-color: #d6bcfa
  }

  .lg\:to-purple-400 {
    --gradient-to-color: #b794f4
  }

  .lg\:to-purple-500 {
    --gradient-to-color: #9f7aea
  }

  .lg\:to-purple-600 {
    --gradient-to-color: #805ad5
  }

  .lg\:to-purple-700 {
    --gradient-to-color: #6b46c1
  }

  .lg\:to-purple-800 {
    --gradient-to-color: #553c9a
  }

  .lg\:to-purple-900 {
    --gradient-to-color: #44337a
  }

  .lg\:to-pink-100 {
    --gradient-to-color: #fff5f7
  }

  .lg\:to-pink-200 {
    --gradient-to-color: #fed7e2
  }

  .lg\:to-pink-300 {
    --gradient-to-color: #fbb6ce
  }

  .lg\:to-pink-400 {
    --gradient-to-color: #f687b3
  }

  .lg\:to-pink-500 {
    --gradient-to-color: #ed64a6
  }

  .lg\:to-pink-600 {
    --gradient-to-color: #d53f8c
  }

  .lg\:to-pink-700 {
    --gradient-to-color: #b83280
  }

  .lg\:to-pink-800 {
    --gradient-to-color: #97266d
  }

  .lg\:to-pink-900 {
    --gradient-to-color: #702459
  }

  .lg\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:hover\:from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:hover\:via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:hover\:to-transparent:hover {
    --gradient-to-color: transparent
  }

  .lg\:hover\:to-current:hover {
    --gradient-to-color: currentColor
  }

  .lg\:hover\:to-black:hover {
    --gradient-to-color: #000
  }

  .lg\:hover\:to-white:hover {
    --gradient-to-color: #fff
  }

  .lg\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc
  }

  .lg\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7
  }

  .lg\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0
  }

  .lg\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0
  }

  .lg\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0
  }

  .lg\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096
  }

  .lg\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568
  }

  .lg\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748
  }

  .lg\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c
  }

  .lg\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5
  }

  .lg\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7
  }

  .lg\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2
  }

  .lg\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181
  }

  .lg\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565
  }

  .lg\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e
  }

  .lg\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030
  }

  .lg\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c
  }

  .lg\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a
  }

  .lg\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0
  }

  .lg\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8
  }

  .lg\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d
  }

  .lg\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55
  }

  .lg\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936
  }

  .lg\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20
  }

  .lg\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621
  }

  .lg\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221
  }

  .lg\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e
  }

  .lg\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0
  }

  .lg\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf
  }

  .lg\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089
  }

  .lg\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e
  }

  .lg\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b
  }

  .lg\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e
  }

  .lg\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f
  }

  .lg\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16
  }

  .lg\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210
  }

  .lg\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4
  }

  .lg\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5
  }

  .lg\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4
  }

  .lg\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391
  }

  .lg\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78
  }

  .lg\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169
  }

  .lg\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a
  }

  .lg\:hover\:to-green-800:hover {
    --gradient-to-color: #276749
  }

  .lg\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d
  }

  .lg\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa
  }

  .lg\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea
  }

  .lg\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9
  }

  .lg\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5
  }

  .lg\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac
  }

  .lg\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795
  }

  .lg\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b
  }

  .lg\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61
  }

  .lg\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52
  }

  .lg\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff
  }

  .lg\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8
  }

  .lg\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4
  }

  .lg\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed
  }

  .lg\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1
  }

  .lg\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce
  }

  .lg\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0
  }

  .lg\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282
  }

  .lg\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365
  }

  .lg\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff
  }

  .lg\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe
  }

  .lg\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa
  }

  .lg\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5
  }

  .lg\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea
  }

  .lg\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8
  }

  .lg\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf
  }

  .lg\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190
  }

  .lg\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b
  }

  .lg\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff
  }

  .lg\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd
  }

  .lg\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa
  }

  .lg\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4
  }

  .lg\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea
  }

  .lg\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5
  }

  .lg\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1
  }

  .lg\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a
  }

  .lg\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a
  }

  .lg\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7
  }

  .lg\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2
  }

  .lg\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce
  }

  .lg\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3
  }

  .lg\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6
  }

  .lg\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c
  }

  .lg\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280
  }

  .lg\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d
  }

  .lg\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459
  }

  .lg\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:focus\:from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:focus\:via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:focus\:to-transparent:focus {
    --gradient-to-color: transparent
  }

  .lg\:focus\:to-current:focus {
    --gradient-to-color: currentColor
  }

  .lg\:focus\:to-black:focus {
    --gradient-to-color: #000
  }

  .lg\:focus\:to-white:focus {
    --gradient-to-color: #fff
  }

  .lg\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc
  }

  .lg\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7
  }

  .lg\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0
  }

  .lg\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0
  }

  .lg\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0
  }

  .lg\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096
  }

  .lg\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568
  }

  .lg\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748
  }

  .lg\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c
  }

  .lg\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5
  }

  .lg\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7
  }

  .lg\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2
  }

  .lg\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181
  }

  .lg\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565
  }

  .lg\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e
  }

  .lg\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030
  }

  .lg\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c
  }

  .lg\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a
  }

  .lg\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0
  }

  .lg\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8
  }

  .lg\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d
  }

  .lg\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55
  }

  .lg\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936
  }

  .lg\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20
  }

  .lg\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621
  }

  .lg\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221
  }

  .lg\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e
  }

  .lg\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0
  }

  .lg\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf
  }

  .lg\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089
  }

  .lg\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e
  }

  .lg\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b
  }

  .lg\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e
  }

  .lg\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f
  }

  .lg\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16
  }

  .lg\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210
  }

  .lg\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4
  }

  .lg\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5
  }

  .lg\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4
  }

  .lg\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391
  }

  .lg\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78
  }

  .lg\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169
  }

  .lg\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a
  }

  .lg\:focus\:to-green-800:focus {
    --gradient-to-color: #276749
  }

  .lg\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d
  }

  .lg\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa
  }

  .lg\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea
  }

  .lg\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9
  }

  .lg\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5
  }

  .lg\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac
  }

  .lg\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795
  }

  .lg\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b
  }

  .lg\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61
  }

  .lg\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52
  }

  .lg\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff
  }

  .lg\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8
  }

  .lg\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4
  }

  .lg\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed
  }

  .lg\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1
  }

  .lg\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce
  }

  .lg\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0
  }

  .lg\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282
  }

  .lg\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365
  }

  .lg\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff
  }

  .lg\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe
  }

  .lg\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa
  }

  .lg\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5
  }

  .lg\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea
  }

  .lg\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8
  }

  .lg\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf
  }

  .lg\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190
  }

  .lg\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b
  }

  .lg\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff
  }

  .lg\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd
  }

  .lg\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa
  }

  .lg\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4
  }

  .lg\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea
  }

  .lg\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5
  }

  .lg\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1
  }

  .lg\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a
  }

  .lg\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a
  }

  .lg\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7
  }

  .lg\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2
  }

  .lg\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce
  }

  .lg\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3
  }

  .lg\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6
  }

  .lg\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c
  }

  .lg\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280
  }

  .lg\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d
  }

  .lg\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459
  }

  .lg\:bg-opacity-0 {
    --bg-opacity: 0
  }

  .lg\:bg-opacity-25 {
    --bg-opacity: 0.25
  }

  .lg\:bg-opacity-50 {
    --bg-opacity: 0.5
  }

  .lg\:bg-opacity-75 {
    --bg-opacity: 0.75
  }

  .lg\:bg-opacity-100 {
    --bg-opacity: 1
  }

  .lg\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0
  }

  .lg\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25
  }

  .lg\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5
  }

  .lg\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75
  }

  .lg\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1
  }

  .lg\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0
  }

  .lg\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25
  }

  .lg\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5
  }

  .lg\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75
  }

  .lg\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1
  }

  .lg\:bg-bottom {
    background-position: bottom
  }

  .lg\:bg-center {
    background-position: center
  }

  .lg\:bg-left {
    background-position: left
  }

  .lg\:bg-left-bottom {
    background-position: left bottom
  }

  .lg\:bg-left-top {
    background-position: left top
  }

  .lg\:bg-right {
    background-position: right
  }

  .lg\:bg-right-bottom {
    background-position: right bottom
  }

  .lg\:bg-right-top {
    background-position: right top
  }

  .lg\:bg-top {
    background-position: top
  }

  .lg\:bg-repeat {
    background-repeat: repeat
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .lg\:bg-repeat-round {
    background-repeat: round
  }

  .lg\:bg-repeat-space {
    background-repeat: space
  }

  .lg\:bg-auto {
    background-size: auto
  }

  .lg\:bg-cover {
    background-size: cover
  }

  .lg\:bg-contain {
    background-size: contain
  }

  .lg\:border-collapse {
    border-collapse: collapse
  }

  .lg\:border-separate {
    border-collapse: separate
  }

  .lg\:border-transparent {
    border-color: transparent
  }

  .lg\:border-current {
    border-color: currentColor
  }

  .lg\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .lg\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .lg\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .lg\:border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .lg\:border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .lg\:border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .lg\:border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .lg\:border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .lg\:border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .lg\:border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .lg\:border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .lg\:border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .lg\:border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .lg\:border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .lg\:border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .lg\:border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .lg\:border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .lg\:border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .lg\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .lg\:border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .lg\:border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .lg\:border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .lg\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .lg\:border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .lg\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .lg\:border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .lg\:border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .lg\:border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .lg\:border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .lg\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .lg\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .lg\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .lg\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .lg\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .lg\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .lg\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .lg\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .lg\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .lg\:border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .lg\:border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .lg\:border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .lg\:border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .lg\:border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .lg\:border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .lg\:border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .lg\:border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .lg\:border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .lg\:border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .lg\:border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .lg\:border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .lg\:border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .lg\:border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .lg\:border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .lg\:border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .lg\:border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .lg\:border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .lg\:border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .lg\:border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .lg\:border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .lg\:border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .lg\:border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .lg\:border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .lg\:border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .lg\:border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .lg\:border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .lg\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .lg\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .lg\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .lg\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .lg\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .lg\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .lg\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .lg\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .lg\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .lg\:border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .lg\:border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .lg\:border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .lg\:border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .lg\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .lg\:border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .lg\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .lg\:border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .lg\:border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .lg\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .lg\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .lg\:border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .lg\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .lg\:border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .lg\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .lg\:border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .lg\:border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .lg\:border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .lg\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .lg\:hover\:border-current:hover {
    border-color: currentColor
  }

  .lg\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .lg\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .lg\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .lg\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .lg\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .lg\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .lg\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .lg\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .lg\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .lg\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .lg\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .lg\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .lg\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .lg\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .lg\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .lg\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .lg\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .lg\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .lg\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .lg\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .lg\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .lg\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .lg\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .lg\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .lg\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .lg\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .lg\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .lg\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .lg\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .lg\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .lg\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .lg\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .lg\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .lg\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .lg\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .lg\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .lg\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .lg\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .lg\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .lg\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .lg\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .lg\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .lg\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .lg\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .lg\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .lg\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .lg\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .lg\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .lg\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .lg\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .lg\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .lg\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .lg\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .lg\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .lg\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .lg\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .lg\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .lg\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .lg\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .lg\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .lg\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .lg\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .lg\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .lg\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .lg\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .lg\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .lg\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .lg\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .lg\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .lg\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .lg\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .lg\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .lg\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .lg\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .lg\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .lg\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .lg\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .lg\:focus\:border-current:focus {
    border-color: currentColor
  }

  .lg\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .lg\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .lg\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .lg\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .lg\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .lg\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .lg\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .lg\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .lg\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .lg\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .lg\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .lg\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .lg\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .lg\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .lg\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .lg\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .lg\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .lg\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .lg\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .lg\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .lg\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .lg\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .lg\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .lg\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .lg\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .lg\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .lg\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .lg\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .lg\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .lg\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .lg\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .lg\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .lg\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .lg\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .lg\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .lg\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .lg\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .lg\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .lg\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .lg\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .lg\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .lg\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .lg\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .lg\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .lg\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .lg\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .lg\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .lg\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .lg\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .lg\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .lg\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .lg\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .lg\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .lg\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .lg\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .lg\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .lg\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .lg\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .lg\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .lg\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .lg\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .lg\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .lg\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .lg\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .lg\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .lg\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .lg\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .lg\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .lg\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .lg\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .lg\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .lg\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .lg\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .lg\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .lg\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .lg\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .lg\:border-opacity-0 {
    --border-opacity: 0
  }

  .lg\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .lg\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .lg\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .lg\:border-opacity-100 {
    --border-opacity: 1
  }

  .lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .lg\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .lg\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .lg\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .lg\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .lg\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .lg\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .lg\:rounded-none {
    border-radius: 0
  }

  .lg\:rounded-sm {
    border-radius: 0.125rem
  }

  .lg\:rounded {
    border-radius: 0.25rem
  }

  .lg\:rounded-md {
    border-radius: 0.375rem
  }

  .lg\:rounded-lg {
    border-radius: 0.5rem
  }

  .lg\:rounded-xl {
    border-radius: 0.75rem
  }

  .lg\:rounded-2xl {
    border-radius: 1rem
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem
  }

  .lg\:rounded-full {
    border-radius: 9999px
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .lg\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .lg\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .lg\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .lg\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .lg\:border-solid {
    border-style: solid
  }

  .lg\:border-dashed {
    border-style: dashed
  }

  .lg\:border-dotted {
    border-style: dotted
  }

  .lg\:border-double {
    border-style: double
  }

  .lg\:border-none {
    border-style: none
  }

  .lg\:border-0 {
    border-width: 0
  }

  .lg\:border-2 {
    border-width: 2px
  }

  .lg\:border-4 {
    border-width: 4px
  }

  .lg\:border-8 {
    border-width: 8px
  }

  .lg\:border {
    border-width: 1px
  }

  .lg\:border-t-0 {
    border-top-width: 0
  }

  .lg\:border-r-0 {
    border-right-width: 0
  }

  .lg\:border-b-0 {
    border-bottom-width: 0
  }

  .lg\:border-l-0 {
    border-left-width: 0
  }

  .lg\:border-t-2 {
    border-top-width: 2px
  }

  .lg\:border-r-2 {
    border-right-width: 2px
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px
  }

  .lg\:border-l-2 {
    border-left-width: 2px
  }

  .lg\:border-t-4 {
    border-top-width: 4px
  }

  .lg\:border-r-4 {
    border-right-width: 4px
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px
  }

  .lg\:border-l-4 {
    border-left-width: 4px
  }

  .lg\:border-t-8 {
    border-top-width: 8px
  }

  .lg\:border-r-8 {
    border-right-width: 8px
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px
  }

  .lg\:border-l-8 {
    border-left-width: 8px
  }

  .lg\:border-t {
    border-top-width: 1px
  }

  .lg\:border-r {
    border-right-width: 1px
  }

  .lg\:border-b {
    border-bottom-width: 1px
  }

  .lg\:border-l {
    border-left-width: 1px
  }

  .lg\:box-border {
    box-sizing: border-box
  }

  .lg\:box-content {
    box-sizing: content-box
  }

  .lg\:cursor-auto {
    cursor: auto
  }

  .lg\:cursor-default {
    cursor: default
  }

  .lg\:cursor-pointer {
    cursor: pointer
  }

  .lg\:cursor-wait {
    cursor: wait
  }

  .lg\:cursor-text {
    cursor: text
  }

  .lg\:cursor-move {
    cursor: move
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed
  }

  .lg\:block {
    display: block
  }

  .lg\:inline-block {
    display: inline-block
  }

  .lg\:inline {
    display: inline
  }

  .lg\:flex {
    display: flex
  }

  .lg\:inline-flex {
    display: inline-flex
  }

  .lg\:table {
    display: table
  }

  .lg\:table-caption {
    display: table-caption
  }

  .lg\:table-cell {
    display: table-cell
  }

  .lg\:table-column {
    display: table-column
  }

  .lg\:table-column-group {
    display: table-column-group
  }

  .lg\:table-footer-group {
    display: table-footer-group
  }

  .lg\:table-header-group {
    display: table-header-group
  }

  .lg\:table-row-group {
    display: table-row-group
  }

  .lg\:table-row {
    display: table-row
  }

  .lg\:flow-root {
    display: flow-root
  }

  .lg\:grid {
    display: grid
  }

  .lg\:inline-grid {
    display: inline-grid
  }

  .lg\:contents {
    display: contents
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .lg\:flex-col {
    flex-direction: column
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .lg\:flex-wrap {
    flex-wrap: wrap
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .lg\:place-items-auto {
    place-items: auto
  }

  .lg\:place-items-start {
    place-items: start
  }

  .lg\:place-items-end {
    place-items: end
  }

  .lg\:place-items-center {
    place-items: center
  }

  .lg\:place-items-stretch {
    place-items: stretch
  }

  .lg\:place-content-center {
    place-content: center
  }

  .lg\:place-content-start {
    place-content: start
  }

  .lg\:place-content-end {
    place-content: end
  }

  .lg\:place-content-between {
    place-content: space-between
  }

  .lg\:place-content-around {
    place-content: space-around
  }

  .lg\:place-content-evenly {
    place-content: space-evenly
  }

  .lg\:place-content-stretch {
    place-content: stretch
  }

  .lg\:place-self-auto {
    place-self: auto
  }

  .lg\:place-self-start {
    place-self: start
  }

  .lg\:place-self-end {
    place-self: end
  }

  .lg\:place-self-center {
    place-self: center
  }

  .lg\:place-self-stretch {
    place-self: stretch
  }

  .lg\:items-start {
    align-items: flex-start
  }

  .lg\:items-end {
    align-items: flex-end
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:items-baseline {
    align-items: baseline
  }

  .lg\:items-stretch {
    align-items: stretch
  }

  .lg\:content-center {
    align-content: center
  }

  .lg\:content-start {
    align-content: flex-start
  }

  .lg\:content-end {
    align-content: flex-end
  }

  .lg\:content-between {
    align-content: space-between
  }

  .lg\:content-around {
    align-content: space-around
  }

  .lg\:content-evenly {
    align-content: space-evenly
  }

  .lg\:self-auto {
    align-self: auto
  }

  .lg\:self-start {
    align-self: flex-start
  }

  .lg\:self-end {
    align-self: flex-end
  }

  .lg\:self-center {
    align-self: center
  }

  .lg\:self-stretch {
    align-self: stretch
  }

  .lg\:justify-items-auto {
    justify-items: auto
  }

  .lg\:justify-items-start {
    justify-items: start
  }

  .lg\:justify-items-end {
    justify-items: end
  }

  .lg\:justify-items-center {
    justify-items: center
  }

  .lg\:justify-items-stretch {
    justify-items: stretch
  }

  .lg\:justify-start {
    justify-content: flex-start
  }

  .lg\:justify-end {
    justify-content: flex-end
  }

  .lg\:justify-center {
    justify-content: center
  }

  .lg\:justify-between {
    justify-content: space-between
  }

  .lg\:justify-around {
    justify-content: space-around
  }

  .lg\:justify-evenly {
    justify-content: space-evenly
  }

  .lg\:justify-self-auto {
    justify-self: auto
  }

  .lg\:justify-self-start {
    justify-self: start
  }

  .lg\:justify-self-end {
    justify-self: end
  }

  .lg\:justify-self-center {
    justify-self: center
  }

  .lg\:justify-self-stretch {
    justify-self: stretch
  }

  .lg\:flex-1 {
    flex: 1 1 0%
  }

  .lg\:flex-auto {
    flex: 1 1 auto
  }

  .lg\:flex-initial {
    flex: 0 1 auto
  }

  .lg\:flex-none {
    flex: none
  }

  .lg\:flex-grow-0 {
    flex-grow: 0
  }

  .lg\:flex-grow {
    flex-grow: 1
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0
  }

  .lg\:flex-shrink {
    flex-shrink: 1
  }

  .lg\:order-1 {
    order: 1
  }

  .lg\:order-2 {
    order: 2
  }

  .lg\:order-3 {
    order: 3
  }

  .lg\:order-4 {
    order: 4
  }

  .lg\:order-5 {
    order: 5
  }

  .lg\:order-6 {
    order: 6
  }

  .lg\:order-7 {
    order: 7
  }

  .lg\:order-8 {
    order: 8
  }

  .lg\:order-9 {
    order: 9
  }

  .lg\:order-10 {
    order: 10
  }

  .lg\:order-11 {
    order: 11
  }

  .lg\:order-12 {
    order: 12
  }

  .lg\:order-first {
    order: -9999
  }

  .lg\:order-last {
    order: 9999
  }

  .lg\:order-none {
    order: 0
  }

  .lg\:float-right {
    float: right
  }

  .lg\:float-left {
    float: left
  }

  .lg\:float-none {
    float: none
  }

  .lg\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .lg\:clear-left {
    clear: left
  }

  .lg\:clear-right {
    clear: right
  }

  .lg\:clear-both {
    clear: both
  }

  .lg\:clear-none {
    clear: none
  }

  .lg\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .lg\:font-hairline {
    font-weight: 100
  }

  .lg\:font-thin {
    font-weight: 200
  }

  .lg\:font-light {
    font-weight: 300
  }

  .lg\:font-normal {
    font-weight: 400
  }

  .lg\:font-medium {
    font-weight: 500
  }

  .lg\:font-semibold {
    font-weight: 600
  }

  .lg\:font-bold {
    font-weight: 700
  }

  .lg\:font-extrabold {
    font-weight: 800
  }

  .lg\:font-black {
    font-weight: 900
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900
  }

  .lg\:h-0 {
    height: 0
  }

  .lg\:h-1 {
    height: 0.25rem
  }

  .lg\:h-2 {
    height: 0.5rem
  }

  .lg\:h-3 {
    height: 0.75rem
  }

  .lg\:h-4 {
    height: 1rem
  }

  .lg\:h-5 {
    height: 1.25rem
  }

  .lg\:h-6 {
    height: 1.5rem
  }

  .lg\:h-8 {
    height: 2rem
  }

  .lg\:h-10 {
    height: 2.5rem
  }

  .lg\:h-12 {
    height: 3rem
  }

  .lg\:h-16 {
    height: 4rem
  }

  .lg\:h-20 {
    height: 5rem
  }

  .lg\:h-24 {
    height: 6rem
  }

  .lg\:h-32 {
    height: 8rem
  }

  .lg\:h-40 {
    height: 10rem
  }

  .lg\:h-48 {
    height: 12rem
  }

  .lg\:h-56 {
    height: 14rem
  }

  .lg\:h-64 {
    height: 16rem
  }

  .lg\:h-auto {
    height: auto
  }

  .lg\:h-px {
    height: 1px
  }

  .lg\:h-full {
    height: 100%
  }

  .lg\:h-screen {
    height: 100vh
  }

  .lg\:text-xs {
    font-size: 0.75rem
  }

  .lg\:text-sm {
    font-size: 0.875rem
  }

  .lg\:text-base {
    font-size: 1rem
  }

  .lg\:text-lg {
    font-size: 1.125rem
  }

  .lg\:text-xl {
    font-size: 1.25rem
  }

  .lg\:text-2xl {
    font-size: 1.5rem
  }

  .lg\:text-3xl {
    font-size: 1.875rem
  }

  .lg\:text-4xl {
    font-size: 2.25rem
  }

  .lg\:text-5xl {
    font-size: 3rem
  }

  .lg\:text-6xl {
    font-size: 4rem
  }

  .lg\:leading-3 {
    line-height: .75rem
  }

  .lg\:leading-4 {
    line-height: 1rem
  }

  .lg\:leading-5 {
    line-height: 1.25rem
  }

  .lg\:leading-6 {
    line-height: 1.5rem
  }

  .lg\:leading-7 {
    line-height: 1.75rem
  }

  .lg\:leading-8 {
    line-height: 2rem
  }

  .lg\:leading-9 {
    line-height: 2.25rem
  }

  .lg\:leading-10 {
    line-height: 2.5rem
  }

  .lg\:leading-none {
    line-height: 1
  }

  .lg\:leading-tight {
    line-height: 1.25
  }

  .lg\:leading-snug {
    line-height: 1.375
  }

  .lg\:leading-normal {
    line-height: 1.5
  }

  .lg\:leading-relaxed {
    line-height: 1.625
  }

  .lg\:leading-loose {
    line-height: 2
  }

  .lg\:list-inside {
    list-style-position: inside
  }

  .lg\:list-outside {
    list-style-position: outside
  }

  .lg\:list-none {
    list-style-type: none
  }

  .lg\:list-disc {
    list-style-type: disc
  }

  .lg\:list-decimal {
    list-style-type: decimal
  }

  .lg\:m-0 {
    margin: 0
  }

  .lg\:m-1 {
    margin: 0.25rem
  }

  .lg\:m-2 {
    margin: 0.5rem
  }

  .lg\:m-3 {
    margin: 0.75rem
  }

  .lg\:m-4 {
    margin: 1rem
  }

  .lg\:m-5 {
    margin: 1.25rem
  }

  .lg\:m-6 {
    margin: 1.5rem
  }

  .lg\:m-8 {
    margin: 2rem
  }

  .lg\:m-10 {
    margin: 2.5rem
  }

  .lg\:m-12 {
    margin: 3rem
  }

  .lg\:m-16 {
    margin: 4rem
  }

  .lg\:m-20 {
    margin: 5rem
  }

  .lg\:m-24 {
    margin: 6rem
  }

  .lg\:m-32 {
    margin: 8rem
  }

  .lg\:m-40 {
    margin: 10rem
  }

  .lg\:m-48 {
    margin: 12rem
  }

  .lg\:m-56 {
    margin: 14rem
  }

  .lg\:m-64 {
    margin: 16rem
  }

  .lg\:m-auto {
    margin: auto
  }

  .lg\:m-px {
    margin: 1px
  }

  .lg\:-m-1 {
    margin: -0.25rem
  }

  .lg\:-m-2 {
    margin: -0.5rem
  }

  .lg\:-m-3 {
    margin: -0.75rem
  }

  .lg\:-m-4 {
    margin: -1rem
  }

  .lg\:-m-5 {
    margin: -1.25rem
  }

  .lg\:-m-6 {
    margin: -1.5rem
  }

  .lg\:-m-8 {
    margin: -2rem
  }

  .lg\:-m-10 {
    margin: -2.5rem
  }

  .lg\:-m-12 {
    margin: -3rem
  }

  .lg\:-m-16 {
    margin: -4rem
  }

  .lg\:-m-20 {
    margin: -5rem
  }

  .lg\:-m-24 {
    margin: -6rem
  }

  .lg\:-m-32 {
    margin: -8rem
  }

  .lg\:-m-40 {
    margin: -10rem
  }

  .lg\:-m-48 {
    margin: -12rem
  }

  .lg\:-m-56 {
    margin: -14rem
  }

  .lg\:-m-64 {
    margin: -16rem
  }

  .lg\:-m-px {
    margin: -1px
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:mr-0 {
    margin-right: 0
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:ml-0 {
    margin-left: 0
  }

  .lg\:mt-1 {
    margin-top: 0.25rem
  }

  .lg\:mr-1 {
    margin-right: 0.25rem
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem
  }

  .lg\:ml-1 {
    margin-left: 0.25rem
  }

  .lg\:mt-2 {
    margin-top: 0.5rem
  }

  .lg\:mr-2 {
    margin-right: 0.5rem
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem
  }

  .lg\:ml-2 {
    margin-left: 0.5rem
  }

  .lg\:mt-3 {
    margin-top: 0.75rem
  }

  .lg\:mr-3 {
    margin-right: 0.75rem
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem
  }

  .lg\:ml-3 {
    margin-left: 0.75rem
  }

  .lg\:mt-4 {
    margin-top: 1rem
  }

  .lg\:mr-4 {
    margin-right: 1rem
  }

  .lg\:mb-4 {
    margin-bottom: 1rem
  }

  .lg\:ml-4 {
    margin-left: 1rem
  }

  .lg\:mt-5 {
    margin-top: 1.25rem
  }

  .lg\:mr-5 {
    margin-right: 1.25rem
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem
  }

  .lg\:ml-5 {
    margin-left: 1.25rem
  }

  .lg\:mt-6 {
    margin-top: 1.5rem
  }

  .lg\:mr-6 {
    margin-right: 1.5rem
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem
  }

  .lg\:ml-6 {
    margin-left: 1.5rem
  }

  .lg\:mt-8 {
    margin-top: 2rem
  }

  .lg\:mr-8 {
    margin-right: 2rem
  }

  .lg\:mb-8 {
    margin-bottom: 2rem
  }

  .lg\:ml-8 {
    margin-left: 2rem
  }

  .lg\:mt-10 {
    margin-top: 2.5rem
  }

  .lg\:mr-10 {
    margin-right: 2.5rem
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem
  }

  .lg\:ml-10 {
    margin-left: 2.5rem
  }

  .lg\:mt-12 {
    margin-top: 3rem
  }

  .lg\:mr-12 {
    margin-right: 3rem
  }

  .lg\:mb-12 {
    margin-bottom: 3rem
  }

  .lg\:ml-12 {
    margin-left: 3rem
  }

  .lg\:mt-16 {
    margin-top: 4rem
  }

  .lg\:mr-16 {
    margin-right: 4rem
  }

  .lg\:mb-16 {
    margin-bottom: 4rem
  }

  .lg\:ml-16 {
    margin-left: 4rem
  }

  .lg\:mt-20 {
    margin-top: 5rem
  }

  .lg\:mr-20 {
    margin-right: 5rem
  }

  .lg\:mb-20 {
    margin-bottom: 5rem
  }

  .lg\:ml-20 {
    margin-left: 5rem
  }

  .lg\:mt-24 {
    margin-top: 6rem
  }

  .lg\:mr-24 {
    margin-right: 6rem
  }

  .lg\:mb-24 {
    margin-bottom: 6rem
  }

  .lg\:ml-24 {
    margin-left: 6rem
  }

  .lg\:mt-32 {
    margin-top: 8rem
  }

  .lg\:mr-32 {
    margin-right: 8rem
  }

  .lg\:mb-32 {
    margin-bottom: 8rem
  }

  .lg\:ml-32 {
    margin-left: 8rem
  }

  .lg\:mt-40 {
    margin-top: 10rem
  }

  .lg\:mr-40 {
    margin-right: 10rem
  }

  .lg\:mb-40 {
    margin-bottom: 10rem
  }

  .lg\:ml-40 {
    margin-left: 10rem
  }

  .lg\:mt-48 {
    margin-top: 12rem
  }

  .lg\:mr-48 {
    margin-right: 12rem
  }

  .lg\:mb-48 {
    margin-bottom: 12rem
  }

  .lg\:ml-48 {
    margin-left: 12rem
  }

  .lg\:mt-56 {
    margin-top: 14rem
  }

  .lg\:mr-56 {
    margin-right: 14rem
  }

  .lg\:mb-56 {
    margin-bottom: 14rem
  }

  .lg\:ml-56 {
    margin-left: 14rem
  }

  .lg\:mt-64 {
    margin-top: 16rem
  }

  .lg\:mr-64 {
    margin-right: 16rem
  }

  .lg\:mb-64 {
    margin-bottom: 16rem
  }

  .lg\:ml-64 {
    margin-left: 16rem
  }

  .lg\:mt-auto {
    margin-top: auto
  }

  .lg\:mr-auto {
    margin-right: auto
  }

  .lg\:mb-auto {
    margin-bottom: auto
  }

  .lg\:ml-auto {
    margin-left: auto
  }

  .lg\:mt-px {
    margin-top: 1px
  }

  .lg\:mr-px {
    margin-right: 1px
  }

  .lg\:mb-px {
    margin-bottom: 1px
  }

  .lg\:ml-px {
    margin-left: 1px
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem
  }

  .lg\:-mt-3 {
    margin-top: -0.75rem
  }

  .lg\:-mr-3 {
    margin-right: -0.75rem
  }

  .lg\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .lg\:-ml-3 {
    margin-left: -0.75rem
  }

  .lg\:-mt-4 {
    margin-top: -1rem
  }

  .lg\:-mr-4 {
    margin-right: -1rem
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem
  }

  .lg\:-ml-4 {
    margin-left: -1rem
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem
  }

  .lg\:-mt-8 {
    margin-top: -2rem
  }

  .lg\:-mr-8 {
    margin-right: -2rem
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem
  }

  .lg\:-ml-8 {
    margin-left: -2rem
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem
  }

  .lg\:-mt-12 {
    margin-top: -3rem
  }

  .lg\:-mr-12 {
    margin-right: -3rem
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem
  }

  .lg\:-ml-12 {
    margin-left: -3rem
  }

  .lg\:-mt-16 {
    margin-top: -4rem
  }

  .lg\:-mr-16 {
    margin-right: -4rem
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem
  }

  .lg\:-ml-16 {
    margin-left: -4rem
  }

  .lg\:-mt-20 {
    margin-top: -5rem
  }

  .lg\:-mr-20 {
    margin-right: -5rem
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem
  }

  .lg\:-ml-20 {
    margin-left: -5rem
  }

  .lg\:-mt-24 {
    margin-top: -6rem
  }

  .lg\:-mr-24 {
    margin-right: -6rem
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem
  }

  .lg\:-ml-24 {
    margin-left: -6rem
  }

  .lg\:-mt-32 {
    margin-top: -8rem
  }

  .lg\:-mr-32 {
    margin-right: -8rem
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem
  }

  .lg\:-ml-32 {
    margin-left: -8rem
  }

  .lg\:-mt-40 {
    margin-top: -10rem
  }

  .lg\:-mr-40 {
    margin-right: -10rem
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem
  }

  .lg\:-ml-40 {
    margin-left: -10rem
  }

  .lg\:-mt-48 {
    margin-top: -12rem
  }

  .lg\:-mr-48 {
    margin-right: -12rem
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem
  }

  .lg\:-ml-48 {
    margin-left: -12rem
  }

  .lg\:-mt-56 {
    margin-top: -14rem
  }

  .lg\:-mr-56 {
    margin-right: -14rem
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem
  }

  .lg\:-ml-56 {
    margin-left: -14rem
  }

  .lg\:-mt-64 {
    margin-top: -16rem
  }

  .lg\:-mr-64 {
    margin-right: -16rem
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem
  }

  .lg\:-ml-64 {
    margin-left: -16rem
  }

  .lg\:-mt-px {
    margin-top: -1px
  }

  .lg\:-mr-px {
    margin-right: -1px
  }

  .lg\:-mb-px {
    margin-bottom: -1px
  }

  .lg\:-ml-px {
    margin-left: -1px
  }

  .lg\:max-h-full {
    max-height: 100%
  }

  .lg\:max-h-screen {
    max-height: 100vh
  }

  .lg\:max-w-none {
    max-width: none
  }

  .lg\:max-w-xs {
    max-width: 20rem
  }

  .lg\:max-w-sm {
    max-width: 24rem
  }

  .lg\:max-w-md {
    max-width: 28rem
  }

  .lg\:max-w-lg {
    max-width: 32rem
  }

  .lg\:max-w-xl {
    max-width: 36rem
  }

  .lg\:max-w-2xl {
    max-width: 42rem
  }

  .lg\:max-w-3xl {
    max-width: 48rem
  }

  .lg\:max-w-4xl {
    max-width: 56rem
  }

  .lg\:max-w-5xl {
    max-width: 64rem
  }

  .lg\:max-w-6xl {
    max-width: 72rem
  }

  .lg\:max-w-full {
    max-width: 100%
  }

  .lg\:max-w-screen-sm {
    max-width: 640px
  }

  .lg\:max-w-screen-md {
    max-width: 768px
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px
  }

  .lg\:min-h-0 {
    min-height: 0
  }

  .lg\:min-h-full {
    min-height: 100%
  }

  .lg\:min-h-screen {
    min-height: 100vh
  }

  .lg\:min-w-0 {
    min-width: 0
  }

  .lg\:min-w-full {
    min-width: 100%
  }

  .lg\:object-contain {
    object-fit: contain
  }

  .lg\:object-cover {
    object-fit: cover
  }

  .lg\:object-fill {
    object-fit: fill
  }

  .lg\:object-none {
    object-fit: none
  }

  .lg\:object-scale-down {
    object-fit: scale-down
  }

  .lg\:object-bottom {
    object-position: bottom
  }

  .lg\:object-center {
    object-position: center
  }

  .lg\:object-left {
    object-position: left
  }

  .lg\:object-left-bottom {
    object-position: left bottom
  }

  .lg\:object-left-top {
    object-position: left top
  }

  .lg\:object-right {
    object-position: right
  }

  .lg\:object-right-bottom {
    object-position: right bottom
  }

  .lg\:object-right-top {
    object-position: right top
  }

  .lg\:object-top {
    object-position: top
  }

  .lg\:opacity-0 {
    opacity: 0
  }

  .lg\:opacity-25 {
    opacity: 0.25
  }

  .lg\:opacity-50 {
    opacity: 0.5
  }

  .lg\:opacity-75 {
    opacity: 0.75
  }

  .lg\:opacity-100 {
    opacity: 1
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1
  }

  .lg\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:overflow-auto {
    overflow: auto
  }

  .lg\:overflow-hidden {
    overflow: hidden
  }

  .lg\:overflow-visible {
    overflow: visible
  }

  .lg\:overflow-scroll {
    overflow: scroll
  }

  .lg\:overflow-x-auto {
    overflow-x: auto
  }

  .lg\:overflow-y-auto {
    overflow-y: auto
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden
  }

  .lg\:overflow-x-visible {
    overflow-x: visible
  }

  .lg\:overflow-y-visible {
    overflow-y: visible
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .lg\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .lg\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .lg\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .lg\:p-0 {
    padding: 0
  }

  .lg\:p-1 {
    padding: 0.25rem
  }

  .lg\:p-2 {
    padding: 0.5rem
  }

  .lg\:p-3 {
    padding: 0.75rem
  }

  .lg\:p-4 {
    padding: 1rem
  }

  .lg\:p-5 {
    padding: 1.25rem
  }

  .lg\:p-6 {
    padding: 1.5rem
  }

  .lg\:p-8 {
    padding: 2rem
  }

  .lg\:p-10 {
    padding: 2.5rem
  }

  .lg\:p-12 {
    padding: 3rem
  }

  .lg\:p-16 {
    padding: 4rem
  }

  .lg\:p-20 {
    padding: 5rem
  }

  .lg\:p-24 {
    padding: 6rem
  }

  .lg\:p-32 {
    padding: 8rem
  }

  .lg\:p-40 {
    padding: 10rem
  }

  .lg\:p-48 {
    padding: 12rem
  }

  .lg\:p-56 {
    padding: 14rem
  }

  .lg\:p-64 {
    padding: 16rem
  }

  .lg\:p-px {
    padding: 1px
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }

  .lg\:pt-1 {
    padding-top: 0.25rem
  }

  .lg\:pr-1 {
    padding-right: 0.25rem
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem
  }

  .lg\:pl-1 {
    padding-left: 0.25rem
  }

  .lg\:pt-2 {
    padding-top: 0.5rem
  }

  .lg\:pr-2 {
    padding-right: 0.5rem
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem
  }

  .lg\:pl-2 {
    padding-left: 0.5rem
  }

  .lg\:pt-3 {
    padding-top: 0.75rem
  }

  .lg\:pr-3 {
    padding-right: 0.75rem
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem
  }

  .lg\:pl-3 {
    padding-left: 0.75rem
  }

  .lg\:pt-4 {
    padding-top: 1rem
  }

  .lg\:pr-4 {
    padding-right: 1rem
  }

  .lg\:pb-4 {
    padding-bottom: 1rem
  }

  .lg\:pl-4 {
    padding-left: 1rem
  }

  .lg\:pt-5 {
    padding-top: 1.25rem
  }

  .lg\:pr-5 {
    padding-right: 1.25rem
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem
  }

  .lg\:pl-5 {
    padding-left: 1.25rem
  }

  .lg\:pt-6 {
    padding-top: 1.5rem
  }

  .lg\:pr-6 {
    padding-right: 1.5rem
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem
  }

  .lg\:pl-6 {
    padding-left: 1.5rem
  }

  .lg\:pt-8 {
    padding-top: 2rem
  }

  .lg\:pr-8 {
    padding-right: 2rem
  }

  .lg\:pb-8 {
    padding-bottom: 2rem
  }

  .lg\:pl-8 {
    padding-left: 2rem
  }

  .lg\:pt-10 {
    padding-top: 2.5rem
  }

  .lg\:pr-10 {
    padding-right: 2.5rem
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem
  }

  .lg\:pl-10 {
    padding-left: 2.5rem
  }

  .lg\:pt-12 {
    padding-top: 3rem
  }

  .lg\:pr-12 {
    padding-right: 3rem
  }

  .lg\:pb-12 {
    padding-bottom: 3rem
  }

  .lg\:pl-12 {
    padding-left: 3rem
  }

  .lg\:pt-16 {
    padding-top: 4rem
  }

  .lg\:pr-16 {
    padding-right: 4rem
  }

  .lg\:pb-16 {
    padding-bottom: 4rem
  }

  .lg\:pl-16 {
    padding-left: 4rem
  }

  .lg\:pt-20 {
    padding-top: 5rem
  }

  .lg\:pr-20 {
    padding-right: 5rem
  }

  .lg\:pb-20 {
    padding-bottom: 5rem
  }

  .lg\:pl-20 {
    padding-left: 5rem
  }

  .lg\:pt-24 {
    padding-top: 6rem
  }

  .lg\:pr-24 {
    padding-right: 6rem
  }

  .lg\:pb-24 {
    padding-bottom: 6rem
  }

  .lg\:pl-24 {
    padding-left: 6rem
  }

  .lg\:pt-32 {
    padding-top: 8rem
  }

  .lg\:pr-32 {
    padding-right: 8rem
  }

  .lg\:pb-32 {
    padding-bottom: 8rem
  }

  .lg\:pl-32 {
    padding-left: 8rem
  }

  .lg\:pt-40 {
    padding-top: 10rem
  }

  .lg\:pr-40 {
    padding-right: 10rem
  }

  .lg\:pb-40 {
    padding-bottom: 10rem
  }

  .lg\:pl-40 {
    padding-left: 10rem
  }

  .lg\:pt-48 {
    padding-top: 12rem
  }

  .lg\:pr-48 {
    padding-right: 12rem
  }

  .lg\:pb-48 {
    padding-bottom: 12rem
  }

  .lg\:pl-48 {
    padding-left: 12rem
  }

  .lg\:pt-56 {
    padding-top: 14rem
  }

  .lg\:pr-56 {
    padding-right: 14rem
  }

  .lg\:pb-56 {
    padding-bottom: 14rem
  }

  .lg\:pl-56 {
    padding-left: 14rem
  }

  .lg\:pt-64 {
    padding-top: 16rem
  }

  .lg\:pr-64 {
    padding-right: 16rem
  }

  .lg\:pb-64 {
    padding-bottom: 16rem
  }

  .lg\:pl-64 {
    padding-left: 16rem
  }

  .lg\:pt-px {
    padding-top: 1px
  }

  .lg\:pr-px {
    padding-right: 1px
  }

  .lg\:pb-px {
    padding-bottom: 1px
  }

  .lg\:pl-px {
    padding-left: 1px
  }

  .lg\:placeholder-transparent:-ms-input-placeholder {
    color: transparent
  }

  .lg\:placeholder-transparent::-ms-input-placeholder {
    color: transparent
  }

  .lg\:placeholder-transparent::placeholder {
    color: transparent
  }

  .lg\:placeholder-current:-ms-input-placeholder {
    color: currentColor
  }

  .lg\:placeholder-current::-ms-input-placeholder {
    color: currentColor
  }

  .lg\:placeholder-current::placeholder {
    color: currentColor
  }

  .lg\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent
  }

  .lg\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .lg\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor
  }

  .lg\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor
  }

  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .lg\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .lg\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .lg\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .lg\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .lg\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .lg\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .lg\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .lg\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .lg\:pointer-events-none {
    pointer-events: none
  }

  .lg\:pointer-events-auto {
    pointer-events: auto
  }

  .lg\:static {
    position: static
  }

  .lg\:fixed {
    position: fixed
  }

  .lg\:absolute {
    position: absolute
  }

  .lg\:relative {
    position: relative
  }

  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .lg\:inset-x-0 {
    right: 0;
    left: 0
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .lg\:inset-x-auto {
    right: auto;
    left: auto
  }

  .lg\:top-0 {
    top: 0
  }

  .lg\:right-0 {
    right: 0
  }

  .lg\:bottom-0 {
    bottom: 0
  }

  .lg\:left-0 {
    left: 0
  }

  .lg\:top-auto {
    top: auto
  }

  .lg\:right-auto {
    right: auto
  }

  .lg\:bottom-auto {
    bottom: auto
  }

  .lg\:left-auto {
    left: auto
  }

  .lg\:resize-none {
    resize: none
  }

  .lg\:resize-y {
    resize: vertical
  }

  .lg\:resize-x {
    resize: horizontal
  }

  .lg\:resize {
    resize: both
  }

  .lg\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:shadow-none {
    box-shadow: none
  }

  .lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .lg\:fill-current {
    fill: currentColor
  }

  .lg\:stroke-current {
    stroke: currentColor
  }

  .lg\:stroke-0 {
    stroke-width: 0
  }

  .lg\:stroke-1 {
    stroke-width: 1
  }

  .lg\:stroke-2 {
    stroke-width: 2
  }

  .lg\:table-auto {
    table-layout: auto
  }

  .lg\:table-fixed {
    table-layout: fixed
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-center {
    text-align: center
  }

  .lg\:text-right {
    text-align: right
  }

  .lg\:text-justify {
    text-align: justify
  }

  .lg\:text-transparent {
    color: transparent
  }

  .lg\:text-current {
    color: currentColor
  }

  .lg\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .lg\:text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .lg\:text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .lg\:text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .lg\:text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .lg\:text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .lg\:text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .lg\:text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .lg\:text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .lg\:text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .lg\:text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .lg\:text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .lg\:text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .lg\:text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .lg\:text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .lg\:text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .lg\:text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .lg\:text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .lg\:text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .lg\:text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .lg\:text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .lg\:text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .lg\:text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .lg\:text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .lg\:text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .lg\:text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .lg\:text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .lg\:text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .lg\:text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .lg\:text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .lg\:text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .lg\:text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .lg\:text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .lg\:text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .lg\:text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .lg\:text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .lg\:text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .lg\:text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .lg\:text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .lg\:text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .lg\:text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .lg\:text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .lg\:text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .lg\:text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .lg\:text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .lg\:text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .lg\:text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .lg\:text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .lg\:text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .lg\:text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .lg\:text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .lg\:text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .lg\:text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .lg\:text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .lg\:text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .lg\:text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .lg\:text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .lg\:text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .lg\:text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .lg\:text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .lg\:text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .lg\:text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .lg\:text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .lg\:text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .lg\:text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .lg\:text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .lg\:text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .lg\:text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .lg\:text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .lg\:text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .lg\:text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .lg\:text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .lg\:text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .lg\:text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .lg\:text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .lg\:text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .lg\:text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .lg\:text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .lg\:text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .lg\:text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .lg\:text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .lg\:text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .lg\:text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .lg\:text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .lg\:text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .lg\:text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .lg\:text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .lg\:text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .lg\:text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .lg\:text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent
  }

  .lg\:hover\:text-current:hover {
    color: currentColor
  }

  .lg\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .lg\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .lg\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .lg\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .lg\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .lg\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .lg\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .lg\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .lg\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .lg\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .lg\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .lg\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .lg\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .lg\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .lg\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .lg\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .lg\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .lg\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .lg\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .lg\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .lg\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .lg\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .lg\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .lg\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .lg\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .lg\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .lg\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .lg\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .lg\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .lg\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .lg\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .lg\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .lg\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .lg\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .lg\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .lg\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .lg\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .lg\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .lg\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .lg\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .lg\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .lg\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .lg\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .lg\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .lg\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .lg\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .lg\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .lg\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .lg\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .lg\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .lg\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .lg\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .lg\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .lg\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .lg\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .lg\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .lg\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .lg\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .lg\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .lg\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .lg\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .lg\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .lg\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .lg\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .lg\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .lg\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .lg\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .lg\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .lg\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .lg\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .lg\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .lg\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .lg\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .lg\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent
  }

  .lg\:focus\:text-current:focus {
    color: currentColor
  }

  .lg\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .lg\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .lg\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .lg\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .lg\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .lg\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .lg\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .lg\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .lg\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .lg\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .lg\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .lg\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .lg\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .lg\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .lg\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .lg\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .lg\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .lg\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .lg\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .lg\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .lg\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .lg\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .lg\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .lg\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .lg\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .lg\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .lg\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .lg\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .lg\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .lg\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .lg\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .lg\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .lg\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .lg\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .lg\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .lg\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .lg\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .lg\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .lg\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .lg\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .lg\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .lg\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .lg\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .lg\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .lg\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .lg\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .lg\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .lg\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .lg\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .lg\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .lg\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .lg\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .lg\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .lg\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .lg\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .lg\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .lg\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .lg\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .lg\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .lg\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .lg\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .lg\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .lg\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .lg\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .lg\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .lg\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .lg\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .lg\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .lg\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .lg\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .lg\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .lg\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .lg\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .lg\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .lg\:text-opacity-0 {
    --text-opacity: 0
  }

  .lg\:text-opacity-25 {
    --text-opacity: 0.25
  }

  .lg\:text-opacity-50 {
    --text-opacity: 0.5
  }

  .lg\:text-opacity-75 {
    --text-opacity: 0.75
  }

  .lg\:text-opacity-100 {
    --text-opacity: 1
  }

  .lg\:hover\:text-opacity-0:hover {
    --text-opacity: 0
  }

  .lg\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25
  }

  .lg\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5
  }

  .lg\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75
  }

  .lg\:hover\:text-opacity-100:hover {
    --text-opacity: 1
  }

  .lg\:focus\:text-opacity-0:focus {
    --text-opacity: 0
  }

  .lg\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25
  }

  .lg\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5
  }

  .lg\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75
  }

  .lg\:focus\:text-opacity-100:focus {
    --text-opacity: 1
  }

  .lg\:italic {
    font-style: italic
  }

  .lg\:not-italic {
    font-style: normal
  }

  .lg\:uppercase {
    text-transform: uppercase
  }

  .lg\:lowercase {
    text-transform: lowercase
  }

  .lg\:capitalize {
    text-transform: capitalize
  }

  .lg\:normal-case {
    text-transform: none
  }

  .lg\:underline {
    text-decoration: underline
  }

  .lg\:line-through {
    text-decoration: line-through
  }

  .lg\:no-underline {
    text-decoration: none
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .lg\:normal-nums {
    font-variant-numeric: normal
  }

  .lg\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .lg\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .lg\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .lg\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .lg\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .lg\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .lg\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .lg\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em
  }

  .lg\:tracking-normal {
    letter-spacing: 0
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em
  }

  .lg\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .lg\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .lg\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .lg\:align-baseline {
    vertical-align: baseline
  }

  .lg\:align-top {
    vertical-align: top
  }

  .lg\:align-middle {
    vertical-align: middle
  }

  .lg\:align-bottom {
    vertical-align: bottom
  }

  .lg\:align-text-top {
    vertical-align: text-top
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom
  }

  .lg\:visible {
    visibility: visible
  }

  .lg\:invisible {
    visibility: hidden
  }

  .lg\:whitespace-normal {
    white-space: normal
  }

  .lg\:whitespace-no-wrap {
    white-space: nowrap
  }

  .lg\:whitespace-pre {
    white-space: pre
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .lg\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .lg\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .lg\:break-all {
    word-break: break-all
  }

  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .lg\:w-0 {
    width: 0
  }

  .lg\:w-1 {
    width: 0.25rem
  }

  .lg\:w-2 {
    width: 0.5rem
  }

  .lg\:w-3 {
    width: 0.75rem
  }

  .lg\:w-4 {
    width: 1rem
  }

  .lg\:w-5 {
    width: 1.25rem
  }

  .lg\:w-6 {
    width: 1.5rem
  }

  .lg\:w-8 {
    width: 2rem
  }

  .lg\:w-10 {
    width: 2.5rem
  }

  .lg\:w-12 {
    width: 3rem
  }

  .lg\:w-16 {
    width: 4rem
  }

  .lg\:w-20 {
    width: 5rem
  }

  .lg\:w-24 {
    width: 6rem
  }

  .lg\:w-32 {
    width: 8rem
  }

  .lg\:w-40 {
    width: 10rem
  }

  .lg\:w-48 {
    width: 12rem
  }

  .lg\:w-56 {
    width: 14rem
  }

  .lg\:w-64 {
    width: 16rem
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-px {
    width: 1px
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-2\/3 {
    width: 66.666667%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-2\/4 {
    width: 50%
  }

  .lg\:w-3\/4 {
    width: 75%
  }

  .lg\:w-1\/5 {
    width: 20%
  }

  .lg\:w-2\/5 {
    width: 40%
  }

  .lg\:w-3\/5 {
    width: 60%
  }

  .lg\:w-4\/5 {
    width: 80%
  }

  .lg\:w-1\/6 {
    width: 16.666667%
  }

  .lg\:w-2\/6 {
    width: 33.333333%
  }

  .lg\:w-3\/6 {
    width: 50%
  }

  .lg\:w-4\/6 {
    width: 66.666667%
  }

  .lg\:w-5\/6 {
    width: 83.333333%
  }

  .lg\:w-1\/12 {
    width: 8.333333%
  }

  .lg\:w-2\/12 {
    width: 16.666667%
  }

  .lg\:w-3\/12 {
    width: 25%
  }

  .lg\:w-4\/12 {
    width: 33.333333%
  }

  .lg\:w-5\/12 {
    width: 41.666667%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-7\/12 {
    width: 58.333333%
  }

  .lg\:w-8\/12 {
    width: 66.666667%
  }

  .lg\:w-9\/12 {
    width: 75%
  }

  .lg\:w-10\/12 {
    width: 83.333333%
  }

  .lg\:w-11\/12 {
    width: 91.666667%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:w-screen {
    width: 100vw
  }

  .lg\:z-0 {
    z-index: 0
  }

  .lg\:z-10 {
    z-index: 10
  }

  .lg\:z-20 {
    z-index: 20
  }

  .lg\:z-30 {
    z-index: 30
  }

  .lg\:z-40 {
    z-index: 40
  }

  .lg\:z-50 {
    z-index: 50
  }

  .lg\:z-auto {
    z-index: auto
  }

  .lg\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .lg\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .lg\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .lg\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .lg\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .lg\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .lg\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .lg\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .lg\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .lg\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .lg\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .lg\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .lg\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .lg\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .lg\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .lg\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .lg\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .lg\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .lg\:col-gap-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .lg\:col-gap-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .lg\:col-gap-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .lg\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .lg\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .lg\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .lg\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .lg\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .lg\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .lg\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .lg\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .lg\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .lg\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .lg\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .lg\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .lg\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .lg\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .lg\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .lg\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .lg\:gap-x-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .lg\:gap-x-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .lg\:gap-x-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .lg\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .lg\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .lg\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .lg\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .lg\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .lg\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .lg\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .lg\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .lg\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .lg\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .lg\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .lg\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .lg\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .lg\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .lg\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .lg\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .lg\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .lg\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .lg\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .lg\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .lg\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .lg\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .lg\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .lg\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .lg\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .lg\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .lg\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .lg\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .lg\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .lg\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .lg\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .lg\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .lg\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .lg\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .lg\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .lg\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .lg\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .lg\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .lg\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .lg\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .lg\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .lg\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .lg\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .lg\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .lg\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .lg\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .lg\:grid-cols-none {
    grid-template-columns: none
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .lg\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .lg\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .lg\:col-auto {
    grid-column: auto
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .lg\:col-span-full {
    grid-column: 1 / -1
  }

  .lg\:col-start-1 {
    grid-column-start: 1
  }

  .lg\:col-start-2 {
    grid-column-start: 2
  }

  .lg\:col-start-3 {
    grid-column-start: 3
  }

  .lg\:col-start-4 {
    grid-column-start: 4
  }

  .lg\:col-start-5 {
    grid-column-start: 5
  }

  .lg\:col-start-6 {
    grid-column-start: 6
  }

  .lg\:col-start-7 {
    grid-column-start: 7
  }

  .lg\:col-start-8 {
    grid-column-start: 8
  }

  .lg\:col-start-9 {
    grid-column-start: 9
  }

  .lg\:col-start-10 {
    grid-column-start: 10
  }

  .lg\:col-start-11 {
    grid-column-start: 11
  }

  .lg\:col-start-12 {
    grid-column-start: 12
  }

  .lg\:col-start-13 {
    grid-column-start: 13
  }

  .lg\:col-start-auto {
    grid-column-start: auto
  }

  .lg\:col-end-1 {
    grid-column-end: 1
  }

  .lg\:col-end-2 {
    grid-column-end: 2
  }

  .lg\:col-end-3 {
    grid-column-end: 3
  }

  .lg\:col-end-4 {
    grid-column-end: 4
  }

  .lg\:col-end-5 {
    grid-column-end: 5
  }

  .lg\:col-end-6 {
    grid-column-end: 6
  }

  .lg\:col-end-7 {
    grid-column-end: 7
  }

  .lg\:col-end-8 {
    grid-column-end: 8
  }

  .lg\:col-end-9 {
    grid-column-end: 9
  }

  .lg\:col-end-10 {
    grid-column-end: 10
  }

  .lg\:col-end-11 {
    grid-column-end: 11
  }

  .lg\:col-end-12 {
    grid-column-end: 12
  }

  .lg\:col-end-13 {
    grid-column-end: 13
  }

  .lg\:col-end-auto {
    grid-column-end: auto
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-rows-none {
    grid-template-rows: none
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .lg\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .lg\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .lg\:row-auto {
    grid-row: auto
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .lg\:row-span-full {
    grid-row: 1 / -1
  }

  .lg\:row-start-1 {
    grid-row-start: 1
  }

  .lg\:row-start-2 {
    grid-row-start: 2
  }

  .lg\:row-start-3 {
    grid-row-start: 3
  }

  .lg\:row-start-4 {
    grid-row-start: 4
  }

  .lg\:row-start-5 {
    grid-row-start: 5
  }

  .lg\:row-start-6 {
    grid-row-start: 6
  }

  .lg\:row-start-7 {
    grid-row-start: 7
  }

  .lg\:row-start-auto {
    grid-row-start: auto
  }

  .lg\:row-end-1 {
    grid-row-end: 1
  }

  .lg\:row-end-2 {
    grid-row-end: 2
  }

  .lg\:row-end-3 {
    grid-row-end: 3
  }

  .lg\:row-end-4 {
    grid-row-end: 4
  }

  .lg\:row-end-5 {
    grid-row-end: 5
  }

  .lg\:row-end-6 {
    grid-row-end: 6
  }

  .lg\:row-end-7 {
    grid-row-end: 7
  }

  .lg\:row-end-auto {
    grid-row-end: auto
  }

  .lg\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .lg\:transform-none {
    transform: none
  }

  .lg\:origin-center {
    transform-origin: center
  }

  .lg\:origin-top {
    transform-origin: top
  }

  .lg\:origin-top-right {
    transform-origin: top right
  }

  .lg\:origin-right {
    transform-origin: right
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right
  }

  .lg\:origin-bottom {
    transform-origin: bottom
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left
  }

  .lg\:origin-left {
    transform-origin: left
  }

  .lg\:origin-top-left {
    transform-origin: top left
  }

  .lg\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:scale-x-0 {
    --transform-scale-x: 0
  }

  .lg\:scale-x-50 {
    --transform-scale-x: .5
  }

  .lg\:scale-x-75 {
    --transform-scale-x: .75
  }

  .lg\:scale-x-90 {
    --transform-scale-x: .9
  }

  .lg\:scale-x-95 {
    --transform-scale-x: .95
  }

  .lg\:scale-x-100 {
    --transform-scale-x: 1
  }

  .lg\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .lg\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .lg\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .lg\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .lg\:scale-y-0 {
    --transform-scale-y: 0
  }

  .lg\:scale-y-50 {
    --transform-scale-y: .5
  }

  .lg\:scale-y-75 {
    --transform-scale-y: .75
  }

  .lg\:scale-y-90 {
    --transform-scale-y: .9
  }

  .lg\:scale-y-95 {
    --transform-scale-y: .95
  }

  .lg\:scale-y-100 {
    --transform-scale-y: 1
  }

  .lg\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .lg\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .lg\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .lg\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .lg\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .lg\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .lg\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .lg\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .lg\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .lg\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .lg\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .lg\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .lg\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .lg\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .lg\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .lg\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .lg\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .lg\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .lg\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .lg\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .lg\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .lg\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .lg\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .lg\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .lg\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .lg\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .lg\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .lg\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .lg\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .lg\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .lg\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .lg\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .lg\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .lg\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .lg\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .lg\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .lg\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .lg\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .lg\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .lg\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .lg\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .lg\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .lg\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .lg\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .lg\:rotate-0 {
    --transform-rotate: 0
  }

  .lg\:rotate-1 {
    --transform-rotate: 1deg
  }

  .lg\:rotate-2 {
    --transform-rotate: 2deg
  }

  .lg\:rotate-3 {
    --transform-rotate: 3deg
  }

  .lg\:rotate-6 {
    --transform-rotate: 6deg
  }

  .lg\:rotate-12 {
    --transform-rotate: 12deg
  }

  .lg\:rotate-45 {
    --transform-rotate: 45deg
  }

  .lg\:rotate-90 {
    --transform-rotate: 90deg
  }

  .lg\:rotate-180 {
    --transform-rotate: 180deg
  }

  .lg\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .lg\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .lg\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .lg\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .lg\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .lg\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .lg\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .lg\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .lg\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .lg\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .lg\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .lg\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .lg\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .lg\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .lg\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .lg\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .lg\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .lg\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .lg\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .lg\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .lg\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .lg\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .lg\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .lg\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .lg\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .lg\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .lg\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .lg\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .lg\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .lg\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .lg\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .lg\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .lg\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .lg\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .lg\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .lg\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .lg\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .lg\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .lg\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .lg\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .lg\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .lg\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .lg\:translate-x-0 {
    --transform-translate-x: 0
  }

  .lg\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .lg\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .lg\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .lg\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .lg\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .lg\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .lg\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .lg\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .lg\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .lg\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .lg\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .lg\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .lg\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .lg\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .lg\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .lg\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .lg\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .lg\:translate-x-px {
    --transform-translate-x: 1px
  }

  .lg\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .lg\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .lg\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .lg\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .lg\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .lg\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .lg\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .lg\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .lg\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .lg\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .lg\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .lg\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .lg\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .lg\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .lg\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .lg\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .lg\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .lg\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .lg\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .lg\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .lg\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .lg\:translate-x-full {
    --transform-translate-x: 100%
  }

  .lg\:translate-y-0 {
    --transform-translate-y: 0
  }

  .lg\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .lg\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .lg\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .lg\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .lg\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .lg\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .lg\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .lg\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .lg\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .lg\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .lg\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .lg\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .lg\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .lg\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .lg\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .lg\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .lg\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .lg\:translate-y-px {
    --transform-translate-y: 1px
  }

  .lg\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .lg\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .lg\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .lg\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .lg\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .lg\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .lg\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .lg\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .lg\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .lg\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .lg\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .lg\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .lg\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .lg\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .lg\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .lg\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .lg\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .lg\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .lg\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .lg\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .lg\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .lg\:translate-y-full {
    --transform-translate-y: 100%
  }

  .lg\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .lg\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .lg\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .lg\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .lg\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .lg\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .lg\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .lg\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .lg\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .lg\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .lg\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .lg\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .lg\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .lg\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .lg\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .lg\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .lg\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .lg\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .lg\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .lg\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .lg\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .lg\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .lg\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .lg\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .lg\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .lg\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .lg\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .lg\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .lg\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .lg\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .lg\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .lg\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .lg\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .lg\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .lg\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .lg\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .lg\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .lg\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .lg\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .lg\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .lg\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .lg\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .lg\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .lg\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .lg\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .lg\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .lg\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .lg\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .lg\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .lg\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .lg\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .lg\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .lg\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .lg\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .lg\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .lg\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .lg\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .lg\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .lg\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .lg\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .lg\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .lg\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .lg\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .lg\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .lg\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .lg\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .lg\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .lg\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .lg\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .lg\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .lg\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .lg\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .lg\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .lg\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .lg\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .lg\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .lg\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .lg\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .lg\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .lg\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .lg\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .lg\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .lg\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .lg\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .lg\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .lg\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .lg\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .lg\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .lg\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .lg\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .lg\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .lg\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .lg\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .lg\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .lg\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .lg\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .lg\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .lg\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .lg\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .lg\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .lg\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .lg\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .lg\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .lg\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .lg\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .lg\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .lg\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .lg\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .lg\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .lg\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .lg\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .lg\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .lg\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .lg\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .lg\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .lg\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .lg\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .lg\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .lg\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .lg\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .lg\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .lg\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .lg\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .lg\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .lg\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .lg\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .lg\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .lg\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .lg\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .lg\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .lg\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .lg\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .lg\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .lg\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .lg\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .lg\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .lg\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .lg\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .lg\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .lg\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .lg\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .lg\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .lg\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .lg\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .lg\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .lg\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .lg\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .lg\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .lg\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .lg\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .lg\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .lg\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .lg\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .lg\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .lg\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .lg\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .lg\:skew-x-0 {
    --transform-skew-x: 0
  }

  .lg\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .lg\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .lg\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .lg\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .lg\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .lg\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .lg\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .lg\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .lg\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .lg\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .lg\:skew-y-0 {
    --transform-skew-y: 0
  }

  .lg\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .lg\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .lg\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .lg\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .lg\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .lg\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .lg\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .lg\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .lg\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .lg\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .lg\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .lg\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .lg\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .lg\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .lg\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .lg\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .lg\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .lg\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .lg\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .lg\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .lg\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .lg\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .lg\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .lg\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .lg\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .lg\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .lg\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .lg\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .lg\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .lg\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .lg\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .lg\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .lg\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .lg\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .lg\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .lg\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .lg\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .lg\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .lg\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .lg\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .lg\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .lg\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .lg\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .lg\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .lg\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .lg\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .lg\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .lg\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .lg\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .lg\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .lg\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .lg\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .lg\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .lg\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .lg\:transition-none {
    transition-property: none
  }

  .lg\:transition-all {
    transition-property: all
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .lg\:transition-opacity {
    transition-property: opacity
  }

  .lg\:transition-shadow {
    transition-property: box-shadow
  }

  .lg\:transition-transform {
    transition-property: transform
  }

  .lg\:ease-linear {
    transition-timing-function: linear
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .lg\:duration-75 {
    transition-duration: 75ms
  }

  .lg\:duration-100 {
    transition-duration: 100ms
  }

  .lg\:duration-150 {
    transition-duration: 150ms
  }

  .lg\:duration-200 {
    transition-duration: 200ms
  }

  .lg\:duration-300 {
    transition-duration: 300ms
  }

  .lg\:duration-500 {
    transition-duration: 500ms
  }

  .lg\:duration-700 {
    transition-duration: 700ms
  }

  .lg\:duration-1000 {
    transition-duration: 1000ms
  }

  .lg\:delay-75 {
    transition-delay: 75ms
  }

  .lg\:delay-100 {
    transition-delay: 100ms
  }

  .lg\:delay-150 {
    transition-delay: 150ms
  }

  .lg\:delay-200 {
    transition-delay: 200ms
  }

  .lg\:delay-300 {
    transition-delay: 300ms
  }

  .lg\:delay-500 {
    transition-delay: 500ms
  }

  .lg\:delay-700 {
    transition-delay: 700ms
  }

  .lg\:delay-1000 {
    transition-delay: 1000ms
  }

  .lg\:animate-none {
    animation: none
  }

  .lg\:animate-spin {
    animation: spin 1s linear infinite
  }

  .lg\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .lg\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .lg\:animate-bounce {
    animation: bounce 1s infinite
  }
}

@media (min-width: 1280px) {
  .xl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .xl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .xl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .xl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .xl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .xl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .xl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .xl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .xl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .xl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .xl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .xl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .xl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .xl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .xl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .xl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .xl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .xl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .xl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .xl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .xl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .xl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .xl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .xl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .xl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .xl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .xl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .xl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .xl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .xl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .xl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .xl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .xl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .xl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .xl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .xl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .xl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .xl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .xl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .xl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .xl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .xl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .xl\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent
  }

  .xl\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor
  }

  .xl\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .xl\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .xl\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .xl\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .xl\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .xl\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .xl\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .xl\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .xl\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .xl\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .xl\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .xl\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .xl\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .xl\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .xl\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .xl\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .xl\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .xl\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .xl\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .xl\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .xl\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .xl\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .xl\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .xl\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .xl\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .xl\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .xl\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .xl\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .xl\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .xl\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .xl\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .xl\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .xl\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .xl\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .xl\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .xl\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .xl\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .xl\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .xl\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .xl\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .xl\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .xl\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .xl\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .xl\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .xl\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .xl\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .xl\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .xl\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .xl\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .xl\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .xl\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .xl\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .xl\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .xl\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .xl\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .xl\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .xl\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .xl\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .xl\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .xl\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .xl\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .xl\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .xl\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .xl\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .xl\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .xl\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .xl\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .xl\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .xl\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .xl\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .xl\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .xl\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .xl\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .xl\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .xl\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .xl\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .xl\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .xl\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .xl\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .xl\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .xl\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .xl\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .xl\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .xl\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .xl\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .xl\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .xl\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .xl\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .xl\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .xl\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .xl\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .xl\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .xl\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .xl\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .xl\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .xl\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .xl\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .xl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .xl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .xl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .xl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .xl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .xl\:bg-fixed {
    background-attachment: fixed
  }

  .xl\:bg-local {
    background-attachment: local
  }

  .xl\:bg-scroll {
    background-attachment: scroll
  }

  .xl\:bg-clip-border {
    background-clip: border-box
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box
  }

  .xl\:bg-clip-content {
    background-clip: content-box
  }

  .xl\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .xl\:bg-transparent {
    background-color: transparent
  }

  .xl\:bg-current {
    background-color: currentColor
  }

  .xl\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .xl\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .xl\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .xl\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .xl\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .xl\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .xl\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .xl\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .xl\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .xl\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .xl\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .xl\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .xl\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .xl\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .xl\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .xl\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .xl\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .xl\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .xl\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .xl\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .xl\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .xl\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .xl\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .xl\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .xl\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .xl\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .xl\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .xl\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .xl\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .xl\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .xl\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .xl\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .xl\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .xl\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .xl\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .xl\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .xl\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .xl\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .xl\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .xl\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .xl\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .xl\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .xl\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .xl\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .xl\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .xl\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .xl\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .xl\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .xl\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .xl\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .xl\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .xl\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .xl\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .xl\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .xl\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .xl\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .xl\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .xl\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .xl\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .xl\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .xl\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .xl\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .xl\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .xl\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .xl\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .xl\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .xl\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .xl\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .xl\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .xl\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .xl\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .xl\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .xl\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .xl\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .xl\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .xl\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .xl\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .xl\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .xl\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .xl\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .xl\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .xl\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .xl\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .xl\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .xl\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .xl\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .xl\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .xl\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .xl\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .xl\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .xl\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .xl\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .xl\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .xl\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .xl\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .xl\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .xl\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .xl\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .xl\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .xl\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .xl\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .xl\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .xl\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .xl\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .xl\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .xl\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .xl\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .xl\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .xl\:bg-none {
    background-image: none
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xl\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:to-transparent {
    --gradient-to-color: transparent
  }

  .xl\:to-current {
    --gradient-to-color: currentColor
  }

  .xl\:to-black {
    --gradient-to-color: #000
  }

  .xl\:to-white {
    --gradient-to-color: #fff
  }

  .xl\:to-gray-100 {
    --gradient-to-color: #f7fafc
  }

  .xl\:to-gray-200 {
    --gradient-to-color: #edf2f7
  }

  .xl\:to-gray-300 {
    --gradient-to-color: #e2e8f0
  }

  .xl\:to-gray-400 {
    --gradient-to-color: #cbd5e0
  }

  .xl\:to-gray-500 {
    --gradient-to-color: #a0aec0
  }

  .xl\:to-gray-600 {
    --gradient-to-color: #718096
  }

  .xl\:to-gray-700 {
    --gradient-to-color: #4a5568
  }

  .xl\:to-gray-800 {
    --gradient-to-color: #2d3748
  }

  .xl\:to-gray-900 {
    --gradient-to-color: #1a202c
  }

  .xl\:to-red-100 {
    --gradient-to-color: #fff5f5
  }

  .xl\:to-red-200 {
    --gradient-to-color: #fed7d7
  }

  .xl\:to-red-300 {
    --gradient-to-color: #feb2b2
  }

  .xl\:to-red-400 {
    --gradient-to-color: #fc8181
  }

  .xl\:to-red-500 {
    --gradient-to-color: #f56565
  }

  .xl\:to-red-600 {
    --gradient-to-color: #e53e3e
  }

  .xl\:to-red-700 {
    --gradient-to-color: #c53030
  }

  .xl\:to-red-800 {
    --gradient-to-color: #9b2c2c
  }

  .xl\:to-red-900 {
    --gradient-to-color: #742a2a
  }

  .xl\:to-orange-100 {
    --gradient-to-color: #fffaf0
  }

  .xl\:to-orange-200 {
    --gradient-to-color: #feebc8
  }

  .xl\:to-orange-300 {
    --gradient-to-color: #fbd38d
  }

  .xl\:to-orange-400 {
    --gradient-to-color: #f6ad55
  }

  .xl\:to-orange-500 {
    --gradient-to-color: #ed8936
  }

  .xl\:to-orange-600 {
    --gradient-to-color: #dd6b20
  }

  .xl\:to-orange-700 {
    --gradient-to-color: #c05621
  }

  .xl\:to-orange-800 {
    --gradient-to-color: #9c4221
  }

  .xl\:to-orange-900 {
    --gradient-to-color: #7b341e
  }

  .xl\:to-yellow-100 {
    --gradient-to-color: #fffff0
  }

  .xl\:to-yellow-200 {
    --gradient-to-color: #fefcbf
  }

  .xl\:to-yellow-300 {
    --gradient-to-color: #faf089
  }

  .xl\:to-yellow-400 {
    --gradient-to-color: #f6e05e
  }

  .xl\:to-yellow-500 {
    --gradient-to-color: #ecc94b
  }

  .xl\:to-yellow-600 {
    --gradient-to-color: #d69e2e
  }

  .xl\:to-yellow-700 {
    --gradient-to-color: #b7791f
  }

  .xl\:to-yellow-800 {
    --gradient-to-color: #975a16
  }

  .xl\:to-yellow-900 {
    --gradient-to-color: #744210
  }

  .xl\:to-green-100 {
    --gradient-to-color: #f0fff4
  }

  .xl\:to-green-200 {
    --gradient-to-color: #c6f6d5
  }

  .xl\:to-green-300 {
    --gradient-to-color: #9ae6b4
  }

  .xl\:to-green-400 {
    --gradient-to-color: #68d391
  }

  .xl\:to-green-500 {
    --gradient-to-color: #48bb78
  }

  .xl\:to-green-600 {
    --gradient-to-color: #38a169
  }

  .xl\:to-green-700 {
    --gradient-to-color: #2f855a
  }

  .xl\:to-green-800 {
    --gradient-to-color: #276749
  }

  .xl\:to-green-900 {
    --gradient-to-color: #22543d
  }

  .xl\:to-teal-100 {
    --gradient-to-color: #e6fffa
  }

  .xl\:to-teal-200 {
    --gradient-to-color: #b2f5ea
  }

  .xl\:to-teal-300 {
    --gradient-to-color: #81e6d9
  }

  .xl\:to-teal-400 {
    --gradient-to-color: #4fd1c5
  }

  .xl\:to-teal-500 {
    --gradient-to-color: #38b2ac
  }

  .xl\:to-teal-600 {
    --gradient-to-color: #319795
  }

  .xl\:to-teal-700 {
    --gradient-to-color: #2c7a7b
  }

  .xl\:to-teal-800 {
    --gradient-to-color: #285e61
  }

  .xl\:to-teal-900 {
    --gradient-to-color: #234e52
  }

  .xl\:to-blue-100 {
    --gradient-to-color: #ebf8ff
  }

  .xl\:to-blue-200 {
    --gradient-to-color: #bee3f8
  }

  .xl\:to-blue-300 {
    --gradient-to-color: #90cdf4
  }

  .xl\:to-blue-400 {
    --gradient-to-color: #63b3ed
  }

  .xl\:to-blue-500 {
    --gradient-to-color: #4299e1
  }

  .xl\:to-blue-600 {
    --gradient-to-color: #3182ce
  }

  .xl\:to-blue-700 {
    --gradient-to-color: #2b6cb0
  }

  .xl\:to-blue-800 {
    --gradient-to-color: #2c5282
  }

  .xl\:to-blue-900 {
    --gradient-to-color: #2a4365
  }

  .xl\:to-indigo-100 {
    --gradient-to-color: #ebf4ff
  }

  .xl\:to-indigo-200 {
    --gradient-to-color: #c3dafe
  }

  .xl\:to-indigo-300 {
    --gradient-to-color: #a3bffa
  }

  .xl\:to-indigo-400 {
    --gradient-to-color: #7f9cf5
  }

  .xl\:to-indigo-500 {
    --gradient-to-color: #667eea
  }

  .xl\:to-indigo-600 {
    --gradient-to-color: #5a67d8
  }

  .xl\:to-indigo-700 {
    --gradient-to-color: #4c51bf
  }

  .xl\:to-indigo-800 {
    --gradient-to-color: #434190
  }

  .xl\:to-indigo-900 {
    --gradient-to-color: #3c366b
  }

  .xl\:to-purple-100 {
    --gradient-to-color: #faf5ff
  }

  .xl\:to-purple-200 {
    --gradient-to-color: #e9d8fd
  }

  .xl\:to-purple-300 {
    --gradient-to-color: #d6bcfa
  }

  .xl\:to-purple-400 {
    --gradient-to-color: #b794f4
  }

  .xl\:to-purple-500 {
    --gradient-to-color: #9f7aea
  }

  .xl\:to-purple-600 {
    --gradient-to-color: #805ad5
  }

  .xl\:to-purple-700 {
    --gradient-to-color: #6b46c1
  }

  .xl\:to-purple-800 {
    --gradient-to-color: #553c9a
  }

  .xl\:to-purple-900 {
    --gradient-to-color: #44337a
  }

  .xl\:to-pink-100 {
    --gradient-to-color: #fff5f7
  }

  .xl\:to-pink-200 {
    --gradient-to-color: #fed7e2
  }

  .xl\:to-pink-300 {
    --gradient-to-color: #fbb6ce
  }

  .xl\:to-pink-400 {
    --gradient-to-color: #f687b3
  }

  .xl\:to-pink-500 {
    --gradient-to-color: #ed64a6
  }

  .xl\:to-pink-600 {
    --gradient-to-color: #d53f8c
  }

  .xl\:to-pink-700 {
    --gradient-to-color: #b83280
  }

  .xl\:to-pink-800 {
    --gradient-to-color: #97266d
  }

  .xl\:to-pink-900 {
    --gradient-to-color: #702459
  }

  .xl\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:hover\:from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:hover\:via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:hover\:to-transparent:hover {
    --gradient-to-color: transparent
  }

  .xl\:hover\:to-current:hover {
    --gradient-to-color: currentColor
  }

  .xl\:hover\:to-black:hover {
    --gradient-to-color: #000
  }

  .xl\:hover\:to-white:hover {
    --gradient-to-color: #fff
  }

  .xl\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc
  }

  .xl\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7
  }

  .xl\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0
  }

  .xl\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0
  }

  .xl\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0
  }

  .xl\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096
  }

  .xl\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568
  }

  .xl\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748
  }

  .xl\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c
  }

  .xl\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5
  }

  .xl\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7
  }

  .xl\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2
  }

  .xl\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181
  }

  .xl\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565
  }

  .xl\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e
  }

  .xl\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030
  }

  .xl\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c
  }

  .xl\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a
  }

  .xl\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0
  }

  .xl\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8
  }

  .xl\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d
  }

  .xl\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55
  }

  .xl\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936
  }

  .xl\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20
  }

  .xl\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621
  }

  .xl\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221
  }

  .xl\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e
  }

  .xl\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0
  }

  .xl\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf
  }

  .xl\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089
  }

  .xl\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e
  }

  .xl\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b
  }

  .xl\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e
  }

  .xl\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f
  }

  .xl\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16
  }

  .xl\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210
  }

  .xl\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4
  }

  .xl\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5
  }

  .xl\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4
  }

  .xl\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391
  }

  .xl\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78
  }

  .xl\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169
  }

  .xl\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a
  }

  .xl\:hover\:to-green-800:hover {
    --gradient-to-color: #276749
  }

  .xl\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d
  }

  .xl\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa
  }

  .xl\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea
  }

  .xl\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9
  }

  .xl\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5
  }

  .xl\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac
  }

  .xl\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795
  }

  .xl\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b
  }

  .xl\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61
  }

  .xl\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52
  }

  .xl\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff
  }

  .xl\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8
  }

  .xl\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4
  }

  .xl\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed
  }

  .xl\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1
  }

  .xl\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce
  }

  .xl\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0
  }

  .xl\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282
  }

  .xl\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365
  }

  .xl\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff
  }

  .xl\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe
  }

  .xl\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa
  }

  .xl\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5
  }

  .xl\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea
  }

  .xl\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8
  }

  .xl\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf
  }

  .xl\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190
  }

  .xl\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b
  }

  .xl\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff
  }

  .xl\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd
  }

  .xl\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa
  }

  .xl\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4
  }

  .xl\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea
  }

  .xl\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5
  }

  .xl\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1
  }

  .xl\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a
  }

  .xl\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a
  }

  .xl\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7
  }

  .xl\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2
  }

  .xl\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce
  }

  .xl\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3
  }

  .xl\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6
  }

  .xl\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c
  }

  .xl\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280
  }

  .xl\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d
  }

  .xl\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459
  }

  .xl\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:focus\:from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:focus\:via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:focus\:to-transparent:focus {
    --gradient-to-color: transparent
  }

  .xl\:focus\:to-current:focus {
    --gradient-to-color: currentColor
  }

  .xl\:focus\:to-black:focus {
    --gradient-to-color: #000
  }

  .xl\:focus\:to-white:focus {
    --gradient-to-color: #fff
  }

  .xl\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc
  }

  .xl\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7
  }

  .xl\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0
  }

  .xl\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0
  }

  .xl\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0
  }

  .xl\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096
  }

  .xl\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568
  }

  .xl\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748
  }

  .xl\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c
  }

  .xl\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5
  }

  .xl\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7
  }

  .xl\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2
  }

  .xl\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181
  }

  .xl\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565
  }

  .xl\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e
  }

  .xl\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030
  }

  .xl\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c
  }

  .xl\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a
  }

  .xl\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0
  }

  .xl\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8
  }

  .xl\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d
  }

  .xl\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55
  }

  .xl\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936
  }

  .xl\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20
  }

  .xl\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621
  }

  .xl\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221
  }

  .xl\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e
  }

  .xl\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0
  }

  .xl\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf
  }

  .xl\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089
  }

  .xl\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e
  }

  .xl\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b
  }

  .xl\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e
  }

  .xl\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f
  }

  .xl\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16
  }

  .xl\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210
  }

  .xl\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4
  }

  .xl\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5
  }

  .xl\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4
  }

  .xl\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391
  }

  .xl\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78
  }

  .xl\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169
  }

  .xl\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a
  }

  .xl\:focus\:to-green-800:focus {
    --gradient-to-color: #276749
  }

  .xl\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d
  }

  .xl\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa
  }

  .xl\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea
  }

  .xl\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9
  }

  .xl\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5
  }

  .xl\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac
  }

  .xl\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795
  }

  .xl\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b
  }

  .xl\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61
  }

  .xl\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52
  }

  .xl\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff
  }

  .xl\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8
  }

  .xl\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4
  }

  .xl\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed
  }

  .xl\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1
  }

  .xl\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce
  }

  .xl\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0
  }

  .xl\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282
  }

  .xl\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365
  }

  .xl\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff
  }

  .xl\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe
  }

  .xl\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa
  }

  .xl\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5
  }

  .xl\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea
  }

  .xl\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8
  }

  .xl\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf
  }

  .xl\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190
  }

  .xl\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b
  }

  .xl\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff
  }

  .xl\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd
  }

  .xl\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa
  }

  .xl\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4
  }

  .xl\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea
  }

  .xl\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5
  }

  .xl\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1
  }

  .xl\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a
  }

  .xl\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a
  }

  .xl\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7
  }

  .xl\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2
  }

  .xl\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce
  }

  .xl\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3
  }

  .xl\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6
  }

  .xl\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c
  }

  .xl\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280
  }

  .xl\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d
  }

  .xl\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459
  }

  .xl\:bg-opacity-0 {
    --bg-opacity: 0
  }

  .xl\:bg-opacity-25 {
    --bg-opacity: 0.25
  }

  .xl\:bg-opacity-50 {
    --bg-opacity: 0.5
  }

  .xl\:bg-opacity-75 {
    --bg-opacity: 0.75
  }

  .xl\:bg-opacity-100 {
    --bg-opacity: 1
  }

  .xl\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0
  }

  .xl\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25
  }

  .xl\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5
  }

  .xl\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75
  }

  .xl\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1
  }

  .xl\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0
  }

  .xl\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25
  }

  .xl\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5
  }

  .xl\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75
  }

  .xl\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1
  }

  .xl\:bg-bottom {
    background-position: bottom
  }

  .xl\:bg-center {
    background-position: center
  }

  .xl\:bg-left {
    background-position: left
  }

  .xl\:bg-left-bottom {
    background-position: left bottom
  }

  .xl\:bg-left-top {
    background-position: left top
  }

  .xl\:bg-right {
    background-position: right
  }

  .xl\:bg-right-bottom {
    background-position: right bottom
  }

  .xl\:bg-right-top {
    background-position: right top
  }

  .xl\:bg-top {
    background-position: top
  }

  .xl\:bg-repeat {
    background-repeat: repeat
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .xl\:bg-repeat-round {
    background-repeat: round
  }

  .xl\:bg-repeat-space {
    background-repeat: space
  }

  .xl\:bg-auto {
    background-size: auto
  }

  .xl\:bg-cover {
    background-size: cover
  }

  .xl\:bg-contain {
    background-size: contain
  }

  .xl\:border-collapse {
    border-collapse: collapse
  }

  .xl\:border-separate {
    border-collapse: separate
  }

  .xl\:border-transparent {
    border-color: transparent
  }

  .xl\:border-current {
    border-color: currentColor
  }

  .xl\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .xl\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .xl\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .xl\:border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .xl\:border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .xl\:border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .xl\:border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .xl\:border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .xl\:border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .xl\:border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .xl\:border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .xl\:border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .xl\:border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .xl\:border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .xl\:border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .xl\:border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .xl\:border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .xl\:border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .xl\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .xl\:border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .xl\:border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .xl\:border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .xl\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .xl\:border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .xl\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .xl\:border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .xl\:border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .xl\:border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .xl\:border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .xl\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .xl\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .xl\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .xl\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .xl\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .xl\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .xl\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .xl\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .xl\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .xl\:border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .xl\:border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .xl\:border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .xl\:border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .xl\:border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .xl\:border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .xl\:border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .xl\:border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .xl\:border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .xl\:border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .xl\:border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .xl\:border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .xl\:border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .xl\:border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .xl\:border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .xl\:border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .xl\:border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .xl\:border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .xl\:border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .xl\:border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .xl\:border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .xl\:border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .xl\:border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .xl\:border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .xl\:border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .xl\:border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .xl\:border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .xl\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .xl\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .xl\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .xl\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .xl\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .xl\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .xl\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .xl\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .xl\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .xl\:border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .xl\:border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .xl\:border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .xl\:border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .xl\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .xl\:border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .xl\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .xl\:border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .xl\:border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .xl\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .xl\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .xl\:border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .xl\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .xl\:border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .xl\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .xl\:border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .xl\:border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .xl\:border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .xl\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .xl\:hover\:border-current:hover {
    border-color: currentColor
  }

  .xl\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .xl\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .xl\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .xl\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .xl\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .xl\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .xl\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .xl\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .xl\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .xl\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .xl\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .xl\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .xl\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .xl\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .xl\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .xl\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .xl\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .xl\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .xl\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .xl\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .xl\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .xl\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .xl\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .xl\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .xl\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .xl\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .xl\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .xl\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .xl\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .xl\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .xl\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .xl\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .xl\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .xl\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .xl\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .xl\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .xl\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .xl\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .xl\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .xl\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .xl\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .xl\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .xl\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .xl\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .xl\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .xl\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .xl\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .xl\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .xl\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .xl\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .xl\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .xl\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .xl\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .xl\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .xl\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .xl\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .xl\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .xl\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .xl\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .xl\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .xl\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .xl\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .xl\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .xl\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .xl\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .xl\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .xl\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .xl\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .xl\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .xl\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .xl\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .xl\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .xl\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .xl\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .xl\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .xl\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .xl\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .xl\:focus\:border-current:focus {
    border-color: currentColor
  }

  .xl\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .xl\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .xl\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .xl\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .xl\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .xl\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .xl\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .xl\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .xl\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .xl\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .xl\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .xl\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .xl\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .xl\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .xl\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .xl\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .xl\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .xl\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .xl\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .xl\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .xl\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .xl\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .xl\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .xl\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .xl\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .xl\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .xl\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .xl\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .xl\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .xl\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .xl\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .xl\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .xl\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .xl\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .xl\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .xl\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .xl\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .xl\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .xl\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .xl\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .xl\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .xl\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .xl\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .xl\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .xl\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .xl\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .xl\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .xl\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .xl\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .xl\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .xl\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .xl\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .xl\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .xl\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .xl\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .xl\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .xl\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .xl\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .xl\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .xl\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .xl\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .xl\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .xl\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .xl\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .xl\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .xl\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .xl\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .xl\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .xl\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .xl\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .xl\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .xl\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .xl\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .xl\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .xl\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .xl\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .xl\:border-opacity-0 {
    --border-opacity: 0
  }

  .xl\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .xl\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .xl\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .xl\:border-opacity-100 {
    --border-opacity: 1
  }

  .xl\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .xl\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .xl\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .xl\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .xl\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .xl\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .xl\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .xl\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .xl\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .xl\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .xl\:rounded-none {
    border-radius: 0
  }

  .xl\:rounded-sm {
    border-radius: 0.125rem
  }

  .xl\:rounded {
    border-radius: 0.25rem
  }

  .xl\:rounded-md {
    border-radius: 0.375rem
  }

  .xl\:rounded-lg {
    border-radius: 0.5rem
  }

  .xl\:rounded-xl {
    border-radius: 0.75rem
  }

  .xl\:rounded-2xl {
    border-radius: 1rem
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem
  }

  .xl\:rounded-full {
    border-radius: 9999px
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .xl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .xl\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .xl\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .xl\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .xl\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .xl\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .xl\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .xl\:border-solid {
    border-style: solid
  }

  .xl\:border-dashed {
    border-style: dashed
  }

  .xl\:border-dotted {
    border-style: dotted
  }

  .xl\:border-double {
    border-style: double
  }

  .xl\:border-none {
    border-style: none
  }

  .xl\:border-0 {
    border-width: 0
  }

  .xl\:border-2 {
    border-width: 2px
  }

  .xl\:border-4 {
    border-width: 4px
  }

  .xl\:border-8 {
    border-width: 8px
  }

  .xl\:border {
    border-width: 1px
  }

  .xl\:border-t-0 {
    border-top-width: 0
  }

  .xl\:border-r-0 {
    border-right-width: 0
  }

  .xl\:border-b-0 {
    border-bottom-width: 0
  }

  .xl\:border-l-0 {
    border-left-width: 0
  }

  .xl\:border-t-2 {
    border-top-width: 2px
  }

  .xl\:border-r-2 {
    border-right-width: 2px
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px
  }

  .xl\:border-l-2 {
    border-left-width: 2px
  }

  .xl\:border-t-4 {
    border-top-width: 4px
  }

  .xl\:border-r-4 {
    border-right-width: 4px
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px
  }

  .xl\:border-l-4 {
    border-left-width: 4px
  }

  .xl\:border-t-8 {
    border-top-width: 8px
  }

  .xl\:border-r-8 {
    border-right-width: 8px
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px
  }

  .xl\:border-l-8 {
    border-left-width: 8px
  }

  .xl\:border-t {
    border-top-width: 1px
  }

  .xl\:border-r {
    border-right-width: 1px
  }

  .xl\:border-b {
    border-bottom-width: 1px
  }

  .xl\:border-l {
    border-left-width: 1px
  }

  .xl\:box-border {
    box-sizing: border-box
  }

  .xl\:box-content {
    box-sizing: content-box
  }

  .xl\:cursor-auto {
    cursor: auto
  }

  .xl\:cursor-default {
    cursor: default
  }

  .xl\:cursor-pointer {
    cursor: pointer
  }

  .xl\:cursor-wait {
    cursor: wait
  }

  .xl\:cursor-text {
    cursor: text
  }

  .xl\:cursor-move {
    cursor: move
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed
  }

  .xl\:block {
    display: block
  }

  .xl\:inline-block {
    display: inline-block
  }

  .xl\:inline {
    display: inline
  }

  .xl\:flex {
    display: flex
  }

  .xl\:inline-flex {
    display: inline-flex
  }

  .xl\:table {
    display: table
  }

  .xl\:table-caption {
    display: table-caption
  }

  .xl\:table-cell {
    display: table-cell
  }

  .xl\:table-column {
    display: table-column
  }

  .xl\:table-column-group {
    display: table-column-group
  }

  .xl\:table-footer-group {
    display: table-footer-group
  }

  .xl\:table-header-group {
    display: table-header-group
  }

  .xl\:table-row-group {
    display: table-row-group
  }

  .xl\:table-row {
    display: table-row
  }

  .xl\:flow-root {
    display: flow-root
  }

  .xl\:grid {
    display: grid
  }

  .xl\:inline-grid {
    display: inline-grid
  }

  .xl\:contents {
    display: contents
  }

  .xl\:hidden {
    display: none
  }

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .xl\:flex-col {
    flex-direction: column
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .xl\:flex-wrap {
    flex-wrap: wrap
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .xl\:place-items-auto {
    place-items: auto
  }

  .xl\:place-items-start {
    place-items: start
  }

  .xl\:place-items-end {
    place-items: end
  }

  .xl\:place-items-center {
    place-items: center
  }

  .xl\:place-items-stretch {
    place-items: stretch
  }

  .xl\:place-content-center {
    place-content: center
  }

  .xl\:place-content-start {
    place-content: start
  }

  .xl\:place-content-end {
    place-content: end
  }

  .xl\:place-content-between {
    place-content: space-between
  }

  .xl\:place-content-around {
    place-content: space-around
  }

  .xl\:place-content-evenly {
    place-content: space-evenly
  }

  .xl\:place-content-stretch {
    place-content: stretch
  }

  .xl\:place-self-auto {
    place-self: auto
  }

  .xl\:place-self-start {
    place-self: start
  }

  .xl\:place-self-end {
    place-self: end
  }

  .xl\:place-self-center {
    place-self: center
  }

  .xl\:place-self-stretch {
    place-self: stretch
  }

  .xl\:items-start {
    align-items: flex-start
  }

  .xl\:items-end {
    align-items: flex-end
  }

  .xl\:items-center {
    align-items: center
  }

  .xl\:items-baseline {
    align-items: baseline
  }

  .xl\:items-stretch {
    align-items: stretch
  }

  .xl\:content-center {
    align-content: center
  }

  .xl\:content-start {
    align-content: flex-start
  }

  .xl\:content-end {
    align-content: flex-end
  }

  .xl\:content-between {
    align-content: space-between
  }

  .xl\:content-around {
    align-content: space-around
  }

  .xl\:content-evenly {
    align-content: space-evenly
  }

  .xl\:self-auto {
    align-self: auto
  }

  .xl\:self-start {
    align-self: flex-start
  }

  .xl\:self-end {
    align-self: flex-end
  }

  .xl\:self-center {
    align-self: center
  }

  .xl\:self-stretch {
    align-self: stretch
  }

  .xl\:justify-items-auto {
    justify-items: auto
  }

  .xl\:justify-items-start {
    justify-items: start
  }

  .xl\:justify-items-end {
    justify-items: end
  }

  .xl\:justify-items-center {
    justify-items: center
  }

  .xl\:justify-items-stretch {
    justify-items: stretch
  }

  .xl\:justify-start {
    justify-content: flex-start
  }

  .xl\:justify-end {
    justify-content: flex-end
  }

  .xl\:justify-center {
    justify-content: center
  }

  .xl\:justify-between {
    justify-content: space-between
  }

  .xl\:justify-around {
    justify-content: space-around
  }

  .xl\:justify-evenly {
    justify-content: space-evenly
  }

  .xl\:justify-self-auto {
    justify-self: auto
  }

  .xl\:justify-self-start {
    justify-self: start
  }

  .xl\:justify-self-end {
    justify-self: end
  }

  .xl\:justify-self-center {
    justify-self: center
  }

  .xl\:justify-self-stretch {
    justify-self: stretch
  }

  .xl\:flex-1 {
    flex: 1 1 0%
  }

  .xl\:flex-auto {
    flex: 1 1 auto
  }

  .xl\:flex-initial {
    flex: 0 1 auto
  }

  .xl\:flex-none {
    flex: none
  }

  .xl\:flex-grow-0 {
    flex-grow: 0
  }

  .xl\:flex-grow {
    flex-grow: 1
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0
  }

  .xl\:flex-shrink {
    flex-shrink: 1
  }

  .xl\:order-1 {
    order: 1
  }

  .xl\:order-2 {
    order: 2
  }

  .xl\:order-3 {
    order: 3
  }

  .xl\:order-4 {
    order: 4
  }

  .xl\:order-5 {
    order: 5
  }

  .xl\:order-6 {
    order: 6
  }

  .xl\:order-7 {
    order: 7
  }

  .xl\:order-8 {
    order: 8
  }

  .xl\:order-9 {
    order: 9
  }

  .xl\:order-10 {
    order: 10
  }

  .xl\:order-11 {
    order: 11
  }

  .xl\:order-12 {
    order: 12
  }

  .xl\:order-first {
    order: -9999
  }

  .xl\:order-last {
    order: 9999
  }

  .xl\:order-none {
    order: 0
  }

  .xl\:float-right {
    float: right
  }

  .xl\:float-left {
    float: left
  }

  .xl\:float-none {
    float: none
  }

  .xl\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .xl\:clear-left {
    clear: left
  }

  .xl\:clear-right {
    clear: right
  }

  .xl\:clear-both {
    clear: both
  }

  .xl\:clear-none {
    clear: none
  }

  .xl\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .xl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .xl\:font-hairline {
    font-weight: 100
  }

  .xl\:font-thin {
    font-weight: 200
  }

  .xl\:font-light {
    font-weight: 300
  }

  .xl\:font-normal {
    font-weight: 400
  }

  .xl\:font-medium {
    font-weight: 500
  }

  .xl\:font-semibold {
    font-weight: 600
  }

  .xl\:font-bold {
    font-weight: 700
  }

  .xl\:font-extrabold {
    font-weight: 800
  }

  .xl\:font-black {
    font-weight: 900
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900
  }

  .xl\:h-0 {
    height: 0
  }

  .xl\:h-1 {
    height: 0.25rem
  }

  .xl\:h-2 {
    height: 0.5rem
  }

  .xl\:h-3 {
    height: 0.75rem
  }

  .xl\:h-4 {
    height: 1rem
  }

  .xl\:h-5 {
    height: 1.25rem
  }

  .xl\:h-6 {
    height: 1.5rem
  }

  .xl\:h-8 {
    height: 2rem
  }

  .xl\:h-10 {
    height: 2.5rem
  }

  .xl\:h-12 {
    height: 3rem
  }

  .xl\:h-16 {
    height: 4rem
  }

  .xl\:h-20 {
    height: 5rem
  }

  .xl\:h-24 {
    height: 6rem
  }

  .xl\:h-32 {
    height: 8rem
  }

  .xl\:h-40 {
    height: 10rem
  }

  .xl\:h-48 {
    height: 12rem
  }

  .xl\:h-56 {
    height: 14rem
  }

  .xl\:h-64 {
    height: 16rem
  }

  .xl\:h-auto {
    height: auto
  }

  .xl\:h-px {
    height: 1px
  }

  .xl\:h-full {
    height: 100%
  }

  .xl\:h-screen {
    height: 100vh
  }

  .xl\:text-xs {
    font-size: 0.75rem
  }

  .xl\:text-sm {
    font-size: 0.875rem
  }

  .xl\:text-base {
    font-size: 1rem
  }

  .xl\:text-lg {
    font-size: 1.125rem
  }

  .xl\:text-xl {
    font-size: 1.25rem
  }

  .xl\:text-2xl {
    font-size: 1.5rem
  }

  .xl\:text-3xl {
    font-size: 1.875rem
  }

  .xl\:text-4xl {
    font-size: 2.25rem
  }

  .xl\:text-5xl {
    font-size: 3rem
  }

  .xl\:text-6xl {
    font-size: 4rem
  }

  .xl\:leading-3 {
    line-height: .75rem
  }

  .xl\:leading-4 {
    line-height: 1rem
  }

  .xl\:leading-5 {
    line-height: 1.25rem
  }

  .xl\:leading-6 {
    line-height: 1.5rem
  }

  .xl\:leading-7 {
    line-height: 1.75rem
  }

  .xl\:leading-8 {
    line-height: 2rem
  }

  .xl\:leading-9 {
    line-height: 2.25rem
  }

  .xl\:leading-10 {
    line-height: 2.5rem
  }

  .xl\:leading-none {
    line-height: 1
  }

  .xl\:leading-tight {
    line-height: 1.25
  }

  .xl\:leading-snug {
    line-height: 1.375
  }

  .xl\:leading-normal {
    line-height: 1.5
  }

  .xl\:leading-relaxed {
    line-height: 1.625
  }

  .xl\:leading-loose {
    line-height: 2
  }

  .xl\:list-inside {
    list-style-position: inside
  }

  .xl\:list-outside {
    list-style-position: outside
  }

  .xl\:list-none {
    list-style-type: none
  }

  .xl\:list-disc {
    list-style-type: disc
  }

  .xl\:list-decimal {
    list-style-type: decimal
  }

  .xl\:m-0 {
    margin: 0
  }

  .xl\:m-1 {
    margin: 0.25rem
  }

  .xl\:m-2 {
    margin: 0.5rem
  }

  .xl\:m-3 {
    margin: 0.75rem
  }

  .xl\:m-4 {
    margin: 1rem
  }

  .xl\:m-5 {
    margin: 1.25rem
  }

  .xl\:m-6 {
    margin: 1.5rem
  }

  .xl\:m-8 {
    margin: 2rem
  }

  .xl\:m-10 {
    margin: 2.5rem
  }

  .xl\:m-12 {
    margin: 3rem
  }

  .xl\:m-16 {
    margin: 4rem
  }

  .xl\:m-20 {
    margin: 5rem
  }

  .xl\:m-24 {
    margin: 6rem
  }

  .xl\:m-32 {
    margin: 8rem
  }

  .xl\:m-40 {
    margin: 10rem
  }

  .xl\:m-48 {
    margin: 12rem
  }

  .xl\:m-56 {
    margin: 14rem
  }

  .xl\:m-64 {
    margin: 16rem
  }

  .xl\:m-auto {
    margin: auto
  }

  .xl\:m-px {
    margin: 1px
  }

  .xl\:-m-1 {
    margin: -0.25rem
  }

  .xl\:-m-2 {
    margin: -0.5rem
  }

  .xl\:-m-3 {
    margin: -0.75rem
  }

  .xl\:-m-4 {
    margin: -1rem
  }

  .xl\:-m-5 {
    margin: -1.25rem
  }

  .xl\:-m-6 {
    margin: -1.5rem
  }

  .xl\:-m-8 {
    margin: -2rem
  }

  .xl\:-m-10 {
    margin: -2.5rem
  }

  .xl\:-m-12 {
    margin: -3rem
  }

  .xl\:-m-16 {
    margin: -4rem
  }

  .xl\:-m-20 {
    margin: -5rem
  }

  .xl\:-m-24 {
    margin: -6rem
  }

  .xl\:-m-32 {
    margin: -8rem
  }

  .xl\:-m-40 {
    margin: -10rem
  }

  .xl\:-m-48 {
    margin: -12rem
  }

  .xl\:-m-56 {
    margin: -14rem
  }

  .xl\:-m-64 {
    margin: -16rem
  }

  .xl\:-m-px {
    margin: -1px
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .xl\:mt-0 {
    margin-top: 0
  }

  .xl\:mr-0 {
    margin-right: 0
  }

  .xl\:mb-0 {
    margin-bottom: 0
  }

  .xl\:ml-0 {
    margin-left: 0
  }

  .xl\:mt-1 {
    margin-top: 0.25rem
  }

  .xl\:mr-1 {
    margin-right: 0.25rem
  }

  .xl\:mb-1 {
    margin-bottom: 0.25rem
  }

  .xl\:ml-1 {
    margin-left: 0.25rem
  }

  .xl\:mt-2 {
    margin-top: 0.5rem
  }

  .xl\:mr-2 {
    margin-right: 0.5rem
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem
  }

  .xl\:ml-2 {
    margin-left: 0.5rem
  }

  .xl\:mt-3 {
    margin-top: 0.75rem
  }

  .xl\:mr-3 {
    margin-right: 0.75rem
  }

  .xl\:mb-3 {
    margin-bottom: 0.75rem
  }

  .xl\:ml-3 {
    margin-left: 0.75rem
  }

  .xl\:mt-4 {
    margin-top: 1rem
  }

  .xl\:mr-4 {
    margin-right: 1rem
  }

  .xl\:mb-4 {
    margin-bottom: 1rem
  }

  .xl\:ml-4 {
    margin-left: 1rem
  }

  .xl\:mt-5 {
    margin-top: 1.25rem
  }

  .xl\:mr-5 {
    margin-right: 1.25rem
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem
  }

  .xl\:ml-5 {
    margin-left: 1.25rem
  }

  .xl\:mt-6 {
    margin-top: 1.5rem
  }

  .xl\:mr-6 {
    margin-right: 1.5rem
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem
  }

  .xl\:ml-6 {
    margin-left: 1.5rem
  }

  .xl\:mt-8 {
    margin-top: 2rem
  }

  .xl\:mr-8 {
    margin-right: 2rem
  }

  .xl\:mb-8 {
    margin-bottom: 2rem
  }

  .xl\:ml-8 {
    margin-left: 2rem
  }

  .xl\:mt-10 {
    margin-top: 2.5rem
  }

  .xl\:mr-10 {
    margin-right: 2.5rem
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem
  }

  .xl\:ml-10 {
    margin-left: 2.5rem
  }

  .xl\:mt-12 {
    margin-top: 3rem
  }

  .xl\:mr-12 {
    margin-right: 3rem
  }

  .xl\:mb-12 {
    margin-bottom: 3rem
  }

  .xl\:ml-12 {
    margin-left: 3rem
  }

  .xl\:mt-16 {
    margin-top: 4rem
  }

  .xl\:mr-16 {
    margin-right: 4rem
  }

  .xl\:mb-16 {
    margin-bottom: 4rem
  }

  .xl\:ml-16 {
    margin-left: 4rem
  }

  .xl\:mt-20 {
    margin-top: 5rem
  }

  .xl\:mr-20 {
    margin-right: 5rem
  }

  .xl\:mb-20 {
    margin-bottom: 5rem
  }

  .xl\:ml-20 {
    margin-left: 5rem
  }

  .xl\:mt-24 {
    margin-top: 6rem
  }

  .xl\:mr-24 {
    margin-right: 6rem
  }

  .xl\:mb-24 {
    margin-bottom: 6rem
  }

  .xl\:ml-24 {
    margin-left: 6rem
  }

  .xl\:mt-32 {
    margin-top: 8rem
  }

  .xl\:mr-32 {
    margin-right: 8rem
  }

  .xl\:mb-32 {
    margin-bottom: 8rem
  }

  .xl\:ml-32 {
    margin-left: 8rem
  }

  .xl\:mt-40 {
    margin-top: 10rem
  }

  .xl\:mr-40 {
    margin-right: 10rem
  }

  .xl\:mb-40 {
    margin-bottom: 10rem
  }

  .xl\:ml-40 {
    margin-left: 10rem
  }

  .xl\:mt-48 {
    margin-top: 12rem
  }

  .xl\:mr-48 {
    margin-right: 12rem
  }

  .xl\:mb-48 {
    margin-bottom: 12rem
  }

  .xl\:ml-48 {
    margin-left: 12rem
  }

  .xl\:mt-56 {
    margin-top: 14rem
  }

  .xl\:mr-56 {
    margin-right: 14rem
  }

  .xl\:mb-56 {
    margin-bottom: 14rem
  }

  .xl\:ml-56 {
    margin-left: 14rem
  }

  .xl\:mt-64 {
    margin-top: 16rem
  }

  .xl\:mr-64 {
    margin-right: 16rem
  }

  .xl\:mb-64 {
    margin-bottom: 16rem
  }

  .xl\:ml-64 {
    margin-left: 16rem
  }

  .xl\:mt-auto {
    margin-top: auto
  }

  .xl\:mr-auto {
    margin-right: auto
  }

  .xl\:mb-auto {
    margin-bottom: auto
  }

  .xl\:ml-auto {
    margin-left: auto
  }

  .xl\:mt-px {
    margin-top: 1px
  }

  .xl\:mr-px {
    margin-right: 1px
  }

  .xl\:mb-px {
    margin-bottom: 1px
  }

  .xl\:ml-px {
    margin-left: 1px
  }

  .xl\:-mt-1 {
    margin-top: -0.25rem
  }

  .xl\:-mr-1 {
    margin-right: -0.25rem
  }

  .xl\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .xl\:-ml-1 {
    margin-left: -0.25rem
  }

  .xl\:-mt-2 {
    margin-top: -0.5rem
  }

  .xl\:-mr-2 {
    margin-right: -0.5rem
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .xl\:-ml-2 {
    margin-left: -0.5rem
  }

  .xl\:-mt-3 {
    margin-top: -0.75rem
  }

  .xl\:-mr-3 {
    margin-right: -0.75rem
  }

  .xl\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .xl\:-ml-3 {
    margin-left: -0.75rem
  }

  .xl\:-mt-4 {
    margin-top: -1rem
  }

  .xl\:-mr-4 {
    margin-right: -1rem
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem
  }

  .xl\:-ml-4 {
    margin-left: -1rem
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem
  }

  .xl\:-mt-8 {
    margin-top: -2rem
  }

  .xl\:-mr-8 {
    margin-right: -2rem
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem
  }

  .xl\:-ml-8 {
    margin-left: -2rem
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem
  }

  .xl\:-mt-12 {
    margin-top: -3rem
  }

  .xl\:-mr-12 {
    margin-right: -3rem
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem
  }

  .xl\:-ml-12 {
    margin-left: -3rem
  }

  .xl\:-mt-16 {
    margin-top: -4rem
  }

  .xl\:-mr-16 {
    margin-right: -4rem
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem
  }

  .xl\:-ml-16 {
    margin-left: -4rem
  }

  .xl\:-mt-20 {
    margin-top: -5rem
  }

  .xl\:-mr-20 {
    margin-right: -5rem
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem
  }

  .xl\:-ml-20 {
    margin-left: -5rem
  }

  .xl\:-mt-24 {
    margin-top: -6rem
  }

  .xl\:-mr-24 {
    margin-right: -6rem
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem
  }

  .xl\:-ml-24 {
    margin-left: -6rem
  }

  .xl\:-mt-32 {
    margin-top: -8rem
  }

  .xl\:-mr-32 {
    margin-right: -8rem
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem
  }

  .xl\:-ml-32 {
    margin-left: -8rem
  }

  .xl\:-mt-40 {
    margin-top: -10rem
  }

  .xl\:-mr-40 {
    margin-right: -10rem
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem
  }

  .xl\:-ml-40 {
    margin-left: -10rem
  }

  .xl\:-mt-48 {
    margin-top: -12rem
  }

  .xl\:-mr-48 {
    margin-right: -12rem
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem
  }

  .xl\:-ml-48 {
    margin-left: -12rem
  }

  .xl\:-mt-56 {
    margin-top: -14rem
  }

  .xl\:-mr-56 {
    margin-right: -14rem
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem
  }

  .xl\:-ml-56 {
    margin-left: -14rem
  }

  .xl\:-mt-64 {
    margin-top: -16rem
  }

  .xl\:-mr-64 {
    margin-right: -16rem
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem
  }

  .xl\:-ml-64 {
    margin-left: -16rem
  }

  .xl\:-mt-px {
    margin-top: -1px
  }

  .xl\:-mr-px {
    margin-right: -1px
  }

  .xl\:-mb-px {
    margin-bottom: -1px
  }

  .xl\:-ml-px {
    margin-left: -1px
  }

  .xl\:max-h-full {
    max-height: 100%
  }

  .xl\:max-h-screen {
    max-height: 100vh
  }

  .xl\:max-w-none {
    max-width: none
  }

  .xl\:max-w-xs {
    max-width: 20rem
  }

  .xl\:max-w-sm {
    max-width: 24rem
  }

  .xl\:max-w-md {
    max-width: 28rem
  }

  .xl\:max-w-lg {
    max-width: 32rem
  }

  .xl\:max-w-xl {
    max-width: 36rem
  }

  .xl\:max-w-2xl {
    max-width: 42rem
  }

  .xl\:max-w-3xl {
    max-width: 48rem
  }

  .xl\:max-w-4xl {
    max-width: 56rem
  }

  .xl\:max-w-5xl {
    max-width: 64rem
  }

  .xl\:max-w-6xl {
    max-width: 72rem
  }

  .xl\:max-w-full {
    max-width: 100%
  }

  .xl\:max-w-screen-sm {
    max-width: 640px
  }

  .xl\:max-w-screen-md {
    max-width: 768px
  }

  .xl\:max-w-screen-lg {
    max-width: 1024px
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px
  }

  .xl\:min-h-0 {
    min-height: 0
  }

  .xl\:min-h-full {
    min-height: 100%
  }

  .xl\:min-h-screen {
    min-height: 100vh
  }

  .xl\:min-w-0 {
    min-width: 0
  }

  .xl\:min-w-full {
    min-width: 100%
  }

  .xl\:object-contain {
    object-fit: contain
  }

  .xl\:object-cover {
    object-fit: cover
  }

  .xl\:object-fill {
    object-fit: fill
  }

  .xl\:object-none {
    object-fit: none
  }

  .xl\:object-scale-down {
    object-fit: scale-down
  }

  .xl\:object-bottom {
    object-position: bottom
  }

  .xl\:object-center {
    object-position: center
  }

  .xl\:object-left {
    object-position: left
  }

  .xl\:object-left-bottom {
    object-position: left bottom
  }

  .xl\:object-left-top {
    object-position: left top
  }

  .xl\:object-right {
    object-position: right
  }

  .xl\:object-right-bottom {
    object-position: right bottom
  }

  .xl\:object-right-top {
    object-position: right top
  }

  .xl\:object-top {
    object-position: top
  }

  .xl\:opacity-0 {
    opacity: 0
  }

  .xl\:opacity-25 {
    opacity: 0.25
  }

  .xl\:opacity-50 {
    opacity: 0.5
  }

  .xl\:opacity-75 {
    opacity: 0.75
  }

  .xl\:opacity-100 {
    opacity: 1
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1
  }

  .xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:overflow-auto {
    overflow: auto
  }

  .xl\:overflow-hidden {
    overflow: hidden
  }

  .xl\:overflow-visible {
    overflow: visible
  }

  .xl\:overflow-scroll {
    overflow: scroll
  }

  .xl\:overflow-x-auto {
    overflow-x: auto
  }

  .xl\:overflow-y-auto {
    overflow-y: auto
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden
  }

  .xl\:overflow-x-visible {
    overflow-x: visible
  }

  .xl\:overflow-y-visible {
    overflow-y: visible
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .xl\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .xl\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .xl\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .xl\:p-0 {
    padding: 0
  }

  .xl\:p-1 {
    padding: 0.25rem
  }

  .xl\:p-2 {
    padding: 0.5rem
  }

  .xl\:p-3 {
    padding: 0.75rem
  }

  .xl\:p-4 {
    padding: 1rem
  }

  .xl\:p-5 {
    padding: 1.25rem
  }

  .xl\:p-6 {
    padding: 1.5rem
  }

  .xl\:p-8 {
    padding: 2rem
  }

  .xl\:p-10 {
    padding: 2.5rem
  }

  .xl\:p-12 {
    padding: 3rem
  }

  .xl\:p-16 {
    padding: 4rem
  }

  .xl\:p-20 {
    padding: 5rem
  }

  .xl\:p-24 {
    padding: 6rem
  }

  .xl\:p-32 {
    padding: 8rem
  }

  .xl\:p-40 {
    padding: 10rem
  }

  .xl\:p-48 {
    padding: 12rem
  }

  .xl\:p-56 {
    padding: 14rem
  }

  .xl\:p-64 {
    padding: 16rem
  }

  .xl\:p-px {
    padding: 1px
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .xl\:pt-0 {
    padding-top: 0
  }

  .xl\:pr-0 {
    padding-right: 0
  }

  .xl\:pb-0 {
    padding-bottom: 0
  }

  .xl\:pl-0 {
    padding-left: 0
  }

  .xl\:pt-1 {
    padding-top: 0.25rem
  }

  .xl\:pr-1 {
    padding-right: 0.25rem
  }

  .xl\:pb-1 {
    padding-bottom: 0.25rem
  }

  .xl\:pl-1 {
    padding-left: 0.25rem
  }

  .xl\:pt-2 {
    padding-top: 0.5rem
  }

  .xl\:pr-2 {
    padding-right: 0.5rem
  }

  .xl\:pb-2 {
    padding-bottom: 0.5rem
  }

  .xl\:pl-2 {
    padding-left: 0.5rem
  }

  .xl\:pt-3 {
    padding-top: 0.75rem
  }

  .xl\:pr-3 {
    padding-right: 0.75rem
  }

  .xl\:pb-3 {
    padding-bottom: 0.75rem
  }

  .xl\:pl-3 {
    padding-left: 0.75rem
  }

  .xl\:pt-4 {
    padding-top: 1rem
  }

  .xl\:pr-4 {
    padding-right: 1rem
  }

  .xl\:pb-4 {
    padding-bottom: 1rem
  }

  .xl\:pl-4 {
    padding-left: 1rem
  }

  .xl\:pt-5 {
    padding-top: 1.25rem
  }

  .xl\:pr-5 {
    padding-right: 1.25rem
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem
  }

  .xl\:pl-5 {
    padding-left: 1.25rem
  }

  .xl\:pt-6 {
    padding-top: 1.5rem
  }

  .xl\:pr-6 {
    padding-right: 1.5rem
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem
  }

  .xl\:pl-6 {
    padding-left: 1.5rem
  }

  .xl\:pt-8 {
    padding-top: 2rem
  }

  .xl\:pr-8 {
    padding-right: 2rem
  }

  .xl\:pb-8 {
    padding-bottom: 2rem
  }

  .xl\:pl-8 {
    padding-left: 2rem
  }

  .xl\:pt-10 {
    padding-top: 2.5rem
  }

  .xl\:pr-10 {
    padding-right: 2.5rem
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem
  }

  .xl\:pl-10 {
    padding-left: 2.5rem
  }

  .xl\:pt-12 {
    padding-top: 3rem
  }

  .xl\:pr-12 {
    padding-right: 3rem
  }

  .xl\:pb-12 {
    padding-bottom: 3rem
  }

  .xl\:pl-12 {
    padding-left: 3rem
  }

  .xl\:pt-16 {
    padding-top: 4rem
  }

  .xl\:pr-16 {
    padding-right: 4rem
  }

  .xl\:pb-16 {
    padding-bottom: 4rem
  }

  .xl\:pl-16 {
    padding-left: 4rem
  }

  .xl\:pt-20 {
    padding-top: 5rem
  }

  .xl\:pr-20 {
    padding-right: 5rem
  }

  .xl\:pb-20 {
    padding-bottom: 5rem
  }

  .xl\:pl-20 {
    padding-left: 5rem
  }

  .xl\:pt-24 {
    padding-top: 6rem
  }

  .xl\:pr-24 {
    padding-right: 6rem
  }

  .xl\:pb-24 {
    padding-bottom: 6rem
  }

  .xl\:pl-24 {
    padding-left: 6rem
  }

  .xl\:pt-32 {
    padding-top: 8rem
  }

  .xl\:pr-32 {
    padding-right: 8rem
  }

  .xl\:pb-32 {
    padding-bottom: 8rem
  }

  .xl\:pl-32 {
    padding-left: 8rem
  }

  .xl\:pt-40 {
    padding-top: 10rem
  }

  .xl\:pr-40 {
    padding-right: 10rem
  }

  .xl\:pb-40 {
    padding-bottom: 10rem
  }

  .xl\:pl-40 {
    padding-left: 10rem
  }

  .xl\:pt-48 {
    padding-top: 12rem
  }

  .xl\:pr-48 {
    padding-right: 12rem
  }

  .xl\:pb-48 {
    padding-bottom: 12rem
  }

  .xl\:pl-48 {
    padding-left: 12rem
  }

  .xl\:pt-56 {
    padding-top: 14rem
  }

  .xl\:pr-56 {
    padding-right: 14rem
  }

  .xl\:pb-56 {
    padding-bottom: 14rem
  }

  .xl\:pl-56 {
    padding-left: 14rem
  }

  .xl\:pt-64 {
    padding-top: 16rem
  }

  .xl\:pr-64 {
    padding-right: 16rem
  }

  .xl\:pb-64 {
    padding-bottom: 16rem
  }

  .xl\:pl-64 {
    padding-left: 16rem
  }

  .xl\:pt-px {
    padding-top: 1px
  }

  .xl\:pr-px {
    padding-right: 1px
  }

  .xl\:pb-px {
    padding-bottom: 1px
  }

  .xl\:pl-px {
    padding-left: 1px
  }

  .xl\:placeholder-transparent:-ms-input-placeholder {
    color: transparent
  }

  .xl\:placeholder-transparent::-ms-input-placeholder {
    color: transparent
  }

  .xl\:placeholder-transparent::placeholder {
    color: transparent
  }

  .xl\:placeholder-current:-ms-input-placeholder {
    color: currentColor
  }

  .xl\:placeholder-current::-ms-input-placeholder {
    color: currentColor
  }

  .xl\:placeholder-current::placeholder {
    color: currentColor
  }

  .xl\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent
  }

  .xl\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .xl\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor
  }

  .xl\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor
  }

  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .xl\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .xl\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .xl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .xl\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .xl\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .xl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .xl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .xl\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .xl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .xl\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .xl\:pointer-events-none {
    pointer-events: none
  }

  .xl\:pointer-events-auto {
    pointer-events: auto
  }

  .xl\:static {
    position: static
  }

  .xl\:fixed {
    position: fixed
  }

  .xl\:absolute {
    position: absolute
  }

  .xl\:relative {
    position: relative
  }

  .xl\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .xl\:inset-x-0 {
    right: 0;
    left: 0
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .xl\:inset-x-auto {
    right: auto;
    left: auto
  }

  .xl\:top-0 {
    top: 0
  }

  .xl\:right-0 {
    right: 0
  }

  .xl\:bottom-0 {
    bottom: 0
  }

  .xl\:left-0 {
    left: 0
  }

  .xl\:top-auto {
    top: auto
  }

  .xl\:right-auto {
    right: auto
  }

  .xl\:bottom-auto {
    bottom: auto
  }

  .xl\:left-auto {
    left: auto
  }

  .xl\:resize-none {
    resize: none
  }

  .xl\:resize-y {
    resize: vertical
  }

  .xl\:resize-x {
    resize: horizontal
  }

  .xl\:resize {
    resize: both
  }

  .xl\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:shadow-none {
    box-shadow: none
  }

  .xl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .xl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .xl\:fill-current {
    fill: currentColor
  }

  .xl\:stroke-current {
    stroke: currentColor
  }

  .xl\:stroke-0 {
    stroke-width: 0
  }

  .xl\:stroke-1 {
    stroke-width: 1
  }

  .xl\:stroke-2 {
    stroke-width: 2
  }

  .xl\:table-auto {
    table-layout: auto
  }

  .xl\:table-fixed {
    table-layout: fixed
  }

  .xl\:text-left {
    text-align: left
  }

  .xl\:text-center {
    text-align: center
  }

  .xl\:text-right {
    text-align: right
  }

  .xl\:text-justify {
    text-align: justify
  }

  .xl\:text-transparent {
    color: transparent
  }

  .xl\:text-current {
    color: currentColor
  }

  .xl\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .xl\:text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .xl\:text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .xl\:text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .xl\:text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .xl\:text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .xl\:text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .xl\:text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .xl\:text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .xl\:text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .xl\:text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .xl\:text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .xl\:text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .xl\:text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .xl\:text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .xl\:text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .xl\:text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .xl\:text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .xl\:text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .xl\:text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .xl\:text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .xl\:text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .xl\:text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .xl\:text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .xl\:text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .xl\:text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .xl\:text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .xl\:text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .xl\:text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .xl\:text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .xl\:text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .xl\:text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .xl\:text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .xl\:text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .xl\:text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .xl\:text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .xl\:text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .xl\:text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .xl\:text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .xl\:text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .xl\:text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .xl\:text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .xl\:text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .xl\:text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .xl\:text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .xl\:text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .xl\:text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .xl\:text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .xl\:text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .xl\:text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .xl\:text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .xl\:text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .xl\:text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .xl\:text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .xl\:text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .xl\:text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .xl\:text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .xl\:text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .xl\:text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .xl\:text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .xl\:text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .xl\:text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .xl\:text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .xl\:text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .xl\:text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .xl\:text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .xl\:text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .xl\:text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .xl\:text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .xl\:text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .xl\:text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .xl\:text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .xl\:text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .xl\:text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .xl\:text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .xl\:text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .xl\:text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .xl\:text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .xl\:text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .xl\:text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .xl\:text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .xl\:text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .xl\:text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .xl\:text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .xl\:text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .xl\:text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .xl\:text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .xl\:text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .xl\:text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .xl\:text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .xl\:hover\:text-transparent:hover {
    color: transparent
  }

  .xl\:hover\:text-current:hover {
    color: currentColor
  }

  .xl\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .xl\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .xl\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .xl\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .xl\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .xl\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .xl\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .xl\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .xl\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .xl\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .xl\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .xl\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .xl\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .xl\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .xl\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .xl\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .xl\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .xl\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .xl\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .xl\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .xl\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .xl\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .xl\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .xl\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .xl\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .xl\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .xl\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .xl\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .xl\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .xl\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .xl\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .xl\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .xl\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .xl\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .xl\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .xl\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .xl\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .xl\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .xl\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .xl\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .xl\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .xl\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .xl\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .xl\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .xl\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .xl\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .xl\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .xl\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .xl\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .xl\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .xl\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .xl\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .xl\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .xl\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .xl\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .xl\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .xl\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .xl\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .xl\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .xl\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .xl\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .xl\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .xl\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .xl\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .xl\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .xl\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .xl\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .xl\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .xl\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .xl\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .xl\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .xl\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .xl\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .xl\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .xl\:focus\:text-transparent:focus {
    color: transparent
  }

  .xl\:focus\:text-current:focus {
    color: currentColor
  }

  .xl\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .xl\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .xl\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .xl\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .xl\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .xl\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .xl\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .xl\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .xl\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .xl\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .xl\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .xl\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .xl\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .xl\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .xl\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .xl\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .xl\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .xl\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .xl\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .xl\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .xl\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .xl\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .xl\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .xl\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .xl\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .xl\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .xl\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .xl\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .xl\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .xl\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .xl\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .xl\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .xl\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .xl\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .xl\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .xl\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .xl\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .xl\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .xl\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .xl\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .xl\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .xl\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .xl\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .xl\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .xl\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .xl\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .xl\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .xl\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .xl\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .xl\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .xl\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .xl\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .xl\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .xl\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .xl\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .xl\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .xl\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .xl\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .xl\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .xl\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .xl\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .xl\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .xl\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .xl\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .xl\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .xl\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .xl\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .xl\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .xl\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .xl\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .xl\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .xl\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .xl\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .xl\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .xl\:text-opacity-0 {
    --text-opacity: 0
  }

  .xl\:text-opacity-25 {
    --text-opacity: 0.25
  }

  .xl\:text-opacity-50 {
    --text-opacity: 0.5
  }

  .xl\:text-opacity-75 {
    --text-opacity: 0.75
  }

  .xl\:text-opacity-100 {
    --text-opacity: 1
  }

  .xl\:hover\:text-opacity-0:hover {
    --text-opacity: 0
  }

  .xl\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25
  }

  .xl\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5
  }

  .xl\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75
  }

  .xl\:hover\:text-opacity-100:hover {
    --text-opacity: 1
  }

  .xl\:focus\:text-opacity-0:focus {
    --text-opacity: 0
  }

  .xl\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25
  }

  .xl\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5
  }

  .xl\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75
  }

  .xl\:focus\:text-opacity-100:focus {
    --text-opacity: 1
  }

  .xl\:italic {
    font-style: italic
  }

  .xl\:not-italic {
    font-style: normal
  }

  .xl\:uppercase {
    text-transform: uppercase
  }

  .xl\:lowercase {
    text-transform: lowercase
  }

  .xl\:capitalize {
    text-transform: capitalize
  }

  .xl\:normal-case {
    text-transform: none
  }

  .xl\:underline {
    text-decoration: underline
  }

  .xl\:line-through {
    text-decoration: line-through
  }

  .xl\:no-underline {
    text-decoration: none
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .xl\:normal-nums {
    font-variant-numeric: normal
  }

  .xl\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .xl\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .xl\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .xl\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .xl\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .xl\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .xl\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .xl\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em
  }

  .xl\:tracking-normal {
    letter-spacing: 0
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em
  }

  .xl\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .xl\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .xl\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .xl\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .xl\:align-baseline {
    vertical-align: baseline
  }

  .xl\:align-top {
    vertical-align: top
  }

  .xl\:align-middle {
    vertical-align: middle
  }

  .xl\:align-bottom {
    vertical-align: bottom
  }

  .xl\:align-text-top {
    vertical-align: text-top
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom
  }

  .xl\:visible {
    visibility: visible
  }

  .xl\:invisible {
    visibility: hidden
  }

  .xl\:whitespace-normal {
    white-space: normal
  }

  .xl\:whitespace-no-wrap {
    white-space: nowrap
  }

  .xl\:whitespace-pre {
    white-space: pre
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .xl\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .xl\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .xl\:break-all {
    word-break: break-all
  }

  .xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .xl\:w-0 {
    width: 0
  }

  .xl\:w-1 {
    width: 0.25rem
  }

  .xl\:w-2 {
    width: 0.5rem
  }

  .xl\:w-3 {
    width: 0.75rem
  }

  .xl\:w-4 {
    width: 1rem
  }

  .xl\:w-5 {
    width: 1.25rem
  }

  .xl\:w-6 {
    width: 1.5rem
  }

  .xl\:w-8 {
    width: 2rem
  }

  .xl\:w-10 {
    width: 2.5rem
  }

  .xl\:w-12 {
    width: 3rem
  }

  .xl\:w-16 {
    width: 4rem
  }

  .xl\:w-20 {
    width: 5rem
  }

  .xl\:w-24 {
    width: 6rem
  }

  .xl\:w-32 {
    width: 8rem
  }

  .xl\:w-40 {
    width: 10rem
  }

  .xl\:w-48 {
    width: 12rem
  }

  .xl\:w-56 {
    width: 14rem
  }

  .xl\:w-64 {
    width: 16rem
  }

  .xl\:w-auto {
    width: auto
  }

  .xl\:w-px {
    width: 1px
  }

  .xl\:w-1\/2 {
    width: 50%
  }

  .xl\:w-1\/3 {
    width: 33.333333%
  }

  .xl\:w-2\/3 {
    width: 66.666667%
  }

  .xl\:w-1\/4 {
    width: 25%
  }

  .xl\:w-2\/4 {
    width: 50%
  }

  .xl\:w-3\/4 {
    width: 75%
  }

  .xl\:w-1\/5 {
    width: 20%
  }

  .xl\:w-2\/5 {
    width: 40%
  }

  .xl\:w-3\/5 {
    width: 60%
  }

  .xl\:w-4\/5 {
    width: 80%
  }

  .xl\:w-1\/6 {
    width: 16.666667%
  }

  .xl\:w-2\/6 {
    width: 33.333333%
  }

  .xl\:w-3\/6 {
    width: 50%
  }

  .xl\:w-4\/6 {
    width: 66.666667%
  }

  .xl\:w-5\/6 {
    width: 83.333333%
  }

  .xl\:w-1\/12 {
    width: 8.333333%
  }

  .xl\:w-2\/12 {
    width: 16.666667%
  }

  .xl\:w-3\/12 {
    width: 25%
  }

  .xl\:w-4\/12 {
    width: 33.333333%
  }

  .xl\:w-5\/12 {
    width: 41.666667%
  }

  .xl\:w-6\/12 {
    width: 50%
  }

  .xl\:w-7\/12 {
    width: 58.333333%
  }

  .xl\:w-8\/12 {
    width: 66.666667%
  }

  .xl\:w-9\/12 {
    width: 75%
  }

  .xl\:w-10\/12 {
    width: 83.333333%
  }

  .xl\:w-11\/12 {
    width: 91.666667%
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:w-screen {
    width: 100vw
  }

  .xl\:z-0 {
    z-index: 0
  }

  .xl\:z-10 {
    z-index: 10
  }

  .xl\:z-20 {
    z-index: 20
  }

  .xl\:z-30 {
    z-index: 30
  }

  .xl\:z-40 {
    z-index: 40
  }

  .xl\:z-50 {
    z-index: 50
  }

  .xl\:z-auto {
    z-index: auto
  }

  .xl\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .xl\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .xl\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .xl\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .xl\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .xl\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .xl\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .xl\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .xl\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .xl\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .xl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .xl\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .xl\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .xl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .xl\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .xl\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .xl\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .xl\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .xl\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .xl\:col-gap-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .xl\:col-gap-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .xl\:col-gap-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .xl\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .xl\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .xl\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .xl\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .xl\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .xl\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .xl\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .xl\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .xl\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .xl\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .xl\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .xl\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .xl\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .xl\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .xl\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .xl\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0
  }

  .xl\:gap-x-1 {
    grid-column-gap: 0.25rem;
    column-gap: 0.25rem
  }

  .xl\:gap-x-2 {
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem
  }

  .xl\:gap-x-3 {
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem
  }

  .xl\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
  }

  .xl\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .xl\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
  }

  .xl\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
  }

  .xl\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
  }

  .xl\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
  }

  .xl\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
  }

  .xl\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
  }

  .xl\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem
  }

  .xl\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem
  }

  .xl\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem
  }

  .xl\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem
  }

  .xl\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem
  }

  .xl\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem
  }

  .xl\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px
  }

  .xl\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .xl\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .xl\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .xl\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .xl\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .xl\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .xl\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .xl\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .xl\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .xl\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .xl\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .xl\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .xl\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .xl\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .xl\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .xl\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .xl\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .xl\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .xl\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .xl\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .xl\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .xl\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .xl\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .xl\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .xl\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .xl\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .xl\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .xl\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .xl\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .xl\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .xl\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .xl\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .xl\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .xl\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .xl\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .xl\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .xl\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .xl\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .xl\:grid-cols-none {
    grid-template-columns: none
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .xl\:col-auto {
    grid-column: auto
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .xl\:col-span-full {
    grid-column: 1 / -1
  }

  .xl\:col-start-1 {
    grid-column-start: 1
  }

  .xl\:col-start-2 {
    grid-column-start: 2
  }

  .xl\:col-start-3 {
    grid-column-start: 3
  }

  .xl\:col-start-4 {
    grid-column-start: 4
  }

  .xl\:col-start-5 {
    grid-column-start: 5
  }

  .xl\:col-start-6 {
    grid-column-start: 6
  }

  .xl\:col-start-7 {
    grid-column-start: 7
  }

  .xl\:col-start-8 {
    grid-column-start: 8
  }

  .xl\:col-start-9 {
    grid-column-start: 9
  }

  .xl\:col-start-10 {
    grid-column-start: 10
  }

  .xl\:col-start-11 {
    grid-column-start: 11
  }

  .xl\:col-start-12 {
    grid-column-start: 12
  }

  .xl\:col-start-13 {
    grid-column-start: 13
  }

  .xl\:col-start-auto {
    grid-column-start: auto
  }

  .xl\:col-end-1 {
    grid-column-end: 1
  }

  .xl\:col-end-2 {
    grid-column-end: 2
  }

  .xl\:col-end-3 {
    grid-column-end: 3
  }

  .xl\:col-end-4 {
    grid-column-end: 4
  }

  .xl\:col-end-5 {
    grid-column-end: 5
  }

  .xl\:col-end-6 {
    grid-column-end: 6
  }

  .xl\:col-end-7 {
    grid-column-end: 7
  }

  .xl\:col-end-8 {
    grid-column-end: 8
  }

  .xl\:col-end-9 {
    grid-column-end: 9
  }

  .xl\:col-end-10 {
    grid-column-end: 10
  }

  .xl\:col-end-11 {
    grid-column-end: 11
  }

  .xl\:col-end-12 {
    grid-column-end: 12
  }

  .xl\:col-end-13 {
    grid-column-end: 13
  }

  .xl\:col-end-auto {
    grid-column-end: auto
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-rows-none {
    grid-template-rows: none
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .xl\:row-auto {
    grid-row: auto
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .xl\:row-span-full {
    grid-row: 1 / -1
  }

  .xl\:row-start-1 {
    grid-row-start: 1
  }

  .xl\:row-start-2 {
    grid-row-start: 2
  }

  .xl\:row-start-3 {
    grid-row-start: 3
  }

  .xl\:row-start-4 {
    grid-row-start: 4
  }

  .xl\:row-start-5 {
    grid-row-start: 5
  }

  .xl\:row-start-6 {
    grid-row-start: 6
  }

  .xl\:row-start-7 {
    grid-row-start: 7
  }

  .xl\:row-start-auto {
    grid-row-start: auto
  }

  .xl\:row-end-1 {
    grid-row-end: 1
  }

  .xl\:row-end-2 {
    grid-row-end: 2
  }

  .xl\:row-end-3 {
    grid-row-end: 3
  }

  .xl\:row-end-4 {
    grid-row-end: 4
  }

  .xl\:row-end-5 {
    grid-row-end: 5
  }

  .xl\:row-end-6 {
    grid-row-end: 6
  }

  .xl\:row-end-7 {
    grid-row-end: 7
  }

  .xl\:row-end-auto {
    grid-row-end: auto
  }

  .xl\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .xl\:transform-none {
    transform: none
  }

  .xl\:origin-center {
    transform-origin: center
  }

  .xl\:origin-top {
    transform-origin: top
  }

  .xl\:origin-top-right {
    transform-origin: top right
  }

  .xl\:origin-right {
    transform-origin: right
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right
  }

  .xl\:origin-bottom {
    transform-origin: bottom
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left
  }

  .xl\:origin-left {
    transform-origin: left
  }

  .xl\:origin-top-left {
    transform-origin: top left
  }

  .xl\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:scale-x-0 {
    --transform-scale-x: 0
  }

  .xl\:scale-x-50 {
    --transform-scale-x: .5
  }

  .xl\:scale-x-75 {
    --transform-scale-x: .75
  }

  .xl\:scale-x-90 {
    --transform-scale-x: .9
  }

  .xl\:scale-x-95 {
    --transform-scale-x: .95
  }

  .xl\:scale-x-100 {
    --transform-scale-x: 1
  }

  .xl\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .xl\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .xl\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .xl\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .xl\:scale-y-0 {
    --transform-scale-y: 0
  }

  .xl\:scale-y-50 {
    --transform-scale-y: .5
  }

  .xl\:scale-y-75 {
    --transform-scale-y: .75
  }

  .xl\:scale-y-90 {
    --transform-scale-y: .9
  }

  .xl\:scale-y-95 {
    --transform-scale-y: .95
  }

  .xl\:scale-y-100 {
    --transform-scale-y: 1
  }

  .xl\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .xl\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .xl\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .xl\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .xl\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .xl\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .xl\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .xl\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .xl\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .xl\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .xl\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .xl\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .xl\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .xl\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .xl\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .xl\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .xl\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .xl\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .xl\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .xl\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .xl\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .xl\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .xl\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .xl\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .xl\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .xl\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .xl\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .xl\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .xl\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .xl\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .xl\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .xl\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .xl\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .xl\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .xl\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .xl\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .xl\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .xl\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .xl\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .xl\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .xl\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .xl\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .xl\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .xl\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .xl\:rotate-0 {
    --transform-rotate: 0
  }

  .xl\:rotate-1 {
    --transform-rotate: 1deg
  }

  .xl\:rotate-2 {
    --transform-rotate: 2deg
  }

  .xl\:rotate-3 {
    --transform-rotate: 3deg
  }

  .xl\:rotate-6 {
    --transform-rotate: 6deg
  }

  .xl\:rotate-12 {
    --transform-rotate: 12deg
  }

  .xl\:rotate-45 {
    --transform-rotate: 45deg
  }

  .xl\:rotate-90 {
    --transform-rotate: 90deg
  }

  .xl\:rotate-180 {
    --transform-rotate: 180deg
  }

  .xl\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .xl\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .xl\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .xl\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .xl\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .xl\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .xl\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .xl\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .xl\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .xl\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .xl\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .xl\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .xl\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .xl\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .xl\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .xl\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .xl\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .xl\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .xl\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .xl\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .xl\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .xl\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .xl\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .xl\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .xl\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .xl\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .xl\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .xl\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .xl\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .xl\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .xl\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .xl\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .xl\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .xl\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .xl\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .xl\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .xl\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .xl\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .xl\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .xl\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .xl\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .xl\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .xl\:translate-x-0 {
    --transform-translate-x: 0
  }

  .xl\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .xl\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .xl\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .xl\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .xl\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .xl\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .xl\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .xl\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .xl\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .xl\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .xl\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .xl\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .xl\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .xl\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .xl\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .xl\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .xl\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .xl\:translate-x-px {
    --transform-translate-x: 1px
  }

  .xl\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .xl\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .xl\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .xl\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .xl\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .xl\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .xl\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .xl\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .xl\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .xl\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .xl\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .xl\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .xl\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .xl\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .xl\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .xl\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .xl\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .xl\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .xl\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .xl\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .xl\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .xl\:translate-x-full {
    --transform-translate-x: 100%
  }

  .xl\:translate-y-0 {
    --transform-translate-y: 0
  }

  .xl\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .xl\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .xl\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .xl\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .xl\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .xl\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .xl\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .xl\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .xl\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .xl\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .xl\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .xl\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .xl\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .xl\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .xl\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .xl\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .xl\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .xl\:translate-y-px {
    --transform-translate-y: 1px
  }

  .xl\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .xl\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .xl\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .xl\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .xl\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .xl\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .xl\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .xl\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .xl\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .xl\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .xl\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .xl\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .xl\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .xl\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .xl\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .xl\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .xl\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .xl\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .xl\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .xl\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .xl\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .xl\:translate-y-full {
    --transform-translate-y: 100%
  }

  .xl\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .xl\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .xl\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .xl\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .xl\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .xl\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .xl\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .xl\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .xl\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .xl\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .xl\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .xl\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .xl\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .xl\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .xl\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .xl\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .xl\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .xl\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .xl\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .xl\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .xl\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .xl\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .xl\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .xl\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .xl\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .xl\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .xl\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .xl\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .xl\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .xl\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .xl\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .xl\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .xl\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .xl\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .xl\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .xl\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .xl\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .xl\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .xl\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .xl\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .xl\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .xl\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .xl\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .xl\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .xl\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .xl\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .xl\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .xl\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .xl\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .xl\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .xl\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .xl\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .xl\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .xl\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .xl\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .xl\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .xl\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .xl\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .xl\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .xl\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .xl\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .xl\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .xl\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .xl\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .xl\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .xl\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .xl\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .xl\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .xl\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .xl\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .xl\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .xl\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .xl\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .xl\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .xl\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .xl\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .xl\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .xl\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .xl\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .xl\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .xl\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .xl\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .xl\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .xl\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .xl\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .xl\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .xl\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .xl\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .xl\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .xl\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .xl\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .xl\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .xl\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .xl\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .xl\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .xl\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .xl\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .xl\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .xl\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .xl\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .xl\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .xl\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .xl\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .xl\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .xl\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .xl\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .xl\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .xl\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .xl\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .xl\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .xl\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .xl\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .xl\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .xl\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .xl\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .xl\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .xl\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .xl\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .xl\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .xl\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .xl\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .xl\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .xl\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .xl\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .xl\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .xl\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .xl\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .xl\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .xl\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .xl\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .xl\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .xl\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .xl\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .xl\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .xl\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .xl\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .xl\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .xl\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .xl\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .xl\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .xl\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .xl\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .xl\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .xl\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .xl\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .xl\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .xl\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .xl\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .xl\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .xl\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .xl\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .xl\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .xl\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .xl\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .xl\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .xl\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .xl\:skew-x-0 {
    --transform-skew-x: 0
  }

  .xl\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .xl\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .xl\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .xl\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .xl\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .xl\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .xl\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .xl\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .xl\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .xl\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .xl\:skew-y-0 {
    --transform-skew-y: 0
  }

  .xl\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .xl\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .xl\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .xl\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .xl\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .xl\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .xl\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .xl\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .xl\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .xl\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .xl\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .xl\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .xl\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .xl\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .xl\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .xl\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .xl\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .xl\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .xl\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .xl\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .xl\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .xl\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .xl\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .xl\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .xl\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .xl\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .xl\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .xl\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .xl\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .xl\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .xl\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .xl\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .xl\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .xl\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .xl\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .xl\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .xl\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .xl\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .xl\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .xl\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .xl\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .xl\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .xl\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .xl\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .xl\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .xl\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .xl\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .xl\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .xl\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .xl\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .xl\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .xl\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .xl\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .xl\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .xl\:transition-none {
    transition-property: none
  }

  .xl\:transition-all {
    transition-property: all
  }

  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .xl\:transition-opacity {
    transition-property: opacity
  }

  .xl\:transition-shadow {
    transition-property: box-shadow
  }

  .xl\:transition-transform {
    transition-property: transform
  }

  .xl\:ease-linear {
    transition-timing-function: linear
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .xl\:duration-75 {
    transition-duration: 75ms
  }

  .xl\:duration-100 {
    transition-duration: 100ms
  }

  .xl\:duration-150 {
    transition-duration: 150ms
  }

  .xl\:duration-200 {
    transition-duration: 200ms
  }

  .xl\:duration-300 {
    transition-duration: 300ms
  }

  .xl\:duration-500 {
    transition-duration: 500ms
  }

  .xl\:duration-700 {
    transition-duration: 700ms
  }

  .xl\:duration-1000 {
    transition-duration: 1000ms
  }

  .xl\:delay-75 {
    transition-delay: 75ms
  }

  .xl\:delay-100 {
    transition-delay: 100ms
  }

  .xl\:delay-150 {
    transition-delay: 150ms
  }

  .xl\:delay-200 {
    transition-delay: 200ms
  }

  .xl\:delay-300 {
    transition-delay: 300ms
  }

  .xl\:delay-500 {
    transition-delay: 500ms
  }

  .xl\:delay-700 {
    transition-delay: 700ms
  }

  .xl\:delay-1000 {
    transition-delay: 1000ms
  }

  .xl\:animate-none {
    animation: none
  }

  .xl\:animate-spin {
    animation: spin 1s linear infinite
  }

  .xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .xl\:animate-bounce {
    animation: bounce 1s infinite
  }
}
