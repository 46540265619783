.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px
    }
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%
  }

  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px
    }
  }

  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px
    }
  }
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%
  }

  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px
    }
  }

  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px
    }
  }
}

@media (min-width: 1280px) {
  .xl\:container {
    width: 100%
  }

  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px
    }
  }

  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px
    }
  }
}
